// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/images/home-page.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".home{width:100vw;height:calc(100vh - 140px)}.home .welcome{color:#343432;font-size:24px}.home .portal{color:#343432;font-size:32px;font-weight:700}.home .service-picker{margin-top:20px}.box{padding:60px;gap:40px;position:absolute;width:433px;left:calc(50% - 217px);top:calc(50% - 142.5px);background:linear-gradient(0deg,#fff,hsla(0,0%,100%,0)),hsla(0,0%,100%,.75);-webkit-backdrop-filter:blur(4px);backdrop-filter:blur(4px);border-radius:32px}.background-image{width:100vw;height:calc(100vh - 80px);background-repeat:no-repeat;background-position:50%;background-size:cover;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:fixed;left:0;top:80px;z-index:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partouPrimaryPurple": "#582354",
	"partouPrimaryPurpleSixty": "#9b7b98",
	"partouPrimaryPurpleFourty": "#bca7bb",
	"partouPrimarySalmon": "#ef575d",
	"partouPrimarySalmonSixty": "#f59a9e",
	"partouPrimarySalmonFourty": "#f9bcbe",
	"partouPrimaryOrange": "#f6a500",
	"partouPrimaryOrangeSixty": "#fac966",
	"partouPrimaryOrangeFourty": "#fbdb99",
	"partouPrimaryWarmGrey": "#f2e9e2",
	"partouPrimaryWarmGreySixty": "#f7f2ee",
	"partouPrimaryWarmGreyFourty": "#faf6f3",
	"partouPrimaryWhite": "#fff",
	"partouPrimaryBackdropWhite": "#f9f9f9",
	"partouPrimaryBlue": "#2782e3",
	"partouSecondaryBordeaux": "#b61544",
	"partouSecondaryBordeauxSixty": "#d3738f",
	"partouSecondaryBordeauxFourty": "#e2a1b4",
	"partouSecondaryPeach": "#fa8870",
	"partouSecondaryPeachSixty": "#fcb8a9",
	"partouSecondaryPeachFourty": "#fdcfc6",
	"partouSecondaryGreen": "#91c37d",
	"partouSecondaryGreenSixty": "#bddbb1",
	"partouSecondaryGreenFourty": "#d3e7cb",
	"partouSecondaryBlue": "#73a4d8",
	"partouSecondaryBlueSixty": "#abc8e8",
	"partouSecondaryBlueFourty": "#c7dbef",
	"partouSecondaryBrightGreen": "#3bc059",
	"partouPrimaryGradient": "linear-gradient(45deg,#a02846 13.5%,#e8565d 87%)",
	"partouSecondaryGradient": "linear-gradient(61.41deg,#572556 7.85%,#eb5e5e 64.25%,#f7a70a 91.8%)",
	"partouPrimaryBlack": "#1d1d1b",
	"partouPrimaryBlackNinty": "#343432",
	"partouPrimaryBlackEighty": "#4a4a49",
	"partouPrimaryBlackSeventy": "#616160",
	"partouPrimaryBlackSixty": "#777776",
	"partouPrimaryBlackFifty": "#8e8e8d",
	"partouPrimaryBlackFourty": "#a5a5a4",
	"partouPrimaryBlackThirty": "#bcbcbb"
};
export default ___CSS_LOADER_EXPORT___;
