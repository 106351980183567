<template>
  <div ref="waiting-list-proposition-table" id="waiting-list-proposition-table">
    <div v-if="showHeader">
      <v-row>
        <v-col cols="5">
          <h2>{{ $t('waitingListAllocationAnalysis.waitingListPropositionTable.startDate') }}</h2>
        </v-col>
        <v-col cols="5">
          <h2>{{ $t('waitingListAllocationAnalysis.waitingListPropositionTable.impact') }}</h2>
        </v-col>
        <v-col cols="2">
          <h2>{{ $t('waitingListAllocationAnalysis.waitingListPropositionTable.availableDays') }}</h2>
        </v-col>
      </v-row>
    </div>
    <div v-for="(waitingListProposition, index) in waitingListPropositions" :key="index" class="pl-2 pr-2">
      <v-divider />
      <v-row class="align-center table-row" justify="center"
        :class="{'non-bookable': !waitingListProposition.canBook, 'best-start-date': isBestWaitingListProposition(waitingListProposition)}">
        <v-col cols="5">
          <p>{{waitingListProposition.startDate | date}}</p>
        </v-col>
        <v-col cols="5">
          <p>{{waitingListProposition.impact}}</p>
        </v-col>
        <v-col cols="2" class="pb-0 pt-0">
          <v-row class="align-center table-row" justify="center">
            <v-col cols="10">
              <div
                v-for="(days, serviceVarietyName, svIndex) in waitingListProposition.availableDaysOfWeekPerServiceVariety"
                :key="svIndex">
                <v-row>
                  <p class="ma-0">{{serviceVarietyName}}: &zwnj;</p>
                  <DaysCoverage :daysOfWeek="days" />
                </v-row>
              </div>
            </v-col>

            <v-col cols="1">
              <PartouFloatingCircleButton v-if="index == (waitingListPropositions.length - 1) && !!waitingListProposition.analysisResultId" ref="analysis-button" class="analysis-button" iconName="$vuetify.icons.partouAnalysis" @click="goToAvailabilityAnalysisPage(waitingListProposition.analysisResultId)" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { WaitingListProposition } from '@/models/WaitingListAnalysisResult'
import { ROUTES } from '@/router/routes'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListAllocationCard from './WaitingListAllocationCard.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'

@Component({
  components: { PartouCard, WaitingListAllocationCard, DaysCoverage, PartouFloatingCircleButton }
})
export default class WaitingListPropositionTable extends Vue {
  @Prop({ required: true })
  waitingListPropositions!: WaitingListProposition[]

  @Prop({ required: false })
  bestWaitingListPropositions!: WaitingListProposition

  @Prop({ required: false, default: true })
  showHeader!: boolean

  isBestWaitingListProposition (waitingListProposition: WaitingListProposition): boolean {
    if (!this.bestWaitingListPropositions) {
      return false
    }

    return this.bestWaitingListPropositions.startDate === waitingListProposition.startDate
  }

  goToAvailabilityAnalysisPage (analysisResultId: string): void {
    const routeData = this.$router.resolve({ name: ROUTES.serviceAnalysisPreview, params: { analysisResultId } })
    window.open(routeData.href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

#waiting-list-proposition-table {
  .non-bookable,
  .non-bookable p,
  .non-bookable span {
    color: rgba(0, 0, 0, 0.322);
  }

  .best-start-date p {
    font-weight: bold;
  }

  .table-row p {
    margin-bottom: 0px;
  }

  .analysis-button {
    width: 35px;
    height: 35px;
    button span svg {
      path {
        fill: $partou-primary-black-ninety !important;
        color: $partou-primary-black-ninety !important;
      }
    }
  }
}
</style>
