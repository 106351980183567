<template>
  <div class="partou-button">
    <v-btn fab class="white pr-3 pl-3" v-on="$listeners" v-bind="$attrs" :disabled="isDisabled">
      {{text}} <v-icon :size="iconSize" >{{iconName}}</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouFloatingCircleButton extends Vue {
  @Prop({ required: false, default: '' })
  text? : string

  @Prop({ required: false, default: '' })
  iconName? : string

  @Prop({ required: false, default: '18px' })
  iconSize?: string

  @Prop({ required: false, default: false })
  isDisabled!: boolean
}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
@import '@/styles/variables/variables.scss';

  .partou-button {
    background: transparent !important;
    background-color: transparent !important;
    z-index: 2;

    button {
      text-transform: unset !important;
      color: $partou-primary-white !important;
      border-radius: 50% !important;
      box-shadow: $partou-elevation-large-subtle;

      font-family: $body-font-family !important;
      font-weight: 500;
      font-style: normal;
      font-size: 0.875rem !important;

      width: 100%;
      height: 100%;

      span, i, .v-icon {
        color: $partou-primary-white;
      }
    }

    button:disabled {
      background: $partou-primary-black-fifty !important;
      color: $partou-primary-white !important;
    }

    &.white {
      button {
        background: $partou-primary-white !important;
        color: $partou-primary-black !important;
        font-family: $body-font-family !important;
      }
    }
  }
</style>
