import { Container } from 'inversify'

const container = new Container()
export default container

export const SERVICE_IDENTIFIERS = {
  Axios: Symbol('Axios'),
  IExampleService: Symbol('IExampleService'),
  IServiceService: Symbol('IServiceService'),
  IServiceVarietyOpeningService: Symbol('IServiceVarietyOpeningService'),
  IReservationService: Symbol('IReservationService'),
  IBookingService: Symbol('IBookingService'),
  IAuthService: Symbol('IAuthService'),
  IUploadService: Symbol('IUploadService'),
  IGroupPincodeService: Symbol('IGroupPincodeService'),
  IServiceMediaService: Symbol('IServiceMediaService'),
  IServicePricingService: Symbol('IServicePricingService'),
  IWaitingListService: Symbol('IWaitingListService'),
  IServiceFeatureService: Symbol('IServiceFeatureService'),
  IPropositionService: Symbol('IPropositionService'),
  IOfferService: Symbol('IOfferService'),
  ISchoolService: Symbol('ISchoolService'),
  IPostalCodeService: Symbol('IPostalCodeService'),
  ISubscriptionService: Symbol('ISubscriptionService'),
  ISendEmailService: Symbol('ISendEmailService'),
  IConfigurationService: Symbol('IConfigurationService'),
  IWaitingListPropositionService: Symbol('IWaitingListPropositionService'),
  IServiceSettingsService: Symbol('IServiceSettingsService'),
  IProductService: Symbol('IProductService')
}
