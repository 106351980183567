<template>
  <div id="app">
  <PartouCard :hide-actions="true" class="mt-2 full-width">
    <template slot="card-body">
      <h1>{{$t("forbidden.title")}}</h1>
      <h3>{{$t("forbidden.message")}}</h3>
    </template>
  </PartouCard>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'

@Component({
  components: { PartouCard }
})
export default class ForbiddenPage extends Vue {}
</script>
