<template>
  <div class="service-product-table-page">
    <div>
      <div class="d-flex pt-4 pb-4 mb-6">
        <div class="category-picker">
          <span>{{$t('services.products.table.filters.categoryLabel')}}</span>
          <PartouAutocomplete
            class="rounded-xl picker-auto-complete"
            v-model="tableOptions.filters.category"
            item-text="name"
            item-value="value"
            label="name"
            :items="categoryOptions"
            :appendIconString="'$vuetify.icons.partouChevronDown'"
            :clearable="true"
            :placeholder="$t('services.products.table.filters.categoryPlaceholder')"
            :noDataText="$t('services.products.table.filters.noData')"
            @input="onSelectedCategoryChanged" />
        </div>
        <div v-if="displayServiceVarietyFilter" class="service-variety-picker ml-4">
          <span>{{$t('services.products.table.filters.serviceVarietyLabel')}}</span>
          <PartouAutocomplete
            class="rounded-xl picker-auto-complete"
            v-model="tableOptions.filters.serviceVariety"
            item-text="name"
            item-value="name"
            label="name"
            :items="serviceVarietyOptions"
            :appendIconString="'$vuetify.icons.partouChevronDown'"
            :clearable="true"
            :placeholder="$t('services.products.table.filters.serviceVarietyPlaceholder')"
            :noDataText="$t('services.products.table.filters.noData')"
            @input="onSelectedCategoryChanged" />
        </div>
        <div class="ml-4">
          <p class="search-label mb-0">{{ $t('services.products.table.filters.searchLabel') }}</p>
          <PartouTextField class="search" v-model="tableOptions.filters.global" size="small" :label="$t('services.products.table.filters.searchPlaceholder')" :iconName="'$vuetify.icons.partouSearch'" :iconPosition="'right'" @input="onSearchChanged"></PartouTextField>
        </div>
      </div>
    </div>
    <h2 class="red-header">{{$t('services.products.tableTitle')}}</h2>
    <PartouDataTable
      class="service-product-table"
      ref="partou-data-table"
      :headers="headers"
      :items="items"
      :totalCount="totalCount"
      :showExpand="true"
      :tableOptions="tableOptions"
      :isLoading="isLoading"

      @expandedChanged="onExpandedChanged"
      @onOptionsChanged="onTableOptionsChangedAsync">
      <!-- custom column templates -->
      <template #item.name="{ item }"> <!-- eslint-disable-line -->
        {{ item.product.name }}
        <v-icon v-if="item.isUsedInChildBenefitCalculator">mdi-calculator</v-icon>
      </template>
      <template #item.category="{ item }"> <!-- eslint-disable-line -->
        {{ $t(`services.products.table.category.${item.product.category}`)  }}
      </template>
      <template #item.serviceVariety="{ item }"> <!-- eslint-disable-line -->
        {{ item.product.serviceVariety }}
      </template>
      <template #item.hourlyRate="{ item }"> <!-- eslint-disable-line -->
        {{ getHourlyRate(item) }}
      </template>
      <template #item.validFrom="{ item }"> <!-- eslint-disable-line -->
        {{ item.validFrom | formatDate }}
      </template>

      <!-- expand template -->
      <template v-slot:expanded-item="{ headers }">
        <td class="no-border pa-0" :colspan="headers.length">
          <div v-if="isLoading" class="d-flex justify-center flex-row">
            <v-progress-circular indeterminate></v-progress-circular>
          </div>
          <div v-else>
            <span class="expanded-divider"></span>
            <ServiceProductTableExpanded
              :serviceId="serviceId"
              :serviceName="serviceName"
              :serviceIsPublished="serviceIsPublished"
              :serviceIsBookable="serviceIsBookable"
              :serviceIsPublishedForChildBenefitCalculator="serviceIsPublishedForChildBenefitCalculator"
              :serviceProduct="expandedItem"
              :activatedProducts="activatedProducts"
              @onExpandedChanged="onExpandedChanged"
              @onProductDeactivated="onProductDeactivated"/>
          </div>
        </td>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, VModel, Watch } from 'vue-property-decorator'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServiceProductTableExpanded from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableExpanded.vue'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import BaseEntityTable from '@/pages/_base/BaseEntityTable'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import ServiceProductTableOptions from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableOptions'
import PartouExclamationMarkVue from '@/components/PartouComponents/Icons/PartouExclamationMark.vue'
import { ServiceProduct, ProductPriceAgreement } from '@/models'
import { CategoryOption } from '@/pages/ServicesPage/ServiceProduct/CategoryOptions'

@Component({
  components: { PartouCard, PartouDataTable, PartouAutocomplete, DaysCoverage, ServiceProductTableExpanded, PartouTextField, PartouExclamationMarkVue }
})
export default class ServiceProductsTable extends BaseEntityTable<ServiceProduct> {
  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  serviceName!: string

  @Prop({ required: true })
  serviceIsPublished!: boolean

  @Prop({ required: true })
  serviceIsPublishedForChildBenefitCalculator!: boolean

  @Prop({ required: true })
  serviceIsBookable!: boolean

  @Prop({ required: true })
  items!: Partial<ServiceProduct>[]

  @Prop({ required: true, default: () => { return {} } })
  expandedItem! : ServiceProduct

  @Prop({ required: true })
  categoryOptions!: CategoryOption[]

  @Prop({ required: true })
  serviceVarietyOptions!: string[]

  @Prop({ required: true, default: false })
  displayServiceVarietyFilter!: boolean

  @Prop({ required: true, default: [] })
  activatedProducts!: Partial<ServiceProduct>[]

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<ServiceProduct>

  @VModel({ required: true })
  public tableOptions!: ServiceProductTableOptions

  headers: DataTableHeader[] = [
    { text: 'services.products.table.columns.name', sortBy: 'product.name', value: 'name', width: '37%' },
    { text: 'services.products.table.columns.category', sortBy: 'product.category', value: 'category', width: '15%' },
    { text: 'services.products.table.columns.serviceVariety', sortBy: 'product.serviceVariety', value: 'serviceVariety', width: '15%', sortable: this.displayServiceVarietyFilter },
    { text: 'services.products.table.columns.hourlyRate', value: 'hourlyRate', width: '15%', sortable: false },
    { text: 'services.products.table.columns.vaildFrom', value: 'validFrom', width: '15%', sortable: true },
    { text: '', value: 'data-table-expand', width: '3%', align: 'center' }
  ]

  tableItems : Partial<ServiceProduct>[] = []

  mounted () : void {
    this.onTableOptionsChangedAsync(this.tableOptions)
  }

  @Watch('displayServiceVarietyFilter')
  ondisplayServiceVarietyFilterChanged () : void {
    this.headers = [
      { text: 'services.products.table.columns.name', sortBy: 'product.name', value: 'name', width: '37%' },
      { text: 'services.products.table.columns.category', sortBy: 'product.category', value: 'category', width: '15%' },
      { text: 'services.products.table.columns.serviceVariety', sortBy: 'product.serviceVariety', value: 'serviceVariety', width: '15%', sortable: this.displayServiceVarietyFilter },
      { text: 'services.products.table.columns.hourlyRate', value: 'hourlyRate', width: '15%', sortable: false },
      { text: 'services.products.table.columns.vaildFrom', value: 'validFrom', width: '15%', sortable: false },
      { text: '', value: 'data-table-expand', width: '3%', align: 'center' }
    ]
  }

  @Emit('onExpandedChanged')
  onExpandedChanged (items: Partial<ServiceProduct>[]) : Partial<ServiceProduct>[] {
    return items
  }

  onSelectedCategoryChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  @Emit('onProductDeactivated')
  onProductDeactivated () : void {
    this.onTableOptionsChangedAsync(this.tableOptions)
  }

  onSearchChanged () : void {
    this.partouDataTable.onFilterChanged()
  }

  getHourlyRate (serviceProduct: ServiceProduct) : string {
    const productPriceAgreement: Partial<ProductPriceAgreement> = serviceProduct.productPriceAgreement?.at(0) ?? {}
    const amountsArray: number[] = Object.values(productPriceAgreement)
      .filter((amount): amount is number => amount !== undefined && amount !== 0)

    const price = amountsArray[0] ?? 0
    return `€ ${(price / 100).toFixed(2).replace('.', ',')}`
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';
.service-product-table .v-data-table__expanded__row {
  height: unset !important;

  td:first-of-type {
    color: black !important;
    font-weight: bold;
    font-size: 16px;
  }
}
</style>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
h2 {
  color: $partou-secondary-bordeaux !important;
  margin-bottom: 16px;
}
.search {
  max-width: 300px !important;
  width: 300px !important;
  margin-top: 12px !important;
}
.search-label {
  color: $partou-primary-black-sixty !important;
}

.service-product-table {
  width: 100%;
}

.expanded-divider {
  background-color: $partou-primary-salmon-fourty;
  height: 1px;
  display: flex;
  margin-left: 16px;
  margin-right: 60px;
}

.new-status {
  font-weight: bold;
  color: $partou-primary-orange;
}

.exclamation-icon-wrapper {
  min-width: 8px;
}

.no-border {
  border: unset;
}

.category-picker,
.service-variety-picker {
  min-width: 300px;
}

.category-picker.background-grey,
.service-variety-picker.background-grey {
  .vs__selected-options {
  background: $partou-background !important;
  }
  .vs__dropdown-menu {
    background: $partou-background !important;
  }
}

.category-picker .vs__selected-options,
.service-variety-picker .vs__selected-options {
  height: 40px;

  .vs__search {
    font-weight: bold;
  }
  .vs__selected {
    display:flex;
    font-weight: bold;
    align-items: center;
    color: $partou-primary-black-ninety  !important;
  }
  .vs__search::placeholder {
    color: $partou-primary-black-fourty !important;
    font-weight: normal;
  }
}

.v-icon {
  color: $partou-primary-salmon;
}

.picker-auto-complete {
  margin-top: 6px;
}
</style>
