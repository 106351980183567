<template>
    <div class="day-table d-flex flex-column mb-2">
      <span class="mr-2">{{ serviceVarietyName }}</span>
      <span class="expanded-divider"></span>
      <div class="d-flex flex-row">
        <div>
            <div class="d-flex flex-row justify-space-between">
                <div class="d-flex mr-2 mt-2 row-column align-center">{{ $t('dayTable.placements') }}</div>
                <div ref="currentReservations" class="d-flex flex-row mt-3 align-center">
                    <template v-for="(reservation, index) in currentReservations">
                        <template>
                            <p ref="reservationDayLabel" class="mr-2 day-label" :key="index"><strong> {{reservation}} </strong></p>
                        </template>
                    </template>
                </div>
            </div>
            <div class="d-flex flex-row justify-space-between">
                <div class="d-flex mr-2 mt-2 row-column align-center">{{ $t('dayTable.waitingLists') }}</div>
                <div class="d-flex flex-row mt-3 align-center">
                    <template v-for="(waitinglist, index) in currentWaitinglistDays">
                        <template>
                            <p ref="waitingListDayLabel" class="mr-2 day-label" :key="index"><strong> {{waitinglist}} </strong></p>
                        </template>
                    </template>
                </div>
            </div>
            <div class="d-flex flex-row justify-space-between">
                <div class="d-flex mr-2 mt-2 row-column align-center">{{ $t('dayTable.OriginalWaitingLists') }}</div>
                <div class="d-flex flex-row mt-3 align-center">
                    <template v-for="(waitinglist, index) in originalWaitingLists">
                        <template>
                            <p ref="originalWaitingListDayLabel" class="mr-2 day-label" :key="index"><strong> {{waitinglist}} </strong></p>
                        </template>
                    </template>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { DayOfWeek, Reservation, ServiceVarietyName, WaitingListPlace } from '@/models'
import { getShortenedDayName } from '@/models/enums/DayOfWeek'
import WaitingListPlaceStatus from '@/models/enums/WaitingListPlaceStatus'
import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
export default class DayTable extends Vue {
    @Prop({ required: true })
    serviceVarietyName!: ServiceVarietyName

    @Prop({ required: true })
    reservations!: Reservation[]

    @Prop({ required: true })
    waitinglistPlaces!: WaitingListPlace[]

    get currentReservations () : string[] {
      const currentReservationsBasedOnFullWeek = new Array<string>(5).fill('-')
      const currentReservations = Array.from(new Set(this.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

      currentReservations.forEach(dayOfReservation => {
        const dayOfWeekIndexOfReservation = Object.keys(DayOfWeek).indexOf(dayOfReservation)
        currentReservationsBasedOnFullWeek[dayOfWeekIndexOfReservation] = this.firstToUpper(getShortenedDayName(dayOfReservation))
      })
      return currentReservationsBasedOnFullWeek
    }

    get currentWaitinglistDays () : string[] {
      const currentWaitinglistsBasedOnFullWeek = new Array<string>(5).fill('-')
      const currentWaitinglists = Array.from(new Set(this.waitinglistPlaces.filter(x => x.serviceVariety.name === this.serviceVarietyName && x.status !== WaitingListPlaceStatus.Accepted).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

      currentWaitinglists.forEach(dayOfWaitinglist => {
        const dayOfWeekIndexOfReservation = Object.keys(DayOfWeek).indexOf(dayOfWaitinglist)
        currentWaitinglistsBasedOnFullWeek[dayOfWeekIndexOfReservation] = this.firstToUpper(getShortenedDayName(dayOfWaitinglist))
      })
      return currentWaitinglistsBasedOnFullWeek
    }

    get originalWaitingLists () : string[] {
      const originalWaitingListsBasedOnFullWeek = new Array<string>(5).fill('-')
      const originalWaitingLists = Array.from(new Set(this.waitinglistPlaces.filter(x => x.serviceVariety.name === this.serviceVarietyName).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

      originalWaitingLists.forEach(dayOfWaitinglist => {
        const dayOfWeekIndexOfReservation = Object.keys(DayOfWeek).indexOf(dayOfWaitinglist)
        originalWaitingListsBasedOnFullWeek[dayOfWeekIndexOfReservation] = this.firstToUpper(getShortenedDayName(dayOfWaitinglist))
      })

      return originalWaitingListsBasedOnFullWeek
    }

    firstToUpper (dayLabel: string):string {
      return dayLabel[0].toUpperCase() + dayLabel.substring(1)
    }
}
</script>

  <style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';

    .day-table {
      width: 244px;
    }

    .fixed-width {
      width: 25px;
      display: inline-block;
      text-align: center;
    }

    .expanded-divider {
      background-color: #Ef575D;
      height: 1px;
      width:100%;
    }

    .day-row-labels {
      width: 110px;
    }

    .day-label {
      width: 24px;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 8px;
    }
    .row-column {
      font-size: 13px;
      color: $partou-primary-black-fifty;
      margin-bottom: 0px;
    }
  </style>
