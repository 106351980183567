<template>
  <PartouDialog
    ref="add-products-to-service-dialog"
    v-if="showAddProductDialog"
    :width="1200"
    :isConfirmButtonDisabled="checkDisabledConfirmButton()"
    :onNotifyAccepted="onAddBookableProductsConfirmedAsync"
    :showDialog="showAddProductDialog"
    :showLoaderOnConfirm="true"
    :confirmTranslationString="$tc('services.products.addProducts.confirmAddProducts', selectedProductsCount)"
    @canceled="onAddBookableProductsCanceled">
    <h2>{{$t('services.products.addProducts.title')}} <b>{{ serviceName }}</b></h2>

    <AddProductsToServiceTable
      :serviceId="serviceId"
      :items="items"
      :activatedProducts="activatedProducts"
      :totalCount="totalCount"
      :isLoading="isLoading"
      :selectedProductsToAdd="selectedProductsToAdd"
      :showAddProductDialog="showAddProductDialog"
      :categoryOptions="categoryOptions"
      :serviceVarietyOptions="serviceVarietyOptions"
      :displayServiceVarietyFilter="displayServiceVarietyFilter"
      @OnSelectedProductsChanged="updateCounter"
      @onTableOptionsChanged="onDialogTableOptionsChangedAsync">
    </AddProductsToServiceTable>

    <div class="button-error-container">
        <span v-if="validationError" class="error-message">{{$t('services.products.addProducts.validationError')}}</span>
      </div>
  </PartouDialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import AddProductsToServiceTable from './AddProductsToServiceTable.vue'
import { IProductService } from '@/services/ProductService/IProductService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Debounce from '@/utils/decorators/debounceDecorator'
import ServiceProductTableOptions from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableOptions'
import { ServiceProduct } from '@/models'
import { CategoryOption } from '@/pages/ServicesPage/ServiceProduct/CategoryOptions'

@Component({
  components: { AddProductsToServiceTable, PartouDialog }
})
export default class AddProductsToServiceDialog extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)

  @VModel({ required: true })
  showAddProductDialog!: boolean

  @Prop({ required: true })
  serviceId!: string

  @Prop({ required: true })
  serviceName!: string

  @Prop({ required: true, default: false })
  displayServiceVarietyFilter!: boolean

  @Prop({ required: true })
  categoryOptions!: CategoryOption[]

  @Prop({ required: true })
  serviceVarietyOptions!: string[]

  @Prop({ required: true, default: [] })
  activatedProducts!: Partial<ServiceProduct>[]

  items: Partial<ServiceProduct>[] = []
  totalCount = 0
  isLoading = false
  selectedProductsToAdd : Set<string> = new Set<string>()
  selectedProductsCount = 0
  validationError = false

  @Debounce(400)
  async onDialogTableOptionsChangedAsync (tableOptions : ServiceProductTableOptions) : Promise<void> {
    this.isLoading = true
    const result = await this.productService.getDeactivatedServiceProductsAsync(this.serviceId, tableOptions)

    this.items = result.items
    this.totalCount = result.totalCount
    this.isLoading = false
  }

  onAddBookableProductsCanceled () : void {
    this.showAddProductDialog = false
    this.selectedProductsToAdd.clear()
    this.updateCounter()
  }

  updateCounter () : void {
    this.selectedProductsCount = this.selectedProductsToAdd.size
  }

  checkDisabledConfirmButton () : boolean {
    return this.selectedProductsToAdd.size === 0
  }

  @Emit('addBookableProductsConfirmedAsync')
  async onAddBookableProductsConfirmedAsync () : Promise<void> {
    this.isLoading = true
    await this.productService.ActivateServiceProductsAsync(this.serviceId, this.selectedProductsToAdd)
    this.showAddProductDialog = false
    this.selectedProductsToAdd.clear()
    this.updateCounter()
    this.isLoading = false
  }
}
</script>
