import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { InsertServiceVarietyOpeningMutation, InsertServiceVarietyOpeningDocument, UpdateServiceVarietyOpeningMutation, UpdateServiceVarietyOpeningDocument, DeleteServiceVarietyOpeningByIdDocument, DeleteServiceVarietyOpeningByIdMutation, GetNonManualServiceVarietyPricingByServiceVarietyIdDocument, GetNonManualServiceVarietyPricingByServiceVarietyIdQuery, ServiceVarietyOpening, UpdateServiceVarietyOpeningDatesMutation, UpdateServiceVarietyOpeningDatesDocument } from '@/models'
import moment from 'moment'
import Permission from '@/models/enums/Permission'
import { IServiceVarietyOpeningService } from './IServiceVarietyOpeningService'

@injectable()
export default class ServiceVarietyOpeningService implements IServiceVarietyOpeningService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async insertManualServiceVarietyOpeningIdAsync (serviceVarietyOpening: ServiceVarietyOpening) : Promise<string> {
    const id = this.getGUID()
    await this.apollo.mutate<InsertServiceVarietyOpeningMutation>({
      mutation: InsertServiceVarietyOpeningDocument,
      variables: {
        id,
        serviceVarietyId: serviceVarietyOpening.serviceVarietyId!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        validFrom: serviceVarietyOpening.validFrom,
        validUntil: serviceVarietyOpening.validUntil,
        monday: serviceVarietyOpening.monday,
        tuesday: serviceVarietyOpening.tuesday,
        wednesday: serviceVarietyOpening.wednesday,
        thursday: serviceVarietyOpening.thursday,
        friday: serviceVarietyOpening.friday,
        saturday: serviceVarietyOpening.saturday,
        sunday: serviceVarietyOpening.sunday
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
    })

    await this.updateNonManualServiceVarietiesDatesAsync(serviceVarietyOpening.serviceVarietyId!, -1000) // eslint-disable-line @typescript-eslint/no-non-null-assertion

    return id
  }

  async updateManualServiceVarietyOpeningIdAsync (serviceVarietyOpening: ServiceVarietyOpening) : Promise<void> {
    await this.apollo.mutate<UpdateServiceVarietyOpeningMutation>({
      mutation: UpdateServiceVarietyOpeningDocument,
      variables: {
        id: serviceVarietyOpening.id!, // eslint-disable-line @typescript-eslint/no-non-null-assertion
        monday: serviceVarietyOpening.monday,
        tuesday: serviceVarietyOpening.tuesday,
        wednesday: serviceVarietyOpening.wednesday,
        thursday: serviceVarietyOpening.thursday,
        friday: serviceVarietyOpening.friday,
        saturday: serviceVarietyOpening.saturday,
        sunday: serviceVarietyOpening.sunday
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
    })
  }

  async deleteManualServiceVarietyOpeningIdAsync (serviceVarietyOpening: ServiceVarietyOpening) : Promise<void> {
    await this.apollo.mutate<DeleteServiceVarietyOpeningByIdMutation>({
      mutation: DeleteServiceVarietyOpeningByIdDocument,
      variables: {
        id: serviceVarietyOpening.id! // eslint-disable-line @typescript-eslint/no-non-null-assertion
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
    })

    await this.updateNonManualServiceVarietiesDatesAsync(serviceVarietyOpening.serviceVarietyId!, 1000) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  async updateNonManualServiceVarietiesDatesAsync (serviceVarietyId: string, yearsToAdd: number) {
    const result = await this.apollo.query<GetNonManualServiceVarietyPricingByServiceVarietyIdQuery>({
      query: GetNonManualServiceVarietyPricingByServiceVarietyIdDocument,
      variables: {
        serviceVarietyId
      },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
    })

    const serviceVarietyOpenings = result.data.service_variety_opening as ServiceVarietyOpening[]
    for (const serviceVarietyOpening of serviceVarietyOpenings) {
      const newValidFrom = moment.utc(serviceVarietyOpening.validFrom).add(yearsToAdd, 'years')
      const newValidUntil = serviceVarietyOpening.validUntil ? moment.utc(serviceVarietyOpening.validUntil).add(yearsToAdd, 'years') : null

      await this.apollo.mutate<UpdateServiceVarietyOpeningDatesMutation>({
        mutation: UpdateServiceVarietyOpeningDatesDocument,
        variables: {
          id: serviceVarietyOpening.id,
          validFrom: newValidFrom.toDate(),
          validUntil: newValidUntil?.toDate()
        },
        context: { headers: { 'X-Hasura-Role': Permission.ManageServiceOpeningTimes } }
      })
    }
  }

  getGUID () : string {
    let d = new Date().getTime()
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
    })
  }
}
