<template>
  <v-snackbar top v-on="$listeners" v-bind="$attrs" v-model="localValue" :timeout="timeout" class="partou-snackbar">
    <slot/>
    <template v-slot:action>
      <slot name="action"></slot>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouSnackbar extends Vue {
  @Prop({ default: 5000 })
  timeout?: number

  @Prop({ default: false })
  value?: boolean

  get localValue () : boolean | undefined {
    return this.value
  }

  set localValue (value:boolean | undefined) {
    this.$emit('input', value)
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  .partou-snackbar {
    .v-btn__content{
      color: $partou-red;
      font-weight:bold;
      letter-spacing: 0;
    }
  }
</style>
