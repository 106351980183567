<template>
  <v-simple-table v-if="value.length > 0">
    <template v-slot:default>
      <thead>
        <th id="childNameHeader" width="156px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.childName') }}</th>
        <th id="childNumberHeader" width="48px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.childNumber') }}</th>
        <th id="dateOfBirthHeader" width="48px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.dateOfBirth') }}</th>
        <th id="morningHeader" width="24px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.morning') }}</th>
        <th id="afternoonHeader" width="24px" class="text-left">{{ $t('pages.ServiceOffer.childrenTable.columns.afternoon') }}</th>
      </thead>
      <tbody>
        <tr v-for="child in orderedItems" :key="child.id">
          <td class="text-left pa-0">{{ getChildName(child) }}</td>
          <td class="text-left pa-0">{{ child.number }}</td>
          <td class="text-left pa-0">{{ parseDateOfBirth(child.dateOfBirth) }}</td>
          <td><v-icon v-if="child.morning">$vuetify.icons.partouCheck</v-icon></td>
          <td><v-icon v-if="child.afternoon">$vuetify.icons.partouCheck</v-icon></td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  <p v-else>{{ $t('pages.ServiceOffer.childrenTable.noReservations') }}</p>
</template>

<script lang="ts">
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ServiceOfferChildTableItem from './ServiceOfferChildTableItem'

@Component({
  components: { PartouCard, PartouCheckBox }
})
export default class ServiceOfferChildrenTable extends Vue {
  @Prop({ required: true })
  value!: ServiceOfferChildTableItem[]

  get orderedItems () : ServiceOfferChildTableItem[] {
    return this.value.sort(function (a, b) {
      const firstNameA = a.firstName ?? ''
      const firstNameB = b.firstName ?? ''

      if (firstNameA < firstNameB) { return -1 }
      if (firstNameA > firstNameB) { return 1 }
      return 0
    })
  }

  getChildName (rowData: ServiceOfferChildTableItem) : string {
    let name = `${rowData.firstName} ${rowData.lastName}`
    if (rowData.reservedSeat) {
      name += ` (${this.$t('pages.ServiceOffer.childrenTable.reservedSeat')})`
    }
    return name
  }

  parseDateOfBirth (dateOfBirth : Date) : string {
    return moment(dateOfBirth).format('DD-MM-YYYY')
  }
}
</script>

<style lang="scss" scoped>

</style>
