<template>
   <div class="flex-margin-container">
    <div class="description-container">
      <div>
        <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.waitingListActualisationIntervals.title')}}</h2>
        <p>{{$t('services.controlVariables.waitingListActualisationIntervals.description')}}</p>
      </div>
    </div>
    <div class="controls-container">
      <template>
        <div>
          <div class="d-flex flex-row justify-space-between">
            <div ></div>
            <div class="d-flex headers">
              <div class="col text-center">
                <strong>{{$t('services.controlVariables.waitingListActualisationIntervals.firstRemainder')}}</strong>
              </div>
              <div class="col text-center">
                <strong>{{$t('services.controlVariables.waitingListActualisationIntervals.secondRemainder')}}</strong>
              </div>
              <div class="col text-center">
                <strong>{{$t('services.controlVariables.waitingListActualisationIntervals.endDate')}}</strong>
              </div>
            </div>
          </div>
        <template>
            <div v-for="(waitingListActualisationIntervals, index) in waitingListActualisationIntervalsOptions" :key="index" class="d-flex flex-row justify-space-between align-center mb-3">
                <h2 class="pr-3">{{waitingListActualisationIntervals.remainOnWaitingListName}}</h2>
                <PartouButton
                    class="option-button-waiting-list-actualisation"
                    :colour="getButtonColour(waitingListActualisationIntervals)"
                    :disabled="!editEnabled"
                    @click="onWaitingListActualisationIntervalsChanged(waitingListActualisationIntervals)"
                    >
                    <div class="d-flex buttons" :class="getTextColour(waitingListActualisationIntervals)" slot="append-item">
                        <div class="col text-left pl-0">
                          {{ getCorrectFormattedStringFromMinutes(waitingListActualisationIntervals.sendRemainOnWaitingListMailInInMinutes) }}
                        </div>
                        <div class="col text-center">
                          {{ getCorrectFormattedStringFromMinutes(waitingListActualisationIntervals.sendRemainOnWaitingListReminderMailInInMinutes) }}
                        </div>
                        <div class="col text-right pr-0">
                          {{ getCorrectFormattedStringFromMinutes(waitingListActualisationIntervals.inquiryExpiresInInMinutes) }}
                        </div>
                    </div>
                </PartouButton>
            </div>
        </template>
        </div>
      </template>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import { TranslateResult } from 'vue-i18n'
import WaitingListActualisationIntervals from '@/models/configuration/WaitingListActualisationIntervals'

  @Component({
    components: { PartouButton }
  })
export default class WaitingListActualisationIntervalsSettings extends Vue {
  @Prop({ required: true })
  waitingListActualisationIntervalsOptions! : WaitingListActualisationIntervals[]

  @Prop({ required: true })
  activeWaitingListActualisationIntervals! : WaitingListActualisationIntervals | null

  @Prop({ required: true })
  editEnabled! : boolean

  getCorrectFormattedStringFromMinutes (minutes : number) : TranslateResult {
    if (minutes % 10080 === 0 && minutes !== 0) {
      return this.$tc('services.controlVariables.waitingListActualisationIntervals.buttonTextWeeks', minutes / 10080)
    } else if (minutes % 1440 === 0 && minutes !== 0) {
      return this.$tc('services.controlVariables.waitingListActualisationIntervals.buttonTextDays', minutes / 1440)
    } else if (minutes % 60 === 0 && minutes !== 0) {
      return this.$tc('services.controlVariables.waitingListActualisationIntervals.buttonTextHours', minutes / 60)
    } else {
      return this.$tc('services.controlVariables.waitingListActualisationIntervals.buttonTextMinutes', minutes)
    }
  }

  getButtonColour (waitingListActualisationIntervals: WaitingListActualisationIntervals) : string {
    return this.checkIfActive(waitingListActualisationIntervals) ? 'partou-secondary-bright-green' : 'partou-black-ninety'
  }

  getTextColour (waitingListActualisationIntervals: WaitingListActualisationIntervals) : string {
    return this.checkIfActive(waitingListActualisationIntervals) ? '' : 'black-ninety'
  }

  checkIfActive (waitingListActualisationIntervals: WaitingListActualisationIntervals) : boolean {
    return waitingListActualisationIntervals.inquiryExpiresInInMinutes === this.activeWaitingListActualisationIntervals?.inquiryExpiresInInMinutes && waitingListActualisationIntervals.sendRemainOnWaitingListMailInInMinutes === this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListMailInInMinutes && waitingListActualisationIntervals.sendRemainOnWaitingListReminderMailInInMinutes === this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListReminderMailInInMinutes
  }

  @Emit('onWaitingListActualisationIntervalsChanged')
  onWaitingListActualisationIntervalsChanged (waitingListActualisationIntervals: WaitingListActualisationIntervals) : WaitingListActualisationIntervals {
    return waitingListActualisationIntervals
  }
}

</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

.option-button-waiting-list-actualisation {
    width: 315px;
    button {
      border: none !important;
      box-shadow: $partou-elevation-large-subtle !important;
      .v-btn__content {
        font-weight: 700;
      }
    }
  }
</style>

  <style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';

  .flex-margin-container {
    width: 100%;
    display: flex;

    .description-container {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .controls-container {
      width: 50%;
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }
  .secondary-bright-green{
    color: $partou-secondary-bright-green;
  }
  .black-ninety{
    color: $partou-primary-black-ninety;
  }
  .headers{
    width: 315px;
  }
  .buttons {
    width: 275px;
  }
</style>
