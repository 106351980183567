import { DayOfWeek } from '.'

enum Day {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export function ParseStringToDay (day : string) : Day {
  switch (day.toLowerCase()) {
  case 'monday':
    return Day.Monday
  case 'tuesday':
    return Day.Tuesday
  case 'wednesday':
    return Day.Wednesday
  case 'thursday':
    return Day.Thursday
  case 'friday':
    return Day.Friday
  case 'saturday':
    return Day.Saturday
  case 'sunday':
    return Day.Sunday
  }
  throw new Error(`Given day string is unknown: ${day}`)
}

export function fromDayOfWeek (dayOfWeek: DayOfWeek) : Day {
  switch (dayOfWeek) {
  case DayOfWeek.Sunday: return 0
  case DayOfWeek.Monday: return 1
  case DayOfWeek.Tuesday: return 2
  case DayOfWeek.Wednesday: return 3
  case DayOfWeek.Thursday: return 4
  case DayOfWeek.Friday: return 5
  case DayOfWeek.Saturday: return 6
  }
}

export default Day
