import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { VuetifyIcons } from 'vuetify/types/services/icons'
import { Vuetify as VuetifyType } from 'vuetify/types'

Vue.use(Vuetify)

export default function initVuetify (icons: Partial<VuetifyIcons> = {}) : VuetifyType {
  return new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#EF575D',
          secondary: 'ECE6DF',
          accent: '#CB5A5C',
          error: '#CB5A5C'
        }
      }
    },
    icons: {
      values: icons
    }
  })
}
