enum Permission {
    GetService = 'get_service',
    PublishService = 'publish_service',
    ManageService = 'manage_service',
    ManageServiceMedia = 'manage_service_media',
    GetOffer = 'get_offer',
    ManageOffer = 'manage_offer',
    GetWaitingList = 'get_waiting_list',
    ManageWaitingList = 'manage_waiting_list',
    GetFeature = 'get_feature',
    ManageFeature = 'manage_feature',
    GetSetting = 'get_setting',
    ManageSetting = 'manage_setting',
    GetPricing = 'get_pricing',
    ManagePricing = 'manage_pricing',
    GetBooking = 'get_booking',
    GetAnalysis = 'get_analysis',
    CallActions = 'call_actions',
    ManageServiceOpeningTimes = 'manage_service_openingtimes',
    GetProduct = 'get_product',
    ManageProduct = 'manage_product'
}

export default Permission
