<template>
  <div class="day-table d-flex flex-column mb-4">
    <span class="mr-2">{{ serviceVarietyName }}</span>
    <span class="expanded-divider"></span>
    <div class="d-flex flex-row">
      <div>
        <div class="d-flex flex-row justify-space-between">
          <div class="d-flex mr-2 mt-2 row-column align-center">{{ $t('dayTable.propositions') }}</div>
          <div ref="currentPropositions" class="d-flex flex-row mt-3 align-center">
            <template v-for="(proposition, index) in currentWaitingListPropositionDays">
              <template>
                <p ref="propositionDayLabel" class="mr-2 day-label" :class="{accepted: dayIsAccepted(proposition), declined: dayIsDeclined(proposition)}" :key="index"><strong> {{getShortDayName(proposition)}} </strong></p>
              </template>
            </template>
          </div>
        </div>
        <div class="d-flex flex-row justify-space-between">
          <div class="d-flex mr-2 mt-2 row-column align-center">{{ $t('dayTable.OriginalWaitingLists') }}</div>
          <div class="d-flex flex-row mt-3 align-center">
            <template v-for="(waitinglist, index) in originalWaitingListDays">
              <template>
                <p ref="originalWaitingListDayLabel" class="mr-2 day-label" :key="index"><strong> {{waitinglist}} </strong></p>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DayOfWeek, ReservationStatus, ServiceVarietyName, WaitingListProposition } from '@/models'
import { getShortenedDayName } from '@/models/enums/DayOfWeek'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DayTableWaitingListProposition extends Vue {
  @Prop({ required: true })
  serviceVarietyName!: ServiceVarietyName

  @Prop({ required: true })
  proposition!: WaitingListProposition

  dayIsAccepted (reservation: string): boolean {
    const reservationDays = this.proposition.placement.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName && x.dayOfWeek === reservation)
    if (!reservationDays[0]) {
      return false
    }

    return reservationDays[0].status === ReservationStatus.Occupied || reservationDays[0].status === ReservationStatus.Reserved
  }

  dayIsDeclined (reservation: string): boolean {
    const reservationDays = this.proposition.placement.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName && x.dayOfWeek === reservation)
    if (!reservationDays[0]) {
      return false
    }

    return reservationDays[0].status === ReservationStatus.Rejected
  }

  getShortDayName (dayName: string) :string {
    return dayName === '-' ? dayName : this.firstToUpper(getShortenedDayName(dayName as DayOfWeek))
  }

  get currentWaitingListPropositionDays () : string[] {
    const currentWaitingListPropositionDaysBasedOnFullWeek = new Array<string>(5).fill('-')
    const currentWaitingListPropositionDays = Array.from(new Set(this.proposition.placement.reservations.filter(x => x.serviceVariety.name === this.serviceVarietyName).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    currentWaitingListPropositionDays.forEach(dayOfProposition => {
      const dayOfWeekIndexOfProposition = Object.keys(DayOfWeek).indexOf(dayOfProposition)
      currentWaitingListPropositionDaysBasedOnFullWeek[dayOfWeekIndexOfProposition] = dayOfProposition
    })

    return currentWaitingListPropositionDaysBasedOnFullWeek
  }

  get originalWaitingListDays () : string[] {
    const waitingListPlaces = this.proposition?.locationInquiry?.waitingListPlaces ?? []
    const originalWaitingListDaysBasedOnFullWeek = new Array<string>(5).fill('-')
    const originalWaitingListDays = Array.from(new Set(waitingListPlaces.filter(x => x.serviceVariety.name === this.serviceVarietyName).flatMap(x => DayOfWeek[x.dayOfWeek as keyof typeof DayOfWeek])))

    originalWaitingListDays.forEach(dayOfWaitinglist => {
      const dayOfWeekIndexOfWaitingList = Object.keys(DayOfWeek).indexOf(dayOfWaitinglist)
      originalWaitingListDaysBasedOnFullWeek[dayOfWeekIndexOfWaitingList] = this.firstToUpper(getShortenedDayName(dayOfWaitinglist))
    })

    return originalWaitingListDaysBasedOnFullWeek
  }

  firstToUpper (dayLabel: string):string {
    return dayLabel[0].toUpperCase() + dayLabel.substring(1)
  }
}
</script>

  <style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';

    .day-table {
      width: 244px;
    }

    .fixed-width {
      width: 25px;
      display: inline-block;
      text-align: center;
    }

    .expanded-divider {
      background-color: $partou-primary-salmon-fourty;
      height: 1px;
      width:100%;
    }

    .day-row-labels {
      width: 110px;
    }

    .day-label {
      width: 24px;
      text-align: center;
      margin-top: 8px;
      margin-bottom: 8px;

      &.accepted {
        color: $partou-green;
      }

      &.declined {
        color: $partou-red;
      }
    }
    .row-column {
      font-size: 13px;
      color: $partou-primary-black-fifty;
      margin-bottom: 0px;
    }
  </style>
