import AgeRange from '@/models/AgeRange'

export const MAX_AGERANGE = 9999

enum AgeCategory {
  ZeroToOne = '0-12',
  OneToTwo = '12-24',
  TwoToThree = '24-36',
  ThreeToFour = '36-48',

  FourToSeven = '48-84',
  SevenPlus = '84-9999',
}

export function parseAgeRangeToAgeCategory (ageRange : AgeRange) : AgeCategory {
  if (ageRange.MinAge === 0 && ageRange.MaxAge === 12) {
    return AgeCategory.ZeroToOne
  } else if (ageRange.MinAge === 12 && ageRange.MaxAge === 24) {
    return AgeCategory.OneToTwo
  } else if (ageRange.MinAge === 24 && ageRange.MaxAge === 36) {
    return AgeCategory.TwoToThree
  } else if (ageRange.MinAge === 36 && ageRange.MaxAge === 48) {
    return AgeCategory.ThreeToFour
  } else if (ageRange.MinAge === 48 && ageRange.MaxAge === 84) {
    return AgeCategory.FourToSeven
  } else if (ageRange.MinAge === 84 && ageRange.MaxAge === MAX_AGERANGE) {
    return AgeCategory.SevenPlus
  }
  throw new Error('min or max age not supported in agerange')
}

export function getAgeCategoryLimits (ageCategory: AgeCategory, toYears = false) : { min: number, max?: number } {
  const splitAgeCategory = ageCategory.split('-').map(x => Number(x))

  let min = splitAgeCategory[0]
  let max = splitAgeCategory[1] === MAX_AGERANGE ? undefined : splitAgeCategory[1]

  if (toYears) {
    min = min / 12
    if (max) {
      max = max / 12
    }
  }

  return { min, max }
}

export function parseAgeCategoryToAgeRange (ageCategory : AgeCategory) : AgeRange {
  switch (ageCategory) {
  case AgeCategory.ZeroToOne:
    return { MinAge: 0, MaxAge: 12 }
  case AgeCategory.OneToTwo:
    return { MinAge: 12, MaxAge: 24 }
  case AgeCategory.TwoToThree:
    return { MinAge: 24, MaxAge: 36 }
  case AgeCategory.ThreeToFour:
    return { MinAge: 36, MaxAge: 48 }
  case AgeCategory.FourToSeven:
    return { MinAge: 48, MaxAge: 84 }
  case AgeCategory.SevenPlus:
    return { MinAge: 84, MaxAge: MAX_AGERANGE }
  }
}

export default AgeCategory
