import { inject, injectable } from 'inversify'
import { AxiosInstance } from 'axios'
import { IUploadService } from './IUploadService'
import { SERVICE_IDENTIFIERS } from '@/container'
import RetryUtils from '@/utils/retryUtils'
import ApolloClient from 'apollo-client'
import { GetSignedDeleteUrlDocument, GetSignedDeleteUrlQueryVariables, GetSignedUploadUrlDocument, GetSignedUploadUrlQueryVariables, MutationRoot, QueryRoot, SetPublicReadAccessDocument, SetPublicReadAccessMutationVariables } from '@/models'
import Permission from '@/models/enums/Permission'

@injectable()
export default class S3UploadService implements IUploadService {
  httpClient?: AxiosInstance
  apollo: ApolloClient<unknown>

  constructor (
    @inject(ApolloClient) apollo: ApolloClient<unknown>,
    @inject(SERVICE_IDENTIFIERS.Axios) axios?: AxiosInstance
  ) {
    this.apollo = apollo
    if (axios) { this.httpClient = axios }
  }

  async getUploadUrl (variables: GetSignedUploadUrlQueryVariables): Promise<string> {
    let result : any // eslint-disable-line @typescript-eslint/no-explicit-any
    await RetryUtils.callAsyncWithRetry(async () => {
      result = await this.apollo.query<QueryRoot>({
        query: GetSignedUploadUrlDocument,
        variables,
        context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
      })
    }, 3)

    return result?.data?.getSignedUploadUrl?.uploadUrl
  }

  async getDeleteUrl (variables: GetSignedDeleteUrlQueryVariables): Promise<string> {
    let result : any // eslint-disable-line @typescript-eslint/no-explicit-any
    await RetryUtils.callAsyncWithRetry(async () => {
      result = await this.apollo.query<QueryRoot>({
        query: GetSignedDeleteUrlDocument,
        variables,
        context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
      })
    }, 3)

    return result?.data?.getSignedDeleteUrl?.deleteUrl
  }

  async getFetchUrl (variables: SetPublicReadAccessMutationVariables): Promise<string> {
    let result : any // eslint-disable-line @typescript-eslint/no-explicit-any
    await RetryUtils.callAsyncWithRetry(async () => {
      result = await this.apollo.mutate<MutationRoot>({
        mutation: SetPublicReadAccessDocument,
        variables,
        context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
      })
    }, 3)

    return result?.data?.setPublicReadAccess?.getUrl
  }

  async uploadOneAsync (filename : string, file: File, folderpath : string): Promise<string | undefined> {
    const url = await this.getUploadUrl({ objectKey: filename, objectKeyPrefix: folderpath })
    const options = { headers: { 'Content-Type': file.type } }

    let response : any // eslint-disable-line @typescript-eslint/no-explicit-any
    await RetryUtils.callAsyncWithRetry(async () => {
      response = await this.httpClient?.put(url, file, options)
    }, 3)
    return response?.data ?? undefined
  }

  async deleteOneAsync (filename: string, folderpath : string): Promise<string | undefined> {
    const url = await this.getDeleteUrl({ objectKey: filename, objectKeyPrefix: folderpath })

    let response : any // eslint-disable-line @typescript-eslint/no-explicit-any
    await RetryUtils.callAsyncWithRetry(async () => {
      response = await this.httpClient?.delete(url)
    }, 3)
    return response?.data ?? undefined
  }
}
