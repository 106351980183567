<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h1>{{ $t('waitingListPropositionAnalysis.possibleDayCombinations') }}</h1>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="content">
          <WaitingListPropositionAnalysisResultDayCombinationsTable :dayCombinations="dayCombinations" />
        </v-expansion-panel-content >
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header class="propositions-header">
          <div class="chosen-proposition" >
            <h1>{{ $t('waitingListPropositionAnalysis.possiblePropositions') }}</h1>
            <WaitingListPropositionAnalysisResultPossiblePropositionTable class="table" :possibleProposition="possiblePropositions[0]" :chosenProposition="true" />
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="propositions-content">
          <WaitingListPropositionAnalysisResultPossiblePropositionTable v-for="(possibleProposition, index) in nonChosenPropositions" :key="`${index}-${possibleProposition}`" :possibleProposition="possibleProposition" :chosenProposition="false" />
        </v-expansion-panel-content >
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import { DayCombination, PossibleProposition } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListPropositionAnalysisResultDayCombinationsTable from './WaitingListPropositionAnalysisResultDayCombinationsTable.vue'
import WaitingListPropositionAnalysisResultPossiblePropositionTable from './WaitingListPropositionAnalysisResultPossiblePropositionTable.vue'

@Component({
  components: { DaysCoverage, WaitingListPropositionAnalysisResultDayCombinationsTable, WaitingListPropositionAnalysisResultPossiblePropositionTable }
})
export default class WaitingListPropositionAnalysisResultPropositionsTable extends Vue {
  @Prop({ required: true })
  dayCombinations!: DayCombination[]

  @Prop({ required: true })
  possiblePropositions!: PossibleProposition[]

  get nonChosenPropositions (): PossibleProposition[] {
    return this.possiblePropositions.length > 1 ? this.possiblePropositions.slice(1) : []
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
.propositions-header {

 .chosen-proposition {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .table {
    margin-top: 24px;
  }
 }
}

.propositions-content {
  padding-top: 16px;
  box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);
}
</style>
