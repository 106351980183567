<template>
  <div class="flex-margin-container">
    <div class="description-container">
      <div>
        <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.flexMargin')}}</h2>
        <p>{{$t('services.controlVariables.flexMarginDescription')}}</p>
      </div>
    </div>
    <div :key="slidersKey" class="controls-container">
      <PartouSlider class="" v-model="value.Monday" @input="onFlexMarginChanged" :max="100" :min="0" :step="5" :vertical="true" :label="$t('daysShort.monday')" :labelPosition="'bottom'" :disabled="!editEnabled"/>
      <PartouSlider class="" v-model="value.Tuesday"  @input="onFlexMarginChanged" :max="100" :min="0" :step="5" :vertical="true" :label="$t('daysShort.tuesday')" :labelPosition="'bottom'" :disabled="!editEnabled"/>
      <PartouSlider class="" v-model="value.Wednesday" @input="onFlexMarginChanged" :max="100" :min="0" :step="5" :vertical="true" :label="$t('daysShort.wednesday')" :labelPosition="'bottom'" :disabled="!editEnabled"/>
      <PartouSlider class="" v-model="value.Thursday" @input="onFlexMarginChanged" :max="100" :min="0" :step="5" :vertical="true" :label="$t('daysShort.thursday')" :labelPosition="'bottom'" :disabled="!editEnabled"/>
      <PartouSlider class="" v-model="value.Friday"  @input="onFlexMarginChanged" :max="100" :min="0" :step="5" :vertical="true" :label="$t('daysShort.friday')" :labelPosition="'bottom'" :disabled="!editEnabled"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import { FlexMargin } from '@/models'

@Component({
  components: { PartouSlider }
})
export default class FlexMarginSettings extends Vue {
  @Prop({ required: true, default: () => { return { Monday: 0, Tuesday: 0, Wednesday: 0, Thursday: 0, Friday: 0, Saturday: 0, Sunday: 0 } } })
  value! : FlexMargin

  @Prop({ required: true, default: false })
  editEnabled! : boolean

  slidersKey = 0

  onFlexMarginChanged () : void {
    this.slidersKey++
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

  .flex-margin-container {
    width: 100%;
    display: flex;

    .description-container {
      width: 50%;
      display: flex;
      align-items: center;
    }

    .controls-container {
      width: 50%;
      display: flex;
      gap: 10px;
      justify-content: center;
    }
  }

  .black-ninety {
    color: $partou-primary-black-ninety !important;
  }
</style>
