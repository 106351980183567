<template>
  <PartouCard :hide-actions="true" class="date-selector-card">
    <template v-slot:card-body>
      <v-card-text class="pb-0">
        <DateInputField
          :use-single-line="false"
          :label="$t('pages.ServiceOffer.dateSelection')"
          :value="date"
          @input="onDateChanged">
        </DateInputField>
      </v-card-text>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'

@Component({
  components: { PartouCard, DateInputField },
  methods: { moment: () => { return moment() } }
})
export default class ServiceOfferDatePicker extends Vue {
  @Prop({ required: true })
  date?: Date

  onDateChanged (date : Date) : void {
    // If saturday or sunday is selected, select monday instead
    const weekDay = moment(date).isoWeekday() // 1: Monday, ..., 7: Sunday
    if (weekDay > 5) {
      date = moment(date).startOf('week').toDate()
    }

    const midnightDate = moment(date).startOf('day').toDate()
    this.$emit('datePicked', midnightDate)
  }
}
</script>

<style scoped>
  .date-selector-card {
    width: 280px;
  }
</style>
