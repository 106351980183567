import i18n from '@/plugins/i18n'
import moment from 'moment'

enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export default DayOfWeek

export function parseStringToDayOfWeek (value : string) : DayOfWeek {
  switch (value.toLowerCase()) {
  case 'sunday': return DayOfWeek.Sunday
  case 'monday': return DayOfWeek.Monday
  case 'tuesday': return DayOfWeek.Tuesday
  case 'wednesday': return DayOfWeek.Wednesday
  case 'thursday': return DayOfWeek.Thursday
  case 'friday': return DayOfWeek.Friday
  case 'saturday': return DayOfWeek.Saturday
  }
  throw new Error(`given string: ${value} cannot be parsed to day of week`)
}

export function parseNumberToDayOfWeek (value : number) : DayOfWeek {
  switch (value) {
  case 0: return DayOfWeek.Sunday
  case 1: return DayOfWeek.Monday
  case 2: return DayOfWeek.Tuesday
  case 3: return DayOfWeek.Wednesday
  case 4: return DayOfWeek.Thursday
  case 5: return DayOfWeek.Friday
  case 6: return DayOfWeek.Saturday
  case 7: return DayOfWeek.Sunday
  }
  throw new Error(`given number: ${value} cannot be parsed to day of week`)
}

export function getCurrentDayOfWeek () : DayOfWeek {
  return (<any>DayOfWeek)[moment().locale('en').format('dddd')] // eslint-disable-line @typescript-eslint/no-explicit-any
}

export function getShortenedDayName (day: DayOfWeek): string {
  switch (day) {
  case DayOfWeek.Monday:
    return i18n.t('days.monday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Tuesday:
    return i18n.t('days.tuesday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Wednesday:
    return i18n.t('days.wednesday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Thursday:
    return i18n.t('days.thursday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Friday:
    return i18n.t('days.friday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Saturday:
    return i18n.t('days.saturday').toString().substring(0, 2).toLowerCase()
  case DayOfWeek.Sunday:
    return i18n.t('days.sunday').toString().substring(0, 2).toLowerCase()
  }
}
