enum WaitingListPlaceStatus {
  Waiting = 'Waiting',
  Approached = 'Approached',
  Accepted = 'Accepted',
  ApproachedByFlexKids = 'ApproachedByFlexKids'
}

export default WaitingListPlaceStatus

export function parseStringToWaitingListPlaceStatus (value : string) : WaitingListPlaceStatus {
  switch (value.toLowerCase()) {
  case 'waiting': return WaitingListPlaceStatus.Waiting
  case 'approached': return WaitingListPlaceStatus.Approached
  case 'accepted': return WaitingListPlaceStatus.Accepted
  case 'approachedbyflexkids': return WaitingListPlaceStatus.ApproachedByFlexKids
  }
  throw new Error(`given string: ${value} cannot be parsed to waiting list place status`)
}
