import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { Child, GetChildReservationsDocument, GetChildReservationsQuery } from '@/models'
import { IReservationService } from './IReservationService'
import { DataTableFilter } from '@/components/PartouComponents/PartouDataTable'

@injectable()
export default class ReservationService implements IReservationService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getPageAsync (variables: DataTableFilter): Promise<{ items: Child[]; totalCount: number }> {
    const result = await this.apollo!.query<GetChildReservationsQuery>({ // eslint-disable-line @typescript-eslint/no-non-null-assertion
      query: GetChildReservationsDocument,
      variables
    })

    const items: any[] = result.data.child // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result.data.child_aggregate?.aggregate?.count || 0
    return { items, totalCount }
  }
}
