import { render, staticRenderFns } from "./SettingCard.vue?vue&type=template&id=71c5e24e&scoped=true"
import script from "./SettingCard.vue?vue&type=script&lang=ts"
export * from "./SettingCard.vue?vue&type=script&lang=ts"
import style0 from "./SettingCard.vue?vue&type=style&index=0&id=71c5e24e&prod&lang=scss"
import style1 from "./SettingCard.vue?vue&type=style&index=1&id=71c5e24e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c5e24e",
  null
  
)

export default component.exports