<template>
  <div class="service-pricing-container">
    <div class="description-container">
      <div>
        <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.servicePricing.title')}}</h2>
        <p>{{$t('services.controlVariables.servicePricing.description')}}</p>
      </div>
    </div>
    <div class="controls-container">
      <template>
        <div class="controls mt-8 mb-4">
          <div class="headers d-flex flex-row justify-space-between">
            <div class="col-3 pa-0 pb-6">
              <strong>{{$t('services.controlVariables.subscriptions.startDate')}}</strong>
            </div>
            <div class="col-3 pa-0 pb-6">
              <strong>{{$t('services.controlVariables.subscriptions.price')}}</strong>
            </div>
            <div class="col-1 pa-0 pb-6">
            </div>
          </div>
          <template v-for="(pricing, index) in sortedPricings">
            <template v-if="!pricing.isDeleted">
              <div class="d-flex flex-row justify-space-between" :key="index">
                <div class="col-3 pa-0 pb-6">
                  {{formatDate(pricing.validFrom)}}
                </div>
                <div class="col-3 pa-0 pb-6">
                  {{ pricing.hourRate | euro }}
                </div>
                <div class="col-1 pa-0 pb-6">
                  <v-icon class="red-text" small v-show="!isAddingPrice && editEnabled" @click="onDeletePricingClicked(pricing)">mdi-delete-outline</v-icon>
                </div>
              </div>
            </template>
          </template>
          <div class="d-flex justify-center col-12 pa-0">
            <PartouFloatingCircleButton ref="add-button" class="add-button mt-4" iconName="$vuetify.icons.partouAdd" @click="onAddPriceClicked" v-show="!isAddingPrice && editEnabled"/>
              <v-form v-model="isFormValid" class="d-flex flex-row justify-space-between align-center col-12 pa-0" v-if="isAddingPrice">
                <DateInputField v-model="newPriceDate" :isRequired="true" type="month" class="date-input" :isAllowedDate="isAllowedDate"/>
                <PartouTextField v-model="newPrice" :isRequired="true" class="price-input" :label="$t('services.controlVariables.subscriptions.price')" v-numberInputDirective/>
                <PartouFloatingCircleButton class="add-button mb-4" ref="add-button" iconName="$vuetify.icons.partouFloppy" @click="onSavePriceClicked" :disabled="!isFormValid"/>
                <PartouFloatingCircleButton class="add-button mb-4" ref="add-button" iconName="$vuetify.icons.partouClose" @click="onCancelPriceClicked"/>
              </v-form>
          </div>
        </div>
      </template>
      <PartouDialog
      :width="412"
      :showDialog="showDeleteDialog"
      @accepted="onDeletePricingConfirmed"
      @canceled="onDeletePricingCanceled"
      :cancelTranslationString="'services.controlVariables.subscriptions.removePriceDialog.cancel'"
      :confirmTranslationString="'services.controlVariables.subscriptions.removePriceDialog.confirm'">
        <h3>{{ $t('services.controlVariables.subscriptions.removePriceDialog.title') }} </h3>
        <p class="mb-6 mt-6"> {{ $t('services.controlVariables.subscriptions.removePriceDialog.description')}} </p>
      </PartouDialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import moment from 'moment'
import { numberInputDirective } from '@/utils/directives'
import { ServicePricingExtended } from './ServicePricingExtended'
import '@/utils/listUtils'

@Component({
  directives: { numberInputDirective },
  components: { PartouSlider, PartouDialog, PartouNotify, PartouFloatingCircleButton, PartouTextField, DateInputField }
})
export default class ServicePricingSettings extends Vue {
  @Prop({ required: true })
  value! : ServicePricingExtended[]

  @Prop({ required: true })
  editEnabled! : boolean

  onDeletePricingConfirmed = () : void => {
    // empty function will be initialized in OnDeletePricingClickedAsync
  }

  defaultDate = moment().add(1, 'month').startOf('month').utc(true).toDate()
  newPrice = 0
  isAddingPrice = false
  showDeleteDialog = false
  isFormValid = false

  servicePricings : ServicePricingExtended[] = []

  @Watch('value', { immediate: true })
  onValueChanged () : void {
    this.servicePricings = this.value.map(x => { return { ...x } }) ?? []
  }

  formatDate (date : Date) : string {
    return moment(date).format('DD-MM-YYYY')
  }

  get newPriceDate () : Date {
    while (!this.isAllowedDate(this.defaultDate)) {
      this.defaultDate = moment(this.defaultDate).add(1, 'month').startOf('month').utc(true).toDate()
    }
    return this.defaultDate
  }

  set newPriceDate (newValue : Date) {
    this.defaultDate = newValue
  }

  get sortedPricings () : ServicePricingExtended[] {
    return this.servicePricings.filter(x => !x.isDeleted).orderBy(x => x.validFrom) ?? []
  }

  onDeletePricingClicked (servicePricing : ServicePricingExtended) : void {
    this.showDeleteDialog = true
    this.onDeletePricingConfirmed = () => {
      servicePricing.isDeleted = true
      const sortedList = this.servicePricings?.orderBy(x => x.validFrom)
      const priceBefore = sortedList?.last(x => moment(x.validUntil).isSameOrBefore(servicePricing.validFrom) && !x.isDeleted)
      const priceAfter = sortedList?.first(x => moment(x.validFrom).isSameOrAfter(servicePricing.validFrom) && !x.isDeleted)

      if (priceBefore && priceAfter) {
        priceBefore.validUntil = priceAfter?.validFrom
        priceBefore.isDirty = true
        priceAfter.isDirty = true
      } else if (priceBefore) {
        priceBefore.validUntil = undefined
        priceBefore.isDirty = true
      }
      this.showDeleteDialog = false
      this.emitService()
    }
  }

  onDeletePricingCanceled () : void {
    this.showDeleteDialog = false
  }

  onAddPriceClicked () : void {
    this.isAddingPrice = true
  }

  onCancelPriceClicked () : void {
    this.isAddingPrice = false
  }

  onSavePriceClicked () : void {
    const currentPricing = this.servicePricings?.first(x => moment(x.validFrom).isBefore(this.newPriceDate) && (!x.validUntil || moment(x.validUntil).isAfter(this.newPriceDate)))
    if (currentPricing) {
      currentPricing.validUntil = this.newPriceDate
      currentPricing.isDirty = true
    }

    const pricingInFuture = this.servicePricings?.orderBy(x => x.validFrom).first(x => moment(x.validFrom).isAfter(this.newPriceDate) && !x.isDeleted)
    this.newPrice = parseFloat(this.newPrice.toString().replace(',', '.'))
    this.servicePricings.push({ hourRate: this.newPrice, validFrom: this.newPriceDate, validUntil: pricingInFuture?.validFrom ?? undefined, isAdded: true, isDirty: false, isDeleted: false })
    this.isAddingPrice = false
    this.defaultDate = moment().add(1, 'month').startOf('month').utc(true).toDate()
    this.emitService()
  }

  @Emit('input')
  emitService () : ServicePricingExtended[] {
    return this.servicePricings
  }

  isAllowedDate (date : Date) : boolean {
    return !this.servicePricings.filter(x => !x.isDeleted).map(x => moment(x.validFrom).format('YYYY-MM')).includes(moment(date).format('YYYY-MM'))
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  .black-ninety {
    color: $partou-primary-black-ninety !important;
  }

  .edit-icon {
    margin-top: 4px;
    width: 18px  !important;
    height: 18px  !important;
    path {
      fill: $partou-red !important;
    }
  }

  .edit-icon.v-icon--disabled{
    margin-top: 4px;
    width: 18px  !important;
    height: 18px  !important;
    path {
      fill: rgba(0, 0, 0, 0.38) !important;
    }
  }
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .service-pricing-container {
    width: 100%;
    display: flex;

    .description-container {
      width: 50%;
      display: flex;
    }

    .controls-container {
      width: 50%;
      display: flex;
      gap: 10px;
      justify-content: center;

      .controls {
        width: 400px;
      }
    }
  }

  .text {
    color: $partou-black;
    font-family: $body-font-family !important;
    font-size: 0.875rem !important;
    line-height: 17.57px;
    margin-bottom: 0px !important;
  }

  .price {
    font-size: 18px !important;
    font-family: $body-font-family !important;
    line-height: 17.57px;
    margin-bottom: 0px !important;
  }

  .add-button {
      width: 32px;
    height: 32px;
  }

  .date-input {
    width: 200px;
  }

  .price-input {
    max-width: 200px;
    width: 200px;
    padding-top: 0px !important;
  }
</style>
