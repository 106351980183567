import { IServiceService } from '@/services/ServiceService/IServiceService'
import IState from '@/store/IState'
import { ActionTree } from 'vuex'
import IServiceState from '@/store/serviceModule/IServiceState'
import { MUTATIONS } from '@/store/serviceModule/serviceMutations'

export const ACTIONS = {
  getServices: 'getServices'
}

export function actions (serviceService : IServiceService): ActionTree<IServiceState, IState> {
  return {
    async getServices ({ commit }) {
      const data = await serviceService.getAllAsync()
      commit(MUTATIONS.setServices, data)
    }
  }
}
