<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width="28" height="28" rx="14" :stroke="color" stroke-width="2"/>
        <path d="M11.0415 8.36353C10.9591 7.82184 10.9998 7.26958 11.1609 6.74422C11.322 6.21887 11.5997 5.73273 11.9752 5.31881C12.3507 4.90489 12.8152 4.57289 13.3371 4.34533C13.859 4.11778 14.4261 4 15 4C15.5739 4 16.141 4.11778 16.6629 4.34533C17.1848 4.57289 17.6493 4.90489 18.0248 5.31881C18.4003 5.73273 18.678 6.21887 18.8391 6.74422C19.0002 7.26958 19.0409 7.82184 18.9585 8.36353L17.6305 17.2257C17.5386 17.8287 17.2222 18.3798 16.7392 18.7784C16.2561 19.177 15.6387 19.3964 15 19.3964C14.3613 19.3964 13.7439 19.177 13.2608 18.7784C12.7778 18.3798 12.4614 17.8287 12.3695 17.2257L11.0415 8.36353ZM17.3055 23.8009C17.3055 24.3842 17.0626 24.9435 16.6302 25.3559C16.1979 25.7683 15.6114 26 15 26C14.3885 26 13.8021 25.7683 13.3698 25.3559C12.9374 24.9435 12.6945 24.3842 12.6945 23.8009C12.6945 23.2177 12.9374 22.6584 13.3698 22.246C13.8021 21.8336 14.3885 21.6019 15 21.6019C15.6114 21.6019 16.1979 21.8336 16.6302 22.246C17.0626 22.6584 17.3055 23.2177 17.3055 23.8009Z" :fill="color"/>
    </svg>
</template>
<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PartouExclamationMarkCircled extends Vue {
  @Prop({ required: true })
  color!: string
}
</script>
