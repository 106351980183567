import ApolloClient, { ApolloError } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IWaitingListPropositionService } from './IWaitingListPropositionService'
import { ProposeCreatedWaitingListPropositionDocument, ProposeCreatedWaitingListPropositionMutation, ProposeCreatedWaitingListPropositionMutationVariables, CancelCreatedWaitingListPropositionDocument, CancelCreatedWaitingListPropositionMutation, CancelCreatedWaitingListPropositionMutationVariables, RevokeProposedWaitingListPropositionDocument, RevokeProposedWaitingListPropositionMutation, RevokeProposedWaitingListPropositionMutationVariables } from '@/models'
import Permission from '@/models/enums/Permission'
import { WaitingListPropositionResponse } from './WaitingListPropositionResponse'

@injectable()
export default class WaitingListPropositionService implements IWaitingListPropositionService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async proposeCreatedWaitingListProposition (variables: ProposeCreatedWaitingListPropositionMutationVariables): Promise<WaitingListPropositionResponse> {
    try {
      await this.apollo.mutate<ProposeCreatedWaitingListPropositionMutation>({ mutation: ProposeCreatedWaitingListPropositionDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } } })
      return new WaitingListPropositionResponse(true)
    } catch (e) {
      if (!(e instanceof ApolloError)) {
        throw e
      }
      return new WaitingListPropositionResponse(false)
    }
  }

  async cancelCreatedWaitingListProposition (variables: CancelCreatedWaitingListPropositionMutationVariables): Promise<WaitingListPropositionResponse> {
    try {
      await this.apollo.mutate<CancelCreatedWaitingListPropositionMutation>({ mutation: CancelCreatedWaitingListPropositionDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } } })
      return new WaitingListPropositionResponse(true)
    } catch (e) {
      if (!(e instanceof ApolloError)) {
        throw e
      }
      return new WaitingListPropositionResponse(false)
    }
  }

  async revokeProposedWaitingListProposition (variables: RevokeProposedWaitingListPropositionMutationVariables): Promise<WaitingListPropositionResponse> {
    try {
      await this.apollo.mutate<RevokeProposedWaitingListPropositionMutation>({ mutation: RevokeProposedWaitingListPropositionDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } } })
      return new WaitingListPropositionResponse(true)
    } catch (e) {
      if (!(e instanceof ApolloError)) {
        throw e
      }
      return new WaitingListPropositionResponse(false)
    }
  }
}
