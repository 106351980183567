import moment, { Moment } from 'moment'
import { Vue } from 'vue-property-decorator'

/**
 * Pipe to format a date in a .vue file
 */
Vue.filter('formatDate', function (value: Date, format?: string) {
  if (value) {
    return moment(value).format(format ?? 'DD-MM-YYYY')
  }
})

export function getDate (year?: number, week?: number, day?: number | string): Moment {
  let date = moment()
  if (year) {
    date = date.isoWeekYear(year)
  }

  if (week) {
    date = date.isoWeek(week)
  }

  if (day !== undefined) {
    date = date.isoWeekday(day)
  }

  return date
}

export function isWeekend (date: Date): boolean {
  const day = moment(date).day()
  return day === 0 || day === 6
}

export function isMorning (date: Date): boolean {
  return moment(date).isBefore(moment(date).hours(13))
}

/**
 * Returns the timedifference in hours, rounded to the nearest quarter
 * @param diff time difference in milliseconds
 */
export function toRoundedHours (diff: number): number {
  const tempTime = moment.duration(diff)
  const rawHours = tempTime.asHours()

  // if rawHours == 0.25, it means it is 0 hours and 15 minutes
  // Therefore, we need to extract everything before the decimal to get to total Hours
  const totalHours = Math.floor(rawHours)

  const totalMinutes = Math.round(tempTime.minutes() / 15) * 15 / 60
  return totalHours + totalMinutes
}

/**
 * Test if a given date is between 2 nullable dates
 * If a date is null, the timeperiod is considered to have no start or no end
 * @param date Date to check
 * @param startDate Nullable startdate
 * @param endDate Nullable enddate
 * @param inclusivity Whether to include start- and endDate
 */
export function isBetween (date: Date, startDate?: Date, endDate?: Date, isStartDateIncluded = true, isEndDateIncluded = false): boolean {
  if (startDate && endDate) {
    return moment(date).isBetween(startDate, endDate, undefined, getInclusivity(isStartDateIncluded, isEndDateIncluded))
  } else if (startDate && !endDate) {
    return moment(date).isSameOrAfter(startDate)
  } else if (!startDate && endDate) {
    return moment(date).isSameOrBefore(endDate)
  } else {
    return true
  }
}

/**
 * Check if 2 timeperiods overlap
 * If a date is null, the timeperiod is considered to have no start or end
 * @param startDate1 Startdate of timeperiod 1, can be undefined, so timeperiod will have no startdate
 * @param endDate1 Enddate of timeperiod 1,  can be undefined, so timeperiod will have no enddate
 * @param startDate2 Startdate of timeperiod 2, can be undefined, so timeperiod will have no startdate
 * @param endDate2  Enddate of timeperiod 2, can be undefined, so timeperiod will have no enddate
 * @param inclusivity Whether to include start- and endDate
 */
export function datesOverlap (startDate1?: Date, endDate1?: Date, startDate2?: Date, endDate2?: Date, isStartDateIncluded = false, isEndDateIncluded = false): boolean {
  if ((startDate1 && isBetween(startDate1, startDate2, endDate2, isStartDateIncluded, isEndDateIncluded)) || (endDate1 && isBetween(endDate1, startDate2, endDate2, isStartDateIncluded, isEndDateIncluded)) || (startDate2 && isBetween(startDate2, startDate1, endDate1, isStartDateIncluded, isEndDateIncluded)) || (endDate2 && isBetween(endDate2, startDate1, endDate1, isStartDateIncluded, isEndDateIncluded))) {
    return true
  }
  return false
}

export function getInclusivity (isStartDateIncluded: boolean, isEndDateIncluded: boolean): '()' | '[)' | '(]' | '[]' {
  return `${isStartDateIncluded ? '[' : '('}${isEndDateIncluded ? ']' : ')'}` as '()' | '[)' | '(]' | '[]'
}

export enum unitOfTime {
  year = 'year',
  week = 'week',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute'
}

export function timeBetween (start: Date, end: Date, unit: unitOfTime): number {
  const startMoment = moment(start)
  const endMoment = moment(end)

  const duration = moment.duration(endMoment.diff(startMoment))

  switch (unit) {
  case unitOfTime.minute: {
    return duration.asMinutes()
  }
  case unitOfTime.hour: {
    return duration.asHours()
  }
  case unitOfTime.day: {
    return duration.asDays()
  }
  case unitOfTime.week: {
    return duration.asWeeks()
  }
  case unitOfTime.month: {
    return duration.asMonths()
  }
  case unitOfTime.year: {
    return duration.asYears()
  }
  }
}

export function addBusinessDays (date: Date, daysToAdd: number): Date {
  let daysRemaining = daysToAdd

  const newDate = moment(date)

  while (daysRemaining > 0) {
    newDate.add(1, 'days')
    // moment day 0 = sunday, 6 = saturday
    if (newDate.day() !== 0 && newDate.day() !== 6) {
      daysRemaining--
    }
  }

  return newDate.toDate()
}

export function ageInYears (dateOfBirth: Date, referenceDate: Date): number {
  const mDateOfBirth = moment(dateOfBirth)
  const mReferenceDate = moment(referenceDate)

  let age = mReferenceDate.diff(mDateOfBirth, 'years', false)
  if (mDateOfBirth.startOf('day') > mReferenceDate.add(-age, 'years')) {
    age--
  }

  return age
}

export function startOfUtcDay (date: Date) : Date {
  const utcTicks = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  return new Date(utcTicks)
}

const minimalDateOfBirthInYears = 13
const maximalStartDateOfCareInYears = 13
const minimalDaysBetweenDateOfBirthAndStartDateOfCare = 70
const minimalBusinessDaysBeforeCareStartDate = 10

export function getDateOfBirthMinDate (startDateOfDayCare : Date | undefined) : Date {
  return moment(startDateOfDayCare).subtract(minimalDateOfBirthInYears, unitOfTime.year).toDate()
}

export function getStartDateOfCareMinDate (dateOfBirth : Date | undefined) : Date | undefined {
  const minimumStartDate = addBusinessDays(new Date(), minimalBusinessDaysBeforeCareStartDate)
  return dateOfBirth ? moment.max(moment(minimumStartDate), moment(dateOfBirth).add(minimalDaysBetweenDateOfBirthAndStartDateOfCare, unitOfTime.day)).toDate() : undefined
}

export function getStartDateOfCareMaxDate (dateOfBirth : Date | undefined) : Date | undefined {
  return dateOfBirth ? moment(dateOfBirth).add(maximalStartDateOfCareInYears, unitOfTime.year).toDate() : undefined
}

export function formatCurrentDateForCsvExportTitle () : string {
  const date = new Date()
  return `${date.getFullYear()}_${('0' + (date.getMonth() + 1)).slice(-2)}_${('0' + date.getDate()).slice(-2)}_${('0' + date.getHours()).slice(-2)}_${('0' + date.getMinutes()).slice(-2)}_${('0' + date.getSeconds()).slice(-2)}`
}
