import Vue from 'vue'
import i18n from '@/plugins/i18n'

const waitingListPlaceStatusFilter = function (status: string): string {
  const lowerCased = status.toString().toLowerCase()
  return i18n.t(`services.waitingList.waitingListPlaceStatus.${lowerCased}`)?.toString()
}

Vue.filter('waitingListPlaceStatus', waitingListPlaceStatusFilter)
export default waitingListPlaceStatusFilter
