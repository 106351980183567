<template>
  <v-dialog ref="timePickerDialog" v-model="showTimePickerDialog" :return-value.sync="time" width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field ref="time-picker-text-field" :disabled="disabled" v-model="time" class="text-field" readonly v-on="on" outlined :label="label"></v-text-field>
    </template>
    <v-time-picker v-if="showTimePickerDialog" v-model="time" full-width :min="min" :max="max" format="24hr" @input="onTimeChanged" @click:minute="$refs.timePickerDialog.save(time)" />
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Emit, Ref } from 'vue-property-decorator'

@Component({})
export default class PartouTimePicker extends Vue {
  @Prop({ required: false, default: '' })
  initialValue! : string

  @Prop({ required: true, default: '' })
  label! : string

  @Prop({ required: false, default: false })
  disabled! : boolean

  @Prop({ required: false, default: '' })
  min! : string

  @Prop({ required: false, default: '' })
  max! : string

  @Ref('timePickerDialog')
  timePickerDialog! : HTMLFormElement

  time = ''
  showTimePickerDialog = false

  @Watch('initialValue', { immediate: true })
  onInitialValueChanged () : void {
    this.time = this.initialValue
  }

  @Emit('onTimeChanged')
  onTimeChanged () : string {
    return this.time
  }
}
</script>

<style lang="scss" scoped>
  @import '@/styles/variables/variables.scss';

  .text-field::v-deep {
    width: 86px;
    border-radius: 20px;

    legend {
      display: none;
    }

    .v-label {
      top: 0;
      width: 100%;
      font-size: 14px;
      line-height: 115%;
      text-align: center;
      padding-top: 5px;
    }

    .v-label--active {
      display:none;
    }

    .v-input__control {
      min-height: 32px;
      height: 32px;
    }

    .v-input__slot {
      min-height: 32px;
      height: 32px;
    }

    input {
      color: $partou-primary-black-ninety;
      font-size: 14px;
      line-height: 115%;
      text-align: center;
      padding-top: 0;
      padding-bottom: 4px;
      height: 32px;
    }
  }
</style>
