<template>
  <div class="daySelector">
    <div class="d-flex flex-nowrap">
      <div
        v-for="(day, index) in sortedDays"
        :key="index"
        class="text-center day-selector">
          <template v-if="showLabelsBelow">
            <PartouCheckBox v-model="day.isChecked" :disabled="disabled" :error="!formIsValid" @click="onClick(day)"/>
            <p class="ma-0 mt-1">{{ day.label }}</p>
            <p v-if="withPriority" class="ma-0 mt-1">{{ day.priority  }}</p>
          </template>
          <template v-else>
            <p class="ma-0 mb-4">{{ day.label }}</p>
            <PartouCheckBox v-model="day.isChecked" :disabled="disabled" :error="!formIsValid" @click="onClick(day)"/>
          </template>
      </div>
    </div>
    <label v-if="!formIsValid && !initial" class="error-message">{{ $t(errorMessageTranslationString) }}</label>
  </div>
</template>

<script lang="ts">
import { DayOfWeek } from '@/models'
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator'
import PartouCheckBox from '@/components/PartouComponents/PartouCheckBox/PartouCheckBox.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import DayCheckboxType from './DayCheckboxType'
import DayCheckboxState from './DayCheckboxState'
import { getShortenedDayName } from '@/models/enums/DayOfWeek'

const defaultCheckBoxes = [
  { day: DayOfWeek.Monday, type: DayCheckboxType.Check, isChecked: false },
  { day: DayOfWeek.Tuesday, type: DayCheckboxType.Check, isChecked: false },
  { day: DayOfWeek.Wednesday, type: DayCheckboxType.Check, isChecked: false },
  { day: DayOfWeek.Thursday, type: DayCheckboxType.Check, isChecked: false },
  { day: DayOfWeek.Friday, type: DayCheckboxType.Check, isChecked: false }
]

@Component({
  components: { PartouCheckBox, PartouNotify }
})
export default class DaySelector extends Vue {
  @Prop({ required: false, default: () => defaultCheckBoxes })
  value!: DayCheckboxState[]

  @Prop({ required: false, default: '' })
  errorMessageTranslationString! : string

  @Prop({ required: false, default: true })
  showLabelsBelow! : boolean

  @Prop({ required: false, default: false })
  disabled! : boolean

  @Prop({ required: false, default: false })
  withPriority! : boolean

  formIsValid = true
  DayCheckboxType = DayCheckboxType

  isWaitingListConfirmVisible = false
  lastSelectedWaitingListDay?: DayCheckboxState

  // If no user input is registered yet, don't show the initial error message
  initial = true

  get sortedDays () : DayCheckboxState[] {
    const dayOfWeekOrder = Object.values(DayOfWeek)
    return this.value.sort((a, b) => dayOfWeekOrder.indexOf(a.day) - dayOfWeekOrder.indexOf(b.day))
  }

  @Watch('value', { immediate: true })
  onCheckboxesChanged () : void {
    this.value.forEach(checkbox => {
      checkbox.label = getShortenedDayName(checkbox.day)
    })
  }

  @Emit('input')
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectionChanged (): DayCheckboxState[] {
    return this.value
  }

  onClick (day: DayCheckboxState) : void {
    this.initial = false

    // Ignore double click events
    if (this.isWaitingListConfirmVisible) {
      return
    }

    day.priority = day.isChecked
      ? this.getNextPriority()
      : undefined

    // If day type is waiting list, show dialog that triggers onSelectionChanged on success
    if ((day.isChecked && day.type === DayCheckboxType.Wait)) {
      this.lastSelectedWaitingListDay = day
      this.isWaitingListConfirmVisible = true
      return
    }

    // Otherwise, trigger onSelectionChanged directly
    this.onSelectionChanged()
  }

  getNextPriority () : number {
    if (!this.withPriority) {
      return 0
    }

    const highestPriority = Math.max(...this.value.map(x => x.priority ?? 0))
    return highestPriority + 1
  }
}
</script>

<style lang="scss">
.day-selector-checkbox {
  padding: 0px !important;
  margin-top: 0px !important;

  .v-input__control {
    .v-input--selection-controls__input {
      margin: 0px !important;
    }
  }
}

.day-selector {
  font-size: 14px !important;
}

.day-selector + .day-selector {
  margin-left: 16px !important;
}

.partou-check {
  width: 10px !important;
  height: 10px !important;
}
</style>
