<template>
  <div ref="analysis" id="analysis">
    <v-row>
      <v-tabs v-if="analysisTabs && analysisTabs.length" ref="service-navigation-tabs" :show-arrows="false"
        class="rounded-xl mb-4">
        <v-tab v-for="(tab, index) in analysisTabs" :key="index" @click="onTabClicked(index)"
          :to="tab.route" :disabled="tab.disabled" ref="service-navigation-tab" class="ml-4 mr-4">
          {{tab.label}}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row>
      <router-view/>
    </v-row>
  </div>
</template>

<script lang="ts">
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { Vue, Component, Watch } from 'vue-property-decorator'

interface AnalysisTab {
  route: string | null;
  label: string;
  disabled?: boolean;
}

@Component
export default class AnalysisPage extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  analysisTabs: AnalysisTab[] = []
  activeTabIndex = 0

  @Watch('$route', { immediate: true })
  onRouteChanged (): void {
    this.buildServiceTabs()
    const activeTabIndex = this.analysisTabs.findIndex(x => x.route === this.$route.path)
    this.activeTabIndex = activeTabIndex > -1 ? activeTabIndex : 0
  }

  buildServiceTabs (): void {
    this.analysisTabs = []

    if (this.authService.hasPermission([Permission.GetAnalysis])) {
      this.analysisTabs.push({ route: '/analyse/service', label: this.$t('analysis.menu.availabilityAnalysis').toString() })
    }

    if (this.authService.hasPermission([Permission.GetService])) {
      this.analysisTabs.push({ route: '/analyse/wachtlijst', label: this.$t('analysis.menu.waitingListAllocationAnalysis').toString() })
    }

    if (this.authService.hasPermission([Permission.GetService])) {
      this.analysisTabs.push({ route: '/analyse/wachtlijst_new', label: this.$t('analysis.menu.waitingListPropositionAnalysis').toString() })
    }
  }

  onTabClicked (tabIndex: number): void {
    this.activeTabIndex = tabIndex
    this.navigateToActiveRoute()
  }

  navigateToActiveRoute (): void {
    if (!this.analysisTabs?.length) {
      return
    }

    const targetPath = this.analysisTabs[this.activeTabIndex]?.route
    if (targetPath && this.$route.path !== targetPath) {
      Vue.nextTick().then(() => this.$router.push(targetPath))
    }
  }
}
</script>

<style lang="scss" scoped>
#analysis {
  padding-top: 8px;
}
</style>
