import Permission from '@/models/enums/Permission'
import { injectable } from 'inversify'
import Keycloak, { KeycloakInitOptions } from 'keycloak-js'
import { IAuthService } from './IAuthService'

const AUTH_URI = process.env.VUE_APP_AUTH_URI_PLACEHOLDER
const AUTH_REALM = process.env.VUE_APP_AUTH_REALM
const AUTH_CLIENTID = process.env.VUE_APP_AUTH_CLIENTID

@injectable()
export class AuthService implements IAuthService {
  private _keycloak: Keycloak
  // Minimum remaining time a token is valid if it should be refreshed
  // Lifespan of token is configured in keycloak
  private readonly _minValidity = 120

  get isAuthenticated (): boolean {
    return !!this._keycloak.authenticated
  }

  get tokenParsed (): Record<string, unknown> {
    return Object(this._keycloak.tokenParsed)
  }

  constructor (client?: Keycloak) {
    this._keycloak = client ?? new Keycloak({ clientId: String(AUTH_CLIENTID), realm: String(AUTH_REALM), url: AUTH_URI })
  }

  async getTokenAsync (): Promise<string> {
    await this._keycloak.updateToken(this._minValidity)
      .catch(() => this.loginAsync(false, window.location.href)) // Redirect to login if token could not be updated (e.g. refresh token has expired for some reason)

    return String(this._keycloak.token)
  }

  async loginAsync (silent: boolean, redirectUri?: string, client?: Keycloak): Promise<void> {
    if (!this.isAuthenticated) {
      this._keycloak = client ?? new Keycloak({ clientId: String(AUTH_CLIENTID), realm: String(AUTH_REALM), url: AUTH_URI })
      const options : KeycloakInitOptions = {
        onLoad: silent ? 'check-sso' : 'login-required',
        redirectUri
      }

      if (options.onLoad === 'check-sso' && !options.silentCheckSsoRedirectUri) {
        options.silentCheckSsoRedirectUri = window.location.origin + '/silent-check-sso.html'
      }

      await this._keycloak.init(options)

      this._keycloak.onTokenExpired = () => {
        this.getTokenAsync() // Automaticly renew refresh token when it's expired
      }
    }
  }

  async logoutAsync (): Promise<void> {
    await this._keycloak.logout()
  }

  hasPermission (permissions: Permission[]): boolean {
    return permissions.some((x) => this._keycloak.hasResourceRole(x))
  }
}
