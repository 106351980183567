<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 3.66663V8.66663L10.3333 12L7 15.3333V20.3333H17V15.3333L13.6667 12L17 8.66663V3.66663H7ZM15.3333 15.75V18.6666H8.66667V15.75L12 12.4166L15.3333 15.75ZM12 11.5833L8.66667 8.24996V5.33329H15.3333V8.24996L12 11.5833Z" :fill="color"/>
  </svg>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PartouWait extends Vue {
  @Prop({ required: true })
  color!: string
}
</script>
