// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateInputField .v-picker__title{background:linear-gradient(45deg,#a02846 13.5%,#e8565d 87%)!important;padding-left:24px!important;padding-right:24px!important;padding-top:16px!important;padding-bottom:16px!important}.dateInputField .v-picker__title .v-picker__title__btn{opacity:1!important;color:#fff!important}.dateInputField .v-picker__title .v-picker__title__btn svg{width:12px;height:12px;margin-left:8px}.dateInputField .v-picker__title .v-picker__title__btn svg path{fill:#fff!important}.dateInputField .v-input__icon button,.dateInputField .v-input__icon i{color:#8e8e8d!important;font-size:24px!important}.dateInputField .v-date-picker-table{padding-left:16px!important;padding-right:16px!important;padding-bottom:16px!important}.dateInputField .v-picker__body .v-date-picker-table table tbody tr td button{color:#343432;font-weight:600;opacity:1!important}.dateInputField .v-picker__body .v-date-picker-table table tbody tr td button.accent,.dateInputField .v-picker__body .v-date-picker-table table tbody tr td button.accent:before{opacity:1!important;background:#343432!important;color:#fff!important}.dateInputField .v-picker__body .v-date-picker-header{padding-top:16px!important}.dateInputField .v-picker__body .v-date-picker-header .v-btn--icon{border-radius:20px!important;box-shadow:0 0 30px rgba(0,0,0,.1)}.dateInputField .v-picker__body .v-date-picker-header .v-btn--icon .v-btn__content svg path{color:#1d1d1b;width:16px!important;height:16px!important}.dateInputField.v-dialog{border-radius:20px!important}.dark-placeholder input::-moz-placeholder{color:#4a4a49!important}.dark-placeholder input::placeholder{color:#4a4a49!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partouPrimaryPurple": "#582354",
	"partouPrimaryPurpleSixty": "#9b7b98",
	"partouPrimaryPurpleFourty": "#bca7bb",
	"partouPrimarySalmon": "#ef575d",
	"partouPrimarySalmonSixty": "#f59a9e",
	"partouPrimarySalmonFourty": "#f9bcbe",
	"partouPrimaryOrange": "#f6a500",
	"partouPrimaryOrangeSixty": "#fac966",
	"partouPrimaryOrangeFourty": "#fbdb99",
	"partouPrimaryWarmGrey": "#f2e9e2",
	"partouPrimaryWarmGreySixty": "#f7f2ee",
	"partouPrimaryWarmGreyFourty": "#faf6f3",
	"partouPrimaryWhite": "#fff",
	"partouPrimaryBackdropWhite": "#f9f9f9",
	"partouPrimaryBlue": "#2782e3",
	"partouSecondaryBordeaux": "#b61544",
	"partouSecondaryBordeauxSixty": "#d3738f",
	"partouSecondaryBordeauxFourty": "#e2a1b4",
	"partouSecondaryPeach": "#fa8870",
	"partouSecondaryPeachSixty": "#fcb8a9",
	"partouSecondaryPeachFourty": "#fdcfc6",
	"partouSecondaryGreen": "#91c37d",
	"partouSecondaryGreenSixty": "#bddbb1",
	"partouSecondaryGreenFourty": "#d3e7cb",
	"partouSecondaryBlue": "#73a4d8",
	"partouSecondaryBlueSixty": "#abc8e8",
	"partouSecondaryBlueFourty": "#c7dbef",
	"partouSecondaryBrightGreen": "#3bc059",
	"partouPrimaryGradient": "linear-gradient(45deg,#a02846 13.5%,#e8565d 87%)",
	"partouSecondaryGradient": "linear-gradient(61.41deg,#572556 7.85%,#eb5e5e 64.25%,#f7a70a 91.8%)",
	"partouPrimaryBlack": "#1d1d1b",
	"partouPrimaryBlackNinty": "#343432",
	"partouPrimaryBlackEighty": "#4a4a49",
	"partouPrimaryBlackSeventy": "#616160",
	"partouPrimaryBlackSixty": "#777776",
	"partouPrimaryBlackFifty": "#8e8e8d",
	"partouPrimaryBlackFourty": "#a5a5a4",
	"partouPrimaryBlackThirty": "#bcbcbb"
};
export default ___CSS_LOADER_EXPORT___;
