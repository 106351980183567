<template>
<div>
  <v-alert v-if="maxFilesWarning" :icon="false" class="error--text" colored-border type="error">
    <v-icon>$vuetify.icons.partouClose</v-icon>
    {{ $t('dropzone.dropzoneMaxFilesWarning') }}
  </v-alert>
  <v-input
  :rules="rules">
    <file-pond
      class="elevation-2 rounded-lg filepond mt-4 col-10"
      ref="filepond"
      max-files="9"
      :accepted-file-types="this.allowedFiles.map((af) => af.toString()).join(',')"
      :label-idle="renderIdleLabel()"
      :credits='""'
      :files="initialFilePondFiles"
      :allow-multiple="true"
      :rules="rules"
      :disabled="isDisabled"
      :allowProcess="false"
      :server="serverConfig"
      :labelFileTypeNotAllowed="$t('dropzone.dropzonePreviewErrorIncorrectType')"
      :imagePreviewHeight="200"
      :imagePreviewMaxFileSize="'10mb'"
      :labelFileProcessing="$t('dropzone.dropzoneLoadingText')"
      :labelFileProcessingComplete="''"
      :labelTapToCancel="''"
      :labelTapToUndo="''"
      @warning="onWarning"
      @addfile="onFileAdded"
      @removefile="onFileDeleted"
      @processfile="onUploadCompleted"
    />
  </v-input>
</div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'
import vueFilePond from 'vue-filepond'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'

import { AllowedFiles } from '@/components/DragAndDropFiles/AllowedFiles'
import { FileOrigin, FilePondErrorDescription, FilePondFile, FilePondInitialFile, LoadServerConfigFunction, ProcessServerConfigFunction, RemoveServerConfigFunction, RevertServerConfigFunction } from 'filepond'
import { ServiceMedia } from '@/models'

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileRename)
const { setOptions } = require('vue-filepond/dist/vue-filepond') // eslint-disable-line @typescript-eslint/no-var-requires

setOptions({
  fileRenameFunction: (file: { basename: string, extension: string, name: string }) => {
    return `${String(Date.now())}${file.extension}`
  }
})
type FilePondServerConfig = { load: LoadServerConfigFunction, process: ProcessServerConfigFunction, remove: RemoveServerConfigFunction, revert: RevertServerConfigFunction }

@Component({
  components: { FilePond }
})
export default class DragAndDropFiles extends Vue {
  @Prop({ required: true })
  allowedFiles! : AllowedFiles[]

  @Prop({ required: false, default: false })
  isDisabled! : boolean

  @Prop({ required: false, default: () => [] })
  files?: ServiceMedia[]

  @Prop({ required: false, default: () => [] })
  rules?: ((value: number | string) => boolean | string)[]

  initialFilePondFiles: FilePondInitialFile[] = []
  addedFiles : FilePondFile[] = []

  maxFilesWarning = false

  mounted () : void {
    if (this.files) {
      this.initialFilePondFiles = this.files.map(file => ({
        source: file.url,
        options: { type: 'local' }
      } as FilePondInitialFile))
    }
  }

  serverConfig: FilePondServerConfig = {
    load: async (source: string, load: (blob: Blob) => void) => {
      const response = await fetch(new Request(source))
      const blob = await response.blob()
      load(blob)
    },
    process: (fileName: string, file: Blob, metadata: any, load: (fileName: string) => void) => load(fileName), // eslint-disable-line @typescript-eslint/no-explicit-any
    remove: (source: string, load: () => void) => load(),
    revert: (fileName: string, load: () => void) => load()
  }

  onWarning (warning : Partial<{body : string}>) : void {
    if (warning.body === 'Max files') {
      this.maxFilesWarning = true
    }
  }

  onFileDeleted (error : FilePondErrorDescription, filePondFile : FilePondFile) : void {
    if (!error) {
      this.$emit('onFileDeleted', filePondFile.file)
    }
  }

  onFileAdded (error : FilePondErrorDescription, filePondFile : FilePondFile) : void {
    if (!error && filePondFile.origin !== FileOrigin.LOCAL) {
      this.maxFilesWarning = false
      this.$emit('onFileAdded', filePondFile.file)
      this.addedFiles.push(filePondFile)
      this.onUploadStarted()
    }
  }

  @Emit('onUploadStarted')
  onUploadStarted () : void {
    // Used to emit the onUploadStarted event
  }

  onUploadCompleted () : void {
    if (this.addedFiles.every(x => x.status === 5)) {
      this.$emit('onUploadCompleted')
    }
  }

  renderIdleLabel () : string {
    return `<div class="filepond-custom-label mt-8 mb-8 d-flex justify-center flex-column">
              <i aria-hidden="true" class="v-icon notranslate mdi mdi-cloud-upload theme--light"></i>
              <h3>${this.$t('dropzone.dropzoneMessageTitle').toString()}</h3>
              <div>${this.$t('dropzone.dropzoneMessageText').toString()}</div>
            </div>`
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

.filepond {
  min-height: 96px;
  background-color: #f1f0ef;
}

.filepond-custom-label {
  font-family: $body-font-family;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.375rem;
  margin-top:16px;
  padding: 16px;
}

.filepond--item {
    width: calc(20% - .5em);
}
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.error--text {
  font-size: 0.875rem !important;
}

.error--text svg {
  width: 0.875rem;
  height: 0.875rem;
  fill: $partou-red;
}
</style>
