import ApolloClient, { ApolloError } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IOfferService } from '@/services/OfferService/IOfferService'
import Permission from '@/models/enums/Permission'
import { CalculateCapacityForOfferDocument, CalculateCapacityForOfferMutation, GetOfferByIdDocument, GetOfferByIdQuery, Offer } from '@/models'
import { StatusCodes } from 'http-status-codes'

@injectable()
export default class OfferService implements IOfferService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async calculateCapacityForOfferAsync (offerId: string): Promise<boolean> {
    try {
      await this.apollo.mutate<CalculateCapacityForOfferMutation>({
        mutation: CalculateCapacityForOfferDocument,
        variables: { offerId },
        context: {
          headers: { 'X-Hasura-Role': Permission.CallActions }
        }
      })

      return true
    } catch (e) {
      if (e instanceof ApolloError && e.graphQLErrors.length > 0) {
        const statusCode = Number(e.graphQLErrors[0].extensions.code) as StatusCodes
        if (statusCode === StatusCodes.UNPROCESSABLE_ENTITY) {
          return false
        }
      }

      throw e
    }
  }

  async getOfferByIdAsync (id: string): Promise<Offer> {
    const result = await this.apollo.query<GetOfferByIdQuery>({
      query: GetOfferByIdDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.CallActions } }
    })
    return result.data?.offer_by_pk as Offer // eslint-disable-line
  }
}
