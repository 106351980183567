<template>
  <div id="app">
    <h3>404 - Page not found</h3>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class NotFoundPage extends Vue {}
</script>
