<template>
  <v-dialog max-width="1200px" content-class="children-dialog" scrollable :value="show" @input="dialogValueChanged">
    <PartouCard class="children-dialog-card" hideActions>
      <template slot="card-body">
        <div ref="childrenDialogContent" class="children-dialog-content">
          <h1 class="children-dialog-header">{{ value.group.name }}</h1>

          <div v-for="(ageCategoryData, index) in value.ageCategoryData" :key="index">
            <h2 class="children-table-header">{{ $t('pages.ServiceOffer.childrenTable.heading', { ageCategory: getAgeCategoryString(ageCategoryData)}) }}</h2>
            <ServiceOfferChildrenTable :value="getTableItems(ageCategoryData)" />
          </div>

          <div v-for="(ageCategoryData, index) in value.ageCategoryData" :key="'flex' + index">
            <h2 class="children-table-header flex-reservations">{{ $t('pages.ServiceOffer.childrenTable.headingFlex', { ageCategory: getAgeCategoryString(ageCategoryData)}) }}</h2>
            <ServiceOfferChildrenTable :value="getFlexTableItems(ageCategoryData)" />
          </div>
        </div>
      </template>
    </PartouCard>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Emit, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServiceOfferChildrenTable from './ServiceOfferChildrenTable.vue'
import ServiceOfferChildTableItem from './ServiceOfferChildTableItem'
import { PartOfDay, Reservation, ReservationStatus } from '@/models'
import AgeCategoryGroupData from '../../models/AgeCategoryGroupData.model'
import { getAgeCategoryLimits } from '@/models/enums/AgeCategory'
import PivotGridRowData from '../../models/PivotGridRowData.model'

@Component({
  components: { PartouCard, ServiceOfferChildrenTable }
})
export default class ServiceOfferChildrenDialog extends Vue {
  @Ref('childrenDialogContent')
  childrenDialogContent!: HTMLElement

  @Prop({ required: true })
  show!: boolean

  @Watch('show')
  showChanged () : void {
    this.childrenDialogContent.scrollTop = 0
  }

  @Emit('showChanged')
  dialogValueChanged () : void {
    // Emit showChanged
  }

  @Prop({ required: true })
  value!: PivotGridRowData

  getAgeCategoryString (rowData: AgeCategoryGroupData) : string {
    const ageCategoryLimits = getAgeCategoryLimits(rowData.ageCategory, true)

    if (!ageCategoryLimits.max) {
      return `${ageCategoryLimits.min}+`
    }

    return `${ageCategoryLimits.min}-${ageCategoryLimits.max}`
  }

  getTableItems (rowData: AgeCategoryGroupData) : ServiceOfferChildTableItem[] {
    return this.mapTableItems(rowData.regularReservations)
  }

  getFlexTableItems (rowData: AgeCategoryGroupData) : ServiceOfferChildTableItem[] {
    return this.mapTableItems(rowData.flexReservations)
  }

  mapTableItems (reservations: Reservation[]) : ServiceOfferChildTableItem[] {
    return [...new Set(reservations.map(x => x.child.id))]
      .map(childId => this.toServiceOfferChildTableItem(childId, reservations))
  }

  toServiceOfferChildTableItem (childId: string, reservations: Reservation[]) : ServiceOfferChildTableItem {
    const childReservations = reservations.filter(x => x.child.id === childId)

    if (!childReservations || childReservations.length === 0) {
      throw new Error('Invalid call on toServiceOfferChildTableItem')
    }

    return {
      ...childReservations[0].child,
      morning: childReservations.some(x => x.partOfDay === PartOfDay.Morning),
      afternoon: childReservations.some(x => x.partOfDay === PartOfDay.Afternoon),
      reservedSeat: childReservations[0].status === ReservationStatus.Reserved || childReservations[0].status === ReservationStatus.Option
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

.children-dialog {
  overflow-y: hidden !important;
  margin-left: auto;
  margin-right: auto;

  .children-dialog-card {
    max-height: 90vh;
    overflow: hidden;

    .children-dialog-content {
      max-height: 88vh;
      padding: 16px;
      overflow-y: auto !important;

      .children-dialog-header {
        color: $partou-primary-black-ninety !important;
      }

      .children-table-header {
        margin-top: 16px;
        &.flex-reservations {
          color: $partou-secondary-blue !important;
        }
      }
    }
  }
}
</style>
