<template>
  <v-checkbox
  ref="partou-checkbox"
  v-on="$listeners"
  class="large day-selector-checkbox align-self-start"
  hide-details
  :on-icon="onIconString"
  :off-icon="offIconString"
  v-model="checkboxValue"
  :error="error"
  :disabled="disabled"
  :rules="rules"/>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PartouCheckBox extends Vue {
  @Prop({})
  value! : boolean

  @Prop({ required: false, default: '$vuetify.icons.partouCheck' })
  onIconString? : string

  @Prop({ required: false, default: '' })
  offIconString? : string

  @Prop({ required: false, default: false })
  disabled? : boolean

  @Prop({})
  rules? : []

  @Prop({})
  error? : boolean

  get checkboxValue () : boolean {
    return this.value
  }

  set checkboxValue (value : boolean) {
    this.$emit('input', value)
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/variables/variables.scss";

$checkbox-large-size: 36px;
$checkbox-icon-color: $partou-red;
$max-tablet-width: 768px;

.day-selector-checkbox {
  padding: 0px !important;
  margin-top: 0px !important;
  width: 24px !important;

  .v-input__control {
    .v-input--selection-controls__input {
      margin: 0px !important;
    }
  }
}

.v-input--checkbox.large {
  i {
    font-size: $checkbox-large-size - 10px !important;
  }

  .primary--text {
    color: $checkbox-icon-color !important;
  }

  [class*="__ripple"] {
    height: $checkbox-large-size !important;
    width: $checkbox-large-size !important;
    left: -($checkbox-large-size - 10) / 2 !important;
    top: -($checkbox-large-size - 10) / 2 !important;
  }
}
.v-input--is-disabled {
  opacity: 0.5;
  background: $partou-grey-lightened
}
.v-input--checkbox {
  color: transparent !important;
  caret-color: transparent !important;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  font-size: calc(100% - 10px) !important;

  .primary--text {
    color: $checkbox-icon-color !important;
  }

  @media (max-width: $max-tablet-width) {
    [class*="__input"]:hover [class*="__ripple"]:before {
      background: transparent !important;
    }
  }
}
</style>
