<template>
  <div class="home">
    <div class="background-image"></div>
    <div class="box">
      <div>
        <span class="welcome">{{$t('home.title1')}}</span>
        <br />
        <span class="portal">{{$t('home.title2')}}</span>
      </div>
      <ServicePicker class="service-picker" v-model="currentService" @input="onCurrentServiceChanged" :services="services"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Service } from '@/models'
import ServicePicker from '@/components/ServicePicker'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import { ROUTES } from '@/router/routes'
import { IAuthService } from '@/services/AuthService/IAuthService'
import container, { SERVICE_IDENTIFIERS } from '@/container'

const serviceModule = namespace(NAMESPACES.service)

@Component({
  components: { ServicePicker }
})
export default class Home extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => void
  @serviceModule.State(STATE.service.services) services!: Array<Service>

  currentService: Service | null = null

  created (): void {
    this.getServices()
  }

  onCurrentServiceChanged (service: Service | null): void {
    if (service?.id) {
      this.$router.push({ name: ROUTES.serviceDetail, params: { entityId: service.id } })
    }
  }
}
</script>

<style lang='scss'>
@import '@/styles/variables/variables.scss';

.home {
  width: 100vw;
  height: calc(100vh - 140px);

  .welcome {
    color: $partou-primary-black-ninety;
    font-size: 24px;
  }

  .portal {
    color: $partou-primary-black-ninety;
    font-size: 32px;
    font-weight: 700;
  }

  .service-picker {
    margin-top: 20px;
  }
}

.box {
  padding: 60px;
  gap: 40px;
  position: absolute;
  width: 433px;
  left: calc(50% - 433px/2 - 0.5px);
  top: calc(50% - 286px/2 + 0.5px);
  background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(4px);
  border-radius: 32px;
}

.background-image {
  width: 100vw;
  height: calc(100vh - 80px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url("~@/assets/images/home-page.png");
  position: fixed;
  left: 0;
  top: 80px;
  z-index: 0;
}
</style>
