<template>
<div class="partou-slider d-flex justify-center flex-column">
  <p v-if="labelPosition == 'top'">{{label}}</p>
  <v-slider
    v-model="sliderValue"
    :vertical="vertical"
    :max="max"
    :min="min"
    :value="sliderValue"
    :inverse-label="labelPosition == 'right' ? true : false"
    :label="labelPosition != 'bottom' ? label : ''"
    :track-color="' #CB5A5C'"
    :track-fill-color="'#C4C4C4'"
    :disabled="disabled"
    @change="onSliderValueChanged">
  <template v-slot:prepend>
    <div>
      <p v-if="labelPosition == 'bottom'" class="d-flex justify-center">{{label}}</p>
      <v-text-field
        v-model="sliderValue"
        class="mt-0 pt-0 slider-text-field d-flex justify-center"
        type="number"
        style="width:66px"
        suffix="%"
        @keydown="validateKeyDown"
        @change="onSliderValueChanged"
        :disabled="disabled"
        :max="max"
        :min="min"
        hide-details
        dense
        full-width>
      </v-text-field>
      </div>
  </template>
</v-slider>
</div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class PartouSlider extends Vue {
  @Prop({ required: true })
  value!: number

  @Prop({ required: false, default: '' })
  label?: string

  @Prop({ required: false, default: 'left' })
  labelPosition?: 'bottom' | 'left'| 'right' | 'top'

  @Prop({ required: false, default: 1 })
  step!: number

  @Prop({ required: false, default: 0 })
  min!: number

  @Prop({ required: false, default: 100 })
  max!: number

  @Prop({ required: false, default: false })
  vertical?: boolean

  @Prop({ required: false, default: false })
  disabled?: boolean

  sliderValue = 0

  @Watch('value', { immediate: true })
  onValueChanged () : void {
    Vue.set(this, 'sliderValue', this.value)
  }

  validateKeyDown (e : any) : void { // eslint-disable-line
    const reg = /^[0-9]|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown$/g
    if (!reg.test(e.key)) {
      e.preventDefault()
    }
  }

  @Emit('input')
  onSliderValueChanged () : number {
    let value = Number(this.sliderValue)
    const modolo = (value % this.step)

    modolo >= Math.round(this.step / 2) ? value += (this.step - modolo) : value -= modolo

    if (value >= this.min && value <= this.max) {
      return value
    } else if (value >= this.max) {
      return this.max
    }
    return this.min
  }
}
</script>

<style lang="scss" scoped>
  .partou-slider {
    width: 70px;
  }

  .slider-text-field {
    width: 44px;
  }
</style>

<style lang="scss">
.v-slider {
  .v-slider__track-container  {
    .v-slider__track-fill {
      width: 4px;
      left: -1px;
    }
  }

  .v-slider__thumb  {
    width: 16px !important;
    height: 16px !important;
    left: -8px !important;

    &.primary {
      width: 20px !important;
      height: 20px !important;
      left: -10px !important;
    }
  }
}
</style>
