<template>
  <div :class="'dateInputField' + (isCustomPlaceholder() ? ' dark-placeholder' : '')">
    <v-dialog
      content-class="dateInputField"
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      persistent
      width="290px"
      @click:outside="onClickOutside">
        <template v-slot:activator="{ on }">
          <PartouTextField
            ref="picker-input"
            name="picker-input"
            v-model="dateString"
            class="picker-input pa-0"
            iconName="$vuetify.icons.partouCalendar"
            iconPosition="left"
            :label="getPlaceholder()"
            :errorMessage="internalErrorMessage"
            :isRequired="isRequired"
            :disabled="disabled"
            single-line
            readonly
            validation-on-blur
            @click="activateModal"
            v-bind="$attrs"
            v-on="on" />
        </template>
        <v-date-picker
          ref="picker"
          locale="nl"
          name="picker"
          year-icon="$vuetify.icons.partouEdit"
          next-icon="$vuetify.icons.partouNext3"
          prev-icon="$vuetify.icons.partouPrevious3"
          v-model="formattedDateString"
          :first-day-of-week="1"
          :autocomplete="autocomplete"
          :max="maxDate ? this.getFormattedDateString(maxDate) : maxDate"
          :min="minDate ? this.getFormattedDateString(minDate) : minDate"
          :weekday-format="formatCellHeader"
          :header-date-format="formatHeader"
          :title-date-format="formatTitle"
          :type="type"
          :allowed-dates="isAllowedDate"
          @change="onDateChanged"
        />
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import moment from 'moment'

@Component({
  components: { PartouTextField },
  methods: {
    moment: () => {
      return moment()
    }
  }
})
export default class DateInputField extends Vue {
  @Prop({ required: true, default: undefined })
  value! : Date | undefined

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ default: () => { return undefined } })
  minDate!: Date

  @Prop({ default: () => { return undefined } })
  maxDate!: Date

  @Prop({ required: false, default: '' })
  errorMessageTranslationString?: string

  @Prop({ required: false })
  activePicker?: string

  @Prop({ required: false })
  placeholder?: string

  @Prop({ required: false, default: '' })
  autocomplete!: string

  @Prop({ required: false, default: false })
  startAtMinDate!: boolean

  @Prop({ required: false, default: 'date' })
  type!: string

  @Prop({ required: false, default: () => { return true } })
  isAllowedDate!: (date : Date) => boolean

  internalErrorMessage = this.errorMessageTranslationString
  date : Date = this.value ? this.value : new Date()
  dateString = this.value ? moment(new Date(this.value)).format('DD - MM - YYYY') : ''
  formattedDateString = this.value ? moment(new Date(this.value)).format('YYYY-MM-DD') : ''
  modal = false

  getFormattedDateString (date : Date) : string {
    return moment(date).format('YYYY-MM-DD')
  }

  getPlaceholder () : string {
    return this.placeholder || moment(this.date).format('DD - MM - YYYY')
  }

  isCustomPlaceholder () : boolean {
    return !!this.placeholder
  }

  @Watch('minDate')
  onMinDateChanged () : void {
    if (this.formattedDateString === '' && this.minDate && this.startAtMinDate) {
      this.formattedDateString = moment(new Date(this.minDate)).format('YYYY-MM-DD')
    }
  }

  @Watch('modal')
  onModalChanged () : void {
    if (this.modal && this.activePicker) {
      setTimeout(() => ((this.$refs.picker as any).activePicker = this.activePicker)) // eslint-disable-line @typescript-eslint/no-explicit-any, no-unused-expressions
    }
  }

  @Watch('errorMessageTranslationString', { immediate: true })
  onErrorMessageTranslationStringChanged () : void {
    this.internalErrorMessage = this.errorMessageTranslationString
  }

  activateModal () : void {
    this.modal = true
  }

  onDateChanged (value : string) : void {
    const date = new Date(value)
    Vue.set(this, 'date', date)
    Vue.set(this, 'dateString', moment(date).format('DD - MM - YYYY'))
    Vue.set(this, 'formattedDateString', moment(date).format('YYYY-MM-DD'))

    this.$emit('input', this.date)
    this.modal = false
  }

  onClickOutside () : void {
    this.modal = false
  }

  formatCellHeader (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('dd') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }

  formatHeader (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('MMMM YYYY') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }

  formatTitle (date : Date) : string {
    const formattedString = date ? moment(date).locale('nl').format('dd DD MMM') : ''
    return formattedString.charAt(0).toUpperCase() + formattedString.substring(1)
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/colours.scss';
  .dateInputField {
    .v-picker__title {
      background: $partou-primary-gradient !important;
      padding-left: 24px !important;
      padding-right: 24px !important;
      padding-top: 16px !important;
      padding-bottom: 16px !important;
      .v-picker__title__btn {
        opacity: 1 !important;
        color: $partou-primary-white !important;
        svg {
          width: 12px;
          height: 12px;
          margin-left: 8px;
          path {
            fill: $partou-primary-white !important;
          }
        }
      }
    }
    .v-input__icon i,  .v-input__icon button {
      color: $partou-primary-black-fifty !important;
      font-size: 24px !important;
    }

    .v-date-picker-table {
      padding-left: 16px !important;
      padding-right: 16px !important;
      padding-bottom: 16px !important;
    }

    .v-picker__body .v-date-picker-table table tbody tr td button {
      color: $partou-primary-black-ninety;
      font-weight: 600;
      opacity: 1 !important;
      &.accent {
        &::before{
          opacity: 1 !important;
          background: $partou-primary-black-ninety !important;
          color: $partou-primary-white !important
        }
        opacity: 1 !important;
        background: $partou-primary-black-ninety !important;
        color: $partou-primary-white !important;
      }
    }

    .v-picker__body .v-date-picker-header  {
      padding-top: 16px !important;
      .v-btn--icon {
        border-radius: 20px !important;
        box-shadow: 0px 0px 30px rgb(0 0 0 / 10%);
        .v-btn__content svg path {
          color: $partou-primary-black;
          width: 16px !important;
          height: 16px !important;
        }
      }
    }
  }

  .dateInputField.v-dialog {
    border-radius: 20px !important;
  }

  .dark-placeholder {
    input::placeholder {
      color: $partou-primary-black-eighty !important;
    }
  }
</style>
