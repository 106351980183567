enum ServiceVarietyName {
    KDV = 'KDV',
    NSO = 'NSO',
    VSO = 'VSO'
}

export default ServiceVarietyName

export function parseStringToServiceVarietyName (value: string): ServiceVarietyName {
  switch (value.toLowerCase()) {
  case 'kdv': return ServiceVarietyName.KDV
  case 'nso': return ServiceVarietyName.NSO
  case 'vso': return ServiceVarietyName.VSO
  }
  throw new Error(`given string: ${value} cannot be parsed to ServiceVarietyName`)
}
