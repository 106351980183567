<template>
 <div>
    <h1 class="pl-8 pt-4">{{ $t('waitingListPropositions.table.heading') }}</h1>
    <WaitingListPropositionTable
      :items="items"
      :expandedItem="expandedItem"
      :totalCount="totalCount"
      :services="services"
      :isLoading="isLoading"
      @onTableOptionsChanged="onTableOptionsChangedAsync"
      @onExpandedChanged="onExpandedChangedAsync">
    </WaitingListPropositionTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { Service, WaitingListProposition } from '@/models'
import ServicePicker from '@/components/ServicePicker'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Debounce from '@/utils/decorators/debounceDecorator'
import WaitingListPropositionTable from './WaitingListPropositionTable'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import WaitingListPropositionTableOptions from './WaitingListPropositionTable/WaitingListPropositionTableOptions'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'

const serviceModule = namespace(NAMESPACES.service)

@Component({
  components: { ServicePicker, PartouCard, WaitingListPropositionTable }
})
export default class WaitingListOfferPage extends Vue {
  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)

  @serviceModule.State(STATE.service.services) services!: Service[]
  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => void

  created (): void {
    this.getServices()
  }

  items: Partial<WaitingListProposition>[] = []
  expandedItem? : Partial<WaitingListProposition> = {}
  totalCount = 0
  isLoading = false

  @Debounce(400)
  async onTableOptionsChangedAsync (tableOptions : WaitingListPropositionTableOptions) : Promise<void> {
    this.isLoading = true
    const result = await this.propositionService.getWaitingListPropositionsPageAsync(tableOptions)
    this.items = result.items
    this.totalCount = result.totalCount
    this.expandedItem = {}
    this.isLoading = false
  }

  async onExpandedChangedAsync (items : Partial<WaitingListProposition>[]) : Promise<void> {
    this.expandedItem = {}
    if (items?.[0]?.id) {
      this.isLoading = true
      this.expandedItem = await this.propositionService.getOnePropositionAsync(items[0].id)
      this.isLoading = false
    }
  }
}
</script>
