/* eslint-disable */
import gql from 'graphql-tag';
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Object: any;
  bytea: any;
  date: any;
  float8: any;
  json: any;
  jsonb: any;
  numeric: any;
  smallint: any;
  time: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};
export type AcceptProposedWaitingListPropositionOutput = {
  __typename?: 'AcceptProposedWaitingListPropositionOutput';
  hash: Scalars['String'];
  isBookingCompleted: Scalars['Boolean'];
  isBookingProcessableByWhyPlan: Scalars['Boolean'];
};
export type AddressRequest = {
  HouseNumber: Scalars['String'];
  HouseNumberAddition?: Maybe<Scalars['String']>;
  Locality: Scalars['String'];
  PostalCode: Scalars['String'];
  Street: Scalars['String'];
};
export type CalculateCapacityForOfferOutput = {
  __typename?: 'CalculateCapacityForOfferOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type CanEnableWaitingListOutput = {
  __typename?: 'CanEnableWaitingListOutput';
  errors: Array<Maybe<Scalars['String']>>;
  result: Scalars['Boolean'];
};
export type CancelCreatedWaitingListPropositionOutput = {
  __typename?: 'CancelCreatedWaitingListPropositionOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type CancelWaitingListOutput = {
  __typename?: 'CancelWaitingListOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type CareTakerRequest = {
  Address: AddressRequest;
  ContactInfo: ContactInfoType;
  DateOfBirth: Scalars['String'];
  EncryptedBSN: Scalars['String'];
  FirstName: Scalars['String'];
  Initials: Scalars['String'];
  LastName: Scalars['String'];
  MiddleName?: Maybe<Scalars['String']>;
};
export type ChildRequest = {
  DateOfBirth: Scalars['String'];
  EncryptedBSN: Scalars['String'];
  FirstName: Scalars['String'];
  Gender: Scalars['String'];
  LastName: Scalars['String'];
  MiddleName?: Maybe<Scalars['String']>;
};
export type ConfirmEmailOutput = {
  __typename?: 'ConfirmEmailOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type ContactInfoType = {
  Email: Scalars['String'];
  PhoneNumber: Scalars['String'];
};
export type CreateBookingOutput = {
  __typename?: 'CreateBookingOutput';
  urlHash?: Maybe<Scalars['String']>;
};
export type DaySelection = {
  SelectedDay: Scalars['String'];
  ServiceVarietyName: Scalars['String'];
};
export type DaySelectionInput = {
  DayOfWeek: Scalars['String'];
  Priority?: Maybe<Scalars['Int']>;
};
export type DeclineProposedWaitingListPropositionOutput = {
  __typename?: 'DeclineProposedWaitingListPropositionOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type DisableWaitingListOutput = {
  __typename?: 'DisableWaitingListOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type EnableWaitingListOutput = {
  __typename?: 'EnableWaitingListOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type ExportServiceSettingsOutput = {
  __typename?: 'ExportServiceSettingsOutput';
  base64CsvServiceExport: Scalars['String'];
};
export type ExtendWaitingListOutput = {
  __typename?: 'ExtendWaitingListOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type FetchPostalcodeInfoOutput = {
  __typename?: 'FetchPostalcodeInfoOutput';
  isSuccess: Scalars['Boolean'];
  postalCodeInfo?: Maybe<Scalars['Object']>;
};
export type GetBookingStatusOutput = {
  __typename?: 'GetBookingStatusOutput';
  bookingType?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  inviteUrl?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isContractDeclined?: Maybe<Scalars['Boolean']>;
  isContractSigned?: Maybe<Scalars['Boolean']>;
  isDossierUnavailable?: Maybe<Scalars['Boolean']>;
  isEmailVerified?: Maybe<Scalars['Boolean']>;
  isError?: Maybe<Scalars['Boolean']>;
  isMandateSigned?: Maybe<Scalars['Boolean']>;
  isWaitingListOnly?: Maybe<Scalars['Boolean']>;
};
export type GetPropositionSubscriptionsOutput = {
  __typename?: 'GetPropositionSubscriptionsOutput';
  propositionSubscriptions: Array<Maybe<Scalars['Object']>>;
};
export type GetPropositionsAnalysisOutput = {
  __typename?: 'GetPropositionsAnalysisOutput';
  id: Scalars['String'];
};
export type GetPropositionsOutput = {
  __typename?: 'GetPropositionsOutput';
  availability: Scalars['String'];
  distance: Scalars['Float'];
  isPreorder: Scalars['Boolean'];
  offer: Offer;
  offerId: Scalars['String'];
  offeredServiceVarieties: Array<Maybe<Scalars['Object']>>;
  serviceVarietyPropositions: Array<Maybe<Scalars['Object']>>;
};
export type GetSignedDeleteUrlOutput = {
  __typename?: 'GetSignedDeleteUrlOutput';
  deleteUrl: Scalars['String'];
};
export type GetSignedUploadUrlOutput = {
  __typename?: 'GetSignedUploadUrlOutput';
  uploadUrl: Scalars['String'];
};
export type GetWaitingListStatusOutput = {
  __typename?: 'GetWaitingListStatusOutput';
  availableWaitingListDays?: Maybe<Scalars['Object']>;
  bookingType?: Maybe<Scalars['String']>;
  finishBookingRegistration: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
  isInternalBooking: Scalars['Boolean'];
  locationName?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  spotAvailableDurationInMinutes?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  subscriptionCode?: Maybe<Scalars['String']>;
};
export type InsertAndOverwriteGroupPinCodeOutput = {
  __typename?: 'InsertAndOverwriteGroupPinCodeOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type PinCodeToInsertAndOverwrite = {
  dayOfWeek: Scalars['String'];
  groupId: Scalars['String'];
  partOfDay: Scalars['String'];
  pinCode: Scalars['jsonb'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
};
export type ProposeCreatedWaitingListPropositionOutput = {
  __typename?: 'ProposeCreatedWaitingListPropositionOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type ResendEmailConfirmationOutput = {
  __typename?: 'ResendEmailConfirmationOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type RevokeProposedWaitingListPropositionOutput = {
  __typename?: 'RevokeProposedWaitingListPropositionOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type SchoolInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locality: Scalars['String'];
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
};
export type SchoolRequest = {
  Id: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  SchoolGroupId: Scalars['String'];
  SchoolGroupName?: Maybe<Scalars['String']>;
};
export type SelectedDaysPerServiceVarietyInput = {
  KDV?: Maybe<Array<Maybe<Scalars['String']>>>;
  NSO?: Maybe<Array<Maybe<Scalars['String']>>>;
  VSO?: Maybe<Array<Maybe<Scalars['String']>>>;
};
export type SendEmailsOutput = {
  __typename?: 'SendEmailsOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type ServiceVarietyDaySelection = {
  KDV?: Maybe<Array<Maybe<DaySelectionInput>>>;
  NSO?: Maybe<Array<Maybe<DaySelectionInput>>>;
  POV?: Maybe<Array<Maybe<DaySelectionInput>>>;
  VSO?: Maybe<Array<Maybe<DaySelectionInput>>>;
};
export type SetPublicReadAccessOutput = {
  __typename?: 'SetPublicReadAccessOutput';
  getUrl: Scalars['String'];
};
export type AcceptProposedWaitingListProposition = {
  __typename?: 'acceptProposedWaitingListProposition';
  created_at: Scalars['timestamptz'];
  errors?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  output?: Maybe<AcceptProposedWaitingListPropositionOutput>;
};
export type AnalysisResult = {
  __typename?: 'analysis_result';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  result: Scalars['jsonb'];
};
export type AnalysisResultAggregateFields = {
  __typename?: 'analysis_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AnalysisResultMaxFields>;
  min?: Maybe<AnalysisResultMinFields>;
};
export type AnalysisResultMaxFields = {
  __typename?: 'analysis_result_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
};
export type AnalysisResultMinFields = {
  __typename?: 'analysis_result_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
};
export type AnalysisResultMutationResponse = {
  __typename?: 'analysis_result_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<AnalysisResult>;
};
export type AnalysisResultOrderBy = {
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  result?: Maybe<OrderBy>;
};
export type Booking = {
  __typename?: 'booking';
  analysis_id?: Maybe<Scalars['String']>;
  bookingResetOn: Scalars['timestamptz'];
  bookingStatus?: Maybe<BookingStatus>;
  bookingType: Scalars['String'];
  child?: Maybe<Child>;
  childId?: Maybe<Scalars['String']>;
  contactEmail: Scalars['String'];
  contracts: Array<Contract>;
  createdAt: Scalars['timestamptz'];
  currentStatusId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  encryptedData?: Maybe<Scalars['bytea']>;
  id: Scalars['String'];
  isInternalBooking: Scalars['Boolean'];
  isPreorder: Scalars['Boolean'];
  locationInquiries: Array<LocationInquiry>;
  mandates: Array<Mandate>;
  outboxes: Array<Outbox>;
  placements: Array<Placement>;
  reservations: Array<Reservation>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  serviceKind?: Maybe<Scalars['String']>;
  subscriptionService?: Maybe<SubscriptionService>;
  subscriptionServiceId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  urlHash?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  waitinglistPlaces: Array<WaitingListPlace>;
};
export type BookingAggregateFields = {
  __typename?: 'booking_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BookingMaxFields>;
  min?: Maybe<BookingMinFields>;
};
export type BookingAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BookingMaxOrderBy>;
  min?: Maybe<BookingMinOrderBy>;
};
export type BookingMaxFields = {
  __typename?: 'booking_max_fields';
  analysis_id?: Maybe<Scalars['String']>;
  bookingResetOn?: Maybe<Scalars['timestamptz']>;
  bookingType?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentStatusId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceKind?: Maybe<Scalars['String']>;
  subscriptionServiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlHash?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type BookingMaxOrderBy = {
  analysis_id?: Maybe<OrderBy>;
  bookingResetOn?: Maybe<OrderBy>;
  bookingType?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  contactEmail?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatusId?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceKind?: Maybe<OrderBy>;
  subscriptionServiceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  urlHash?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type BookingMinFields = {
  __typename?: 'booking_min_fields';
  analysis_id?: Maybe<Scalars['String']>;
  bookingResetOn?: Maybe<Scalars['timestamptz']>;
  bookingType?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentStatusId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceKind?: Maybe<Scalars['String']>;
  subscriptionServiceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  urlHash?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type BookingMinOrderBy = {
  analysis_id?: Maybe<OrderBy>;
  bookingResetOn?: Maybe<OrderBy>;
  bookingType?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  contactEmail?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatusId?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceKind?: Maybe<OrderBy>;
  subscriptionServiceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  urlHash?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type BookingMutationResponse = {
  __typename?: 'booking_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Booking>;
};
export type BookingOrderBy = {
  analysis_id?: Maybe<OrderBy>;
  bookingResetOn?: Maybe<OrderBy>;
  bookingStatus?: Maybe<BookingStatusOrderBy>;
  bookingType?: Maybe<OrderBy>;
  child?: Maybe<ChildOrderBy>;
  childId?: Maybe<OrderBy>;
  contactEmail?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatusId?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  encryptedData?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isInternalBooking?: Maybe<OrderBy>;
  isPreorder?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceKind?: Maybe<OrderBy>;
  subscriptionService?: Maybe<SubscriptionServiceOrderBy>;
  subscriptionServiceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  urlHash?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type BookingStatus = {
  __typename?: 'booking_status';
  bookingId: Scalars['String'];
  causedByReason?: Maybe<Scalars['String']>;
  causedByType: Scalars['String'];
  causedByUser?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailConfirmationHash?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isCancelled: Scalars['Boolean'];
  isCompleted: Scalars['Boolean'];
  isContractSigned: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isMandateSigned: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type BookingStatusAggregateFields = {
  __typename?: 'booking_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<BookingStatusMaxFields>;
  min?: Maybe<BookingStatusMinFields>;
};
export type BookingStatusMaxFields = {
  __typename?: 'booking_status_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  causedByReason?: Maybe<Scalars['String']>;
  causedByType?: Maybe<Scalars['String']>;
  causedByUser?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailConfirmationHash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type BookingStatusMinFields = {
  __typename?: 'booking_status_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  causedByReason?: Maybe<Scalars['String']>;
  causedByType?: Maybe<Scalars['String']>;
  causedByUser?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailConfirmationHash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type BookingStatusMutationResponse = {
  __typename?: 'booking_status_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<BookingStatus>;
};
export type BookingStatusOrderBy = {
  bookingId?: Maybe<OrderBy>;
  causedByReason?: Maybe<OrderBy>;
  causedByType?: Maybe<OrderBy>;
  causedByUser?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  date?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  emailConfirmationHash?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isCancelled?: Maybe<OrderBy>;
  isCompleted?: Maybe<OrderBy>;
  isContractSigned?: Maybe<OrderBy>;
  isEmailVerified?: Maybe<OrderBy>;
  isMandateSigned?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Capacity = {
  __typename?: 'capacity';
  ageRange: Scalars['jsonb'];
  availableFrom: Scalars['date'];
  availableUntil?: Maybe<Scalars['date']>;
  baseCapacity: Scalars['smallint'];
  createdAt: Scalars['timestamptz'];
  currentCapacity: Scalars['smallint'];
  dayOfWeek: Scalars['String'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  effectivePeriodFrom?: Maybe<Scalars['date']>;
  effectivePeriodUntil?: Maybe<Scalars['date']>;
  extraCapacity: Scalars['smallint'];
  groupId: Scalars['String'];
  id: Scalars['String'];
  lowestExtraCapacity?: Maybe<ExtraCapacity>;
  lowestExtraCapacityId?: Maybe<Scalars['String']>;
  lowestImpact: Scalars['smallint'];
  occupied_capacity: Scalars['smallint'];
  offer: Offer;
  offerId: Scalars['String'];
  serviceVarietyName: Scalars['String'];
  totalCapacity: Scalars['smallint'];
  updatedAt: Scalars['timestamptz'];
};
export type CapacityAggregateFields = {
  __typename?: 'capacity_aggregate_fields';
  avg?: Maybe<CapacityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<CapacityMaxFields>;
  min?: Maybe<CapacityMinFields>;
  stddev?: Maybe<CapacityStddevFields>;
  stddev_pop?: Maybe<CapacityStddevPopFields>;
  stddev_samp?: Maybe<CapacityStddevSampFields>;
  sum?: Maybe<CapacitySumFields>;
  var_pop?: Maybe<CapacityVarPopFields>;
  var_samp?: Maybe<CapacityVarSampFields>;
  variance?: Maybe<CapacityVarianceFields>;
};
export type CapacityAggregateOrderBy = {
  avg?: Maybe<CapacityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<CapacityMaxOrderBy>;
  min?: Maybe<CapacityMinOrderBy>;
  stddev?: Maybe<CapacityStddevOrderBy>;
  stddev_pop?: Maybe<CapacityStddevPopOrderBy>;
  stddev_samp?: Maybe<CapacityStddevSampOrderBy>;
  sum?: Maybe<CapacitySumOrderBy>;
  var_pop?: Maybe<CapacityVarPopOrderBy>;
  var_samp?: Maybe<CapacityVarSampOrderBy>;
  variance?: Maybe<CapacityVarianceOrderBy>;
};
export type CapacityAvgFields = {
  __typename?: 'capacity_avg_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityAvgOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityMaxFields = {
  __typename?: 'capacity_max_fields';
  availableFrom?: Maybe<Scalars['date']>;
  availableUntil?: Maybe<Scalars['date']>;
  baseCapacity?: Maybe<Scalars['smallint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentCapacity?: Maybe<Scalars['smallint']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  effectivePeriodFrom?: Maybe<Scalars['date']>;
  effectivePeriodUntil?: Maybe<Scalars['date']>;
  extraCapacity?: Maybe<Scalars['smallint']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lowestExtraCapacityId?: Maybe<Scalars['String']>;
  lowestImpact?: Maybe<Scalars['smallint']>;
  occupied_capacity?: Maybe<Scalars['smallint']>;
  offerId?: Maybe<Scalars['String']>;
  serviceVarietyName?: Maybe<Scalars['String']>;
  totalCapacity?: Maybe<Scalars['smallint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type CapacityMaxOrderBy = {
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  baseCapacity?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  effectivePeriodFrom?: Maybe<OrderBy>;
  effectivePeriodUntil?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lowestExtraCapacityId?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  offerId?: Maybe<OrderBy>;
  serviceVarietyName?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type CapacityMinFields = {
  __typename?: 'capacity_min_fields';
  availableFrom?: Maybe<Scalars['date']>;
  availableUntil?: Maybe<Scalars['date']>;
  baseCapacity?: Maybe<Scalars['smallint']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentCapacity?: Maybe<Scalars['smallint']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  effectivePeriodFrom?: Maybe<Scalars['date']>;
  effectivePeriodUntil?: Maybe<Scalars['date']>;
  extraCapacity?: Maybe<Scalars['smallint']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lowestExtraCapacityId?: Maybe<Scalars['String']>;
  lowestImpact?: Maybe<Scalars['smallint']>;
  occupied_capacity?: Maybe<Scalars['smallint']>;
  offerId?: Maybe<Scalars['String']>;
  serviceVarietyName?: Maybe<Scalars['String']>;
  totalCapacity?: Maybe<Scalars['smallint']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type CapacityMinOrderBy = {
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  baseCapacity?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  effectivePeriodFrom?: Maybe<OrderBy>;
  effectivePeriodUntil?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lowestExtraCapacityId?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  offerId?: Maybe<OrderBy>;
  serviceVarietyName?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type CapacityMutationResponse = {
  __typename?: 'capacity_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Capacity>;
};
export type CapacityOrderBy = {
  ageRange?: Maybe<OrderBy>;
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  baseCapacity?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  effectivePeriodFrom?: Maybe<OrderBy>;
  effectivePeriodUntil?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lowestExtraCapacity?: Maybe<ExtraCapacityOrderBy>;
  lowestExtraCapacityId?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  offer?: Maybe<OfferOrderBy>;
  offerId?: Maybe<OrderBy>;
  serviceVarietyName?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type CapacityStddevFields = {
  __typename?: 'capacity_stddev_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityStddevOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityStddevPopFields = {
  __typename?: 'capacity_stddev_pop_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityStddevPopOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityStddevSampFields = {
  __typename?: 'capacity_stddev_samp_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityStddevSampOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacitySumFields = {
  __typename?: 'capacity_sum_fields';
  baseCapacity?: Maybe<Scalars['smallint']>;
  currentCapacity?: Maybe<Scalars['smallint']>;
  extraCapacity?: Maybe<Scalars['smallint']>;
  lowestImpact?: Maybe<Scalars['smallint']>;
  occupied_capacity?: Maybe<Scalars['smallint']>;
  totalCapacity?: Maybe<Scalars['smallint']>;
};
export type CapacitySumOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityVarPopFields = {
  __typename?: 'capacity_var_pop_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityVarPopOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityVarSampFields = {
  __typename?: 'capacity_var_samp_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityVarSampOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type CapacityVarianceFields = {
  __typename?: 'capacity_variance_fields';
  baseCapacity?: Maybe<Scalars['Float']>;
  currentCapacity?: Maybe<Scalars['Float']>;
  extraCapacity?: Maybe<Scalars['Float']>;
  lowestImpact?: Maybe<Scalars['Float']>;
  occupied_capacity?: Maybe<Scalars['Float']>;
  totalCapacity?: Maybe<Scalars['Float']>;
};
export type CapacityVarianceOrderBy = {
  baseCapacity?: Maybe<OrderBy>;
  currentCapacity?: Maybe<OrderBy>;
  extraCapacity?: Maybe<OrderBy>;
  lowestImpact?: Maybe<OrderBy>;
  occupied_capacity?: Maybe<OrderBy>;
  totalCapacity?: Maybe<OrderBy>;
};
export type Child = {
  __typename?: 'child';
  bookings: Array<Booking>;
  createdAt: Scalars['timestamptz'];
  dateOfBirth: Scalars['date'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstName: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName: Scalars['String'];
  number?: Maybe<Scalars['String']>;
  reservations: Array<Reservation>;
  updatedAt: Scalars['timestamptz'];
  waitingListPlaces: Array<WaitingListPlace>;
};
export type ChildAggregateFields = {
  __typename?: 'child_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ChildMaxFields>;
  min?: Maybe<ChildMinFields>;
};
export type ChildMaxFields = {
  __typename?: 'child_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ChildMinFields = {
  __typename?: 'child_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ChildMutationResponse = {
  __typename?: 'child_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Child>;
};
export type ChildOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dateOfBirth?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  firstName?: Maybe<OrderBy>;
  fullName?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  lastName?: Maybe<OrderBy>;
  number?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Configuration = {
  __typename?: 'configuration';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  key: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  value?: Maybe<Scalars['jsonb']>;
};
export type ConfigurationAggregateFields = {
  __typename?: 'configuration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ConfigurationMaxFields>;
  min?: Maybe<ConfigurationMinFields>;
};
export type ConfigurationMaxFields = {
  __typename?: 'configuration_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ConfigurationMinFields = {
  __typename?: 'configuration_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ConfigurationMutationResponse = {
  __typename?: 'configuration_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Configuration>;
};
export type ConfigurationOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  key?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  value?: Maybe<OrderBy>;
};
export type Contract = {
  __typename?: 'contract';
  booking: Booking;
  bookingId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  currentStatus: Scalars['String'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dossierId: Scalars['uuid'];
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  inviteUrl: Scalars['String'];
  inviteeId: Scalars['uuid'];
  statusReason?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  vendor: Scalars['String'];
};
export type ContractAggregateFields = {
  __typename?: 'contract_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ContractMaxFields>;
  min?: Maybe<ContractMinFields>;
};
export type ContractAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ContractMaxOrderBy>;
  min?: Maybe<ContractMinOrderBy>;
};
export type ContractMaxFields = {
  __typename?: 'contract_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentStatus?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dossierId?: Maybe<Scalars['uuid']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inviteUrl?: Maybe<Scalars['String']>;
  inviteeId?: Maybe<Scalars['uuid']>;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
};
export type ContractMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatus?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  dossierId?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  inviteeId?: Maybe<OrderBy>;
  statusReason?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type ContractMinFields = {
  __typename?: 'contract_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  currentStatus?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  dossierId?: Maybe<Scalars['uuid']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  inviteUrl?: Maybe<Scalars['String']>;
  inviteeId?: Maybe<Scalars['uuid']>;
  statusReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
};
export type ContractMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatus?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  dossierId?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  inviteeId?: Maybe<OrderBy>;
  statusReason?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type ContractMutationResponse = {
  __typename?: 'contract_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Contract>;
};
export type ContractOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  currentStatus?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  dossierId?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  inviteeId?: Maybe<OrderBy>;
  statusReason?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type CreateBooking = {
  __typename?: 'createBooking';
  created_at: Scalars['timestamptz'];
  errors?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  output?: Maybe<CreateBookingOutput>;
};
export type ExternalIdTranslation = {
  __typename?: 'external_id_translation';
  externalId: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
  whyplanId: Scalars['String'];
};
export type ExternalIdTranslationAggregateFields = {
  __typename?: 'external_id_translation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ExternalIdTranslationMaxFields>;
  min?: Maybe<ExternalIdTranslationMinFields>;
};
export type ExternalIdTranslationMaxFields = {
  __typename?: 'external_id_translation_max_fields';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  whyplanId?: Maybe<Scalars['String']>;
};
export type ExternalIdTranslationMinFields = {
  __typename?: 'external_id_translation_min_fields';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  whyplanId?: Maybe<Scalars['String']>;
};
export type ExternalIdTranslationMutationResponse = {
  __typename?: 'external_id_translation_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ExternalIdTranslation>;
};
export type ExternalIdTranslationOrderBy = {
  externalId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  whyplanId?: Maybe<OrderBy>;
};
export type ExtraCapacity = {
  __typename?: 'extra_capacity';
  action?: Maybe<Scalars['String']>;
  ageRange: Scalars['jsonb'];
  amount: Scalars['smallint'];
  availableFrom: Scalars['date'];
  availableUntil?: Maybe<Scalars['date']>;
  capacities: Array<Capacity>;
  createdAt: Scalars['timestamptz'];
  dayOfWeek: Scalars['String'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  impact: Scalars['smallint'];
  isExecuted: Scalars['Boolean'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};
export type ExtraCapacityAggregateFields = {
  __typename?: 'extra_capacity_aggregate_fields';
  avg?: Maybe<ExtraCapacityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ExtraCapacityMaxFields>;
  min?: Maybe<ExtraCapacityMinFields>;
  stddev?: Maybe<ExtraCapacityStddevFields>;
  stddev_pop?: Maybe<ExtraCapacityStddevPopFields>;
  stddev_samp?: Maybe<ExtraCapacityStddevSampFields>;
  sum?: Maybe<ExtraCapacitySumFields>;
  var_pop?: Maybe<ExtraCapacityVarPopFields>;
  var_samp?: Maybe<ExtraCapacityVarSampFields>;
  variance?: Maybe<ExtraCapacityVarianceFields>;
};
export type ExtraCapacityAggregateOrderBy = {
  avg?: Maybe<ExtraCapacityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ExtraCapacityMaxOrderBy>;
  min?: Maybe<ExtraCapacityMinOrderBy>;
  stddev?: Maybe<ExtraCapacityStddevOrderBy>;
  stddev_pop?: Maybe<ExtraCapacityStddevPopOrderBy>;
  stddev_samp?: Maybe<ExtraCapacityStddevSampOrderBy>;
  sum?: Maybe<ExtraCapacitySumOrderBy>;
  var_pop?: Maybe<ExtraCapacityVarPopOrderBy>;
  var_samp?: Maybe<ExtraCapacityVarSampOrderBy>;
  variance?: Maybe<ExtraCapacityVarianceOrderBy>;
};
export type ExtraCapacityAvgFields = {
  __typename?: 'extra_capacity_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityMaxFields = {
  __typename?: 'extra_capacity_max_fields';
  action?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['smallint']>;
  availableFrom?: Maybe<Scalars['date']>;
  availableUntil?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ExtraCapacityMaxOrderBy = {
  action?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ExtraCapacityMinFields = {
  __typename?: 'extra_capacity_min_fields';
  action?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['smallint']>;
  availableFrom?: Maybe<Scalars['date']>;
  availableUntil?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  impact?: Maybe<Scalars['smallint']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ExtraCapacityMinOrderBy = {
  action?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ExtraCapacityMutationResponse = {
  __typename?: 'extra_capacity_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ExtraCapacity>;
};
export type ExtraCapacityOrderBy = {
  action?: Maybe<OrderBy>;
  ageRange?: Maybe<OrderBy>;
  amount?: Maybe<OrderBy>;
  availableFrom?: Maybe<OrderBy>;
  availableUntil?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  group?: Maybe<GroupOrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
  isExecuted?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ExtraCapacityStddevFields = {
  __typename?: 'extra_capacity_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityStddevPopFields = {
  __typename?: 'extra_capacity_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityStddevSampFields = {
  __typename?: 'extra_capacity_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacitySumFields = {
  __typename?: 'extra_capacity_sum_fields';
  amount?: Maybe<Scalars['smallint']>;
  impact?: Maybe<Scalars['smallint']>;
};
export type ExtraCapacitySumOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityVarPopFields = {
  __typename?: 'extra_capacity_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityVarSampFields = {
  __typename?: 'extra_capacity_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type ExtraCapacityVarianceFields = {
  __typename?: 'extra_capacity_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  impact?: Maybe<Scalars['Float']>;
};
export type ExtraCapacityVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  impact?: Maybe<OrderBy>;
};
export type Feature = {
  __typename?: 'feature';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['String'];
  isDefaultFeature: Scalars['Boolean'];
  persona_features: Array<PersonaFeature>;
  service_features: Array<ServiceFeature>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type FeatureAggregateFields = {
  __typename?: 'feature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<FeatureMaxFields>;
  min?: Maybe<FeatureMinFields>;
};
export type FeatureMaxFields = {
  __typename?: 'feature_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type FeatureMinFields = {
  __typename?: 'feature_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type FeatureMutationResponse = {
  __typename?: 'feature_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Feature>;
};
export type FeatureOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isDefaultFeature?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type GetShareOutput = {
  __typename?: 'getShareOutput';
  encryptedState: Scalars['String'];
  sharedFromUrl: Scalars['String'];
};
export type Group = {
  __typename?: 'group';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  extraCapacities: Array<ExtraCapacity>;
  groupCapacities: Array<GroupCapacity>;
  groupOpenings: Array<GroupOpening>;
  groupPincodes: Array<GroupPincode>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  reservations: Array<Reservation>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};
export type GroupAggregateFields = {
  __typename?: 'group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GroupMaxFields>;
  min?: Maybe<GroupMinFields>;
};
export type GroupAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupMaxOrderBy>;
  min?: Maybe<GroupMinOrderBy>;
};
export type GroupCapacity = {
  __typename?: 'group_capacity';
  ageRange: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fridayPlanCapacity: Scalars['Int'];
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  mondayPlanCapacity: Scalars['Int'];
  strategy: Scalars['String'];
  thursdayPlanCapacity: Scalars['Int'];
  tuesdayPlanCapacity: Scalars['Int'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  wednesdayPlanCapacity: Scalars['Int'];
};
export type GroupCapacityAggregateFields = {
  __typename?: 'group_capacity_aggregate_fields';
  avg?: Maybe<GroupCapacityAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<GroupCapacityMaxFields>;
  min?: Maybe<GroupCapacityMinFields>;
  stddev?: Maybe<GroupCapacityStddevFields>;
  stddev_pop?: Maybe<GroupCapacityStddevPopFields>;
  stddev_samp?: Maybe<GroupCapacityStddevSampFields>;
  sum?: Maybe<GroupCapacitySumFields>;
  var_pop?: Maybe<GroupCapacityVarPopFields>;
  var_samp?: Maybe<GroupCapacityVarSampFields>;
  variance?: Maybe<GroupCapacityVarianceFields>;
};
export type GroupCapacityAggregateOrderBy = {
  avg?: Maybe<GroupCapacityAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupCapacityMaxOrderBy>;
  min?: Maybe<GroupCapacityMinOrderBy>;
  stddev?: Maybe<GroupCapacityStddevOrderBy>;
  stddev_pop?: Maybe<GroupCapacityStddevPopOrderBy>;
  stddev_samp?: Maybe<GroupCapacityStddevSampOrderBy>;
  sum?: Maybe<GroupCapacitySumOrderBy>;
  var_pop?: Maybe<GroupCapacityVarPopOrderBy>;
  var_samp?: Maybe<GroupCapacityVarSampOrderBy>;
  variance?: Maybe<GroupCapacityVarianceOrderBy>;
};
export type GroupCapacityAvgFields = {
  __typename?: 'group_capacity_avg_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityAvgOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityMaxFields = {
  __typename?: 'group_capacity_max_fields';
  ageRange?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fridayPlanCapacity?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mondayPlanCapacity?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['String']>;
  thursdayPlanCapacity?: Maybe<Scalars['Int']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Int']>;
};
export type GroupCapacityMaxOrderBy = {
  ageRange?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  fridayPlanCapacity?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  strategy?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityMinFields = {
  __typename?: 'group_capacity_min_fields';
  ageRange?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  fridayPlanCapacity?: Maybe<Scalars['Int']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mondayPlanCapacity?: Maybe<Scalars['Int']>;
  strategy?: Maybe<Scalars['String']>;
  thursdayPlanCapacity?: Maybe<Scalars['Int']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Int']>;
};
export type GroupCapacityMinOrderBy = {
  ageRange?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  fridayPlanCapacity?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  strategy?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityMutationResponse = {
  __typename?: 'group_capacity_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<GroupCapacity>;
};
export type GroupCapacityOrderBy = {
  ageRange?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  fridayPlanCapacity?: Maybe<OrderBy>;
  group?: Maybe<GroupOrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  strategy?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityStddevFields = {
  __typename?: 'group_capacity_stddev_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityStddevOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityStddevPopFields = {
  __typename?: 'group_capacity_stddev_pop_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityStddevPopOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityStddevSampFields = {
  __typename?: 'group_capacity_stddev_samp_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityStddevSampOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacitySumFields = {
  __typename?: 'group_capacity_sum_fields';
  fridayPlanCapacity?: Maybe<Scalars['Int']>;
  mondayPlanCapacity?: Maybe<Scalars['Int']>;
  thursdayPlanCapacity?: Maybe<Scalars['Int']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Int']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Int']>;
};
export type GroupCapacitySumOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityVarPopFields = {
  __typename?: 'group_capacity_var_pop_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityVarPopOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityVarSampFields = {
  __typename?: 'group_capacity_var_samp_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityVarSampOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupCapacityVarianceFields = {
  __typename?: 'group_capacity_variance_fields';
  fridayPlanCapacity?: Maybe<Scalars['Float']>;
  mondayPlanCapacity?: Maybe<Scalars['Float']>;
  thursdayPlanCapacity?: Maybe<Scalars['Float']>;
  tuesdayPlanCapacity?: Maybe<Scalars['Float']>;
  wednesdayPlanCapacity?: Maybe<Scalars['Float']>;
};
export type GroupCapacityVarianceOrderBy = {
  fridayPlanCapacity?: Maybe<OrderBy>;
  mondayPlanCapacity?: Maybe<OrderBy>;
  thursdayPlanCapacity?: Maybe<OrderBy>;
  tuesdayPlanCapacity?: Maybe<OrderBy>;
  wednesdayPlanCapacity?: Maybe<OrderBy>;
};
export type GroupMaxFields = {
  __typename?: 'group_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type GroupMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type GroupMinFields = {
  __typename?: 'group_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type GroupMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type GroupMutationResponse = {
  __typename?: 'group_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Group>;
};
export type GroupOpening = {
  __typename?: 'group_opening';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  friday?: Maybe<Scalars['jsonb']>;
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  monday?: Maybe<Scalars['jsonb']>;
  saturday?: Maybe<Scalars['jsonb']>;
  sunday?: Maybe<Scalars['jsonb']>;
  thursday?: Maybe<Scalars['jsonb']>;
  tuesday?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  wednesday?: Maybe<Scalars['jsonb']>;
};
export type GroupOpeningAggregateFields = {
  __typename?: 'group_opening_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GroupOpeningMaxFields>;
  min?: Maybe<GroupOpeningMinFields>;
};
export type GroupOpeningAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupOpeningMaxOrderBy>;
  min?: Maybe<GroupOpeningMinOrderBy>;
};
export type GroupOpeningMaxFields = {
  __typename?: 'group_opening_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type GroupOpeningMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type GroupOpeningMinFields = {
  __typename?: 'group_opening_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type GroupOpeningMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type GroupOpeningMutationResponse = {
  __typename?: 'group_opening_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<GroupOpening>;
};
export type GroupOpeningOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  friday?: Maybe<OrderBy>;
  group?: Maybe<GroupOrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monday?: Maybe<OrderBy>;
  saturday?: Maybe<OrderBy>;
  sunday?: Maybe<OrderBy>;
  thursday?: Maybe<OrderBy>;
  tuesday?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesday?: Maybe<OrderBy>;
};
export type GroupOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type GroupPincode = {
  __typename?: 'group_pincode';
  createdAt: Scalars['timestamptz'];
  dayOfWeek: Scalars['String'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  partOfDay: Scalars['String'];
  pincode: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
};
export type GroupPincodeAggregateFields = {
  __typename?: 'group_pincode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GroupPincodeMaxFields>;
  min?: Maybe<GroupPincodeMinFields>;
};
export type GroupPincodeAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<GroupPincodeMaxOrderBy>;
  min?: Maybe<GroupPincodeMinOrderBy>;
};
export type GroupPincodeMaxFields = {
  __typename?: 'group_pincode_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type GroupPincodeMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type GroupPincodeMinFields = {
  __typename?: 'group_pincode_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type GroupPincodeMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type GroupPincodeMutationResponse = {
  __typename?: 'group_pincode_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<GroupPincode>;
};
export type GroupPincodeOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  group?: Maybe<GroupOrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  pincode?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type GroupProduct = {
  __typename?: 'group_product';
  created_at: Scalars['timestamptz'];
  group_id: Scalars['String'];
  id: Scalars['String'];
  product_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  valid_from: Scalars['timestamptz'];
  valid_until: Scalars['timestamptz'];
};
export type GroupProductAggregateFields = {
  __typename?: 'group_product_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<GroupProductMaxFields>;
  min?: Maybe<GroupProductMinFields>;
};
export type GroupProductMaxFields = {
  __typename?: 'group_product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};
export type GroupProductMinFields = {
  __typename?: 'group_product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};
export type GroupProductMutationResponse = {
  __typename?: 'group_product_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<GroupProduct>;
};
export type GroupProductOrderBy = {
  created_at?: Maybe<OrderBy>;
  group_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  valid_from?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
};
export type LastUpdated = {
  __typename?: 'last_updated';
  key: Scalars['String'];
  lastUpdatedAt: Scalars['timestamptz'];
};
export type LastUpdatedAggregateFields = {
  __typename?: 'last_updated_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LastUpdatedMaxFields>;
  min?: Maybe<LastUpdatedMinFields>;
};
export type LastUpdatedMaxFields = {
  __typename?: 'last_updated_max_fields';
  key?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['timestamptz']>;
};
export type LastUpdatedMinFields = {
  __typename?: 'last_updated_min_fields';
  key?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['timestamptz']>;
};
export type LastUpdatedMutationResponse = {
  __typename?: 'last_updated_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<LastUpdated>;
};
export type LastUpdatedOrderBy = {
  key?: Maybe<OrderBy>;
  lastUpdatedAt?: Maybe<OrderBy>;
};
export type Location = {
  __typename?: 'location';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  services: Array<Service>;
  updatedAt: Scalars['timestamptz'];
};
export type LocationAggregateFields = {
  __typename?: 'location_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LocationMaxFields>;
  min?: Maybe<LocationMinFields>;
};
export type LocationInquiry = {
  __typename?: 'location_inquiry';
  booking: Booking;
  bookingId: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  child: Child;
  childId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  inquiry_expires_in_in_minutes: Scalars['Int'];
  isCallbackReceived: Scalars['Boolean'];
  lastApproached?: Maybe<Scalars['timestamptz']>;
  priority: Scalars['String'];
  priorityRank: Scalars['Int'];
  registrationDate: Scalars['timestamptz'];
  send_remain_on_waiting_list_mail_in_in_minutes: Scalars['Int'];
  send_remain_on_waiting_list_reminder_mail_in_in_minutes: Scalars['Int'];
  service: Service;
  serviceId: Scalars['String'];
  sortDate: Scalars['timestamptz'];
  status: Scalars['String'];
  subscriptionService: SubscriptionService;
  subscription_service_id: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  useFlexkidsProducts: Scalars['Boolean'];
  validFrom: Scalars['date'];
  validUntil: Scalars['date'];
  waitingListPlaces: Array<WaitingListPlace>;
  waitingListPropositions: Array<WaitingListProposition>;
};
export type LocationInquiryAggregateFields = {
  __typename?: 'location_inquiry_aggregate_fields';
  avg?: Maybe<LocationInquiryAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<LocationInquiryMaxFields>;
  min?: Maybe<LocationInquiryMinFields>;
  stddev?: Maybe<LocationInquiryStddevFields>;
  stddev_pop?: Maybe<LocationInquiryStddevPopFields>;
  stddev_samp?: Maybe<LocationInquiryStddevSampFields>;
  sum?: Maybe<LocationInquirySumFields>;
  var_pop?: Maybe<LocationInquiryVarPopFields>;
  var_samp?: Maybe<LocationInquiryVarSampFields>;
  variance?: Maybe<LocationInquiryVarianceFields>;
};
export type LocationInquiryAggregateOrderBy = {
  avg?: Maybe<LocationInquiryAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<LocationInquiryMaxOrderBy>;
  min?: Maybe<LocationInquiryMinOrderBy>;
  stddev?: Maybe<LocationInquiryStddevOrderBy>;
  stddev_pop?: Maybe<LocationInquiryStddevPopOrderBy>;
  stddev_samp?: Maybe<LocationInquiryStddevSampOrderBy>;
  sum?: Maybe<LocationInquirySumOrderBy>;
  var_pop?: Maybe<LocationInquiryVarPopOrderBy>;
  var_samp?: Maybe<LocationInquiryVarSampOrderBy>;
  variance?: Maybe<LocationInquiryVarianceOrderBy>;
};
export type LocationInquiryAvgFields = {
  __typename?: 'location_inquiry_avg_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryAvgOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryMaxFields = {
  __typename?: 'location_inquiry_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Int']>;
  lastApproached?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['String']>;
  priorityRank?: Maybe<Scalars['Int']>;
  registrationDate?: Maybe<Scalars['timestamptz']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Int']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  sortDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  subscription_service_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type LocationInquiryMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  lastApproached?: Maybe<OrderBy>;
  priority?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  registrationDate?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  sortDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  subscription_service_id?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type LocationInquiryMinFields = {
  __typename?: 'location_inquiry_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Int']>;
  lastApproached?: Maybe<Scalars['timestamptz']>;
  priority?: Maybe<Scalars['String']>;
  priorityRank?: Maybe<Scalars['Int']>;
  registrationDate?: Maybe<Scalars['timestamptz']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Int']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  sortDate?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  subscription_service_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type LocationInquiryMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  lastApproached?: Maybe<OrderBy>;
  priority?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  registrationDate?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  sortDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  subscription_service_id?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type LocationInquiryMutationResponse = {
  __typename?: 'location_inquiry_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<LocationInquiry>;
};
export type LocationInquiryOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  child?: Maybe<ChildOrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  isCallbackReceived?: Maybe<OrderBy>;
  lastApproached?: Maybe<OrderBy>;
  priority?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  registrationDate?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  sortDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  subscriptionService?: Maybe<SubscriptionServiceOrderBy>;
  subscription_service_id?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  useFlexkidsProducts?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type LocationInquiryStddevFields = {
  __typename?: 'location_inquiry_stddev_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryStddevOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryStddevPopFields = {
  __typename?: 'location_inquiry_stddev_pop_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryStddevPopOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryStddevSampFields = {
  __typename?: 'location_inquiry_stddev_samp_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryStddevSampOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquirySumFields = {
  __typename?: 'location_inquiry_sum_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Int']>;
  priorityRank?: Maybe<Scalars['Int']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Int']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Int']>;
};
export type LocationInquirySumOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryVarPopFields = {
  __typename?: 'location_inquiry_var_pop_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryVarPopOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryVarSampFields = {
  __typename?: 'location_inquiry_var_samp_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryVarSampOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationInquiryVarianceFields = {
  __typename?: 'location_inquiry_variance_fields';
  inquiry_expires_in_in_minutes?: Maybe<Scalars['Float']>;
  priorityRank?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<Scalars['Float']>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<Scalars['Float']>;
};
export type LocationInquiryVarianceOrderBy = {
  inquiry_expires_in_in_minutes?: Maybe<OrderBy>;
  priorityRank?: Maybe<OrderBy>;
  send_remain_on_waiting_list_mail_in_in_minutes?: Maybe<OrderBy>;
  send_remain_on_waiting_list_reminder_mail_in_in_minutes?: Maybe<OrderBy>;
};
export type LocationMaxFields = {
  __typename?: 'location_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type LocationMinFields = {
  __typename?: 'location_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type LocationMutationResponse = {
  __typename?: 'location_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Location>;
};
export type LocationOrderBy = {
  addressLine1?: Maybe<OrderBy>;
  addressLine2?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locality?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Mandate = {
  __typename?: 'mandate';
  booking: Booking;
  bookingId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  externalMandateId: Scalars['String'];
  id: Scalars['String'];
  inviteUrl: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  vendor: Scalars['String'];
};
export type MandateAggregateFields = {
  __typename?: 'mandate_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MandateMaxFields>;
  min?: Maybe<MandateMinFields>;
};
export type MandateAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<MandateMaxOrderBy>;
  min?: Maybe<MandateMinOrderBy>;
};
export type MandateMaxFields = {
  __typename?: 'mandate_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  externalMandateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
};
export type MandateMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  externalMandateId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type MandateMinFields = {
  __typename?: 'mandate_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  externalMandateId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  inviteUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
};
export type MandateMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  externalMandateId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type MandateMutationResponse = {
  __typename?: 'mandate_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Mandate>;
};
export type MandateOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  externalMandateId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inviteUrl?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
};
export type Metric = {
  __typename?: 'metric';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['json']>;
  timestamp: Scalars['timestamptz'];
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type MetricAggregateFields = {
  __typename?: 'metric_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<MetricMaxFields>;
  min?: Maybe<MetricMinFields>;
};
export type MetricMaxFields = {
  __typename?: 'metric_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type MetricMinFields = {
  __typename?: 'metric_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type MetricMutationResponse = {
  __typename?: 'metric_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Metric>;
};
export type MetricOrderBy = {
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metadata?: Maybe<OrderBy>;
  timestamp?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type MockEndpointResponse = {
  __typename?: 'mock_endpoint_response';
  created_at: Scalars['timestamptz'];
  delay_in_milliseconds: Scalars['Int'];
  description: Scalars['String'];
  headers: Scalars['jsonb'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  path: Scalars['String'];
  response_body: Scalars['jsonb'];
  status_code: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};
export type MockEndpointResponseAggregateFields = {
  __typename?: 'mock_endpoint_response_aggregate_fields';
  avg?: Maybe<MockEndpointResponseAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<MockEndpointResponseMaxFields>;
  min?: Maybe<MockEndpointResponseMinFields>;
  stddev?: Maybe<MockEndpointResponseStddevFields>;
  stddev_pop?: Maybe<MockEndpointResponseStddevPopFields>;
  stddev_samp?: Maybe<MockEndpointResponseStddevSampFields>;
  sum?: Maybe<MockEndpointResponseSumFields>;
  var_pop?: Maybe<MockEndpointResponseVarPopFields>;
  var_samp?: Maybe<MockEndpointResponseVarSampFields>;
  variance?: Maybe<MockEndpointResponseVarianceFields>;
};
export type MockEndpointResponseAvgFields = {
  __typename?: 'mock_endpoint_response_avg_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseMaxFields = {
  __typename?: 'mock_endpoint_response_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  delay_in_milliseconds?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type MockEndpointResponseMinFields = {
  __typename?: 'mock_endpoint_response_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  delay_in_milliseconds?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  status_code?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type MockEndpointResponseMutationResponse = {
  __typename?: 'mock_endpoint_response_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<MockEndpointResponse>;
};
export type MockEndpointResponseOrderBy = {
  created_at?: Maybe<OrderBy>;
  delay_in_milliseconds?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  headers?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_active?: Maybe<OrderBy>;
  path?: Maybe<OrderBy>;
  response_body?: Maybe<OrderBy>;
  status_code?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type MockEndpointResponseStddevFields = {
  __typename?: 'mock_endpoint_response_stddev_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseStddevPopFields = {
  __typename?: 'mock_endpoint_response_stddev_pop_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseStddevSampFields = {
  __typename?: 'mock_endpoint_response_stddev_samp_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseSumFields = {
  __typename?: 'mock_endpoint_response_sum_fields';
  delay_in_milliseconds?: Maybe<Scalars['Int']>;
  status_code?: Maybe<Scalars['Int']>;
};
export type MockEndpointResponseVarPopFields = {
  __typename?: 'mock_endpoint_response_var_pop_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseVarSampFields = {
  __typename?: 'mock_endpoint_response_var_samp_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MockEndpointResponseVarianceFields = {
  __typename?: 'mock_endpoint_response_variance_fields';
  delay_in_milliseconds?: Maybe<Scalars['Float']>;
  status_code?: Maybe<Scalars['Float']>;
};
export type MutationRoot = {
  __typename?: 'mutation_root';
  acceptProposedWaitingListProposition: Scalars['uuid'];
  calculateCapacityForOffer?: Maybe<CalculateCapacityForOfferOutput>;
  cancelCreatedWaitingListProposition?: Maybe<CancelCreatedWaitingListPropositionOutput>;
  cancelWaitingList?: Maybe<CancelWaitingListOutput>;
  confirmEmail?: Maybe<ConfirmEmailOutput>;
  createBooking: Scalars['uuid'];
  declineProposedWaitingListProposition?: Maybe<DeclineProposedWaitingListPropositionOutput>;
  delete_analysis_result?: Maybe<AnalysisResultMutationResponse>;
  delete_analysis_result_by_pk?: Maybe<AnalysisResult>;
  delete_booking?: Maybe<BookingMutationResponse>;
  delete_booking_by_pk?: Maybe<Booking>;
  delete_booking_status?: Maybe<BookingStatusMutationResponse>;
  delete_booking_status_by_pk?: Maybe<BookingStatus>;
  delete_capacity?: Maybe<CapacityMutationResponse>;
  delete_capacity_by_pk?: Maybe<Capacity>;
  delete_child?: Maybe<ChildMutationResponse>;
  delete_child_by_pk?: Maybe<Child>;
  delete_configuration?: Maybe<ConfigurationMutationResponse>;
  delete_configuration_by_pk?: Maybe<Configuration>;
  delete_contract?: Maybe<ContractMutationResponse>;
  delete_contract_by_pk?: Maybe<Contract>;
  delete_external_id_translation?: Maybe<ExternalIdTranslationMutationResponse>;
  delete_external_id_translation_by_pk?: Maybe<ExternalIdTranslation>;
  delete_extra_capacity?: Maybe<ExtraCapacityMutationResponse>;
  delete_extra_capacity_by_pk?: Maybe<ExtraCapacity>;
  delete_feature?: Maybe<FeatureMutationResponse>;
  delete_feature_by_pk?: Maybe<Feature>;
  delete_group?: Maybe<GroupMutationResponse>;
  delete_group_by_pk?: Maybe<Group>;
  delete_group_capacity?: Maybe<GroupCapacityMutationResponse>;
  delete_group_capacity_by_pk?: Maybe<GroupCapacity>;
  delete_group_opening?: Maybe<GroupOpeningMutationResponse>;
  delete_group_opening_by_pk?: Maybe<GroupOpening>;
  delete_group_pincode?: Maybe<GroupPincodeMutationResponse>;
  delete_group_pincode_by_pk?: Maybe<GroupPincode>;
  delete_group_product?: Maybe<GroupProductMutationResponse>;
  delete_group_product_by_pk?: Maybe<GroupProduct>;
  delete_last_updated?: Maybe<LastUpdatedMutationResponse>;
  delete_last_updated_by_pk?: Maybe<LastUpdated>;
  delete_location?: Maybe<LocationMutationResponse>;
  delete_location_by_pk?: Maybe<Location>;
  delete_location_inquiry?: Maybe<LocationInquiryMutationResponse>;
  delete_location_inquiry_by_pk?: Maybe<LocationInquiry>;
  delete_mandate?: Maybe<MandateMutationResponse>;
  delete_mandate_by_pk?: Maybe<Mandate>;
  delete_metric?: Maybe<MetricMutationResponse>;
  delete_metric_by_pk?: Maybe<Metric>;
  delete_mock_endpoint_response?: Maybe<MockEndpointResponseMutationResponse>;
  delete_mock_endpoint_response_by_pk?: Maybe<MockEndpointResponse>;
  delete_offer?: Maybe<OfferMutationResponse>;
  delete_offer_by_pk?: Maybe<Offer>;
  delete_offer_distance?: Maybe<OfferDistanceMutationResponse>;
  delete_outbox?: Maybe<OutboxMutationResponse>;
  delete_outbox_by_pk?: Maybe<Outbox>;
  delete_persona?: Maybe<PersonaMutationResponse>;
  delete_persona_by_pk?: Maybe<Persona>;
  delete_persona_feature?: Maybe<PersonaFeatureMutationResponse>;
  delete_persona_feature_by_pk?: Maybe<PersonaFeature>;
  delete_placement?: Maybe<PlacementMutationResponse>;
  delete_placement_by_pk?: Maybe<Placement>;
  delete_postal_code_persona?: Maybe<PostalCodePersonaMutationResponse>;
  delete_postal_code_persona_by_pk?: Maybe<PostalCodePersona>;
  delete_product?: Maybe<ProductMutationResponse>;
  delete_product_by_pk?: Maybe<Product>;
  delete_product_day_info?: Maybe<ProductDayInfoMutationResponse>;
  delete_product_day_info_by_pk?: Maybe<ProductDayInfo>;
  delete_product_price_agreement?: Maybe<ProductPriceAgreementMutationResponse>;
  delete_product_price_agreement_by_pk?: Maybe<ProductPriceAgreement>;
  delete_reservation?: Maybe<ReservationMutationResponse>;
  delete_reservation_by_pk?: Maybe<Reservation>;
  delete_school?: Maybe<SchoolMutationResponse>;
  delete_school_by_pk?: Maybe<School>;
  delete_school_group?: Maybe<SchoolGroupMutationResponse>;
  delete_school_group_by_pk?: Maybe<SchoolGroup>;
  delete_school_group_opening?: Maybe<SchoolGroupOpeningMutationResponse>;
  delete_school_group_opening_by_pk?: Maybe<SchoolGroupOpening>;
  delete_school_service?: Maybe<SchoolServiceMutationResponse>;
  delete_school_service_by_pk?: Maybe<SchoolService>;
  delete_service?: Maybe<ServiceMutationResponse>;
  delete_service_by_pk?: Maybe<Service>;
  delete_service_feature?: Maybe<ServiceFeatureMutationResponse>;
  delete_service_feature_by_pk?: Maybe<ServiceFeature>;
  delete_service_media?: Maybe<ServiceMediaMutationResponse>;
  delete_service_media_by_pk?: Maybe<ServiceMedia>;
  delete_service_pricing?: Maybe<ServicePricingMutationResponse>;
  delete_service_pricing_by_pk?: Maybe<ServicePricing>;
  delete_service_product?: Maybe<ServiceProductMutationResponse>;
  delete_service_product_by_pk?: Maybe<ServiceProduct>;
  delete_service_provider?: Maybe<ServiceProviderMutationResponse>;
  delete_service_provider_by_pk?: Maybe<ServiceProvider>;
  delete_service_settings?: Maybe<ServiceSettingsMutationResponse>;
  delete_service_settings_by_pk?: Maybe<ServiceSettings>;
  delete_service_variety?: Maybe<ServiceVarietyMutationResponse>;
  delete_service_variety_by_pk?: Maybe<ServiceVariety>;
  delete_service_variety_opening?: Maybe<ServiceVarietyOpeningMutationResponse>;
  delete_service_variety_opening_by_pk?: Maybe<ServiceVarietyOpening>;
  delete_share?: Maybe<ShareMutationResponse>;
  delete_share_by_pk?: Maybe<Share>;
  delete_subscription?: Maybe<SubscriptionMutationResponse>;
  delete_subscription_by_pk?: Maybe<Subscription>;
  delete_subscription_service?: Maybe<SubscriptionServiceMutationResponse>;
  delete_subscription_service_by_pk?: Maybe<SubscriptionService>;
  delete_sync_object?: Maybe<SyncObjectMutationResponse>;
  delete_sync_object_by_pk?: Maybe<SyncObject>;
  delete_waiting_list_analysis_result?: Maybe<WaitingListAnalysisResultMutationResponse>;
  delete_waiting_list_analysis_result_by_pk?: Maybe<WaitingListAnalysisResult>;
  delete_waiting_list_place?: Maybe<WaitingListPlaceMutationResponse>;
  delete_waiting_list_place_by_pk?: Maybe<WaitingListPlace>;
  delete_waiting_list_proposition?: Maybe<WaitingListPropositionMutationResponse>;
  delete_waiting_list_proposition_analysis_result?: Maybe<WaitingListPropositionAnalysisResultMutationResponse>;
  delete_waiting_list_proposition_analysis_result_by_pk?: Maybe<WaitingListPropositionAnalysisResult>;
  delete_waiting_list_proposition_by_pk?: Maybe<WaitingListProposition>;
  disableWaitingList?: Maybe<DisableWaitingListOutput>;
  enableWaitingList?: Maybe<EnableWaitingListOutput>;
  extendWaitingList?: Maybe<ExtendWaitingListOutput>;
  insertAndOverwriteGroupPinCode?: Maybe<InsertAndOverwriteGroupPinCodeOutput>;
  insert_analysis_result?: Maybe<AnalysisResultMutationResponse>;
  insert_analysis_result_one?: Maybe<AnalysisResult>;
  insert_booking?: Maybe<BookingMutationResponse>;
  insert_booking_one?: Maybe<Booking>;
  insert_booking_status?: Maybe<BookingStatusMutationResponse>;
  insert_booking_status_one?: Maybe<BookingStatus>;
  insert_capacity?: Maybe<CapacityMutationResponse>;
  insert_capacity_one?: Maybe<Capacity>;
  insert_child?: Maybe<ChildMutationResponse>;
  insert_child_one?: Maybe<Child>;
  insert_configuration?: Maybe<ConfigurationMutationResponse>;
  insert_configuration_one?: Maybe<Configuration>;
  insert_contract?: Maybe<ContractMutationResponse>;
  insert_contract_one?: Maybe<Contract>;
  insert_external_id_translation?: Maybe<ExternalIdTranslationMutationResponse>;
  insert_external_id_translation_one?: Maybe<ExternalIdTranslation>;
  insert_extra_capacity?: Maybe<ExtraCapacityMutationResponse>;
  insert_extra_capacity_one?: Maybe<ExtraCapacity>;
  insert_feature?: Maybe<FeatureMutationResponse>;
  insert_feature_one?: Maybe<Feature>;
  insert_group?: Maybe<GroupMutationResponse>;
  insert_group_capacity?: Maybe<GroupCapacityMutationResponse>;
  insert_group_capacity_one?: Maybe<GroupCapacity>;
  insert_group_one?: Maybe<Group>;
  insert_group_opening?: Maybe<GroupOpeningMutationResponse>;
  insert_group_opening_one?: Maybe<GroupOpening>;
  insert_group_pincode?: Maybe<GroupPincodeMutationResponse>;
  insert_group_pincode_one?: Maybe<GroupPincode>;
  insert_group_product?: Maybe<GroupProductMutationResponse>;
  insert_group_product_one?: Maybe<GroupProduct>;
  insert_last_updated?: Maybe<LastUpdatedMutationResponse>;
  insert_last_updated_one?: Maybe<LastUpdated>;
  insert_location?: Maybe<LocationMutationResponse>;
  insert_location_inquiry?: Maybe<LocationInquiryMutationResponse>;
  insert_location_inquiry_one?: Maybe<LocationInquiry>;
  insert_location_one?: Maybe<Location>;
  insert_mandate?: Maybe<MandateMutationResponse>;
  insert_mandate_one?: Maybe<Mandate>;
  insert_metric?: Maybe<MetricMutationResponse>;
  insert_metric_one?: Maybe<Metric>;
  insert_mock_endpoint_response?: Maybe<MockEndpointResponseMutationResponse>;
  insert_mock_endpoint_response_one?: Maybe<MockEndpointResponse>;
  insert_offer?: Maybe<OfferMutationResponse>;
  insert_offer_distance?: Maybe<OfferDistanceMutationResponse>;
  insert_offer_distance_one?: Maybe<OfferDistance>;
  insert_offer_one?: Maybe<Offer>;
  insert_outbox?: Maybe<OutboxMutationResponse>;
  insert_outbox_one?: Maybe<Outbox>;
  insert_persona?: Maybe<PersonaMutationResponse>;
  insert_persona_feature?: Maybe<PersonaFeatureMutationResponse>;
  insert_persona_feature_one?: Maybe<PersonaFeature>;
  insert_persona_one?: Maybe<Persona>;
  insert_placement?: Maybe<PlacementMutationResponse>;
  insert_placement_one?: Maybe<Placement>;
  insert_postal_code_persona?: Maybe<PostalCodePersonaMutationResponse>;
  insert_postal_code_persona_one?: Maybe<PostalCodePersona>;
  insert_product?: Maybe<ProductMutationResponse>;
  insert_product_day_info?: Maybe<ProductDayInfoMutationResponse>;
  insert_product_day_info_one?: Maybe<ProductDayInfo>;
  insert_product_one?: Maybe<Product>;
  insert_product_price_agreement?: Maybe<ProductPriceAgreementMutationResponse>;
  insert_product_price_agreement_one?: Maybe<ProductPriceAgreement>;
  insert_reservation?: Maybe<ReservationMutationResponse>;
  insert_reservation_one?: Maybe<Reservation>;
  insert_school?: Maybe<SchoolMutationResponse>;
  insert_school_group?: Maybe<SchoolGroupMutationResponse>;
  insert_school_group_one?: Maybe<SchoolGroup>;
  insert_school_group_opening?: Maybe<SchoolGroupOpeningMutationResponse>;
  insert_school_group_opening_one?: Maybe<SchoolGroupOpening>;
  insert_school_one?: Maybe<School>;
  insert_school_service?: Maybe<SchoolServiceMutationResponse>;
  insert_school_service_one?: Maybe<SchoolService>;
  insert_service?: Maybe<ServiceMutationResponse>;
  insert_service_feature?: Maybe<ServiceFeatureMutationResponse>;
  insert_service_feature_one?: Maybe<ServiceFeature>;
  insert_service_media?: Maybe<ServiceMediaMutationResponse>;
  insert_service_media_one?: Maybe<ServiceMedia>;
  insert_service_one?: Maybe<Service>;
  insert_service_pricing?: Maybe<ServicePricingMutationResponse>;
  insert_service_pricing_one?: Maybe<ServicePricing>;
  insert_service_product?: Maybe<ServiceProductMutationResponse>;
  insert_service_product_one?: Maybe<ServiceProduct>;
  insert_service_provider?: Maybe<ServiceProviderMutationResponse>;
  insert_service_provider_one?: Maybe<ServiceProvider>;
  insert_service_settings?: Maybe<ServiceSettingsMutationResponse>;
  insert_service_settings_one?: Maybe<ServiceSettings>;
  insert_service_variety?: Maybe<ServiceVarietyMutationResponse>;
  insert_service_variety_one?: Maybe<ServiceVariety>;
  insert_service_variety_opening?: Maybe<ServiceVarietyOpeningMutationResponse>;
  insert_service_variety_opening_one?: Maybe<ServiceVarietyOpening>;
  insert_share?: Maybe<ShareMutationResponse>;
  insert_share_one?: Maybe<Share>;
  insert_subscription?: Maybe<SubscriptionMutationResponse>;
  insert_subscription_one?: Maybe<Subscription>;
  insert_subscription_service?: Maybe<SubscriptionServiceMutationResponse>;
  insert_subscription_service_one?: Maybe<SubscriptionService>;
  insert_sync_object?: Maybe<SyncObjectMutationResponse>;
  insert_sync_object_one?: Maybe<SyncObject>;
  insert_waiting_list_analysis_result?: Maybe<WaitingListAnalysisResultMutationResponse>;
  insert_waiting_list_analysis_result_one?: Maybe<WaitingListAnalysisResult>;
  insert_waiting_list_place?: Maybe<WaitingListPlaceMutationResponse>;
  insert_waiting_list_place_one?: Maybe<WaitingListPlace>;
  insert_waiting_list_proposition?: Maybe<WaitingListPropositionMutationResponse>;
  insert_waiting_list_proposition_analysis_result?: Maybe<WaitingListPropositionAnalysisResultMutationResponse>;
  insert_waiting_list_proposition_analysis_result_one?: Maybe<WaitingListPropositionAnalysisResult>;
  insert_waiting_list_proposition_one?: Maybe<WaitingListProposition>;
  proposeCreatedWaitingListProposition?: Maybe<ProposeCreatedWaitingListPropositionOutput>;
  resendEmailConfirmation?: Maybe<ResendEmailConfirmationOutput>;
  revokeProposedWaitingListProposition?: Maybe<RevokeProposedWaitingListPropositionOutput>;
  sendEmails?: Maybe<SendEmailsOutput>;
  setPublicReadAccess?: Maybe<SetPublicReadAccessOutput>;
  setWaitingListActualisationIntervals?: Maybe<SetWaitingListActualisationIntervalsOutput>;
  update_analysis_result?: Maybe<AnalysisResultMutationResponse>;
  update_analysis_result_by_pk?: Maybe<AnalysisResult>;
  update_booking?: Maybe<BookingMutationResponse>;
  update_booking_by_pk?: Maybe<Booking>;
  update_booking_status?: Maybe<BookingStatusMutationResponse>;
  update_booking_status_by_pk?: Maybe<BookingStatus>;
  update_capacity?: Maybe<CapacityMutationResponse>;
  update_capacity_by_pk?: Maybe<Capacity>;
  update_child?: Maybe<ChildMutationResponse>;
  update_child_by_pk?: Maybe<Child>;
  update_configuration?: Maybe<ConfigurationMutationResponse>;
  update_configuration_by_pk?: Maybe<Configuration>;
  update_contract?: Maybe<ContractMutationResponse>;
  update_contract_by_pk?: Maybe<Contract>;
  update_external_id_translation?: Maybe<ExternalIdTranslationMutationResponse>;
  update_external_id_translation_by_pk?: Maybe<ExternalIdTranslation>;
  update_extra_capacity?: Maybe<ExtraCapacityMutationResponse>;
  update_extra_capacity_by_pk?: Maybe<ExtraCapacity>;
  update_feature?: Maybe<FeatureMutationResponse>;
  update_feature_by_pk?: Maybe<Feature>;
  update_group?: Maybe<GroupMutationResponse>;
  update_group_by_pk?: Maybe<Group>;
  update_group_capacity?: Maybe<GroupCapacityMutationResponse>;
  update_group_capacity_by_pk?: Maybe<GroupCapacity>;
  update_group_opening?: Maybe<GroupOpeningMutationResponse>;
  update_group_opening_by_pk?: Maybe<GroupOpening>;
  update_group_pincode?: Maybe<GroupPincodeMutationResponse>;
  update_group_pincode_by_pk?: Maybe<GroupPincode>;
  update_group_product?: Maybe<GroupProductMutationResponse>;
  update_group_product_by_pk?: Maybe<GroupProduct>;
  update_last_updated?: Maybe<LastUpdatedMutationResponse>;
  update_last_updated_by_pk?: Maybe<LastUpdated>;
  update_location?: Maybe<LocationMutationResponse>;
  update_location_by_pk?: Maybe<Location>;
  update_location_inquiry?: Maybe<LocationInquiryMutationResponse>;
  update_location_inquiry_by_pk?: Maybe<LocationInquiry>;
  update_mandate?: Maybe<MandateMutationResponse>;
  update_mandate_by_pk?: Maybe<Mandate>;
  update_metric?: Maybe<MetricMutationResponse>;
  update_metric_by_pk?: Maybe<Metric>;
  update_mock_endpoint_response?: Maybe<MockEndpointResponseMutationResponse>;
  update_mock_endpoint_response_by_pk?: Maybe<MockEndpointResponse>;
  update_offer?: Maybe<OfferMutationResponse>;
  update_offer_by_pk?: Maybe<Offer>;
  update_offer_distance?: Maybe<OfferDistanceMutationResponse>;
  update_outbox?: Maybe<OutboxMutationResponse>;
  update_outbox_by_pk?: Maybe<Outbox>;
  update_persona?: Maybe<PersonaMutationResponse>;
  update_persona_by_pk?: Maybe<Persona>;
  update_persona_feature?: Maybe<PersonaFeatureMutationResponse>;
  update_persona_feature_by_pk?: Maybe<PersonaFeature>;
  update_placement?: Maybe<PlacementMutationResponse>;
  update_placement_by_pk?: Maybe<Placement>;
  update_postal_code_persona?: Maybe<PostalCodePersonaMutationResponse>;
  update_postal_code_persona_by_pk?: Maybe<PostalCodePersona>;
  update_product?: Maybe<ProductMutationResponse>;
  update_product_by_pk?: Maybe<Product>;
  update_product_day_info?: Maybe<ProductDayInfoMutationResponse>;
  update_product_day_info_by_pk?: Maybe<ProductDayInfo>;
  update_product_price_agreement?: Maybe<ProductPriceAgreementMutationResponse>;
  update_product_price_agreement_by_pk?: Maybe<ProductPriceAgreement>;
  update_reservation?: Maybe<ReservationMutationResponse>;
  update_reservation_by_pk?: Maybe<Reservation>;
  update_school?: Maybe<SchoolMutationResponse>;
  update_school_by_pk?: Maybe<School>;
  update_school_group?: Maybe<SchoolGroupMutationResponse>;
  update_school_group_by_pk?: Maybe<SchoolGroup>;
  update_school_group_opening?: Maybe<SchoolGroupOpeningMutationResponse>;
  update_school_group_opening_by_pk?: Maybe<SchoolGroupOpening>;
  update_school_service?: Maybe<SchoolServiceMutationResponse>;
  update_school_service_by_pk?: Maybe<SchoolService>;
  update_service?: Maybe<ServiceMutationResponse>;
  update_service_by_pk?: Maybe<Service>;
  update_service_feature?: Maybe<ServiceFeatureMutationResponse>;
  update_service_feature_by_pk?: Maybe<ServiceFeature>;
  update_service_media?: Maybe<ServiceMediaMutationResponse>;
  update_service_media_by_pk?: Maybe<ServiceMedia>;
  update_service_pricing?: Maybe<ServicePricingMutationResponse>;
  update_service_pricing_by_pk?: Maybe<ServicePricing>;
  update_service_product?: Maybe<ServiceProductMutationResponse>;
  update_service_product_by_pk?: Maybe<ServiceProduct>;
  update_service_provider?: Maybe<ServiceProviderMutationResponse>;
  update_service_provider_by_pk?: Maybe<ServiceProvider>;
  update_service_settings?: Maybe<ServiceSettingsMutationResponse>;
  update_service_settings_by_pk?: Maybe<ServiceSettings>;
  update_service_variety?: Maybe<ServiceVarietyMutationResponse>;
  update_service_variety_by_pk?: Maybe<ServiceVariety>;
  update_service_variety_opening?: Maybe<ServiceVarietyOpeningMutationResponse>;
  update_service_variety_opening_by_pk?: Maybe<ServiceVarietyOpening>;
  update_share?: Maybe<ShareMutationResponse>;
  update_share_by_pk?: Maybe<Share>;
  update_subscription?: Maybe<SubscriptionMutationResponse>;
  update_subscription_by_pk?: Maybe<Subscription>;
  update_subscription_service?: Maybe<SubscriptionServiceMutationResponse>;
  update_subscription_service_by_pk?: Maybe<SubscriptionService>;
  update_sync_object?: Maybe<SyncObjectMutationResponse>;
  update_sync_object_by_pk?: Maybe<SyncObject>;
  update_waiting_list_analysis_result?: Maybe<WaitingListAnalysisResultMutationResponse>;
  update_waiting_list_analysis_result_by_pk?: Maybe<WaitingListAnalysisResult>;
  update_waiting_list_place?: Maybe<WaitingListPlaceMutationResponse>;
  update_waiting_list_place_by_pk?: Maybe<WaitingListPlace>;
  update_waiting_list_proposition?: Maybe<WaitingListPropositionMutationResponse>;
  update_waiting_list_proposition_analysis_result?: Maybe<WaitingListPropositionAnalysisResultMutationResponse>;
  update_waiting_list_proposition_analysis_result_by_pk?: Maybe<WaitingListPropositionAnalysisResult>;
  update_waiting_list_proposition_by_pk?: Maybe<WaitingListProposition>;
};
export type Offer = {
  __typename?: 'offer';
  addressLine1: Scalars['String'];
  addressLine2?: Maybe<Scalars['String']>;
  bookingsUrl?: Maybe<Scalars['String']>;
  capacities: Array<Capacity>;
  capacityLastCalculatedDate?: Maybe<Scalars['timestamptz']>;
  capacity_changed_on?: Maybe<Scalars['timestamptz']>;
  contactInfo?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  flexMargin: Scalars['jsonb'];
  id: Scalars['String'];
  images?: Maybe<Scalars['jsonb']>;
  kind: Scalars['String'];
  latitude: Scalars['float8'];
  locality: Scalars['String'];
  longitude: Scalars['float8'];
  name: Scalars['String'];
  postalCode: Scalars['String'];
  service: Service;
  serviceFeatures: Array<ServiceFeature>;
  serviceId: Scalars['String'];
  serviceVarieties: Array<ServiceVariety>;
  slug?: Maybe<Scalars['String']>;
  startTimeCapacityCalculation?: Maybe<Scalars['timestamptz']>;
  tourUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
};
export type OfferAggregateFields = {
  __typename?: 'offer_aggregate_fields';
  avg?: Maybe<OfferAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OfferMaxFields>;
  min?: Maybe<OfferMinFields>;
  stddev?: Maybe<OfferStddevFields>;
  stddev_pop?: Maybe<OfferStddevPopFields>;
  stddev_samp?: Maybe<OfferStddevSampFields>;
  sum?: Maybe<OfferSumFields>;
  var_pop?: Maybe<OfferVarPopFields>;
  var_samp?: Maybe<OfferVarSampFields>;
  variance?: Maybe<OfferVarianceFields>;
};
export type OfferAggregateOrderBy = {
  avg?: Maybe<OfferAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OfferMaxOrderBy>;
  min?: Maybe<OfferMinOrderBy>;
  stddev?: Maybe<OfferStddevOrderBy>;
  stddev_pop?: Maybe<OfferStddevPopOrderBy>;
  stddev_samp?: Maybe<OfferStddevSampOrderBy>;
  sum?: Maybe<OfferSumOrderBy>;
  var_pop?: Maybe<OfferVarPopOrderBy>;
  var_samp?: Maybe<OfferVarSampOrderBy>;
  variance?: Maybe<OfferVarianceOrderBy>;
};
export type OfferAvgFields = {
  __typename?: 'offer_avg_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferAvgOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferDistance = {
  __typename?: 'offer_distance';
  distance: Scalars['float8'];
  id: Scalars['String'];
  offerDistanceOffer?: Maybe<Offer>;
};
export type OfferDistanceAggregateFields = {
  __typename?: 'offer_distance_aggregate_fields';
  avg?: Maybe<OfferDistanceAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<OfferDistanceMaxFields>;
  min?: Maybe<OfferDistanceMinFields>;
  stddev?: Maybe<OfferDistanceStddevFields>;
  stddev_pop?: Maybe<OfferDistanceStddevPopFields>;
  stddev_samp?: Maybe<OfferDistanceStddevSampFields>;
  sum?: Maybe<OfferDistanceSumFields>;
  var_pop?: Maybe<OfferDistanceVarPopFields>;
  var_samp?: Maybe<OfferDistanceVarSampFields>;
  variance?: Maybe<OfferDistanceVarianceFields>;
};
export type OfferDistanceAvgFields = {
  __typename?: 'offer_distance_avg_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceMaxFields = {
  __typename?: 'offer_distance_max_fields';
  distance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
};
export type OfferDistanceMinFields = {
  __typename?: 'offer_distance_min_fields';
  distance?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['String']>;
};
export type OfferDistanceMutationResponse = {
  __typename?: 'offer_distance_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<OfferDistance>;
};
export type OfferDistanceOrderBy = {
  distance?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  offerDistanceOffer?: Maybe<OfferOrderBy>;
};
export type OfferDistanceStddevFields = {
  __typename?: 'offer_distance_stddev_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceStddevPopFields = {
  __typename?: 'offer_distance_stddev_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceStddevSampFields = {
  __typename?: 'offer_distance_stddev_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceSumFields = {
  __typename?: 'offer_distance_sum_fields';
  distance?: Maybe<Scalars['float8']>;
};
export type OfferDistanceVarPopFields = {
  __typename?: 'offer_distance_var_pop_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceVarSampFields = {
  __typename?: 'offer_distance_var_samp_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferDistanceVarianceFields = {
  __typename?: 'offer_distance_variance_fields';
  distance?: Maybe<Scalars['Float']>;
};
export type OfferMaxFields = {
  __typename?: 'offer_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  bookingsUrl?: Maybe<Scalars['String']>;
  capacityLastCalculatedDate?: Maybe<Scalars['timestamptz']>;
  capacity_changed_on?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  locality?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startTimeCapacityCalculation?: Maybe<Scalars['timestamptz']>;
  tourUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OfferMaxOrderBy = {
  addressLine1?: Maybe<OrderBy>;
  addressLine2?: Maybe<OrderBy>;
  bookingsUrl?: Maybe<OrderBy>;
  capacityLastCalculatedDate?: Maybe<OrderBy>;
  capacity_changed_on?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  latitude?: Maybe<OrderBy>;
  locality?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  startTimeCapacityCalculation?: Maybe<OrderBy>;
  tourUrl?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type OfferMinFields = {
  __typename?: 'offer_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  bookingsUrl?: Maybe<Scalars['String']>;
  capacityLastCalculatedDate?: Maybe<Scalars['timestamptz']>;
  capacity_changed_on?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['float8']>;
  locality?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['float8']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  startTimeCapacityCalculation?: Maybe<Scalars['timestamptz']>;
  tourUrl?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OfferMinOrderBy = {
  addressLine1?: Maybe<OrderBy>;
  addressLine2?: Maybe<OrderBy>;
  bookingsUrl?: Maybe<OrderBy>;
  capacityLastCalculatedDate?: Maybe<OrderBy>;
  capacity_changed_on?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  latitude?: Maybe<OrderBy>;
  locality?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  startTimeCapacityCalculation?: Maybe<OrderBy>;
  tourUrl?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type OfferMutationResponse = {
  __typename?: 'offer_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Offer>;
};
export type OfferOrderBy = {
  addressLine1?: Maybe<OrderBy>;
  addressLine2?: Maybe<OrderBy>;
  bookingsUrl?: Maybe<OrderBy>;
  capacityLastCalculatedDate?: Maybe<OrderBy>;
  capacity_changed_on?: Maybe<OrderBy>;
  contactInfo?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  flexMargin?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  images?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  latitude?: Maybe<OrderBy>;
  locality?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  startTimeCapacityCalculation?: Maybe<OrderBy>;
  tourUrl?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type OfferStddevFields = {
  __typename?: 'offer_stddev_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferStddevOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferStddevPopFields = {
  __typename?: 'offer_stddev_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferStddevPopOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferStddevSampFields = {
  __typename?: 'offer_stddev_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferStddevSampOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferSumFields = {
  __typename?: 'offer_sum_fields';
  latitude?: Maybe<Scalars['float8']>;
  longitude?: Maybe<Scalars['float8']>;
};
export type OfferSumOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferVarPopFields = {
  __typename?: 'offer_var_pop_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferVarPopOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferVarSampFields = {
  __typename?: 'offer_var_samp_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferVarSampOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export type OfferVarianceFields = {
  __typename?: 'offer_variance_fields';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};
export type OfferVarianceOrderBy = {
  latitude?: Maybe<OrderBy>;
  longitude?: Maybe<OrderBy>;
};
export enum OrderBy {
  asc = 0,
  asc_nulls_first = 1,
  asc_nulls_last = 2,
  desc = 3,
  desc_nulls_first = 4,
  desc_nulls_last = 5
}
export type Outbox = {
  __typename?: 'outbox';
  attachmentData?: Maybe<Scalars['jsonb']>;
  bookingId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  emailContext?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  sendDate: Scalars['timestamptz'];
  sendFromEmail?: Maybe<Scalars['String']>;
  sendStatus: Scalars['String'];
  sendToEmail: Scalars['String'];
  senderName?: Maybe<Scalars['String']>;
  template: Scalars['String'];
  templateData?: Maybe<Scalars['jsonb']>;
  triedToSendDate?: Maybe<Scalars['timestamptz']>;
  updatedAt: Scalars['timestamptz'];
};
export type OutboxAggregateFields = {
  __typename?: 'outbox_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<OutboxMaxFields>;
  min?: Maybe<OutboxMinFields>;
};
export type OutboxAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<OutboxMaxOrderBy>;
  min?: Maybe<OutboxMinOrderBy>;
};
export type OutboxMaxFields = {
  __typename?: 'outbox_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  sendFromEmail?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<Scalars['String']>;
  sendToEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  triedToSendDate?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OutboxMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  sendFromEmail?: Maybe<OrderBy>;
  sendStatus?: Maybe<OrderBy>;
  sendToEmail?: Maybe<OrderBy>;
  senderName?: Maybe<OrderBy>;
  template?: Maybe<OrderBy>;
  triedToSendDate?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type OutboxMinFields = {
  __typename?: 'outbox_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  sendDate?: Maybe<Scalars['timestamptz']>;
  sendFromEmail?: Maybe<Scalars['String']>;
  sendStatus?: Maybe<Scalars['String']>;
  sendToEmail?: Maybe<Scalars['String']>;
  senderName?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
  triedToSendDate?: Maybe<Scalars['timestamptz']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type OutboxMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  sendFromEmail?: Maybe<OrderBy>;
  sendStatus?: Maybe<OrderBy>;
  sendToEmail?: Maybe<OrderBy>;
  senderName?: Maybe<OrderBy>;
  template?: Maybe<OrderBy>;
  triedToSendDate?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type OutboxMutationResponse = {
  __typename?: 'outbox_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Outbox>;
};
export type OutboxOrderBy = {
  attachmentData?: Maybe<OrderBy>;
  bookingId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  emailContext?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  sendDate?: Maybe<OrderBy>;
  sendFromEmail?: Maybe<OrderBy>;
  sendStatus?: Maybe<OrderBy>;
  sendToEmail?: Maybe<OrderBy>;
  senderName?: Maybe<OrderBy>;
  template?: Maybe<OrderBy>;
  templateData?: Maybe<OrderBy>;
  triedToSendDate?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Persona = {
  __typename?: 'persona';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name: Scalars['String'];
  persona_features: Array<PersonaFeature>;
  postal_code_personas: Array<PostalCodePersona>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaAggregateFields = {
  __typename?: 'persona_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PersonaMaxFields>;
  min?: Maybe<PersonaMinFields>;
};
export type PersonaFeature = {
  __typename?: 'persona_feature';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feature: Feature;
  featureId: Scalars['String'];
  id: Scalars['String'];
  persona: Persona;
  personaId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaFeatureAggregateFields = {
  __typename?: 'persona_feature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PersonaFeatureMaxFields>;
  min?: Maybe<PersonaFeatureMinFields>;
};
export type PersonaFeatureAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PersonaFeatureMaxOrderBy>;
  min?: Maybe<PersonaFeatureMinOrderBy>;
};
export type PersonaFeatureMaxFields = {
  __typename?: 'persona_feature_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  featureId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  personaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaFeatureMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  personaId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type PersonaFeatureMinFields = {
  __typename?: 'persona_feature_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  featureId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  personaId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaFeatureMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  personaId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type PersonaFeatureMutationResponse = {
  __typename?: 'persona_feature_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<PersonaFeature>;
};
export type PersonaFeatureOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  feature?: Maybe<FeatureOrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  persona?: Maybe<PersonaOrderBy>;
  personaId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type PersonaMaxFields = {
  __typename?: 'persona_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaMinFields = {
  __typename?: 'persona_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PersonaMutationResponse = {
  __typename?: 'persona_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Persona>;
};
export type PersonaOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Placement = {
  __typename?: 'placement';
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isCallbackReceived: Scalars['Boolean'];
  locationInquiryId?: Maybe<Scalars['String']>;
  reservations: Array<Reservation>;
  status: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  useFlexkidsProducts: Scalars['Boolean'];
  validFrom: Scalars['date'];
  validUntil: Scalars['date'];
};
export type PlacementAggregateFields = {
  __typename?: 'placement_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PlacementMaxFields>;
  min?: Maybe<PlacementMinFields>;
};
export type PlacementAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PlacementMaxOrderBy>;
  min?: Maybe<PlacementMinOrderBy>;
};
export type PlacementMaxFields = {
  __typename?: 'placement_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locationInquiryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type PlacementMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type PlacementMinFields = {
  __typename?: 'placement_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  cancellationReason?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locationInquiryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type PlacementMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type PlacementMutationResponse = {
  __typename?: 'placement_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Placement>;
};
export type PlacementOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  cancellationReason?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isCallbackReceived?: Maybe<OrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  useFlexkidsProducts?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type PostalCodePersona = {
  __typename?: 'postal_code_persona';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  persona: Persona;
  personaId: Scalars['String'];
  postal_code: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PostalCodePersonaAggregateFields = {
  __typename?: 'postal_code_persona_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PostalCodePersonaMaxFields>;
  min?: Maybe<PostalCodePersonaMinFields>;
};
export type PostalCodePersonaAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<PostalCodePersonaMaxOrderBy>;
  min?: Maybe<PostalCodePersonaMinOrderBy>;
};
export type PostalCodePersonaMaxFields = {
  __typename?: 'postal_code_persona_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  personaId?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PostalCodePersonaMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  personaId?: Maybe<OrderBy>;
  postal_code?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type PostalCodePersonaMinFields = {
  __typename?: 'postal_code_persona_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  personaId?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type PostalCodePersonaMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  personaId?: Maybe<OrderBy>;
  postal_code?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type PostalCodePersonaMutationResponse = {
  __typename?: 'postal_code_persona_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<PostalCodePersona>;
};
export type PostalCodePersonaOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  persona?: Maybe<PersonaOrderBy>;
  personaId?: Maybe<OrderBy>;
  postal_code?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Product = {
  __typename?: 'product';
  category: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  isDivergentPricing: Scalars['Boolean'];
  isSupported: Scalars['Boolean'];
  name: Scalars['String'];
  placementType: Scalars['String'];
  productDayInfos: Array<ProductDayInfo>;
  serviceVariety: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  vaildUntil?: Maybe<Scalars['timestamptz']>;
  validFrom: Scalars['timestamptz'];
};
export type ProductAggregateFields = {
  __typename?: 'product_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ProductMaxFields>;
  min?: Maybe<ProductMinFields>;
};
export type ProductDayInfo = {
  __typename?: 'product_day_info';
  createdAt: Scalars['timestamptz'];
  dayOfWeek: Scalars['String'];
  endTime: Scalars['time'];
  id: Scalars['String'];
  monthlyExtraMinutes: Scalars['Int'];
  partOfDay: Scalars['String'];
  productId: Scalars['String'];
  startTime: Scalars['time'];
  timeFromCalculateMethod: Scalars['String'];
  timeUntilCalculateMethod: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  yearlyMonths: Scalars['Int'];
  yearlyWeeks: Scalars['Int'];
};
export type ProductDayInfoAggregateFields = {
  __typename?: 'product_day_info_aggregate_fields';
  avg?: Maybe<ProductDayInfoAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProductDayInfoMaxFields>;
  min?: Maybe<ProductDayInfoMinFields>;
  stddev?: Maybe<ProductDayInfoStddevFields>;
  stddev_pop?: Maybe<ProductDayInfoStddevPopFields>;
  stddev_samp?: Maybe<ProductDayInfoStddevSampFields>;
  sum?: Maybe<ProductDayInfoSumFields>;
  var_pop?: Maybe<ProductDayInfoVarPopFields>;
  var_samp?: Maybe<ProductDayInfoVarSampFields>;
  variance?: Maybe<ProductDayInfoVarianceFields>;
};
export type ProductDayInfoAggregateOrderBy = {
  avg?: Maybe<ProductDayInfoAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductDayInfoMaxOrderBy>;
  min?: Maybe<ProductDayInfoMinOrderBy>;
  stddev?: Maybe<ProductDayInfoStddevOrderBy>;
  stddev_pop?: Maybe<ProductDayInfoStddevPopOrderBy>;
  stddev_samp?: Maybe<ProductDayInfoStddevSampOrderBy>;
  sum?: Maybe<ProductDayInfoSumOrderBy>;
  var_pop?: Maybe<ProductDayInfoVarPopOrderBy>;
  var_samp?: Maybe<ProductDayInfoVarSampOrderBy>;
  variance?: Maybe<ProductDayInfoVarianceOrderBy>;
};
export type ProductDayInfoAvgFields = {
  __typename?: 'product_day_info_avg_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoAvgOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoMaxFields = {
  __typename?: 'product_day_info_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  monthlyExtraMinutes?: Maybe<Scalars['Int']>;
  partOfDay?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  timeFromCalculateMethod?: Maybe<Scalars['String']>;
  timeUntilCalculateMethod?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  yearlyMonths?: Maybe<Scalars['Int']>;
  yearlyWeeks?: Maybe<Scalars['Int']>;
};
export type ProductDayInfoMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monthlyExtraMinutes?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  timeFromCalculateMethod?: Maybe<OrderBy>;
  timeUntilCalculateMethod?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoMinFields = {
  __typename?: 'product_day_info_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  monthlyExtraMinutes?: Maybe<Scalars['Int']>;
  partOfDay?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  timeFromCalculateMethod?: Maybe<Scalars['String']>;
  timeUntilCalculateMethod?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  yearlyMonths?: Maybe<Scalars['Int']>;
  yearlyWeeks?: Maybe<Scalars['Int']>;
};
export type ProductDayInfoMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monthlyExtraMinutes?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  timeFromCalculateMethod?: Maybe<OrderBy>;
  timeUntilCalculateMethod?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoMutationResponse = {
  __typename?: 'product_day_info_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ProductDayInfo>;
};
export type ProductDayInfoOrderBy = {
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  endTime?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monthlyExtraMinutes?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  startTime?: Maybe<OrderBy>;
  timeFromCalculateMethod?: Maybe<OrderBy>;
  timeUntilCalculateMethod?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoStddevFields = {
  __typename?: 'product_day_info_stddev_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoStddevOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoStddevPopFields = {
  __typename?: 'product_day_info_stddev_pop_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoStddevPopOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoStddevSampFields = {
  __typename?: 'product_day_info_stddev_samp_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoStddevSampOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoSumFields = {
  __typename?: 'product_day_info_sum_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Int']>;
  yearlyMonths?: Maybe<Scalars['Int']>;
  yearlyWeeks?: Maybe<Scalars['Int']>;
};
export type ProductDayInfoSumOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoVarPopFields = {
  __typename?: 'product_day_info_var_pop_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoVarPopOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoVarSampFields = {
  __typename?: 'product_day_info_var_samp_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoVarSampOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductDayInfoVarianceFields = {
  __typename?: 'product_day_info_variance_fields';
  monthlyExtraMinutes?: Maybe<Scalars['Float']>;
  yearlyMonths?: Maybe<Scalars['Float']>;
  yearlyWeeks?: Maybe<Scalars['Float']>;
};
export type ProductDayInfoVarianceOrderBy = {
  monthlyExtraMinutes?: Maybe<OrderBy>;
  yearlyMonths?: Maybe<OrderBy>;
  yearlyWeeks?: Maybe<OrderBy>;
};
export type ProductMaxFields = {
  __typename?: 'product_max_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placementType?: Maybe<Scalars['String']>;
  serviceVariety?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaildUntil?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
};
export type ProductMinFields = {
  __typename?: 'product_min_fields';
  category?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  placementType?: Maybe<Scalars['String']>;
  serviceVariety?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vaildUntil?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
};
export type ProductMutationResponse = {
  __typename?: 'product_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Product>;
};
export type ProductOrderBy = {
  category?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isDivergentPricing?: Maybe<OrderBy>;
  isSupported?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  placementType?: Maybe<OrderBy>;
  serviceVariety?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vaildUntil?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
};
export type ProductPriceAgreement = {
  __typename?: 'product_price_agreement';
  centsAmountFriday: Scalars['Int'];
  centsAmountMonday: Scalars['Int'];
  centsAmountThursday: Scalars['Int'];
  centsAmountTuesday: Scalars['Int'];
  centsAmountWednesday: Scalars['Int'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  priceType: Scalars['String'];
  productId: Scalars['String'];
  serviceId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['timestamptz'];
  validUntil?: Maybe<Scalars['timestamptz']>;
};
export type ProductPriceAgreementAggregateFields = {
  __typename?: 'product_price_agreement_aggregate_fields';
  avg?: Maybe<ProductPriceAgreementAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ProductPriceAgreementMaxFields>;
  min?: Maybe<ProductPriceAgreementMinFields>;
  stddev?: Maybe<ProductPriceAgreementStddevFields>;
  stddev_pop?: Maybe<ProductPriceAgreementStddevPopFields>;
  stddev_samp?: Maybe<ProductPriceAgreementStddevSampFields>;
  sum?: Maybe<ProductPriceAgreementSumFields>;
  var_pop?: Maybe<ProductPriceAgreementVarPopFields>;
  var_samp?: Maybe<ProductPriceAgreementVarSampFields>;
  variance?: Maybe<ProductPriceAgreementVarianceFields>;
};
export type ProductPriceAgreementAggregateOrderBy = {
  avg?: Maybe<ProductPriceAgreementAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductPriceAgreementMaxOrderBy>;
  min?: Maybe<ProductPriceAgreementMinOrderBy>;
  stddev?: Maybe<ProductPriceAgreementStddevOrderBy>;
  stddev_pop?: Maybe<ProductPriceAgreementStddevPopOrderBy>;
  stddev_samp?: Maybe<ProductPriceAgreementStddevSampOrderBy>;
  sum?: Maybe<ProductPriceAgreementSumOrderBy>;
  var_pop?: Maybe<ProductPriceAgreementVarPopOrderBy>;
  var_samp?: Maybe<ProductPriceAgreementVarSampOrderBy>;
  variance?: Maybe<ProductPriceAgreementVarianceOrderBy>;
};
export type ProductPriceAgreementAvgFields = {
  __typename?: 'product_price_agreement_avg_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementAvgOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementMaxFields = {
  __typename?: 'product_price_agreement_max_fields';
  centsAmountFriday?: Maybe<Scalars['Int']>;
  centsAmountMonday?: Maybe<Scalars['Int']>;
  centsAmountThursday?: Maybe<Scalars['Int']>;
  centsAmountTuesday?: Maybe<Scalars['Int']>;
  centsAmountWednesday?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  priceType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validUntil?: Maybe<Scalars['timestamptz']>;
};
export type ProductPriceAgreementMaxOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  priceType?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ProductPriceAgreementMinFields = {
  __typename?: 'product_price_agreement_min_fields';
  centsAmountFriday?: Maybe<Scalars['Int']>;
  centsAmountMonday?: Maybe<Scalars['Int']>;
  centsAmountThursday?: Maybe<Scalars['Int']>;
  centsAmountTuesday?: Maybe<Scalars['Int']>;
  centsAmountWednesday?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  priceType?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
  validUntil?: Maybe<Scalars['timestamptz']>;
};
export type ProductPriceAgreementMinOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  priceType?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ProductPriceAgreementMutationResponse = {
  __typename?: 'product_price_agreement_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ProductPriceAgreement>;
};
export type ProductPriceAgreementOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  priceType?: Maybe<OrderBy>;
  productId?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ProductPriceAgreementStddevFields = {
  __typename?: 'product_price_agreement_stddev_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementStddevOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementStddevPopFields = {
  __typename?: 'product_price_agreement_stddev_pop_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementStddevPopOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementStddevSampFields = {
  __typename?: 'product_price_agreement_stddev_samp_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementStddevSampOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementSumFields = {
  __typename?: 'product_price_agreement_sum_fields';
  centsAmountFriday?: Maybe<Scalars['Int']>;
  centsAmountMonday?: Maybe<Scalars['Int']>;
  centsAmountThursday?: Maybe<Scalars['Int']>;
  centsAmountTuesday?: Maybe<Scalars['Int']>;
  centsAmountWednesday?: Maybe<Scalars['Int']>;
};
export type ProductPriceAgreementSumOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementVarPopFields = {
  __typename?: 'product_price_agreement_var_pop_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementVarPopOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementVarSampFields = {
  __typename?: 'product_price_agreement_var_samp_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementVarSampOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type ProductPriceAgreementVarianceFields = {
  __typename?: 'product_price_agreement_variance_fields';
  centsAmountFriday?: Maybe<Scalars['Float']>;
  centsAmountMonday?: Maybe<Scalars['Float']>;
  centsAmountThursday?: Maybe<Scalars['Float']>;
  centsAmountTuesday?: Maybe<Scalars['Float']>;
  centsAmountWednesday?: Maybe<Scalars['Float']>;
};
export type ProductPriceAgreementVarianceOrderBy = {
  centsAmountFriday?: Maybe<OrderBy>;
  centsAmountMonday?: Maybe<OrderBy>;
  centsAmountThursday?: Maybe<OrderBy>;
  centsAmountTuesday?: Maybe<OrderBy>;
  centsAmountWednesday?: Maybe<OrderBy>;
};
export type QueryRoot = {
  __typename?: 'query_root';
  acceptProposedWaitingListProposition?: Maybe<AcceptProposedWaitingListProposition>;
  analysis_result: Array<AnalysisResult>;
  analysis_result_by_pk?: Maybe<AnalysisResult>;
  booking: Array<Booking>;
  booking_by_pk?: Maybe<Booking>;
  booking_status: Array<BookingStatus>;
  booking_status_by_pk?: Maybe<BookingStatus>;
  canEnableWaitingList?: Maybe<CanEnableWaitingListOutput>;
  capacity: Array<Capacity>;
  capacity_by_pk?: Maybe<Capacity>;
  child: Array<Child>;
  child_by_pk?: Maybe<Child>;
  configuration: Array<Configuration>;
  configuration_by_pk?: Maybe<Configuration>;
  contract: Array<Contract>;
  contract_by_pk?: Maybe<Contract>;
  createBooking?: Maybe<CreateBooking>;
  exportServiceSettings?: Maybe<ExportServiceSettingsOutput>;
  external_id_translation: Array<ExternalIdTranslation>;
  external_id_translation_by_pk?: Maybe<ExternalIdTranslation>;
  extra_capacity: Array<ExtraCapacity>;
  extra_capacity_by_pk?: Maybe<ExtraCapacity>;
  feature: Array<Feature>;
  feature_by_pk?: Maybe<Feature>;
  fetchPostalcodeInfo?: Maybe<FetchPostalcodeInfoOutput>;
  getBookingStatus?: Maybe<GetBookingStatusOutput>;
  getEncryptedState?: Maybe<GetShareOutput>;
  getPropositionSubscriptions: GetPropositionSubscriptionsOutput;
  getPropositions?: Maybe<Array<GetPropositionsOutput>>;
  getPropositionsAnalysis: GetPropositionsAnalysisOutput;
  getSignedDeleteUrl?: Maybe<GetSignedDeleteUrlOutput>;
  getSignedUploadUrl?: Maybe<GetSignedUploadUrlOutput>;
  getWaitingListStatus?: Maybe<GetWaitingListStatusOutput>;
  group: Array<Group>;
  group_by_pk?: Maybe<Group>;
  group_capacity: Array<GroupCapacity>;
  group_capacity_by_pk?: Maybe<GroupCapacity>;
  group_opening: Array<GroupOpening>;
  group_opening_by_pk?: Maybe<GroupOpening>;
  group_pincode: Array<GroupPincode>;
  group_pincode_by_pk?: Maybe<GroupPincode>;
  group_product: Array<GroupProduct>;
  group_product_by_pk?: Maybe<GroupProduct>;
  last_updated: Array<LastUpdated>;
  last_updated_by_pk?: Maybe<LastUpdated>;
  location: Array<Location>;
  location_by_pk?: Maybe<Location>;
  location_inquiry: Array<LocationInquiry>;
  location_inquiry_by_pk?: Maybe<LocationInquiry>;
  mandate: Array<Mandate>;
  mandate_by_pk?: Maybe<Mandate>;
  metric: Array<Metric>;
  metric_by_pk?: Maybe<Metric>;
  mock_endpoint_response: Array<MockEndpointResponse>;
  mock_endpoint_response_by_pk?: Maybe<MockEndpointResponse>;
  offer: Array<Offer>;
  offer_by_distance: Array<OfferDistance>;
  offer_by_pk?: Maybe<Offer>;
  offer_distance: Array<OfferDistance>;
  outbox: Array<Outbox>;
  outbox_by_pk?: Maybe<Outbox>;
  persona: Array<Persona>;
  persona_by_pk?: Maybe<Persona>;
  persona_feature: Array<PersonaFeature>;
  persona_feature_by_pk?: Maybe<PersonaFeature>;
  placement: Array<Placement>;
  placement_by_pk?: Maybe<Placement>;
  postal_code_persona: Array<PostalCodePersona>;
  postal_code_persona_by_pk?: Maybe<PostalCodePersona>;
  product: Array<Product>;
  product_by_pk?: Maybe<Product>;
  product_day_info: Array<ProductDayInfo>;
  product_day_info_by_pk?: Maybe<ProductDayInfo>;
  product_price_agreement: Array<ProductPriceAgreement>;
  product_price_agreement_by_pk?: Maybe<ProductPriceAgreement>;
  reservation: Array<Reservation>;
  reservation_by_pk?: Maybe<Reservation>;
  school: Array<School>;
  school_by_pk?: Maybe<School>;
  school_group: Array<SchoolGroup>;
  school_group_by_pk?: Maybe<SchoolGroup>;
  school_group_opening: Array<SchoolGroupOpening>;
  school_group_opening_by_pk?: Maybe<SchoolGroupOpening>;
  school_service: Array<SchoolService>;
  school_service_by_pk?: Maybe<SchoolService>;
  service: Array<Service>;
  service_by_pk?: Maybe<Service>;
  service_feature: Array<ServiceFeature>;
  service_feature_by_pk?: Maybe<ServiceFeature>;
  service_media: Array<ServiceMedia>;
  service_media_by_pk?: Maybe<ServiceMedia>;
  service_pricing: Array<ServicePricing>;
  service_pricing_by_pk?: Maybe<ServicePricing>;
  service_product: Array<ServiceProduct>;
  service_product_by_pk?: Maybe<ServiceProduct>;
  service_provider: Array<ServiceProvider>;
  service_provider_by_pk?: Maybe<ServiceProvider>;
  service_settings: Array<ServiceSettings>;
  service_settings_by_pk?: Maybe<ServiceSettings>;
  service_variety: Array<ServiceVariety>;
  service_variety_by_pk?: Maybe<ServiceVariety>;
  service_variety_opening: Array<ServiceVarietyOpening>;
  service_variety_opening_by_pk?: Maybe<ServiceVarietyOpening>;
  share: Array<Share>;
  share_by_pk?: Maybe<Share>;
  subscription: Array<Subscription>;
  subscription_by_pk?: Maybe<Subscription>;
  subscription_service: Array<SubscriptionService>;
  subscription_service_by_pk?: Maybe<SubscriptionService>;
  sync_object: Array<SyncObject>;
  sync_object_by_pk?: Maybe<SyncObject>;
  waiting_list_analysis_result: Array<WaitingListAnalysisResult>;
  waiting_list_analysis_result_by_pk?: Maybe<WaitingListAnalysisResult>;
  waiting_list_place: Array<WaitingListPlace>;
  waiting_list_place_by_pk?: Maybe<WaitingListPlace>;
  waiting_list_proposition: Array<WaitingListProposition>;
  waiting_list_proposition_analysis_result: Array<WaitingListPropositionAnalysisResult>;
  waiting_list_proposition_analysis_result_by_pk?: Maybe<WaitingListPropositionAnalysisResult>;
  waiting_list_proposition_by_pk?: Maybe<WaitingListProposition>;
};
export type Reservation = {
  __typename?: 'reservation';
  booking?: Maybe<Booking>;
  bookingId?: Maybe<Scalars['String']>;
  child: Child;
  childId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  dayOfWeek: Scalars['String'];
  deletedAt?: Maybe<Scalars['timestamp']>;
  group: Group;
  groupId: Scalars['String'];
  id: Scalars['String'];
  partOfDay: Scalars['String'];
  placement?: Maybe<Placement>;
  placementId?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  serviceVariety: ServiceVariety;
  serviceVarietyId: Scalars['String'];
  status: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  weekInterval: Scalars['String'];
};
export type ReservationAggregateFields = {
  __typename?: 'reservation_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ReservationMaxFields>;
  min?: Maybe<ReservationMinFields>;
};
export type ReservationAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ReservationMaxOrderBy>;
  min?: Maybe<ReservationMinOrderBy>;
};
export type ReservationMaxFields = {
  __typename?: 'reservation_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  weekInterval?: Maybe<Scalars['String']>;
};
export type ReservationMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  placementId?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  weekInterval?: Maybe<OrderBy>;
};
export type ReservationMinFields = {
  __typename?: 'reservation_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['timestamp']>;
  groupId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
  weekInterval?: Maybe<Scalars['String']>;
};
export type ReservationMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  placementId?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  weekInterval?: Maybe<OrderBy>;
};
export type ReservationMutationResponse = {
  __typename?: 'reservation_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Reservation>;
};
export type ReservationOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  child?: Maybe<ChildOrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  group?: Maybe<GroupOrderBy>;
  groupId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  placement?: Maybe<PlacementOrderBy>;
  placementId?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  serviceVariety?: Maybe<ServiceVarietyOrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  weekInterval?: Maybe<OrderBy>;
};
export type School = {
  __typename?: 'school';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  locality: Scalars['String'];
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  schoolGroups: Array<SchoolGroup>;
  schoolServices: Array<SchoolService>;
  updatedAt: Scalars['timestamptz'];
};
export type SchoolAggregateFields = {
  __typename?: 'school_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SchoolMaxFields>;
  min?: Maybe<SchoolMinFields>;
};
export type SchoolGroup = {
  __typename?: 'school_group';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name: Scalars['String'];
  schoolGroupOpenings: Array<SchoolGroupOpening>;
  schoolId: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type SchoolGroupAggregateFields = {
  __typename?: 'school_group_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SchoolGroupMaxFields>;
  min?: Maybe<SchoolGroupMinFields>;
};
export type SchoolGroupAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<SchoolGroupMaxOrderBy>;
  min?: Maybe<SchoolGroupMinOrderBy>;
};
export type SchoolGroupMaxFields = {
  __typename?: 'school_group_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type SchoolGroupMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  schoolId?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type SchoolGroupMinFields = {
  __typename?: 'school_group_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type SchoolGroupMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  schoolId?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type SchoolGroupMutationResponse = {
  __typename?: 'school_group_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<SchoolGroup>;
};
export type SchoolGroupOpening = {
  __typename?: 'school_group_opening';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  friday?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  monday?: Maybe<Scalars['jsonb']>;
  saturday?: Maybe<Scalars['jsonb']>;
  school_group_id: Scalars['String'];
  sunday?: Maybe<Scalars['jsonb']>;
  thursday?: Maybe<Scalars['jsonb']>;
  tuesday?: Maybe<Scalars['jsonb']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  wednesday?: Maybe<Scalars['jsonb']>;
};
export type SchoolGroupOpeningAggregateFields = {
  __typename?: 'school_group_opening_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SchoolGroupOpeningMaxFields>;
  min?: Maybe<SchoolGroupOpeningMinFields>;
};
export type SchoolGroupOpeningAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<SchoolGroupOpeningMaxOrderBy>;
  min?: Maybe<SchoolGroupOpeningMinOrderBy>;
};
export type SchoolGroupOpeningMaxFields = {
  __typename?: 'school_group_opening_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  school_group_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type SchoolGroupOpeningMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  school_group_id?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type SchoolGroupOpeningMinFields = {
  __typename?: 'school_group_opening_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  school_group_id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type SchoolGroupOpeningMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  school_group_id?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type SchoolGroupOpeningMutationResponse = {
  __typename?: 'school_group_opening_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<SchoolGroupOpening>;
};
export type SchoolGroupOpeningOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  friday?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  monday?: Maybe<OrderBy>;
  saturday?: Maybe<OrderBy>;
  school_group_id?: Maybe<OrderBy>;
  sunday?: Maybe<OrderBy>;
  thursday?: Maybe<OrderBy>;
  tuesday?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesday?: Maybe<OrderBy>;
};
export type SchoolGroupOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  schoolId?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type SchoolMaxFields = {
  __typename?: 'school_max_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SchoolMinFields = {
  __typename?: 'school_min_fields';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SchoolMutationResponse = {
  __typename?: 'school_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<School>;
};
export type SchoolOrderBy = {
  addressLine1?: Maybe<OrderBy>;
  addressLine2?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locality?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  postalCode?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SchoolService = {
  __typename?: 'school_service';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  school: School;
  schoolId: Scalars['String'];
  service: Service;
  serviceId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};
export type SchoolServiceAggregateFields = {
  __typename?: 'school_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SchoolServiceMaxFields>;
  min?: Maybe<SchoolServiceMinFields>;
};
export type SchoolServiceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<SchoolServiceMaxOrderBy>;
  min?: Maybe<SchoolServiceMinOrderBy>;
};
export type SchoolServiceMaxFields = {
  __typename?: 'school_service_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SchoolServiceMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  schoolId?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SchoolServiceMinFields = {
  __typename?: 'school_service_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  schoolId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SchoolServiceMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  schoolId?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SchoolServiceMutationResponse = {
  __typename?: 'school_service_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<SchoolService>;
};
export type SchoolServiceOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  school?: Maybe<SchoolOrderBy>;
  schoolId?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type Service = {
  __typename?: 'service';
  bookings: Array<Booking>;
  contactInfo?: Maybe<Scalars['jsonb']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  flexMargin: Scalars['jsonb'];
  groups: Array<Group>;
  id: Scalars['String'];
  isBookable: Scalars['Boolean'];
  isCustomSubscriptionEnabled: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isPublishedForChildBenefitCalculator: Scalars['Boolean'];
  kind: Scalars['String'];
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']>;
  location_inquiries: Array<LocationInquiry>;
  mdmId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  offers: Array<Offer>;
  permitNumber?: Maybe<Scalars['String']>;
  schoolServices: Array<SchoolService>;
  serviceFeatures: Array<ServiceFeature>;
  serviceMedia: Array<ServiceMedia>;
  servicePricings: Array<ServicePricing>;
  serviceProvider?: Maybe<ServiceProvider>;
  serviceProviderId?: Maybe<Scalars['String']>;
  serviceSettings: Array<ServiceSettings>;
  serviceVarieties: Array<ServiceVariety>;
  slug?: Maybe<Scalars['String']>;
  subscriptionServices: Array<SubscriptionService>;
  updatedAt: Scalars['timestamptz'];
  validateWaitingListPropositions: Scalars['Boolean'];
  vendor: Scalars['String'];
  waitingListFrozenSince?: Maybe<Scalars['timestamptz']>;
  waiting_list_places: Array<WaitingListPlace>;
};
export type ServiceAggregateFields = {
  __typename?: 'service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceMaxFields>;
  min?: Maybe<ServiceMinFields>;
};
export type ServiceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceMaxOrderBy>;
  min?: Maybe<ServiceMinOrderBy>;
};
export type ServiceFeature = {
  __typename?: 'service_feature';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  feature: Feature;
  featureId: Scalars['String'];
  id: Scalars['String'];
  isMainFeature: Scalars['Boolean'];
  service: Service;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceFeatureAggregateFields = {
  __typename?: 'service_feature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceFeatureMaxFields>;
  min?: Maybe<ServiceFeatureMinFields>;
};
export type ServiceFeatureAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceFeatureMaxOrderBy>;
  min?: Maybe<ServiceFeatureMinOrderBy>;
};
export type ServiceFeatureMaxFields = {
  __typename?: 'service_feature_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  featureId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceFeatureMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceFeatureMinFields = {
  __typename?: 'service_feature_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  featureId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceFeatureMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceFeatureMutationResponse = {
  __typename?: 'service_feature_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceFeature>;
};
export type ServiceFeatureOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  feature?: Maybe<FeatureOrderBy>;
  featureId?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isMainFeature?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceMaxFields = {
  __typename?: 'service_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  mdmId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permitNumber?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
  waitingListFrozenSince?: Maybe<Scalars['timestamptz']>;
};
export type ServiceMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  locationId?: Maybe<OrderBy>;
  mdmId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  permitNumber?: Maybe<OrderBy>;
  serviceProviderId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
  waitingListFrozenSince?: Maybe<OrderBy>;
};
export type ServiceMedia = {
  __typename?: 'service_media';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename: Scalars['String'];
  id: Scalars['String'];
  mimeType: Scalars['String'];
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['timestamptz'];
  url: Scalars['String'];
};
export type ServiceMediaAggregateFields = {
  __typename?: 'service_media_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceMediaMaxFields>;
  min?: Maybe<ServiceMediaMinFields>;
};
export type ServiceMediaAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceMediaMaxOrderBy>;
  min?: Maybe<ServiceMediaMinOrderBy>;
};
export type ServiceMediaMaxFields = {
  __typename?: 'service_media_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};
export type ServiceMediaMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimeType?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
};
export type ServiceMediaMinFields = {
  __typename?: 'service_media_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};
export type ServiceMediaMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimeType?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
};
export type ServiceMediaMutationResponse = {
  __typename?: 'service_media_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceMedia>;
};
export type ServiceMediaOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  filename?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mimeType?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  url?: Maybe<OrderBy>;
};
export type ServiceMinFields = {
  __typename?: 'service_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['String']>;
  mdmId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  permitNumber?: Maybe<Scalars['String']>;
  serviceProviderId?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  vendor?: Maybe<Scalars['String']>;
  waitingListFrozenSince?: Maybe<Scalars['timestamptz']>;
};
export type ServiceMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  locationId?: Maybe<OrderBy>;
  mdmId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  permitNumber?: Maybe<OrderBy>;
  serviceProviderId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
  waitingListFrozenSince?: Maybe<OrderBy>;
};
export type ServiceMutationResponse = {
  __typename?: 'service_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Service>;
};
export type ServiceOrderBy = {
  contactInfo?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  flexMargin?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isBookable?: Maybe<OrderBy>;
  isCustomSubscriptionEnabled?: Maybe<OrderBy>;
  isPublished?: Maybe<OrderBy>;
  isPublishedForChildBenefitCalculator?: Maybe<OrderBy>;
  kind?: Maybe<OrderBy>;
  location?: Maybe<LocationOrderBy>;
  locationId?: Maybe<OrderBy>;
  mdmId?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  permitNumber?: Maybe<OrderBy>;
  serviceProvider?: Maybe<ServiceProviderOrderBy>;
  serviceProviderId?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validateWaitingListPropositions?: Maybe<OrderBy>;
  vendor?: Maybe<OrderBy>;
  waitingListFrozenSince?: Maybe<OrderBy>;
};
export type ServicePricing = {
  __typename?: 'service_pricing';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hourRate: Scalars['numeric'];
  id: Scalars['String'];
  service: Service;
  serviceId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
};
export type ServicePricingAggregateFields = {
  __typename?: 'service_pricing_aggregate_fields';
  avg?: Maybe<ServicePricingAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ServicePricingMaxFields>;
  min?: Maybe<ServicePricingMinFields>;
  stddev?: Maybe<ServicePricingStddevFields>;
  stddev_pop?: Maybe<ServicePricingStddevPopFields>;
  stddev_samp?: Maybe<ServicePricingStddevSampFields>;
  sum?: Maybe<ServicePricingSumFields>;
  var_pop?: Maybe<ServicePricingVarPopFields>;
  var_samp?: Maybe<ServicePricingVarSampFields>;
  variance?: Maybe<ServicePricingVarianceFields>;
};
export type ServicePricingAggregateOrderBy = {
  avg?: Maybe<ServicePricingAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ServicePricingMaxOrderBy>;
  min?: Maybe<ServicePricingMinOrderBy>;
  stddev?: Maybe<ServicePricingStddevOrderBy>;
  stddev_pop?: Maybe<ServicePricingStddevPopOrderBy>;
  stddev_samp?: Maybe<ServicePricingStddevSampOrderBy>;
  sum?: Maybe<ServicePricingSumOrderBy>;
  var_pop?: Maybe<ServicePricingVarPopOrderBy>;
  var_samp?: Maybe<ServicePricingVarSampOrderBy>;
  variance?: Maybe<ServicePricingVarianceOrderBy>;
};
export type ServicePricingAvgFields = {
  __typename?: 'service_pricing_avg_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingAvgOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingMaxFields = {
  __typename?: 'service_pricing_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hourRate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ServicePricingMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  hourRate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ServicePricingMinFields = {
  __typename?: 'service_pricing_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hourRate?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ServicePricingMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  hourRate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ServicePricingMutationResponse = {
  __typename?: 'service_pricing_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServicePricing>;
};
export type ServicePricingOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  hourRate?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ServicePricingStddevFields = {
  __typename?: 'service_pricing_stddev_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingStddevOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingStddevPopFields = {
  __typename?: 'service_pricing_stddev_pop_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingStddevPopOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingStddevSampFields = {
  __typename?: 'service_pricing_stddev_samp_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingStddevSampOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingSumFields = {
  __typename?: 'service_pricing_sum_fields';
  hourRate?: Maybe<Scalars['numeric']>;
};
export type ServicePricingSumOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingVarPopFields = {
  __typename?: 'service_pricing_var_pop_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingVarPopOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingVarSampFields = {
  __typename?: 'service_pricing_var_samp_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingVarSampOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServicePricingVarianceFields = {
  __typename?: 'service_pricing_variance_fields';
  hourRate?: Maybe<Scalars['Float']>;
};
export type ServicePricingVarianceOrderBy = {
  hourRate?: Maybe<OrderBy>;
};
export type ServiceProduct = {
  __typename?: 'service_product';
  createdAt: Scalars['timestamptz'];
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  isBookable: Scalars['Boolean'];
  isUsedInChildBenefitCalculator: Scalars['Boolean'];
  product?: Maybe<Product>;
  productId: Scalars['String'];
  productPriceAgreement: Array<ProductPriceAgreement>;
  service: Service;
  serviceId: Scalars['String'];
  showTextualAddition: Scalars['Boolean'];
  textualAddition: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom?: Maybe<Scalars['timestamptz']>;
};
export type ServiceProductAggregateFields = {
  __typename?: 'service_product_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceProductMaxFields>;
  min?: Maybe<ServiceProductMinFields>;
};
export type ServiceProductMaxFields = {
  __typename?: 'service_product_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  textualAddition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
};
export type ServiceProductMinFields = {
  __typename?: 'service_product_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  textualAddition?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['timestamptz']>;
};
export type ServiceProductMutationResponse = {
  __typename?: 'service_product_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceProduct>;
};
export type ServiceProductOrderBy = {
  createdAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isActive?: Maybe<OrderBy>;
  isBookable?: Maybe<OrderBy>;
  isUsedInChildBenefitCalculator?: Maybe<OrderBy>;
  product?: Maybe<ProductOrderBy>;
  productId?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  showTextualAddition?: Maybe<OrderBy>;
  textualAddition?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
};
export type ServiceProvider = {
  __typename?: 'service_provider';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  name: Scalars['String'];
  services: Array<Service>;
  updatedAt: Scalars['timestamptz'];
};
export type ServiceProviderAggregateFields = {
  __typename?: 'service_provider_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceProviderMaxFields>;
  min?: Maybe<ServiceProviderMinFields>;
};
export type ServiceProviderMaxFields = {
  __typename?: 'service_provider_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceProviderMinFields = {
  __typename?: 'service_provider_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceProviderMutationResponse = {
  __typename?: 'service_provider_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceProvider>;
};
export type ServiceProviderOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceSettings = {
  __typename?: 'service_settings';
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  inquiryExpiresInInMinutes: Scalars['Int'];
  openingTimesNote?: Maybe<Scalars['String']>;
  planningHorizonInMonths: Scalars['Int'];
  sendRemainOnWaitingListMailInInMinutes: Scalars['Int'];
  sendRemainOnWaitingListReminderMailInInMinutes: Scalars['Int'];
  service: Service;
  serviceId: Scalars['String'];
  showLinkToPartouOffers: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  useFlexkidsProducts: Scalars['Boolean'];
};
export type ServiceSettingsAggregateFields = {
  __typename?: 'service_settings_aggregate_fields';
  avg?: Maybe<ServiceSettingsAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<ServiceSettingsMaxFields>;
  min?: Maybe<ServiceSettingsMinFields>;
  stddev?: Maybe<ServiceSettingsStddevFields>;
  stddev_pop?: Maybe<ServiceSettingsStddevPopFields>;
  stddev_samp?: Maybe<ServiceSettingsStddevSampFields>;
  sum?: Maybe<ServiceSettingsSumFields>;
  var_pop?: Maybe<ServiceSettingsVarPopFields>;
  var_samp?: Maybe<ServiceSettingsVarSampFields>;
  variance?: Maybe<ServiceSettingsVarianceFields>;
};
export type ServiceSettingsAggregateOrderBy = {
  avg?: Maybe<ServiceSettingsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceSettingsMaxOrderBy>;
  min?: Maybe<ServiceSettingsMinOrderBy>;
  stddev?: Maybe<ServiceSettingsStddevOrderBy>;
  stddev_pop?: Maybe<ServiceSettingsStddevPopOrderBy>;
  stddev_samp?: Maybe<ServiceSettingsStddevSampOrderBy>;
  sum?: Maybe<ServiceSettingsSumOrderBy>;
  var_pop?: Maybe<ServiceSettingsVarPopOrderBy>;
  var_samp?: Maybe<ServiceSettingsVarSampOrderBy>;
  variance?: Maybe<ServiceSettingsVarianceOrderBy>;
};
export type ServiceSettingsAvgFields = {
  __typename?: 'service_settings_avg_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsAvgOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsMaxFields = {
  __typename?: 'service_settings_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inquiryExpiresInInMinutes?: Maybe<Scalars['Int']>;
  openingTimesNote?: Maybe<Scalars['String']>;
  planningHorizonInMonths?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type ServiceSettingsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  openingTimesNote?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type ServiceSettingsMinFields = {
  __typename?: 'service_settings_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  inquiryExpiresInInMinutes?: Maybe<Scalars['Int']>;
  openingTimesNote?: Maybe<Scalars['String']>;
  planningHorizonInMonths?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Int']>;
  serviceId?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type ServiceSettingsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  openingTimesNote?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type ServiceSettingsMutationResponse = {
  __typename?: 'service_settings_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceSettings>;
};
export type ServiceSettingsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  openingTimesNote?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  showLinkToPartouOffers?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  useFlexkidsProducts?: Maybe<OrderBy>;
};
export type ServiceSettingsStddevFields = {
  __typename?: 'service_settings_stddev_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsStddevOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsStddevPopFields = {
  __typename?: 'service_settings_stddev_pop_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsStddevPopOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsStddevSampFields = {
  __typename?: 'service_settings_stddev_samp_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsStddevSampOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsSumFields = {
  __typename?: 'service_settings_sum_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Int']>;
  planningHorizonInMonths?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Int']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Int']>;
};
export type ServiceSettingsSumOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsVarPopFields = {
  __typename?: 'service_settings_var_pop_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsVarPopOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsVarSampFields = {
  __typename?: 'service_settings_var_samp_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsVarSampOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceSettingsVarianceFields = {
  __typename?: 'service_settings_variance_fields';
  inquiryExpiresInInMinutes?: Maybe<Scalars['Float']>;
  planningHorizonInMonths?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<Scalars['Float']>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<Scalars['Float']>;
};
export type ServiceSettingsVarianceOrderBy = {
  inquiryExpiresInInMinutes?: Maybe<OrderBy>;
  planningHorizonInMonths?: Maybe<OrderBy>;
  sendRemainOnWaitingListMailInInMinutes?: Maybe<OrderBy>;
  sendRemainOnWaitingListReminderMailInInMinutes?: Maybe<OrderBy>;
};
export type ServiceVariety = {
  __typename?: 'service_variety';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  groups: Array<Group>;
  id: Scalars['String'];
  name: Scalars['String'];
  reservations: Array<Reservation>;
  service?: Maybe<Service>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyOpenings: Array<ServiceVarietyOpening>;
  updatedAt: Scalars['timestamptz'];
  waitingListPlaces: Array<WaitingListPlace>;
};
export type ServiceVarietyAggregateFields = {
  __typename?: 'service_variety_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceVarietyMaxFields>;
  min?: Maybe<ServiceVarietyMinFields>;
};
export type ServiceVarietyAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceVarietyMaxOrderBy>;
  min?: Maybe<ServiceVarietyMinOrderBy>;
};
export type ServiceVarietyMaxFields = {
  __typename?: 'service_variety_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceVarietyMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceVarietyMinFields = {
  __typename?: 'service_variety_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type ServiceVarietyMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type ServiceVarietyMutationResponse = {
  __typename?: 'service_variety_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceVariety>;
};
export type ServiceVarietyOpening = {
  __typename?: 'service_variety_opening';
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  friday?: Maybe<Scalars['jsonb']>;
  id: Scalars['String'];
  isManuallySet: Scalars['Boolean'];
  monday?: Maybe<Scalars['jsonb']>;
  saturday?: Maybe<Scalars['jsonb']>;
  serviceVarietyId: Scalars['String'];
  sunday?: Maybe<Scalars['jsonb']>;
  thursday?: Maybe<Scalars['jsonb']>;
  tuesday?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  wednesday?: Maybe<Scalars['jsonb']>;
};
export type ServiceVarietyOpeningAggregateFields = {
  __typename?: 'service_variety_opening_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ServiceVarietyOpeningMaxFields>;
  min?: Maybe<ServiceVarietyOpeningMinFields>;
};
export type ServiceVarietyOpeningAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ServiceVarietyOpeningMaxOrderBy>;
  min?: Maybe<ServiceVarietyOpeningMinOrderBy>;
};
export type ServiceVarietyOpeningMaxFields = {
  __typename?: 'service_variety_opening_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ServiceVarietyOpeningMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ServiceVarietyOpeningMinFields = {
  __typename?: 'service_variety_opening_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type ServiceVarietyOpeningMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type ServiceVarietyOpeningMutationResponse = {
  __typename?: 'service_variety_opening_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<ServiceVarietyOpening>;
};
export type ServiceVarietyOpeningOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  friday?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isManuallySet?: Maybe<OrderBy>;
  monday?: Maybe<OrderBy>;
  saturday?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  sunday?: Maybe<OrderBy>;
  thursday?: Maybe<OrderBy>;
  tuesday?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
  wednesday?: Maybe<OrderBy>;
};
export type ServiceVarietyOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SetWaitingListActualisationIntervalsOutput = {
  __typename?: 'setWaitingListActualisationIntervalsOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};
export type Share = {
  __typename?: 'share';
  createdAt: Scalars['timestamptz'];
  encryptedState: Scalars['String'];
  id: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  sharedFromIP: Scalars['String'];
  sharedFromUrl: Scalars['String'];
  urlHash: Scalars['String'];
};
export type ShareAggregateFields = {
  __typename?: 'share_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ShareMaxFields>;
  min?: Maybe<ShareMinFields>;
};
export type ShareMaxFields = {
  __typename?: 'share_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  encryptedState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sharedFromIP?: Maybe<Scalars['String']>;
  sharedFromUrl?: Maybe<Scalars['String']>;
  urlHash?: Maybe<Scalars['String']>;
};
export type ShareMinFields = {
  __typename?: 'share_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  encryptedState?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  sharedFromIP?: Maybe<Scalars['String']>;
  sharedFromUrl?: Maybe<Scalars['String']>;
  urlHash?: Maybe<Scalars['String']>;
};
export type ShareMutationResponse = {
  __typename?: 'share_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Share>;
};
export type ShareOrderBy = {
  createdAt?: Maybe<OrderBy>;
  encryptedState?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  metadata?: Maybe<OrderBy>;
  sharedFromIP?: Maybe<OrderBy>;
  sharedFromUrl?: Maybe<OrderBy>;
  urlHash?: Maybe<OrderBy>;
};
export type Subscription = {
  __typename?: 'subscription';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  features: Scalars['jsonb'];
  id: Scalars['String'];
  isRoadie: Scalars['Boolean'];
  name: Scalars['String'];
  numberOfWeeks: Scalars['Int'];
  order: Scalars['Int'];
  reservationType: Scalars['String'];
  subscriptionCode?: Maybe<Scalars['String']>;
  subscription_services: Array<SubscriptionService>;
  surchargePercentageBso: Scalars['Int'];
  surchargePercentageKdv: Scalars['Int'];
  surchargePercentageNso: Scalars['Int'];
  surchargePercentageVso: Scalars['Int'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionAggregateFields = {
  __typename?: 'subscription_aggregate_fields';
  avg?: Maybe<SubscriptionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<SubscriptionMaxFields>;
  min?: Maybe<SubscriptionMinFields>;
  stddev?: Maybe<SubscriptionStddevFields>;
  stddev_pop?: Maybe<SubscriptionStddevPopFields>;
  stddev_samp?: Maybe<SubscriptionStddevSampFields>;
  sum?: Maybe<SubscriptionSumFields>;
  var_pop?: Maybe<SubscriptionVarPopFields>;
  var_samp?: Maybe<SubscriptionVarSampFields>;
  variance?: Maybe<SubscriptionVarianceFields>;
};
export type SubscriptionAvgFields = {
  __typename?: 'subscription_avg_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionMaxFields = {
  __typename?: 'subscription_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfWeeks?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  reservationType?: Maybe<Scalars['String']>;
  subscriptionCode?: Maybe<Scalars['String']>;
  surchargePercentageBso?: Maybe<Scalars['Int']>;
  surchargePercentageKdv?: Maybe<Scalars['Int']>;
  surchargePercentageNso?: Maybe<Scalars['Int']>;
  surchargePercentageVso?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionMinFields = {
  __typename?: 'subscription_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfWeeks?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  reservationType?: Maybe<Scalars['String']>;
  subscriptionCode?: Maybe<Scalars['String']>;
  surchargePercentageBso?: Maybe<Scalars['Int']>;
  surchargePercentageKdv?: Maybe<Scalars['Int']>;
  surchargePercentageNso?: Maybe<Scalars['Int']>;
  surchargePercentageVso?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionMutationResponse = {
  __typename?: 'subscription_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<Subscription>;
};
export type SubscriptionOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  features?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isRoadie?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  numberOfWeeks?: Maybe<OrderBy>;
  order?: Maybe<OrderBy>;
  reservationType?: Maybe<OrderBy>;
  subscriptionCode?: Maybe<OrderBy>;
  surchargePercentageBso?: Maybe<OrderBy>;
  surchargePercentageKdv?: Maybe<OrderBy>;
  surchargePercentageNso?: Maybe<OrderBy>;
  surchargePercentageVso?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SubscriptionService = {
  __typename?: 'subscription_service';
  bookings: Array<Booking>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isDisplayNote: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  service: Service;
  serviceId: Scalars['String'];
  subscription: Subscription;
  subscriptionId: Scalars['String'];
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionServiceAggregateFields = {
  __typename?: 'subscription_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SubscriptionServiceMaxFields>;
  min?: Maybe<SubscriptionServiceMinFields>;
};
export type SubscriptionServiceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<SubscriptionServiceMaxOrderBy>;
  min?: Maybe<SubscriptionServiceMinOrderBy>;
};
export type SubscriptionServiceMaxFields = {
  __typename?: 'subscription_service_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionServiceMaxOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  subscriptionId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SubscriptionServiceMinFields = {
  __typename?: 'subscription_service_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type SubscriptionServiceMinOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  subscriptionId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SubscriptionServiceMutationResponse = {
  __typename?: 'subscription_service_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<SubscriptionService>;
};
export type SubscriptionServiceOrderBy = {
  createdAt?: Maybe<OrderBy>;
  deletedAt?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  isAvailable?: Maybe<OrderBy>;
  isDisplayNote?: Maybe<OrderBy>;
  note?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  subscription?: Maybe<SubscriptionOrderBy>;
  subscriptionId?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type SubscriptionStddevFields = {
  __typename?: 'subscription_stddev_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionStddevPopFields = {
  __typename?: 'subscription_stddev_pop_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionStddevSampFields = {
  __typename?: 'subscription_stddev_samp_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionSumFields = {
  __typename?: 'subscription_sum_fields';
  numberOfWeeks?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  surchargePercentageBso?: Maybe<Scalars['Int']>;
  surchargePercentageKdv?: Maybe<Scalars['Int']>;
  surchargePercentageNso?: Maybe<Scalars['Int']>;
  surchargePercentageVso?: Maybe<Scalars['Int']>;
};
export type SubscriptionVarPopFields = {
  __typename?: 'subscription_var_pop_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionVarSampFields = {
  __typename?: 'subscription_var_samp_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SubscriptionVarianceFields = {
  __typename?: 'subscription_variance_fields';
  numberOfWeeks?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
  surchargePercentageBso?: Maybe<Scalars['Float']>;
  surchargePercentageKdv?: Maybe<Scalars['Float']>;
  surchargePercentageNso?: Maybe<Scalars['Float']>;
  surchargePercentageVso?: Maybe<Scalars['Float']>;
};
export type SyncObject = {
  __typename?: 'sync_object';
  correlation_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  external_ids: Scalars['jsonb'];
  hash?: Maybe<Scalars['String']>;
  internal_id: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};
export type SyncObjectAggregateFields = {
  __typename?: 'sync_object_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<SyncObjectMaxFields>;
  min?: Maybe<SyncObjectMinFields>;
};
export type SyncObjectMaxFields = {
  __typename?: 'sync_object_max_fields';
  correlation_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type SyncObjectMinFields = {
  __typename?: 'sync_object_min_fields';
  correlation_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  hash?: Maybe<Scalars['String']>;
  internal_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};
export type SyncObjectMutationResponse = {
  __typename?: 'sync_object_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<SyncObject>;
};
export type SyncObjectOrderBy = {
  correlation_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  external_ids?: Maybe<OrderBy>;
  hash?: Maybe<OrderBy>;
  internal_id?: Maybe<OrderBy>;
  metadata?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};
export type WaitingListAnalysisResult = {
  __typename?: 'waiting_list_analysis_result';
  calculateDate: Scalars['timestamptz'];
  createdAt: Scalars['timestamptz'];
  didAllocateWaitingListSpots: Scalars['Boolean'];
  id: Scalars['String'];
  serviceId: Scalars['String'];
  waitingListAllocation: Scalars['jsonb'];
};
export type WaitingListAnalysisResultAggregateFields = {
  __typename?: 'waiting_list_analysis_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WaitingListAnalysisResultMaxFields>;
  min?: Maybe<WaitingListAnalysisResultMinFields>;
};
export type WaitingListAnalysisResultMaxFields = {
  __typename?: 'waiting_list_analysis_result_max_fields';
  calculateDate?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};
export type WaitingListAnalysisResultMinFields = {
  __typename?: 'waiting_list_analysis_result_min_fields';
  calculateDate?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};
export type WaitingListAnalysisResultMutationResponse = {
  __typename?: 'waiting_list_analysis_result_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<WaitingListAnalysisResult>;
};
export type WaitingListAnalysisResultOrderBy = {
  calculateDate?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  didAllocateWaitingListSpots?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  waitingListAllocation?: Maybe<OrderBy>;
};
export type WaitingListPlace = {
  __typename?: 'waiting_list_place';
  booking: Booking;
  bookingId: Scalars['String'];
  child: Child;
  childId: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  dayOfWeek: Scalars['String'];
  id: Scalars['String'];
  location_inquiry_id?: Maybe<Scalars['String']>;
  partOfDay: Scalars['String'];
  product_id?: Maybe<Scalars['String']>;
  service: Service;
  serviceId: Scalars['String'];
  serviceVariety: ServiceVariety;
  serviceVarietyId: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  validFrom: Scalars['date'];
  validUntil: Scalars['date'];
};
export type WaitingListPlaceAggregateFields = {
  __typename?: 'waiting_list_place_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WaitingListPlaceMaxFields>;
  min?: Maybe<WaitingListPlaceMinFields>;
};
export type WaitingListPlaceAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<WaitingListPlaceMaxOrderBy>;
  min?: Maybe<WaitingListPlaceMinOrderBy>;
};
export type WaitingListPlaceMaxFields = {
  __typename?: 'waiting_list_place_max_fields';
  bookingId?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location_inquiry_id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type WaitingListPlaceMaxOrderBy = {
  bookingId?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  location_inquiry_id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type WaitingListPlaceMinFields = {
  __typename?: 'waiting_list_place_min_fields';
  bookingId?: Maybe<Scalars['String']>;
  childId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  dayOfWeek?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location_inquiry_id?: Maybe<Scalars['String']>;
  partOfDay?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceVarietyId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  validFrom?: Maybe<Scalars['date']>;
  validUntil?: Maybe<Scalars['date']>;
};
export type WaitingListPlaceMinOrderBy = {
  bookingId?: Maybe<OrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  location_inquiry_id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type WaitingListPlaceMutationResponse = {
  __typename?: 'waiting_list_place_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<WaitingListPlace>;
};
export type WaitingListPlaceOrderBy = {
  booking?: Maybe<BookingOrderBy>;
  bookingId?: Maybe<OrderBy>;
  child?: Maybe<ChildOrderBy>;
  childId?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  dayOfWeek?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  location_inquiry_id?: Maybe<OrderBy>;
  partOfDay?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
  serviceVariety?: Maybe<ServiceVarietyOrderBy>;
  serviceVarietyId?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
  validFrom?: Maybe<OrderBy>;
  validUntil?: Maybe<OrderBy>;
};
export type WaitingListProposition = {
  __typename?: 'waiting_list_proposition';
  causedBy?: Maybe<Scalars['String']>;
  causedByReason?: Maybe<Scalars['String']>;
  contractType: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  locationInquiry: LocationInquiry;
  locationInquiryId: Scalars['String'];
  placement: Placement;
  placementId: Scalars['String'];
  proposedOn?: Maybe<Scalars['timestamptz']>;
  startDate: Scalars['date'];
  status: Scalars['String'];
  status_rank: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};
export type WaitingListPropositionAggregateFields = {
  __typename?: 'waiting_list_proposition_aggregate_fields';
  avg?: Maybe<WaitingListPropositionAvgFields>;
  count: Scalars['Int'];
  max?: Maybe<WaitingListPropositionMaxFields>;
  min?: Maybe<WaitingListPropositionMinFields>;
  stddev?: Maybe<WaitingListPropositionStddevFields>;
  stddev_pop?: Maybe<WaitingListPropositionStddevPopFields>;
  stddev_samp?: Maybe<WaitingListPropositionStddevSampFields>;
  sum?: Maybe<WaitingListPropositionSumFields>;
  var_pop?: Maybe<WaitingListPropositionVarPopFields>;
  var_samp?: Maybe<WaitingListPropositionVarSampFields>;
  variance?: Maybe<WaitingListPropositionVarianceFields>;
};
export type WaitingListPropositionAggregateOrderBy = {
  avg?: Maybe<WaitingListPropositionAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<WaitingListPropositionMaxOrderBy>;
  min?: Maybe<WaitingListPropositionMinOrderBy>;
  stddev?: Maybe<WaitingListPropositionStddevOrderBy>;
  stddev_pop?: Maybe<WaitingListPropositionStddevPopOrderBy>;
  stddev_samp?: Maybe<WaitingListPropositionStddevSampOrderBy>;
  sum?: Maybe<WaitingListPropositionSumOrderBy>;
  var_pop?: Maybe<WaitingListPropositionVarPopOrderBy>;
  var_samp?: Maybe<WaitingListPropositionVarSampOrderBy>;
  variance?: Maybe<WaitingListPropositionVarianceOrderBy>;
};
export type WaitingListPropositionAnalysisResult = {
  __typename?: 'waiting_list_proposition_analysis_result';
  calculationDate: Scalars['timestamptz'];
  created_at: Scalars['timestamptz'];
  data: Scalars['jsonb'];
  didAllocateWaitingListSpots: Scalars['Boolean'];
  id: Scalars['String'];
  service: Service;
  serviceId: Scalars['String'];
};
export type WaitingListPropositionAnalysisResultAggregateFields = {
  __typename?: 'waiting_list_proposition_analysis_result_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<WaitingListPropositionAnalysisResultMaxFields>;
  min?: Maybe<WaitingListPropositionAnalysisResultMinFields>;
};
export type WaitingListPropositionAnalysisResultMaxFields = {
  __typename?: 'waiting_list_proposition_analysis_result_max_fields';
  calculationDate?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};
export type WaitingListPropositionAnalysisResultMinFields = {
  __typename?: 'waiting_list_proposition_analysis_result_min_fields';
  calculationDate?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
};
export type WaitingListPropositionAnalysisResultMutationResponse = {
  __typename?: 'waiting_list_proposition_analysis_result_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<WaitingListPropositionAnalysisResult>;
};
export type WaitingListPropositionAnalysisResultOrderBy = {
  calculationDate?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  data?: Maybe<OrderBy>;
  didAllocateWaitingListSpots?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  service?: Maybe<ServiceOrderBy>;
  serviceId?: Maybe<OrderBy>;
};
export type WaitingListPropositionAvgFields = {
  __typename?: 'waiting_list_proposition_avg_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionAvgOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionMaxFields = {
  __typename?: 'waiting_list_proposition_max_fields';
  causedBy?: Maybe<Scalars['String']>;
  causedByReason?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locationInquiryId?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['String']>;
  proposedOn?: Maybe<Scalars['timestamptz']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_rank?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type WaitingListPropositionMaxOrderBy = {
  causedBy?: Maybe<OrderBy>;
  causedByReason?: Maybe<OrderBy>;
  contractType?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  placementId?: Maybe<OrderBy>;
  proposedOn?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  status_rank?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type WaitingListPropositionMinFields = {
  __typename?: 'waiting_list_proposition_min_fields';
  causedBy?: Maybe<Scalars['String']>;
  causedByReason?: Maybe<Scalars['String']>;
  contractType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  expiresOn?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  locationInquiryId?: Maybe<Scalars['String']>;
  placementId?: Maybe<Scalars['String']>;
  proposedOn?: Maybe<Scalars['timestamptz']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
  status_rank?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};
export type WaitingListPropositionMinOrderBy = {
  causedBy?: Maybe<OrderBy>;
  causedByReason?: Maybe<OrderBy>;
  contractType?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  placementId?: Maybe<OrderBy>;
  proposedOn?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  status_rank?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type WaitingListPropositionMutationResponse = {
  __typename?: 'waiting_list_proposition_mutation_response';
  affected_rows: Scalars['Int'];
  returning: Array<WaitingListProposition>;
};
export type WaitingListPropositionOrderBy = {
  causedBy?: Maybe<OrderBy>;
  causedByReason?: Maybe<OrderBy>;
  contractType?: Maybe<OrderBy>;
  createdAt?: Maybe<OrderBy>;
  expiresOn?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  locationInquiry?: Maybe<LocationInquiryOrderBy>;
  locationInquiryId?: Maybe<OrderBy>;
  placement?: Maybe<PlacementOrderBy>;
  placementId?: Maybe<OrderBy>;
  proposedOn?: Maybe<OrderBy>;
  startDate?: Maybe<OrderBy>;
  status?: Maybe<OrderBy>;
  status_rank?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updatedAt?: Maybe<OrderBy>;
};
export type WaitingListPropositionStddevFields = {
  __typename?: 'waiting_list_proposition_stddev_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionStddevOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionStddevPopFields = {
  __typename?: 'waiting_list_proposition_stddev_pop_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionStddevPopOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionStddevSampFields = {
  __typename?: 'waiting_list_proposition_stddev_samp_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionStddevSampOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionSumFields = {
  __typename?: 'waiting_list_proposition_sum_fields';
  status_rank?: Maybe<Scalars['Int']>;
};
export type WaitingListPropositionSumOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionVarPopFields = {
  __typename?: 'waiting_list_proposition_var_pop_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionVarPopOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionVarSampFields = {
  __typename?: 'waiting_list_proposition_var_samp_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionVarSampOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type WaitingListPropositionVarianceFields = {
  __typename?: 'waiting_list_proposition_variance_fields';
  status_rank?: Maybe<Scalars['Float']>;
};
export type WaitingListPropositionVarianceOrderBy = {
  status_rank?: Maybe<OrderBy>;
};
export type SetFlexMarginByServiceIdMutationVariables = Exact<{
  id: Scalars['String'];
  flexMargin?: Maybe<Scalars['jsonb']>;
}>;
export type SetFlexMarginByServiceIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_service?: Maybe<(
    { __typename?: 'service_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service' }
      & Pick<Service, 'id' | 'name' | 'description' | 'kind' | 'contactInfo' | 'flexMargin' | 'isPublished'>
      & { serviceProvider?: Maybe<(
        { __typename?: 'service_provider' }
        & Pick<ServiceProvider, 'id' | 'name'>
      )>, location?: Maybe<(
        { __typename?: 'location' }
        & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'locality'>
      )> }
    )> }
  )> }
);
export type UpdateServiceMutationVariables = Exact<{
  id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;
export type UpdateServiceMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'description'>
  )> }
);
export type DisableWaitingListAutomationMutationVariables = Exact<{
  serviceId: Scalars['String'];
  referenceDate?: Maybe<Scalars['String']>;
}>;
export type DisableWaitingListAutomationMutation = (
  { __typename?: 'mutation_root' }
  & { disableWaitingList?: Maybe<(
    { __typename?: 'DisableWaitingListOutput' }
    & Pick<DisableWaitingListOutput, 'code' | 'message'>
  )> }
);
export type EnableWaitingListAutomationMutationVariables = Exact<{
  serviceId: Scalars['String'];
  referenceDate?: Maybe<Scalars['String']>;
}>;
export type EnableWaitingListAutomationMutation = (
  { __typename?: 'mutation_root' }
  & { enableWaitingList?: Maybe<(
    { __typename?: 'EnableWaitingListOutput' }
    & Pick<EnableWaitingListOutput, 'code' | 'message'>
  )> }
);
export type UpdateServiceIsPublishedMutationVariables = Exact<{
  id: Scalars['String'];
  isPublished?: Maybe<Scalars['Boolean']>;
  isPublishedForChildBenefitCalculator?: Maybe<Scalars['Boolean']>;
  isBookable?: Maybe<Scalars['Boolean']>;
}>;
export type UpdateServiceIsPublishedMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'isPublished' | 'isPublishedForChildBenefitCalculator' | 'isBookable'>
  )> }
);
export type UpdateServiceToggleCustomSubscriptionMutationVariables = Exact<{
  id: Scalars['String'];
  isCustomSubscriptionEnabled: Scalars['Boolean'];
}>;
export type UpdateServiceToggleCustomSubscriptionMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id'>
  )> }
);
export type UpdateServiceValidateWaitingListPropositionsMutationVariables = Exact<{
  id: Scalars['String'];
  validateWaitingListPropositions: Scalars['Boolean'];
}>;
export type UpdateServiceValidateWaitingListPropositionsMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'validateWaitingListPropositions'>
  )> }
);
export type SendEmailsMutationVariables = Exact<{ [key: string]: never; }>;
export type SendEmailsMutation = (
  { __typename?: 'mutation_root' }
  & { sendEmails?: Maybe<(
    { __typename?: 'SendEmailsOutput' }
    & Pick<SendEmailsOutput, 'code' | 'message'>
  )> }
);
export type InsertAndOverwriteGroupPinCodeMutationVariables = Exact<{
  pinCodesToInsertAndOverwrite: Array<Maybe<PinCodeToInsertAndOverwrite>> | Maybe<PinCodeToInsertAndOverwrite>;
}>;
export type InsertAndOverwriteGroupPinCodeMutation = (
  { __typename?: 'mutation_root' }
  & { insertAndOverwriteGroupPinCode?: Maybe<(
    { __typename?: 'InsertAndOverwriteGroupPinCodeOutput' }
    & Pick<InsertAndOverwriteGroupPinCodeOutput, 'code' | 'message'>
  )> }
);
export type UpdateGroupPincodeMutationVariables = Exact<{
  id: Scalars['String'];
  validUntil?: Maybe<Scalars['date']>;
}>;
export type UpdateGroupPincodeMutation = (
  { __typename?: 'mutation_root' }
  & { update_group_pincode_by_pk?: Maybe<(
    { __typename?: 'group_pincode' }
    & Pick<GroupPincode, 'id' | 'dayOfWeek' | 'partOfDay' | 'pincode' | 'validFrom' | 'validUntil' | 'groupId'>
  )> }
);
export type CalculateCapacityForOfferMutationVariables = Exact<{
  offerId: Scalars['String'];
}>;
export type CalculateCapacityForOfferMutation = (
  { __typename?: 'mutation_root' }
  & { calculateCapacityForOffer?: Maybe<(
    { __typename?: 'CalculateCapacityForOfferOutput' }
    & Pick<CalculateCapacityForOfferOutput, 'code' | 'message'>
  )> }
);
export type DeleteServiceFeatureMutationVariables = Exact<{
  serviceId: Scalars['String'];
  featureId: Scalars['String'];
}>;
export type DeleteServiceFeatureMutation = (
  { __typename?: 'mutation_root' }
  & { delete_service_feature?: Maybe<(
    { __typename?: 'service_feature_mutation_response' }
    & Pick<ServiceFeatureMutationResponse, 'affected_rows'>
  )> }
);
export type InsertServiceFeatureMutationVariables = Exact<{
  featureId: Scalars['String'];
  serviceId: Scalars['String'];
  isMainFeature: Scalars['Boolean'];
}>;
export type InsertServiceFeatureMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_feature_one?: Maybe<(
    { __typename?: 'service_feature' }
    & Pick<ServiceFeature, 'id' | 'featureId' | 'serviceId' | 'isMainFeature'>
  )> }
);
export type UpdateServiceFeatureMutationVariables = Exact<{
  serviceId: Scalars['String'];
  featureId: Scalars['String'];
  isMainFeature: Scalars['Boolean'];
}>;
export type UpdateServiceFeatureMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_feature?: Maybe<(
    { __typename?: 'service_feature_mutation_response' }
    & Pick<ServiceFeatureMutationResponse, 'affected_rows'>
  )> }
);
export type DeleteServiceMediaByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;
export type DeleteServiceMediaByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_service_media_by_pk?: Maybe<(
    { __typename?: 'service_media' }
    & Pick<ServiceMedia, 'id'>
  )> }
);
export type InsertServiceMediaMutationVariables = Exact<{
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  serviceId: Scalars['String'];
  url: Scalars['String'];
}>;
export type InsertServiceMediaMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_media_one?: Maybe<(
    { __typename?: 'service_media' }
    & Pick<ServiceMedia, 'id' | 'filename' | 'url' | 'mimeType'>
  )> }
);
export type UpdateServiceMediaMutationVariables = Exact<{
  id: Scalars['String'];
  filename: Scalars['String'];
  mimeType: Scalars['String'];
  serviceId: Scalars['String'];
  url: Scalars['String'];
}>;
export type UpdateServiceMediaMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_media_by_pk?: Maybe<(
    { __typename?: 'service_media' }
    & Pick<ServiceMedia, 'id' | 'filename' | 'url' | 'mimeType'>
  )> }
);
export type DeleteServicePricingByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;
export type DeleteServicePricingByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_service_pricing_by_pk?: Maybe<(
    { __typename?: 'service_pricing' }
    & Pick<ServicePricing, 'id'>
  )> }
);
export type InsertServicePricingMutationVariables = Exact<{
  hourRate: Scalars['numeric'];
  serviceId: Scalars['String'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
}>;
export type InsertServicePricingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_pricing?: Maybe<(
    { __typename?: 'service_pricing_mutation_response' }
    & Pick<ServicePricingMutationResponse, 'affected_rows'>
  )> }
);
export type UpdateServicePricingMutationVariables = Exact<{
  id: Scalars['String'];
  validUntil?: Maybe<Scalars['date']>;
  validFrom: Scalars['date'];
}>;
export type UpdateServicePricingMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_pricing_by_pk?: Maybe<(
    { __typename?: 'service_pricing' }
    & Pick<ServicePricing, 'validUntil' | 'hourRate' | 'id' | 'validFrom' | 'serviceId'>
  )> }
);
export type ActivateServiceProductsMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productIds: Array<Scalars['String']> | Scalars['String'];
}>;
export type ActivateServiceProductsMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_product' }
      & Pick<ServiceProduct, 'isActive'>
    )> }
  )> }
);
export type ActivateServiceProductIsUsedInChildBenefitCalculatorMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productId: Scalars['String'];
}>;
export type ActivateServiceProductIsUsedInChildBenefitCalculatorMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & Pick<ServiceProductMutationResponse, 'affected_rows'>
  )> }
);
export type DeactivateServiceProductIsUsedInChildBenefitCalculatorMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
}>;
export type DeactivateServiceProductIsUsedInChildBenefitCalculatorMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & Pick<ServiceProductMutationResponse, 'affected_rows'>
  )> }
);
export type DeactivateServiceProductMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productId: Scalars['String'];
}>;
export type DeactivateServiceProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & Pick<ServiceProductMutationResponse, 'affected_rows'>
  )> }
);
export type UpdateServiceProductShowTextualAdditionMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productId: Scalars['String'];
  showTextualAddition: Scalars['Boolean'];
}>;
export type UpdateServiceProductShowTextualAdditionMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & Pick<ServiceProductMutationResponse, 'affected_rows'>
  )> }
);
export type UpdateServiceProductTextualAdditionMutationVariables = Exact<{
  serviceId: Scalars['String'];
  productId: Scalars['String'];
  textualAddition: Scalars['String'];
}>;
export type UpdateServiceProductTextualAdditionMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_product?: Maybe<(
    { __typename?: 'service_product_mutation_response' }
    & Pick<ServiceProductMutationResponse, 'affected_rows'>
  )> }
);
export type SetShowLinkToPartouOffersMutationVariables = Exact<{
  serviceId: Scalars['String'];
  showLinkToPartouOffers: Scalars['Boolean'];
}>;
export type SetShowLinkToPartouOffersMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_settings?: Maybe<(
    { __typename?: 'service_settings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_settings' }
      & Pick<ServiceSettings, 'id' | 'serviceId' | 'showLinkToPartouOffers'>
    )> }
  )> }
);
export type SetWaitingListActualisationIntervalsMutationVariables = Exact<{
  serviceId: Scalars['String'];
  inquiryExpiresInInMinutes: Scalars['Int'];
  sendRemainOnWaitingListMailInInMinutes: Scalars['Int'];
  sendRemainOnWaitingListReminderMailInInMinutes: Scalars['Int'];
}>;
export type SetWaitingListActualisationIntervalsMutation = (
  { __typename?: 'mutation_root' }
  & { setWaitingListActualisationIntervals?: Maybe<(
    { __typename?: 'setWaitingListActualisationIntervalsOutput' }
    & Pick<SetWaitingListActualisationIntervalsOutput, 'code' | 'message'>
  )> }
);
export type DeleteServiceVarietyOpeningByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;
export type DeleteServiceVarietyOpeningByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_service_variety_opening_by_pk?: Maybe<(
    { __typename?: 'service_variety_opening' }
    & Pick<ServiceVarietyOpening, 'id'>
  )> }
);
export type GetNonManualServiceVarietyPricingByServiceVarietyIdQueryVariables = Exact<{
  serviceVarietyId: Scalars['String'];
}>;
export type GetNonManualServiceVarietyPricingByServiceVarietyIdQuery = (
  { __typename?: 'query_root' }
  & { service_variety_opening: Array<(
    { __typename?: 'service_variety_opening' }
    & Pick<ServiceVarietyOpening, 'id' | 'validFrom' | 'validUntil'>
  )> }
);
export type InsertServiceVarietyOpeningMutationVariables = Exact<{
  id: Scalars['String'];
  serviceVarietyId: Scalars['String'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
  monday: Scalars['jsonb'];
  tuesday: Scalars['jsonb'];
  wednesday: Scalars['jsonb'];
  thursday: Scalars['jsonb'];
  friday: Scalars['jsonb'];
  saturday: Scalars['jsonb'];
  sunday: Scalars['jsonb'];
}>;
export type InsertServiceVarietyOpeningMutation = (
  { __typename?: 'mutation_root' }
  & { insert_service_variety_opening?: Maybe<(
    { __typename?: 'service_variety_opening_mutation_response' }
    & Pick<ServiceVarietyOpeningMutationResponse, 'affected_rows'>
  )> }
);
export type UpdateServiceVarietyOpeningMutationVariables = Exact<{
  id: Scalars['String'];
  monday: Scalars['jsonb'];
  tuesday: Scalars['jsonb'];
  wednesday: Scalars['jsonb'];
  thursday: Scalars['jsonb'];
  friday: Scalars['jsonb'];
  saturday: Scalars['jsonb'];
  sunday: Scalars['jsonb'];
}>;
export type UpdateServiceVarietyOpeningMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_variety_opening_by_pk?: Maybe<(
    { __typename?: 'service_variety_opening' }
    & Pick<ServiceVarietyOpening, 'id'>
  )> }
);
export type UpdateServiceVarietyOpeningDatesMutationVariables = Exact<{
  id: Scalars['String'];
  validFrom: Scalars['date'];
  validUntil?: Maybe<Scalars['date']>;
}>;
export type UpdateServiceVarietyOpeningDatesMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_variety_opening_by_pk?: Maybe<(
    { __typename?: 'service_variety_opening' }
    & Pick<ServiceVarietyOpening, 'id'>
  )> }
);
export type GetServicePricingByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetServicePricingByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_pricing: Array<(
    { __typename?: 'service_pricing' }
    & Pick<ServicePricing, 'id' | 'hourRate' | 'validFrom' | 'validUntil'>
  )> }
);
export type InsertSubscriptionServiceMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
  serviceId: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isDisplayNote: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
}>;
export type InsertSubscriptionServiceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_subscription_service_one?: Maybe<(
    { __typename?: 'subscription_service' }
    & Pick<SubscriptionService, 'id' | 'isAvailable' | 'serviceId' | 'subscriptionId' | 'isDisplayNote' | 'note'>
  )> }
);
export type UpdateSubscriptionServiceMutationVariables = Exact<{
  id: Scalars['String'];
  isAvailable: Scalars['Boolean'];
  isDisplayNote: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
}>;
export type UpdateSubscriptionServiceMutation = (
  { __typename?: 'mutation_root' }
  & { update_subscription_service?: Maybe<(
    { __typename?: 'subscription_service_mutation_response' }
    & Pick<SubscriptionServiceMutationResponse, 'affected_rows'>
  )> }
);
export type SetPublicReadAccessMutationVariables = Exact<{
  objectKey: Scalars['String'];
  objectKeyPrefix: Scalars['String'];
}>;
export type SetPublicReadAccessMutation = (
  { __typename?: 'mutation_root' }
  & { setPublicReadAccess?: Maybe<(
    { __typename?: 'SetPublicReadAccessOutput' }
    & Pick<SetPublicReadAccessOutput, 'getUrl'>
  )> }
);
export type CancelWaitingListMutationVariables = Exact<{
  bookingHash: Scalars['String'];
  days?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  serviceVarietyName?: Maybe<Scalars['String']>;
}>;
export type CancelWaitingListMutation = (
  { __typename?: 'mutation_root' }
  & { cancelWaitingList?: Maybe<(
    { __typename?: 'CancelWaitingListOutput' }
    & Pick<CancelWaitingListOutput, 'code' | 'message'>
  )> }
);
export type GetBookingsSinceLivegangQueryVariables = Exact<{ [key: string]: never; }>;
export type GetBookingsSinceLivegangQuery = (
  { __typename?: 'query_root' }
  & { booking: Array<(
    { __typename?: 'booking' }
    & Pick<Booking, 'id' | 'bookingType'>
    & { bookingStatus?: Maybe<(
      { __typename?: 'booking_status' }
      & Pick<BookingStatus, 'isCompleted' | 'isCancelled' | 'isContractSigned'>
    )> }
  )> }
);
export type GetBookingByIdQueryVariables = Exact<{
  bookingId?: Maybe<Scalars['String']>;
}>;
export type GetBookingByIdQuery = (
  { __typename?: 'query_root' }
  & { booking: Array<(
    { __typename?: 'booking' }
    & Pick<Booking, 'id' | 'serviceKind' | 'analysis_id'>
    & { bookingStatus?: Maybe<(
      { __typename?: 'booking_status' }
      & Pick<BookingStatus, 'isEmailVerified' | 'isContractSigned' | 'isMandateSigned' | 'isCompleted' | 'isCancelled'>
    )>, child?: Maybe<(
      { __typename?: 'child' }
      & Pick<Child, 'firstName' | 'lastName' | 'number'>
    )>, placements: Array<(
      { __typename?: 'placement' }
      & Pick<Placement, 'status' | 'isCallbackReceived'>
      & { reservations: Array<(
        { __typename?: 'reservation' }
        & Pick<Reservation, 'id' | 'dayOfWeek'>
        & { serviceVariety: (
          { __typename?: 'service_variety' }
          & Pick<ServiceVariety, 'name'>
        ) }
      )> }
    )>, locationInquiries: Array<(
      { __typename?: 'location_inquiry' }
      & Pick<LocationInquiry, 'isCallbackReceived'>
      & { waitingListPlaces: Array<(
        { __typename?: 'waiting_list_place' }
        & Pick<WaitingListPlace, 'dayOfWeek' | 'status'>
        & { serviceVariety: (
          { __typename?: 'service_variety' }
          & Pick<ServiceVariety, 'name'>
        ) }
      )> }
    )> }
  )> }
);
export type GetBookingChildsQueryVariables = Exact<{
  locationSearchOn?: Maybe<Scalars['String']>;
  searchOn?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<BookingOrderBy> | BookingOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;
export type GetBookingChildsQuery = (
  { __typename?: 'query_root' }
  & { booking: Array<(
    { __typename?: 'booking' }
    & Pick<Booking, 'id' | 'urlHash' | 'validFrom' | 'validUntil' | 'createdAt' | 'isInternalBooking' | 'bookingType'>
    & { bookingStatus?: Maybe<(
      { __typename?: 'booking_status' }
      & Pick<BookingStatus, 'status' | 'isEmailVerified' | 'isContractSigned' | 'isMandateSigned' | 'isCompleted' | 'isCancelled'>
    )>, child?: Maybe<(
      { __typename?: 'child' }
      & Pick<Child, 'id' | 'firstName' | 'lastName' | 'fullName' | 'dateOfBirth' | 'number'>
    )>, service?: Maybe<(
      { __typename?: 'service' }
      & Pick<Service, 'name'>
      & { serviceVarieties: Array<(
        { __typename?: 'service_variety' }
        & Pick<ServiceVariety, 'name'>
      )> }
    )>, placements: Array<(
      { __typename?: 'placement' }
      & Pick<Placement, 'status' | 'isCallbackReceived'>
      & { reservations: Array<(
        { __typename?: 'reservation' }
        & Pick<Reservation, 'dayOfWeek'>
      )> }
    )>, locationInquiries: Array<(
      { __typename?: 'location_inquiry' }
      & { waitingListPlaces: Array<(
        { __typename?: 'waiting_list_place' }
        & Pick<WaitingListPlace, 'dayOfWeek' | 'status'>
      )> }
    )> }
  )>, booking_aggregate: (
    { __typename?: 'booking_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'booking_aggregate_fields' }
      & Pick<BookingAggregateFields, 'count'>
    )> }
  ) }
);
export type FetchConfigurationByKeyQueryVariables = Exact<{
  key: Scalars['String'];
  date?: Maybe<Scalars['date']>;
}>;
export type FetchConfigurationByKeyQuery = (
  { __typename?: 'query_root' }
  & { configuration: Array<(
    { __typename?: 'configuration' }
    & Pick<Configuration, 'value'>
  )> }
);
export type GetGroupPincodesOfGroupQueryVariables = Exact<{
  groupId: Scalars['String'];
  startDate: Scalars['date'];
  dayOfWeek?: Maybe<Scalars['String']>;
}>;
export type GetGroupPincodesOfGroupQuery = (
  { __typename?: 'query_root' }
  & { group_pincode: Array<(
    { __typename?: 'group_pincode' }
    & Pick<GroupPincode, 'id' | 'dayOfWeek' | 'partOfDay' | 'pincode' | 'validFrom' | 'validUntil' | 'groupId'>
  )> }
);
export type GetGroupPincodeByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetGroupPincodeByIdQuery = (
  { __typename?: 'query_root' }
  & { group_pincode: Array<(
    { __typename?: 'group_pincode' }
    & Pick<GroupPincode, 'id' | 'dayOfWeek' | 'partOfDay' | 'pincode' | 'validFrom' | 'validUntil' | 'groupId'>
  )> }
);
export type GetOfferByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetOfferByIdQuery = (
  { __typename?: 'query_root' }
  & { offer_by_pk?: Maybe<(
    { __typename?: 'offer' }
    & Pick<Offer, 'id' | 'capacityLastCalculatedDate' | 'startTimeCapacityCalculation'>
  )> }
);
export type FetchPostalcodeInfoQueryVariables = Exact<{
  postalCode: Scalars['String'];
  houseNumber?: Maybe<Scalars['Int']>;
  houseNumberAddition?: Maybe<Scalars['String']>;
}>;
export type FetchPostalcodeInfoQuery = (
  { __typename?: 'query_root' }
  & { fetchPostalcodeInfo?: Maybe<(
    { __typename?: 'FetchPostalcodeInfoOutput' }
    & Pick<FetchPostalcodeInfoOutput, 'isSuccess' | 'postalCodeInfo'>
  )> }
);
export type GetActivatedServiceProductsQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['String']>;
  categorySearchOn?: Maybe<Scalars['String']>;
  serviceVarietySearchOn?: Maybe<Scalars['String']>;
  searchOn?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<ServiceProductOrderBy> | ServiceProductOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  referenceDate?: Maybe<Scalars['timestamptz']>;
}>;
export type GetActivatedServiceProductsQuery = (
  { __typename?: 'query_root' }
  & { service_product: Array<(
    { __typename?: 'service_product' }
    & Pick<ServiceProduct, 'id' | 'serviceId' | 'productId' | 'validFrom' | 'isActive' | 'isBookable' | 'isUsedInChildBenefitCalculator' | 'textualAddition' | 'showTextualAddition'>
    & { product?: Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'serviceVariety' | 'category'>
      & { productDayInfos: Array<(
        { __typename?: 'product_day_info' }
        & Pick<ProductDayInfo, 'dayOfWeek' | 'type' | 'startTime' | 'endTime'>
      )> }
    )>, productPriceAgreement: Array<(
      { __typename?: 'product_price_agreement' }
      & Pick<ProductPriceAgreement, 'centsAmountMonday' | 'centsAmountTuesday' | 'centsAmountWednesday' | 'centsAmountThursday' | 'centsAmountFriday' | 'validFrom' | 'validUntil'>
    )> }
  )>, service_product_aggregate: (
    { __typename?: 'service_product_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'service_product_aggregate_fields' }
      & Pick<ServiceProductAggregateFields, 'count'>
    )> }
  ) }
);
export type GetActiveBenefitCalculatorProductsQueryVariables = Exact<{
  serviceId: Scalars['String'];
  referenceDate: Scalars['timestamptz'];
}>;
export type GetActiveBenefitCalculatorProductsQuery = (
  { __typename?: 'query_root' }
  & { service_product: Array<(
    { __typename?: 'service_product' }
    & Pick<ServiceProduct, 'id'>
    & { product?: Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'category' | 'serviceVariety'>
    )> }
  )> }
);
export type GetDeactivatedServiceProductsQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['String']>;
  categorySearchOn?: Maybe<Scalars['String']>;
  serviceVarietySearchOn?: Maybe<Scalars['String']>;
  searchOn?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<ServiceProductOrderBy> | ServiceProductOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  referenceDate?: Maybe<Scalars['timestamptz']>;
}>;
export type GetDeactivatedServiceProductsQuery = (
  { __typename?: 'query_root' }
  & { service_product: Array<(
    { __typename?: 'service_product' }
    & Pick<ServiceProduct, 'id' | 'serviceId' | 'productId' | 'validFrom' | 'isActive' | 'isBookable' | 'textualAddition' | 'showTextualAddition'>
    & { product?: Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'serviceVariety' | 'category'>
      & { productDayInfos: Array<(
        { __typename?: 'product_day_info' }
        & Pick<ProductDayInfo, 'dayOfWeek' | 'type' | 'startTime' | 'endTime'>
      )> }
    )>, productPriceAgreement: Array<(
      { __typename?: 'product_price_agreement' }
      & Pick<ProductPriceAgreement, 'centsAmountMonday' | 'centsAmountTuesday' | 'centsAmountWednesday' | 'centsAmountThursday' | 'centsAmountFriday' | 'validFrom' | 'validUntil'>
    )> }
  )>, service_product_aggregate: (
    { __typename?: 'service_product_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'service_product_aggregate_fields' }
      & Pick<ServiceProductAggregateFields, 'count'>
    )> }
  ) }
);
export type GetHasActivatedServiceProductsQueryVariables = Exact<{
  serviceId: Scalars['String'];
  referenceDate: Scalars['timestamptz'];
}>;
export type GetHasActivatedServiceProductsQuery = (
  { __typename?: 'query_root' }
  & { service_product: Array<(
    { __typename?: 'service_product' }
    & Pick<ServiceProduct, 'id'>
  )> }
);
export type GetSingleServiceProductQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['String']>;
}>;
export type GetSingleServiceProductQuery = (
  { __typename?: 'query_root' }
  & { service_product: Array<(
    { __typename?: 'service_product' }
    & Pick<ServiceProduct, 'id' | 'serviceId' | 'productId' | 'validFrom' | 'isActive' | 'isBookable' | 'isUsedInChildBenefitCalculator' | 'textualAddition' | 'showTextualAddition'>
    & { product?: Maybe<(
      { __typename?: 'product' }
      & Pick<Product, 'id' | 'name' | 'serviceVariety' | 'category'>
    )> }
  )> }
);
export type GetPropositionByIdQueryVariables = Exact<{
  propositionId?: Maybe<Scalars['String']>;
}>;
export type GetPropositionByIdQuery = (
  { __typename?: 'query_root' }
  & { waiting_list_proposition: Array<(
    { __typename?: 'waiting_list_proposition' }
    & Pick<WaitingListProposition, 'id' | 'status' | 'proposedOn' | 'expiresOn' | 'causedByReason' | 'causedBy'>
    & { locationInquiry: (
      { __typename?: 'location_inquiry' }
      & Pick<LocationInquiry, 'isCallbackReceived' | 'priority'>
      & { waitingListPlaces: Array<(
        { __typename?: 'waiting_list_place' }
        & Pick<WaitingListPlace, 'dayOfWeek' | 'status'>
        & { serviceVariety: (
          { __typename?: 'service_variety' }
          & Pick<ServiceVariety, 'name'>
        ) }
      )>, child: (
        { __typename?: 'child' }
        & Pick<Child, 'firstName' | 'lastName' | 'number'>
      ), booking: (
        { __typename?: 'booking' }
        & Pick<Booking, 'id' | 'serviceId' | 'serviceKind' | 'analysis_id'>
        & { bookingStatus?: Maybe<(
          { __typename?: 'booking_status' }
          & Pick<BookingStatus, 'isEmailVerified' | 'isContractSigned' | 'isMandateSigned' | 'isCompleted' | 'isCancelled'>
        )> }
      ) }
    ), placement: (
      { __typename?: 'placement' }
      & Pick<Placement, 'status' | 'validFrom' | 'isCallbackReceived'>
      & { reservations: Array<(
        { __typename?: 'reservation' }
        & Pick<Reservation, 'id' | 'dayOfWeek' | 'validFrom' | 'status'>
        & { serviceVariety: (
          { __typename?: 'service_variety' }
          & Pick<ServiceVariety, 'name'>
        ) }
      )> }
    ) }
  )> }
);
export type GetPropositionChildsQueryVariables = Exact<{
  locationSearchOn?: Maybe<Scalars['String']>;
  statusSearchOn?: Maybe<Scalars['String']>;
  searchOn?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<WaitingListPropositionOrderBy> | WaitingListPropositionOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;
export type GetPropositionChildsQuery = (
  { __typename?: 'query_root' }
  & { waiting_list_proposition: Array<(
    { __typename?: 'waiting_list_proposition' }
    & Pick<WaitingListProposition, 'id' | 'status' | 'status_rank' | 'causedBy' | 'createdAt'>
    & { placement: (
      { __typename?: 'placement' }
      & Pick<Placement, 'status' | 'validFrom' | 'isCallbackReceived'>
      & { reservations: Array<(
        { __typename?: 'reservation' }
        & Pick<Reservation, 'dayOfWeek' | 'status' | 'validFrom'>
      )> }
    ), locationInquiry: (
      { __typename?: 'location_inquiry' }
      & { waitingListPlaces: Array<(
        { __typename?: 'waiting_list_place' }
        & Pick<WaitingListPlace, 'dayOfWeek' | 'status'>
      )>, waitingListPropositions: Array<(
        { __typename?: 'waiting_list_proposition' }
        & Pick<WaitingListProposition, 'status' | 'startDate' | 'expiresOn' | 'createdAt'>
      )>, service: (
        { __typename?: 'service' }
        & Pick<Service, 'name'>
        & { serviceVarieties: Array<(
          { __typename?: 'service_variety' }
          & Pick<ServiceVariety, 'name'>
        )> }
      ), child: (
        { __typename?: 'child' }
        & Pick<Child, 'id' | 'firstName' | 'lastName' | 'fullName' | 'dateOfBirth' | 'number'>
      ), booking: (
        { __typename?: 'booking' }
        & Pick<Booking, 'id' | 'urlHash' | 'validFrom' | 'validUntil' | 'serviceId' | 'isInternalBooking' | 'bookingType'>
        & { bookingStatus?: Maybe<(
          { __typename?: 'booking_status' }
          & Pick<BookingStatus, 'status' | 'isEmailVerified' | 'isContractSigned' | 'isMandateSigned' | 'isCompleted' | 'isCancelled'>
        )> }
      ) }
    ) }
  )>, waiting_list_proposition_aggregate: (
    { __typename?: 'waiting_list_proposition_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'waiting_list_proposition_aggregate_fields' }
      & Pick<WaitingListPropositionAggregateFields, 'count'>
    )> }
  ) }
);
export type DeleteAnalysisResultByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;
export type DeleteAnalysisResultByIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_analysis_result_by_pk?: Maybe<(
    { __typename?: 'analysis_result' }
    & Pick<AnalysisResult, 'id'>
  )> }
);
export type GetAnalysisResultByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetAnalysisResultByIdQuery = (
  { __typename?: 'query_root' }
  & { analysis_result_by_pk?: Maybe<(
    { __typename?: 'analysis_result' }
    & Pick<AnalysisResult, 'result'>
  )> }
);
export type GetPropositionsQueryVariables = Exact<{
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  birthDate: Scalars['String'];
  startDateOfCare: Scalars['date'];
  endDateOfCare: Scalars['date'];
  daysOfWeek?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  selectedSchool?: Maybe<SchoolInput>;
  selectedServiceVarietyNames?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  serviceVarietyDaySelection: ServiceVarietyDaySelection;
}>;
export type GetPropositionsQuery = (
  { __typename?: 'query_root' }
  & { getPropositions?: Maybe<Array<(
    { __typename?: 'GetPropositionsOutput' }
    & Pick<GetPropositionsOutput, 'offerId' | 'distance' | 'availability' | 'isPreorder' | 'serviceVarietyPropositions'>
    & { offer: (
      { __typename?: 'offer' }
      & Pick<Offer, 'name' | 'description' | 'kind' | 'addressLine1' | 'addressLine2' | 'locality' | 'postalCode' | 'images'>
      & { service: (
        { __typename?: 'service' }
        & { servicePricings: Array<(
          { __typename?: 'service_pricing' }
          & Pick<ServicePricing, 'hourRate'>
        )> }
      ), serviceVarieties: Array<(
        { __typename?: 'service_variety' }
        & Pick<ServiceVariety, 'name'>
        & { serviceVarietyOpenings: Array<(
          { __typename?: 'service_variety_opening' }
          & Pick<ServiceVarietyOpening, 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
        )> }
      )>, serviceFeatures: Array<(
        { __typename?: 'service_feature' }
        & Pick<ServiceFeature, 'isMainFeature'>
        & { feature: (
          { __typename?: 'feature' }
          & Pick<Feature, 'description'>
        ) }
      )> }
    ) }
  )>> }
);
export type GetPropositionsAnalysisQueryVariables = Exact<{
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  birthDate: Scalars['String'];
  startDateOfCare: Scalars['date'];
  daysOfWeek?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
  selectedSchool?: Maybe<SchoolInput>;
  selectedServiceVarietyNames?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  serviceVarietyDaySelection: ServiceVarietyDaySelection;
}>;
export type GetPropositionsAnalysisQuery = (
  { __typename?: 'query_root' }
  & { getPropositionsAnalysis: (
    { __typename?: 'GetPropositionsAnalysisOutput' }
    & Pick<GetPropositionsAnalysisOutput, 'id'>
  ) }
);
export type GetChildReservationsQueryVariables = Exact<{
  order_by?: Maybe<Array<ChildOrderBy> | ChildOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;
export type GetChildReservationsQuery = (
  { __typename?: 'query_root' }
  & { child: Array<(
    { __typename?: 'child' }
    & Pick<Child, 'id' | 'dateOfBirth'>
    & { reservations: Array<(
      { __typename?: 'reservation' }
      & Pick<Reservation, 'id' | 'dayOfWeek' | 'status'>
      & { group: (
        { __typename?: 'group' }
        & { service?: Maybe<(
          { __typename?: 'service' }
          & Pick<Service, 'name'>
          & { serviceVarieties: Array<(
            { __typename?: 'service_variety' }
            & Pick<ServiceVariety, 'name'>
          )> }
        )> }
      ) }
    )>, reservations_aggregate: (
      { __typename?: 'reservation_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'reservation_aggregate_fields' }
        & { max?: Maybe<(
          { __typename?: 'reservation_max_fields' }
          & Pick<ReservationMaxFields, 'createdAt' | 'validUntil'>
        )>, min?: Maybe<(
          { __typename?: 'reservation_min_fields' }
          & Pick<ReservationMinFields, 'validFrom'>
        )> }
      )> }
    ) }
  )>, child_aggregate: (
    { __typename?: 'child_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'child_aggregate_fields' }
      & Pick<ChildAggregateFields, 'count'>
    )> }
  ) }
);
export type GetSchoolsByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetSchoolsByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { school_service: Array<(
    { __typename?: 'school_service' }
    & { school: (
      { __typename?: 'school' }
      & Pick<School, 'name'>
      & { schoolGroups: Array<(
        { __typename?: 'school_group' }
        & Pick<SchoolGroup, 'name'>
        & { schoolGroupOpenings: Array<(
          { __typename?: 'school_group_opening' }
          & Pick<SchoolGroupOpening, 'validFrom' | 'validUntil'>
        )> }
      )> }
    ) }
  )> }
);
export type SearchSchoolByLocalityAndNameQueryVariables = Exact<{
  locationInput: Scalars['String'];
  nameInput: Scalars['String'];
}>;
export type SearchSchoolByLocalityAndNameQuery = (
  { __typename?: 'query_root' }
  & { school: Array<(
    { __typename?: 'school' }
    & Pick<School, 'id' | 'name' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'locality'>
  )> }
);
export type GetSchoolOfferCountBySchoolIdQueryVariables = Exact<{
  schoolId: Scalars['String'];
}>;
export type GetSchoolOfferCountBySchoolIdQuery = (
  { __typename?: 'query_root' }
  & { school_service_aggregate: (
    { __typename?: 'school_service_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'school_service_aggregate_fields' }
      & Pick<SchoolServiceAggregateFields, 'count'>
    )> }
  ) }
);
export type CanEnableWaitingListQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type CanEnableWaitingListQuery = (
  { __typename?: 'query_root' }
  & { canEnableWaitingList?: Maybe<(
    { __typename?: 'CanEnableWaitingListOutput' }
    & Pick<CanEnableWaitingListOutput, 'result' | 'errors'>
  )> }
);
export type ExportServiceSettingsQueryVariables = Exact<{ [key: string]: never; }>;
export type ExportServiceSettingsQuery = (
  { __typename?: 'query_root' }
  & { exportServiceSettings?: Maybe<(
    { __typename?: 'ExportServiceSettingsOutput' }
    & Pick<ExportServiceSettingsOutput, 'base64CsvServiceExport'>
  )> }
);
export type GetActiveSubscriptionsByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetActiveSubscriptionsByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { subscription_service: Array<(
    { __typename?: 'subscription_service' }
    & Pick<SubscriptionService, 'id'>
  )> }
);
export type GetControlVariablesByServiceIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetControlVariablesByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'name' | 'description' | 'kind' | 'contactInfo' | 'flexMargin' | 'isPublished' | 'isPublishedForChildBenefitCalculator' | 'isBookable' | 'validateWaitingListPropositions' | 'isCustomSubscriptionEnabled'>
    & { serviceProvider?: Maybe<(
      { __typename?: 'service_provider' }
      & Pick<ServiceProvider, 'id' | 'name'>
    )>, location?: Maybe<(
      { __typename?: 'location' }
      & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'locality'>
    )>, servicePricings: Array<(
      { __typename?: 'service_pricing' }
      & Pick<ServicePricing, 'id' | 'validFrom' | 'validUntil' | 'hourRate' | 'serviceId'>
    )>, serviceVarieties: Array<(
      { __typename?: 'service_variety' }
      & Pick<ServiceVariety, 'id' | 'name'>
      & { serviceVarietyOpenings: Array<(
        { __typename?: 'service_variety_opening' }
        & Pick<ServiceVarietyOpening, 'id' | 'validFrom' | 'validUntil' | 'serviceVarietyId' | 'type' | 'isManuallySet' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
      )> }
    )> }
  )> }
);
export type GetDashboardDataByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
  mondayDate: Scalars['date'];
  tuesdayDate: Scalars['date'];
  wednesdayDate: Scalars['date'];
  thursdayDate: Scalars['date'];
  fridayDate: Scalars['date'];
  saturdayDate: Scalars['date'];
  sundayDate: Scalars['date'];
}>;
export type GetDashboardDataByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'kind' | 'flexMargin'>
    & { groups: Array<(
      { __typename?: 'group' }
      & Pick<Group, 'id' | 'name'>
      & { groupPincodes: Array<(
        { __typename?: 'group_pincode' }
        & Pick<GroupPincode, 'id' | 'dayOfWeek' | 'partOfDay' | 'pincode' | 'validFrom' | 'validUntil' | 'groupId'>
      )>, reservations: Array<(
        { __typename?: 'reservation' }
        & Pick<Reservation, 'dayOfWeek' | 'partOfDay' | 'status' | 'weekInterval' | 'type'>
        & { child: (
          { __typename?: 'child' }
          & Pick<Child, 'id' | 'firstName' | 'lastName' | 'number' | 'dateOfBirth'>
        ) }
      )>, groupCapacities: Array<(
        { __typename?: 'group_capacity' }
        & Pick<GroupCapacity, 'id' | 'mondayPlanCapacity' | 'tuesdayPlanCapacity' | 'wednesdayPlanCapacity' | 'thursdayPlanCapacity' | 'fridayPlanCapacity' | 'strategy' | 'validFrom' | 'validUntil'>
      )> }
    )> }
  )> }
);
export type GetServicesQueryVariables = Exact<{ [key: string]: never; }>;
export type GetServicesQuery = (
  { __typename?: 'query_root' }
  & { service: Array<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'name'>
  )> }
);
export type GetServiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetServiceByIdQuery = (
  { __typename?: 'query_root' }
  & { service_by_pk?: Maybe<(
    { __typename?: 'service' }
    & Pick<Service, 'id' | 'name' | 'description' | 'kind' | 'isPublished' | 'isPublishedForChildBenefitCalculator' | 'validateWaitingListPropositions' | 'isBookable' | 'waitingListFrozenSince' | 'contactInfo' | 'serviceProviderId' | 'locationId' | 'permitNumber'>
    & { location?: Maybe<(
      { __typename?: 'location' }
      & Pick<Location, 'id' | 'addressLine1' | 'addressLine2' | 'postalCode' | 'locality'>
    )>, serviceProvider?: Maybe<(
      { __typename?: 'service_provider' }
      & Pick<ServiceProvider, 'id' | 'name'>
    )>, servicePricings: Array<(
      { __typename?: 'service_pricing' }
      & Pick<ServicePricing, 'id' | 'hourRate' | 'validFrom' | 'validUntil' | 'serviceId'>
    )>, serviceMedia: Array<(
      { __typename?: 'service_media' }
      & Pick<ServiceMedia, 'id' | 'filename' | 'url' | 'mimeType'>
    )>, serviceVarieties: Array<(
      { __typename?: 'service_variety' }
      & Pick<ServiceVariety, 'id' | 'name'>
      & { serviceVarietyOpenings: Array<(
        { __typename?: 'service_variety_opening' }
        & Pick<ServiceVarietyOpening, 'id' | 'validFrom' | 'validUntil' | 'serviceVarietyId' | 'type' | 'isManuallySet' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
      )> }
    )> }
  )> }
);
export type HasCreatedWaitingListPropositionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type HasCreatedWaitingListPropositionsQuery = (
  { __typename?: 'query_root' }
  & { service_aggregate: (
    { __typename?: 'service_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'service_aggregate_fields' }
      & Pick<ServiceAggregateFields, 'count'>
    )> }
  ) }
);
export type GetFeaturesQueryVariables = Exact<{
  searchOn?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FeatureOrderBy> | FeatureOrderBy>;
}>;
export type GetFeaturesQuery = (
  { __typename?: 'query_root' }
  & { feature: Array<(
    { __typename?: 'feature' }
    & Pick<Feature, 'id' | 'description' | 'isDefaultFeature'>
    & { persona_features: Array<(
      { __typename?: 'persona_feature' }
      & { persona: (
        { __typename?: 'persona' }
        & Pick<Persona, 'name' | 'id'>
      ) }
    )> }
  )>, feature_aggregate: (
    { __typename?: 'feature_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feature_aggregate_fields' }
      & Pick<FeatureAggregateFields, 'count'>
    )> }
  ) }
);
export type GetServiceFeaturesByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetServiceFeaturesByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_feature: Array<(
    { __typename?: 'service_feature' }
    & Pick<ServiceFeature, 'id' | 'isMainFeature' | 'featureId'>
    & { feature: (
      { __typename?: 'feature' }
      & Pick<Feature, 'id' | 'description'>
      & { persona_features: Array<(
        { __typename?: 'persona_feature' }
        & { feature: (
          { __typename?: 'feature' }
          & Pick<Feature, 'description' | 'id'>
        ) }
      )> }
    ) }
  )> }
);
export type GetServiceFeatureByIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
  featureId: Scalars['String'];
}>;
export type GetServiceFeatureByIdQuery = (
  { __typename?: 'query_root' }
  & { service_feature: Array<(
    { __typename?: 'service_feature' }
    & Pick<ServiceFeature, 'id' | 'featureId' | 'serviceId' | 'isMainFeature'>
  )> }
);
export type GetServiceSettingsByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetServiceSettingsByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_settings: Array<(
    { __typename?: 'service_settings' }
    & Pick<ServiceSettings, 'planningHorizonInMonths' | 'sendRemainOnWaitingListMailInInMinutes' | 'sendRemainOnWaitingListReminderMailInInMinutes' | 'inquiryExpiresInInMinutes' | 'useFlexkidsProducts' | 'showLinkToPartouOffers' | 'openingTimesNote'>
  )> }
);
export type GetServiceSettingsForServiceDetailPageByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetServiceSettingsForServiceDetailPageByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { service_settings: Array<(
    { __typename?: 'service_settings' }
    & Pick<ServiceSettings, 'useFlexkidsProducts' | 'openingTimesNote'>
  )> }
);
export type SetOpeningTimesNoteByServiceIdMutationVariables = Exact<{
  serviceId: Scalars['String'];
  note: Scalars['String'];
}>;
export type SetOpeningTimesNoteByServiceIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_settings?: Maybe<(
    { __typename?: 'service_settings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_settings' }
      & Pick<ServiceSettings, 'id' | 'serviceId' | 'openingTimesNote'>
    )> }
  )> }
);
export type SetPlanningHorizonByServiceIdMutationVariables = Exact<{
  serviceId: Scalars['String'];
  planningHorizonInMonths: Scalars['Int'];
}>;
export type SetPlanningHorizonByServiceIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_settings?: Maybe<(
    { __typename?: 'service_settings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_settings' }
      & Pick<ServiceSettings, 'id' | 'serviceId' | 'planningHorizonInMonths'>
    )> }
  )> }
);
export type SetUseFlexkidsProductsByServiceIdMutationVariables = Exact<{
  serviceId: Scalars['String'];
  useFlexkidsProducts: Scalars['Boolean'];
}>;
export type SetUseFlexkidsProductsByServiceIdMutation = (
  { __typename?: 'mutation_root' }
  & { update_service_settings?: Maybe<(
    { __typename?: 'service_settings_mutation_response' }
    & { returning: Array<(
      { __typename?: 'service_settings' }
      & Pick<ServiceSettings, 'id' | 'serviceId' | 'useFlexkidsProducts'>
    )> }
  )> }
);
export type GetDefaultSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;
export type GetDefaultSubscriptionsQuery = (
  { __typename?: 'query_root' }
  & { subscription: Array<(
    { __typename?: 'subscription' }
    & Pick<Subscription, 'id' | 'name' | 'description' | 'features' | 'numberOfWeeks' | 'order' | 'reservationType' | 'surchargePercentageKdv' | 'surchargePercentageVso' | 'surchargePercentageNso'>
  )> }
);
export type GetSubscriptionsWithPricingsByServiceIdQueryVariables = Exact<{
  serviceId: Scalars['String'];
}>;
export type GetSubscriptionsWithPricingsByServiceIdQuery = (
  { __typename?: 'query_root' }
  & { subscription_service: Array<(
    { __typename?: 'subscription_service' }
    & Pick<SubscriptionService, 'id' | 'isAvailable' | 'isDisplayNote' | 'note' | 'subscriptionId'>
    & { subscription: (
      { __typename?: 'subscription' }
      & Pick<Subscription, 'id' | 'name' | 'description' | 'features' | 'numberOfWeeks' | 'order' | 'reservationType' | 'surchargePercentageKdv' | 'surchargePercentageVso' | 'surchargePercentageNso'>
    ), service: (
      { __typename?: 'service' }
      & { servicePricings: Array<(
        { __typename?: 'service_pricing' }
        & Pick<ServicePricing, 'id' | 'serviceId' | 'hourRate' | 'validFrom' | 'validUntil'>
      )> }
    ) }
  )> }
);
export type GetSignedDeleteUrlQueryVariables = Exact<{
  objectKey: Scalars['String'];
  objectKeyPrefix: Scalars['String'];
}>;
export type GetSignedDeleteUrlQuery = (
  { __typename?: 'query_root' }
  & { getSignedDeleteUrl?: Maybe<(
    { __typename?: 'GetSignedDeleteUrlOutput' }
    & Pick<GetSignedDeleteUrlOutput, 'deleteUrl'>
  )> }
);
export type GetSignedUploadUrlQueryVariables = Exact<{
  objectKey: Scalars['String'];
  objectKeyPrefix: Scalars['String'];
}>;
export type GetSignedUploadUrlQuery = (
  { __typename?: 'query_root' }
  & { getSignedUploadUrl?: Maybe<(
    { __typename?: 'GetSignedUploadUrlOutput' }
    & Pick<GetSignedUploadUrlOutput, 'uploadUrl'>
  )> }
);
export type CancelCreatedWaitingListPropositionMutationVariables = Exact<{
  waitingListPropositionId: Scalars['String'];
  causedByReason: Scalars['String'];
}>;
export type CancelCreatedWaitingListPropositionMutation = (
  { __typename?: 'mutation_root' }
  & { cancelCreatedWaitingListProposition?: Maybe<(
    { __typename?: 'CancelCreatedWaitingListPropositionOutput' }
    & Pick<CancelCreatedWaitingListPropositionOutput, 'code' | 'message'>
  )> }
);
export type ProposeCreatedWaitingListPropositionMutationVariables = Exact<{
  waitingListPropositionId: Scalars['String'];
}>;
export type ProposeCreatedWaitingListPropositionMutation = (
  { __typename?: 'mutation_root' }
  & { proposeCreatedWaitingListProposition?: Maybe<(
    { __typename?: 'ProposeCreatedWaitingListPropositionOutput' }
    & Pick<ProposeCreatedWaitingListPropositionOutput, 'code' | 'message'>
  )> }
);
export type RevokeProposedWaitingListPropositionMutationVariables = Exact<{
  waitingListPropositionId: Scalars['String'];
  causedByReason: Scalars['String'];
}>;
export type RevokeProposedWaitingListPropositionMutation = (
  { __typename?: 'mutation_root' }
  & { revokeProposedWaitingListProposition?: Maybe<(
    { __typename?: 'RevokeProposedWaitingListPropositionOutput' }
    & Pick<RevokeProposedWaitingListPropositionOutput, 'code' | 'message'>
  )> }
);
export type GetLocationInquiryByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;
export type GetLocationInquiryByIdQuery = (
  { __typename?: 'query_root' }
  & { location_inquiry_by_pk?: Maybe<(
    { __typename?: 'location_inquiry' }
    & Pick<LocationInquiry, 'id'>
    & { waitingListPlaces: Array<(
      { __typename?: 'waiting_list_place' }
      & Pick<WaitingListPlace, 'dayOfWeek' | 'status'>
      & { serviceVariety: (
        { __typename?: 'service_variety' }
        & Pick<ServiceVariety, 'id' | 'name'>
      ) }
    )>, booking: (
      { __typename?: 'booking' }
      & { placements: Array<(
        { __typename?: 'placement' }
        & { reservations: Array<(
          { __typename?: 'reservation' }
          & Pick<Reservation, 'dayOfWeek' | 'status'>
          & { serviceVariety: (
            { __typename?: 'service_variety' }
            & Pick<ServiceVariety, 'name'>
          ) }
        )> }
      )> }
    ), subscriptionService: (
      { __typename?: 'subscription_service' }
      & { subscription: (
        { __typename?: 'subscription' }
        & Pick<Subscription, 'name'>
      ) }
    ), child: (
      { __typename?: 'child' }
      & Pick<Child, 'id' | 'number'>
    ) }
  )> }
);
export type GetWaitingListPlacesQueryVariables = Exact<{
  serviceId?: Maybe<Scalars['String']>;
  selectedDay?: Maybe<Scalars['String']>;
  order_by?: Maybe<Array<LocationInquiryOrderBy> | LocationInquiryOrderBy>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;
export type GetWaitingListPlacesQuery = (
  { __typename?: 'query_root' }
  & { location_inquiry: Array<(
    { __typename?: 'location_inquiry' }
    & Pick<LocationInquiry, 'id' | 'priority' | 'validFrom' | 'createdAt' | 'status'>
    & { waitingListPlaces: Array<(
      { __typename?: 'waiting_list_place' }
      & Pick<WaitingListPlace, 'id' | 'dayOfWeek' | 'status'>
      & { serviceVariety: (
        { __typename?: 'service_variety' }
        & Pick<ServiceVariety, 'name'>
      ) }
    )>, child: (
      { __typename?: 'child' }
      & Pick<Child, 'firstName' | 'lastName' | 'dateOfBirth'>
    ), subscriptionService: (
      { __typename?: 'subscription_service' }
      & { subscription: (
        { __typename?: 'subscription' }
        & Pick<Subscription, 'name'>
      ) }
    ) }
  )>, location_inquiry_aggregate: (
    { __typename?: 'location_inquiry_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'location_inquiry_aggregate_fields' }
      & Pick<LocationInquiryAggregateFields, 'count'>
    )> }
  ) }
);
export type GetWaitingListAnalysisResultsQueryVariables = Exact<{
  serviceId: Scalars['String'];
  fromDate?: Maybe<Scalars['timestamptz']>;
  untilDate?: Maybe<Scalars['timestamptz']>;
  didAllocateWaitingListSpots?: Maybe<Scalars['Boolean']>;
}>;
export type GetWaitingListAnalysisResultsQuery = (
  { __typename?: 'query_root' }
  & { waiting_list_analysis_result: Array<(
    { __typename?: 'waiting_list_analysis_result' }
    & Pick<WaitingListAnalysisResult, 'id' | 'serviceId' | 'calculateDate' | 'didAllocateWaitingListSpots' | 'waitingListAllocation'>
  )> }
);
export type GetWaitingListPropositionAnalysisResultsQueryVariables = Exact<{
  serviceId: Scalars['String'];
  fromDate?: Maybe<Scalars['timestamptz']>;
  untilDate?: Maybe<Scalars['timestamptz']>;
  didAllocateWaitingListSpots?: Maybe<Scalars['Boolean']>;
}>;
export type GetWaitingListPropositionAnalysisResultsQuery = (
  { __typename?: 'query_root' }
  & { waiting_list_proposition_analysis_result: Array<(
    { __typename?: 'waiting_list_proposition_analysis_result' }
    & Pick<WaitingListPropositionAnalysisResult, 'id' | 'serviceId' | 'calculationDate' | 'didAllocateWaitingListSpots' | 'data'>
  )> }
);
export const SetFlexMarginByServiceIdDocument = gql`
    mutation setFlexMarginByServiceId($id: String!, $flexMargin: jsonb) {
  update_service(where: {id: {_eq: $id}}, _set: {flexMargin: $flexMargin}) {
    returning {
      id
      name
      description
      kind
      contactInfo
      flexMargin
      isPublished
      serviceProvider {
        id
        name
      }
      location {
        id
        addressLine1
        addressLine2
        postalCode
        locality
      }
    }
  }
}
    `;
export const UpdateServiceDocument = gql`
    mutation updateService($id: String!, $description: String) {
  update_service_by_pk(pk_columns: {id: $id}, _set: {description: $description}) {
    id
    description
  }
}
    `;
export const DisableWaitingListAutomationDocument = gql`
    mutation disableWaitingListAutomation($serviceId: String!, $referenceDate: String) {
  disableWaitingList(ServiceId: $serviceId, ReferenceDate: $referenceDate) {
    code
    message
  }
}
    `;
export const EnableWaitingListAutomationDocument = gql`
    mutation enableWaitingListAutomation($serviceId: String!, $referenceDate: String) {
  enableWaitingList(ServiceId: $serviceId, ReferenceDate: $referenceDate) {
    code
    message
  }
}
    `;
export const UpdateServiceIsPublishedDocument = gql`
    mutation updateServiceIsPublished($id: String!, $isPublished: Boolean, $isPublishedForChildBenefitCalculator: Boolean, $isBookable: Boolean) {
  update_service_by_pk(
    pk_columns: {id: $id}
    _set: {isPublished: $isPublished, isPublishedForChildBenefitCalculator: $isPublishedForChildBenefitCalculator, isBookable: $isBookable}
  ) {
    id
    isPublished
    isPublishedForChildBenefitCalculator
    isBookable
  }
}
    `;
export const UpdateServiceToggleCustomSubscriptionDocument = gql`
    mutation updateServiceToggleCustomSubscription($id: String!, $isCustomSubscriptionEnabled: Boolean!) {
  update_service_by_pk(
    pk_columns: {id: $id}
    _set: {isCustomSubscriptionEnabled: $isCustomSubscriptionEnabled}
  ) {
    id
  }
}
    `;
export const UpdateServiceValidateWaitingListPropositionsDocument = gql`
    mutation updateServiceValidateWaitingListPropositions($id: String!, $validateWaitingListPropositions: Boolean!) {
  update_service_by_pk(
    pk_columns: {id: $id}
    _set: {validateWaitingListPropositions: $validateWaitingListPropositions}
  ) {
    id
    validateWaitingListPropositions
  }
}
    `;
export const SendEmailsDocument = gql`
    mutation sendEmails {
  sendEmails {
    code
    message
  }
}
    `;
export const InsertAndOverwriteGroupPinCodeDocument = gql`
    mutation insertAndOverwriteGroupPinCode($pinCodesToInsertAndOverwrite: [PinCodeToInsertAndOverwrite]!) {
  insertAndOverwriteGroupPinCode(
    pinCodesToInsertAndOverwrite: $pinCodesToInsertAndOverwrite
  ) {
    code
    message
  }
}
    `;
export const UpdateGroupPincodeDocument = gql`
    mutation updateGroupPincode($id: String!, $validUntil: date) {
  update_group_pincode_by_pk(
    pk_columns: {id: $id}
    _set: {validUntil: $validUntil}
  ) {
    id
    dayOfWeek
    partOfDay
    pincode
    validFrom
    validUntil
    groupId
  }
}
    `;
export const CalculateCapacityForOfferDocument = gql`
    mutation calculateCapacityForOffer($offerId: String!) {
  calculateCapacityForOffer(OfferId: $offerId) {
    code
    message
  }
}
    `;
export const DeleteServiceFeatureDocument = gql`
    mutation deleteServiceFeature($serviceId: String!, $featureId: String!) {
  delete_service_feature(
    where: {serviceId: {_eq: $serviceId}, featureId: {_eq: $featureId}}
  ) {
    affected_rows
  }
}
    `;
export const InsertServiceFeatureDocument = gql`
    mutation insertServiceFeature($featureId: String!, $serviceId: String!, $isMainFeature: Boolean!) {
  insert_service_feature_one(
    object: {featureId: $featureId, serviceId: $serviceId, isMainFeature: $isMainFeature}
  ) {
    id
    featureId
    serviceId
    isMainFeature
  }
}
    `;
export const UpdateServiceFeatureDocument = gql`
    mutation updateServiceFeature($serviceId: String!, $featureId: String!, $isMainFeature: Boolean!) {
  update_service_feature(
    where: {serviceId: {_eq: $serviceId}, featureId: {_eq: $featureId}}
    _set: {isMainFeature: $isMainFeature}
  ) {
    affected_rows
  }
}
    `;
export const DeleteServiceMediaByIdDocument = gql`
    mutation DeleteServiceMediaById($id: String!) {
  delete_service_media_by_pk(id: $id) {
    id
  }
}
    `;
export const InsertServiceMediaDocument = gql`
    mutation InsertServiceMedia($filename: String!, $mimeType: String!, $serviceId: String!, $url: String!) {
  insert_service_media_one(
    object: {filename: $filename, mimeType: $mimeType, serviceId: $serviceId, url: $url}
  ) {
    id
    filename
    url
    mimeType
  }
}
    `;
export const UpdateServiceMediaDocument = gql`
    mutation UpdateServiceMedia($id: String!, $filename: String!, $mimeType: String!, $serviceId: String!, $url: String!) {
  update_service_media_by_pk(
    pk_columns: {id: $id}
    _set: {filename: $filename, mimeType: $mimeType, serviceId: $serviceId, url: $url}
  ) {
    id
    filename
    url
    mimeType
  }
}
    `;
export const DeleteServicePricingByIdDocument = gql`
    mutation DeleteServicePricingById($id: String!) {
  delete_service_pricing_by_pk(id: $id) {
    id
  }
}
    `;
export const InsertServicePricingDocument = gql`
    mutation insertServicePricing($hourRate: numeric!, $serviceId: String!, $validFrom: date!, $validUntil: date) {
  insert_service_pricing(
    objects: {hourRate: $hourRate, serviceId: $serviceId, validFrom: $validFrom, validUntil: $validUntil}
  ) {
    affected_rows
  }
}
    `;
export const UpdateServicePricingDocument = gql`
    mutation updateServicePricing($id: String!, $validUntil: date, $validFrom: date!) {
  update_service_pricing_by_pk(
    pk_columns: {id: $id}
    _set: {validUntil: $validUntil, validFrom: $validFrom}
  ) {
    validUntil
    hourRate
    id
    validFrom
    serviceId
  }
}
    `;
export const ActivateServiceProductsDocument = gql`
    mutation ActivateServiceProducts($serviceId: String!, $productIds: [String!]!) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, productId: {_in: $productIds}}
    _set: {isActive: true}
  ) {
    returning {
      isActive
    }
  }
}
    `;
export const ActivateServiceProductIsUsedInChildBenefitCalculatorDocument = gql`
    mutation ActivateServiceProductIsUsedInChildBenefitCalculator($serviceId: String!, $productId: String!) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, productId: {_eq: $productId}}
    _set: {isUsedInChildBenefitCalculator: true}
  ) {
    affected_rows
  }
}
    `;
export const DeactivateServiceProductIsUsedInChildBenefitCalculatorDocument = gql`
    mutation DeactivateServiceProductIsUsedInChildBenefitCalculator($serviceId: String!, $productId: String) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, _and: [{_or: [{productId: {_eq: $productId}}, {productId: {_is_null: true}}]}]}
    _set: {isUsedInChildBenefitCalculator: false}
  ) {
    affected_rows
  }
}
    `;
export const DeactivateServiceProductDocument = gql`
    mutation DeactivateServiceProduct($serviceId: String!, $productId: String!) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, productId: {_eq: $productId}}
    _set: {isActive: false}
  ) {
    affected_rows
  }
}
    `;
export const UpdateServiceProductShowTextualAdditionDocument = gql`
    mutation UpdateServiceProductShowTextualAddition($serviceId: String!, $productId: String!, $showTextualAddition: Boolean!) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, productId: {_eq: $productId}}
    _set: {showTextualAddition: $showTextualAddition}
  ) {
    affected_rows
  }
}
    `;
export const UpdateServiceProductTextualAdditionDocument = gql`
    mutation UpdateServiceProductTextualAddition($serviceId: String!, $productId: String!, $textualAddition: String!) {
  update_service_product(
    where: {serviceId: {_eq: $serviceId}, productId: {_eq: $productId}}
    _set: {textualAddition: $textualAddition}
  ) {
    affected_rows
  }
}
    `;
export const SetShowLinkToPartouOffersDocument = gql`
    mutation setShowLinkToPartouOffers($serviceId: String!, $showLinkToPartouOffers: Boolean!) {
  update_service_settings(
    where: {serviceId: {_eq: $serviceId}}
    _set: {showLinkToPartouOffers: $showLinkToPartouOffers}
  ) {
    returning {
      id
      serviceId
      showLinkToPartouOffers
    }
  }
}
    `;
export const SetWaitingListActualisationIntervalsDocument = gql`
    mutation setWaitingListActualisationIntervals($serviceId: String!, $inquiryExpiresInInMinutes: Int!, $sendRemainOnWaitingListMailInInMinutes: Int!, $sendRemainOnWaitingListReminderMailInInMinutes: Int!) {
  setWaitingListActualisationIntervals(
    ServiceId: $serviceId
    InquiryExpiresInInMinutes: $inquiryExpiresInInMinutes
    SendRemainOnWaitingListMailInInMinutes: $sendRemainOnWaitingListMailInInMinutes
    SendRemainOnWaitingListReminderMailInInMinutes: $sendRemainOnWaitingListReminderMailInInMinutes
  ) {
    code
    message
  }
}
    `;
export const DeleteServiceVarietyOpeningByIdDocument = gql`
    mutation DeleteServiceVarietyOpeningById($id: String!) {
  delete_service_variety_opening_by_pk(id: $id) {
    id
  }
}
    `;
export const GetNonManualServiceVarietyPricingByServiceVarietyIdDocument = gql`
    query GetNonManualServiceVarietyPricingByServiceVarietyId($serviceVarietyId: String!) {
  service_variety_opening(
    where: {serviceVarietyId: {_eq: $serviceVarietyId}, isManuallySet: {_eq: false}, type: {_eq: "School"}}
  ) {
    id
    validFrom
    validUntil
  }
}
    `;
export const InsertServiceVarietyOpeningDocument = gql`
    mutation insertServiceVarietyOpening($id: String!, $serviceVarietyId: String!, $validFrom: date!, $validUntil: date, $monday: jsonb!, $tuesday: jsonb!, $wednesday: jsonb!, $thursday: jsonb!, $friday: jsonb!, $saturday: jsonb!, $sunday: jsonb!) {
  insert_service_variety_opening(
    objects: {id: $id, serviceVarietyId: $serviceVarietyId, validFrom: $validFrom, validUntil: $validUntil, type: "School", isManuallySet: true, monday: $monday, tuesday: $tuesday, wednesday: $wednesday, thursday: $thursday, friday: $friday, saturday: $saturday, sunday: $sunday}
  ) {
    affected_rows
  }
}
    `;
export const UpdateServiceVarietyOpeningDocument = gql`
    mutation updateServiceVarietyOpening($id: String!, $monday: jsonb!, $tuesday: jsonb!, $wednesday: jsonb!, $thursday: jsonb!, $friday: jsonb!, $saturday: jsonb!, $sunday: jsonb!) {
  update_service_variety_opening_by_pk(
    pk_columns: {id: $id}
    _set: {monday: $monday, tuesday: $tuesday, wednesday: $wednesday, thursday: $thursday, friday: $friday, saturday: $saturday, sunday: $sunday}
  ) {
    id
  }
}
    `;
export const UpdateServiceVarietyOpeningDatesDocument = gql`
    mutation updateServiceVarietyOpeningDates($id: String!, $validFrom: date!, $validUntil: date) {
  update_service_variety_opening_by_pk(
    pk_columns: {id: $id}
    _set: {validFrom: $validFrom, validUntil: $validUntil}
  ) {
    id
  }
}
    `;
export const GetServicePricingByServiceIdDocument = gql`
    query getServicePricingByServiceId($serviceId: String!) {
  service_pricing(where: {serviceId: {_eq: $serviceId}}) {
    id
    hourRate
    validFrom
    validUntil
  }
}
    `;
export const InsertSubscriptionServiceDocument = gql`
    mutation insertSubscriptionService($subscriptionId: String!, $serviceId: String!, $isAvailable: Boolean!, $isDisplayNote: Boolean!, $note: String) {
  insert_subscription_service_one(
    object: {subscriptionId: $subscriptionId, serviceId: $serviceId, isAvailable: $isAvailable, isDisplayNote: $isDisplayNote, note: $note}
  ) {
    id
    isAvailable
    serviceId
    subscriptionId
    isDisplayNote
    note
  }
}
    `;
export const UpdateSubscriptionServiceDocument = gql`
    mutation updateSubscriptionService($id: String!, $isAvailable: Boolean!, $isDisplayNote: Boolean!, $note: String) {
  update_subscription_service(
    where: {id: {_eq: $id}}
    _set: {isAvailable: $isAvailable, isDisplayNote: $isDisplayNote, note: $note}
  ) {
    affected_rows
  }
}
    `;
export const SetPublicReadAccessDocument = gql`
    mutation setPublicReadAccess($objectKey: String!, $objectKeyPrefix: String!) {
  setPublicReadAccess(ObjectKey: $objectKey, ObjectKeyPrefix: $objectKeyPrefix) {
    getUrl
  }
}
    `;
export const CancelWaitingListDocument = gql`
    mutation cancelWaitingList($bookingHash: String!, $days: [String], $serviceVarietyName: String) {
  cancelWaitingList(
    BookingHash: $bookingHash
    Days: $days
    ServiceVarietyName: $serviceVarietyName
  ) {
    code
    message
  }
}
    `;
export const GetBookingsSinceLivegangDocument = gql`
    query getBookingsSinceLivegang {
  booking(
    where: {createdAt: {_gte: "2023-06-05"}, isInternalBooking: {_eq: true}, service: {name: {_nin: ["Konnect KDV test", "Konnect BSO test"]}}}
  ) {
    id
    bookingType
    bookingStatus {
      isCompleted
      isCancelled
      isContractSigned
    }
  }
}
    `;
export const GetBookingByIdDocument = gql`
    query getBookingById($bookingId: String) {
  booking(where: {id: {_eq: $bookingId}}) {
    id
    serviceKind
    bookingStatus {
      isEmailVerified
      isContractSigned
      isMandateSigned
      isCompleted
      isCancelled
    }
    child {
      firstName
      lastName
      number
    }
    placements(where: {status: {_nin: ["Option", "Cancelled"]}}) {
      status
      isCallbackReceived
      reservations(where: {status: {_neq: "Rejected"}}) {
        id
        dayOfWeek
        serviceVariety {
          name
        }
      }
    }
    locationInquiries {
      isCallbackReceived
      waitingListPlaces {
        dayOfWeek
        status
        serviceVariety {
          name
        }
      }
    }
    analysis_id
  }
}
    `;
export const GetBookingChildsDocument = gql`
    query getBookingChilds($locationSearchOn: String, $searchOn: String, $order_by: [booking_order_by!], $limit: Int, $offset: Int) {
  booking(
    where: {_and: [{isInternalBooking: {_eq: true}}, {service: {name: {_ilike: $locationSearchOn}}}, {_or: [{child: {fullName: {_ilike: $searchOn}}}, {child: {number: {_ilike: $searchOn}}}, {service: {name: {_ilike: $searchOn}}}, {service: {serviceVarieties: {name: {_ilike: $searchOn}}}}, {bookingStatus: {status: {_ilike: $searchOn}}}]}]}
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    id
    bookingStatus {
      status
      isEmailVerified
      isContractSigned
      isMandateSigned
      isCompleted
      isCancelled
    }
    urlHash
    validFrom
    validUntil
    createdAt
    isInternalBooking
    bookingType
    child {
      id
      firstName
      lastName
      fullName
      dateOfBirth
      number
    }
    service {
      name
      serviceVarieties {
        name
      }
    }
    placements(where: {status: {_nin: ["Option", "Cancelled"]}}) {
      status
      isCallbackReceived
      reservations(where: {status: {_neq: "Rejected"}}) {
        dayOfWeek
      }
    }
    locationInquiries(where: {status: {_neq: "Placed"}}) {
      waitingListPlaces(where: {status: {_neq: "Accepted"}}) {
        dayOfWeek
        status
      }
    }
  }
  booking_aggregate(
    where: {_and: [{isInternalBooking: {_eq: true}}, {bookingType: {_neq: "WaitingList"}}, {service: {name: {_ilike: $locationSearchOn}}}, {_or: [{child: {fullName: {_ilike: $searchOn}}}, {child: {number: {_ilike: $searchOn}}}, {service: {name: {_ilike: $searchOn}}}, {service: {serviceVarieties: {name: {_ilike: $searchOn}}}}, {bookingStatus: {status: {_ilike: $searchOn}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const FetchConfigurationByKeyDocument = gql`
    query fetchConfigurationByKey($key: String!, $date: date) {
  configuration(
    where: {_and: [{key: {_eq: $key}}, {_or: [{validFrom: {_is_null: true}, validUntil: {_is_null: true}}, {validFrom: {_lte: $date}, validUntil: {_gte: $date}}, {validFrom: {_is_null: true}, validUntil: {_gte: $date}}, {validFrom: {_lte: $date}, validUntil: {_is_null: true}}]}]}
  ) {
    value
  }
}
    `;
export const GetGroupPincodesOfGroupDocument = gql`
    query getGroupPincodesOfGroup($groupId: String!, $startDate: date!, $dayOfWeek: String) {
  group_pincode(
    where: {groupId: {_eq: $groupId}, validFrom: {_gte: $startDate}, dayOfWeek: {_eq: $dayOfWeek}}
  ) {
    id
    dayOfWeek
    partOfDay
    pincode
    validFrom
    validUntil
    groupId
  }
}
    `;
export const GetGroupPincodeByIdDocument = gql`
    query getGroupPincodeById($id: String!) {
  group_pincode(where: {id: {_eq: $id}}) {
    id
    dayOfWeek
    partOfDay
    pincode
    validFrom
    validUntil
    groupId
  }
}
    `;
export const GetOfferByIdDocument = gql`
    query GetOfferById($id: String!) {
  offer_by_pk(id: $id) {
    id
    capacityLastCalculatedDate
    startTimeCapacityCalculation
  }
}
    `;
export const FetchPostalcodeInfoDocument = gql`
    query fetchPostalcodeInfo($postalCode: String!, $houseNumber: Int, $houseNumberAddition: String) {
  fetchPostalcodeInfo(
    PostalCode: $postalCode
    HouseNumber: $houseNumber
    HouseNumberAddition: $houseNumberAddition
  ) {
    isSuccess
    postalCodeInfo
  }
}
    `;
export const GetActivatedServiceProductsDocument = gql`
    query getActivatedServiceProducts($serviceId: String, $categorySearchOn: String, $serviceVarietySearchOn: String, $searchOn: String, $order_by: [service_product_order_by!], $limit: Int, $offset: Int, $referenceDate: timestamptz) {
  service_product(
    where: {_and: [{serviceId: {_eq: $serviceId}, isActive: {_eq: true}}, {product: {name: {_ilike: $searchOn}, category: {_ilike: $categorySearchOn}, serviceVariety: {_ilike: $serviceVarietySearchOn}}}]}
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    id
    serviceId
    productId
    validFrom
    product {
      id
      name
      serviceVariety
      category
      productDayInfos(where: {type: {_eq: "School"}}, limit: 1) {
        dayOfWeek
        type
        startTime
        endTime
      }
    }
    productPriceAgreement(
      where: {validFrom: {_lte: $referenceDate}, _or: [{validUntil: {_is_null: true}}, {validUntil: {_gt: $referenceDate}}]}
    ) {
      centsAmountMonday
      centsAmountTuesday
      centsAmountWednesday
      centsAmountThursday
      centsAmountFriday
      validFrom
      validUntil
    }
    isActive
    isBookable
    isUsedInChildBenefitCalculator
    textualAddition
    showTextualAddition
  }
  service_product_aggregate(
    where: {_and: [{serviceId: {_eq: $serviceId}, isActive: {_eq: true}}, {product: {name: {_ilike: $searchOn}, category: {_ilike: $categorySearchOn}, serviceVariety: {_ilike: $serviceVarietySearchOn}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetActiveBenefitCalculatorProductsDocument = gql`
    query getActiveBenefitCalculatorProducts($serviceId: String!, $referenceDate: timestamptz!) {
  service_product(
    where: {serviceId: {_eq: $serviceId}, isActive: {_eq: true}, isBookable: {_eq: true}, isUsedInChildBenefitCalculator: {_eq: true}, validFrom: {_lte: $referenceDate}}
    limit: 2
  ) {
    id
    product {
      category
      serviceVariety
    }
  }
}
    `;
export const GetDeactivatedServiceProductsDocument = gql`
    query getDeactivatedServiceProducts($serviceId: String, $categorySearchOn: String, $serviceVarietySearchOn: String, $searchOn: String, $order_by: [service_product_order_by!], $limit: Int, $offset: Int, $referenceDate: timestamptz) {
  service_product(
    where: {_and: [{serviceId: {_eq: $serviceId}, isActive: {_eq: false}}, {product: {name: {_ilike: $searchOn}, category: {_ilike: $categorySearchOn}, serviceVariety: {_ilike: $serviceVarietySearchOn}}}]}
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    id
    serviceId
    productId
    validFrom
    product {
      id
      name
      serviceVariety
      category
      productDayInfos(where: {type: {_eq: "School"}}, limit: 1) {
        dayOfWeek
        type
        startTime
        endTime
      }
    }
    productPriceAgreement(
      where: {validFrom: {_lte: $referenceDate}, _or: [{validUntil: {_is_null: true}}, {validUntil: {_gt: $referenceDate}}]}
    ) {
      centsAmountMonday
      centsAmountTuesday
      centsAmountWednesday
      centsAmountThursday
      centsAmountFriday
      validFrom
      validUntil
    }
    isActive
    isBookable
    textualAddition
    showTextualAddition
  }
  service_product_aggregate(
    where: {_and: [{serviceId: {_eq: $serviceId}, isActive: {_eq: false}}, {product: {name: {_ilike: $searchOn}, category: {_ilike: $categorySearchOn}, serviceVariety: {_ilike: $serviceVarietySearchOn}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetHasActivatedServiceProductsDocument = gql`
    query getHasActivatedServiceProducts($serviceId: String!, $referenceDate: timestamptz!) {
  service_product(
    where: {serviceId: {_eq: $serviceId}, isActive: {_eq: true}, isBookable: {_eq: true}, validFrom: {_lte: $referenceDate}, product: {serviceVariety: {_neq: "VSO"}, category: {_eq: "AllWeeks"}}}
    limit: 1
  ) {
    id
  }
}
    `;
export const GetSingleServiceProductDocument = gql`
    query getSingleServiceProduct($serviceId: String, $productId: String) {
  service_product(
    where: {_and: [{serviceId: {_eq: $serviceId}}, {productId: {_eq: $productId}}]}
  ) {
    id
    serviceId
    productId
    validFrom
    product {
      id
      name
      serviceVariety
      category
    }
    isActive
    isBookable
    isUsedInChildBenefitCalculator
    textualAddition
    showTextualAddition
  }
}
    `;
export const GetPropositionByIdDocument = gql`
    query getPropositionById($propositionId: String) {
  waiting_list_proposition(where: {id: {_eq: $propositionId}}) {
    id
    status
    proposedOn
    expiresOn
    causedByReason
    causedBy
    locationInquiry {
      isCallbackReceived
      priority
      waitingListPlaces {
        dayOfWeek
        status
        serviceVariety {
          name
        }
      }
      child {
        firstName
        lastName
        number
      }
      booking {
        id
        serviceId
        serviceKind
        analysis_id
        bookingStatus {
          isEmailVerified
          isContractSigned
          isMandateSigned
          isCompleted
          isCancelled
        }
      }
    }
    placement {
      status
      validFrom
      isCallbackReceived
      reservations(
        distinct_on: [serviceVarietyId, dayOfWeek]
        order_by: [{serviceVarietyId: asc}, {dayOfWeek: asc}, {validFrom: asc}]
      ) {
        id
        dayOfWeek
        validFrom
        status
        serviceVariety {
          name
        }
      }
    }
  }
}
    `;
export const GetPropositionChildsDocument = gql`
    query getPropositionChilds($locationSearchOn: String, $statusSearchOn: String, $searchOn: String, $order_by: [waiting_list_proposition_order_by!], $limit: Int, $offset: Int) {
  waiting_list_proposition(
    where: {_and: [{locationInquiry: {service: {name: {_ilike: $locationSearchOn}}}}, {_or: [{status: {_ilike: $statusSearchOn}}, {_and: [{status: {_eq: "Accepted"}}, {placement: {status: {_ilike: $statusSearchOn}}}]}]}, {_or: [{locationInquiry: {child: {fullName: {_ilike: $searchOn}}}}, {locationInquiry: {child: {number: {_ilike: $searchOn}}}}, {locationInquiry: {service: {name: {_ilike: $searchOn}}}}]}]}
    order_by: $order_by
    limit: $limit
    offset: $offset
  ) {
    id
    status
    status_rank
    causedBy
    createdAt
    placement {
      status
      validFrom
      isCallbackReceived
      reservations(distinct_on: dayOfWeek) {
        dayOfWeek
        status
        validFrom
      }
    }
    locationInquiry {
      waitingListPlaces(where: {status: {_neq: "Accepted"}}, distinct_on: dayOfWeek) {
        dayOfWeek
        status
      }
      waitingListPropositions {
        status
        startDate
        expiresOn
        createdAt
      }
      service {
        name
        serviceVarieties {
          name
        }
      }
      child {
        id
        firstName
        lastName
        fullName
        dateOfBirth
        number
      }
      booking {
        id
        urlHash
        validFrom
        validUntil
        serviceId
        isInternalBooking
        bookingType
        bookingStatus {
          status
          isEmailVerified
          isContractSigned
          isMandateSigned
          isCompleted
          isCancelled
        }
      }
    }
  }
  waiting_list_proposition_aggregate(
    where: {_and: [{locationInquiry: {service: {name: {_ilike: $locationSearchOn}}}}, {_or: [{status: {_ilike: $statusSearchOn}}, {_and: [{status: {_eq: "Accepted"}}, {placement: {status: {_ilike: $statusSearchOn}}}]}]}, {_or: [{locationInquiry: {child: {fullName: {_ilike: $searchOn}}}}, {locationInquiry: {child: {number: {_ilike: $searchOn}}}}, {locationInquiry: {service: {name: {_ilike: $searchOn}}}}]}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const DeleteAnalysisResultByIdDocument = gql`
    mutation DeleteAnalysisResultById($id: String!) {
  delete_analysis_result_by_pk(id: $id) {
    id
  }
}
    `;
export const GetAnalysisResultByIdDocument = gql`
    query GetAnalysisResultById($id: String!) {
  analysis_result_by_pk(id: $id) {
    result
  }
}
    `;
export const GetPropositionsDocument = gql`
    query getPropositions($latitude: Float!, $longitude: Float!, $birthDate: String!, $startDateOfCare: date!, $endDateOfCare: date!, $daysOfWeek: [Int], $selectedSchool: SchoolInput, $selectedServiceVarietyNames: [String], $serviceVarietyDaySelection: ServiceVarietyDaySelection!) {
  getPropositions(
    Latitude: $latitude
    Longitude: $longitude
    BirthDate: $birthDate
    StartDateOfCare: $startDateOfCare
    DaysOfWeek: $daysOfWeek
    SelectedServiceVarietyNames: $selectedServiceVarietyNames
    SelectedSchool: $selectedSchool
    SelectedDaysPerServiceVariety: $serviceVarietyDaySelection
  ) {
    offerId
    distance
    availability
    isPreorder
    serviceVarietyPropositions
    offer {
      name
      description
      kind
      addressLine1
      addressLine2
      locality
      postalCode
      images
      service {
        servicePricings {
          hourRate
        }
      }
      serviceVarieties {
        serviceVarietyOpenings(
          where: {_or: [{validFrom: {_lte: $endDateOfCare}, validUntil: {_gte: $startDateOfCare}}, {validFrom: {_lte: $endDateOfCare}, validUntil: {_is_null: true}}], type: {_eq: "School"}}
        ) {
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        name
      }
      serviceFeatures(order_by: {isMainFeature: desc}) {
        isMainFeature
        feature {
          description
        }
      }
    }
  }
}
    `;
export const GetPropositionsAnalysisDocument = gql`
    query getPropositionsAnalysis($latitude: Float!, $longitude: Float!, $birthDate: String!, $startDateOfCare: date!, $daysOfWeek: [Int], $selectedSchool: SchoolInput, $selectedServiceVarietyNames: [String], $serviceVarietyDaySelection: ServiceVarietyDaySelection!) {
  getPropositionsAnalysis(
    Latitude: $latitude
    Longitude: $longitude
    BirthDate: $birthDate
    StartDateOfCare: $startDateOfCare
    DaysOfWeek: $daysOfWeek
    SelectedServiceVarietyNames: $selectedServiceVarietyNames
    SelectedSchool: $selectedSchool
    SelectedDaysPerServiceVariety: $serviceVarietyDaySelection
  ) {
    id
  }
}
    `;
export const GetChildReservationsDocument = gql`
    query getChildReservations($order_by: [child_order_by!], $where: child_bool_exp, $limit: Int, $offset: Int) {
  child(order_by: $order_by, where: $where, limit: $limit, offset: $offset) {
    id
    dateOfBirth
    reservations(where: {status: {_neq: "Rejected"}}) {
      id
      dayOfWeek
      status
      group {
        service {
          name
          serviceVarieties {
            name
          }
        }
      }
    }
    reservations_aggregate(where: {status: {_neq: "Rejected"}}) {
      aggregate {
        max {
          createdAt
          validUntil
        }
        min {
          validFrom
        }
      }
    }
  }
  child_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    `;
export const GetSchoolsByServiceIdDocument = gql`
    query getSchoolsByServiceId($serviceId: String!) {
  school_service(where: {serviceId: {_eq: $serviceId}}) {
    school {
      name
      schoolGroups {
        name
        schoolGroupOpenings {
          validFrom
          validUntil
        }
      }
    }
  }
}
    `;
export const SearchSchoolByLocalityAndNameDocument = gql`
    query SearchSchoolByLocalityAndName($locationInput: String!, $nameInput: String!) {
  school(where: {locality: {_ilike: $locationInput}, name: {_ilike: $nameInput}}) {
    id
    name
    addressLine1
    addressLine2
    postalCode
    locality
  }
}
    `;
export const GetSchoolOfferCountBySchoolIdDocument = gql`
    query getSchoolOfferCountBySchoolId($schoolId: String!) {
  school_service_aggregate(where: {schoolId: {_eq: $schoolId}}) {
    aggregate {
      count
    }
  }
}
    `;
export const CanEnableWaitingListDocument = gql`
    query canEnableWaitingList($id: String!) {
  canEnableWaitingList(ServiceId: $id) {
    result
    errors
  }
}
    `;
export const ExportServiceSettingsDocument = gql`
    query ExportServiceSettings {
  exportServiceSettings {
    base64CsvServiceExport
  }
}
    `;
export const GetActiveSubscriptionsByServiceIdDocument = gql`
    query getActiveSubscriptionsByServiceId($serviceId: String!) {
  subscription_service(
    where: {serviceId: {_eq: $serviceId}, isAvailable: {_eq: true}}
  ) {
    id
  }
}
    `;
export const GetControlVariablesByServiceIdDocument = gql`
    query GetControlVariablesByServiceId($id: String!) {
  service_by_pk(id: $id) {
    id
    name
    description
    kind
    contactInfo
    flexMargin
    isPublished
    isPublishedForChildBenefitCalculator
    isBookable
    validateWaitingListPropositions
    isCustomSubscriptionEnabled
    serviceProvider {
      id
      name
    }
    location {
      id
      addressLine1
      addressLine2
      postalCode
      locality
    }
    servicePricings {
      id
      validFrom
      validUntil
      hourRate
      serviceId
    }
    serviceVarieties {
      id
      name
      serviceVarietyOpenings(where: {type: {_eq: "School"}}) {
        id
        validFrom
        validUntil
        serviceVarietyId
        type
        isManuallySet
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
}
    `;
export const GetDashboardDataByServiceIdDocument = gql`
    query getDashboardDataByServiceId($serviceId: String!, $mondayDate: date!, $tuesdayDate: date!, $wednesdayDate: date!, $thursdayDate: date!, $fridayDate: date!, $saturdayDate: date!, $sundayDate: date!) {
  service_by_pk(id: $serviceId) {
    id
    kind
    flexMargin
    groups {
      id
      name
      groupPincodes(
        where: {_or: [{validFrom: {_lte: $sundayDate}, validUntil: {_is_null: true}}, {validFrom: {_lte: $sundayDate}, validUntil: {_gt: $mondayDate}}]}
      ) {
        id
        dayOfWeek
        partOfDay
        pincode
        validFrom
        validUntil
        groupId
      }
      reservations(
        where: {_or: [{_or: [{dayOfWeek: {_eq: "Monday"}, validFrom: {_lte: $mondayDate}, validUntil: {_gt: $mondayDate}}, {dayOfWeek: {_eq: "Monday"}, validFrom: {_lte: $mondayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Tuesday"}, validFrom: {_lte: $tuesdayDate}, validUntil: {_gt: $tuesdayDate}}, {dayOfWeek: {_eq: "Tuesday"}, validFrom: {_lte: $tuesdayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Wednesday"}, validFrom: {_lte: $wednesdayDate}, validUntil: {_gt: $wednesdayDate}}, {dayOfWeek: {_eq: "Wednesday"}, validFrom: {_lte: $wednesdayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Thursday"}, validFrom: {_lte: $thursdayDate}, validUntil: {_gt: $thursdayDate}}, {dayOfWeek: {_eq: "Thursday"}, validFrom: {_lte: $thursdayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Friday"}, validFrom: {_lte: $fridayDate}, validUntil: {_gt: $fridayDate}}, {dayOfWeek: {_eq: "Friday"}, validFrom: {_lte: $fridayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Saturday"}, validFrom: {_lte: $saturdayDate}, validUntil: {_gt: $saturdayDate}}, {dayOfWeek: {_eq: "Saturday"}, validFrom: {_lte: $saturdayDate}, validUntil: {_is_null: true}}]}, {_or: [{dayOfWeek: {_eq: "Sunday"}, validFrom: {_lte: $sundayDate}, validUntil: {_gt: $sundayDate}}, {dayOfWeek: {_eq: "Sunday"}, validFrom: {_lte: $sundayDate}, validUntil: {_is_null: true}}]}], _and: {status: {_neq: "Rejected"}, type: {_neq: "FlexWithoutHours"}}}
        distinct_on: [dayOfWeek, partOfDay, childId, weekInterval, status, type]
        order_by: {dayOfWeek: asc, partOfDay: asc, childId: asc, weekInterval: asc, status: asc, type: desc}
      ) {
        dayOfWeek
        partOfDay
        status
        weekInterval
        type
        child {
          id
          firstName
          lastName
          number
          dateOfBirth
        }
      }
      groupCapacities(
        where: {_or: [{validFrom: {_lte: $sundayDate}, validUntil: {_is_null: true}}, {validFrom: {_lte: $sundayDate}, validUntil: {_gte: $mondayDate}}]}
      ) {
        id
        mondayPlanCapacity
        tuesdayPlanCapacity
        wednesdayPlanCapacity
        thursdayPlanCapacity
        fridayPlanCapacity
        strategy
        validFrom
        validUntil
      }
    }
  }
}
    `;
export const GetServicesDocument = gql`
    query getServices {
  service {
    id
    name
  }
}
    `;
export const GetServiceByIdDocument = gql`
    query GetServiceById($id: String!) {
  service_by_pk(id: $id) {
    id
    name
    description
    kind
    isPublished
    isPublishedForChildBenefitCalculator
    validateWaitingListPropositions
    isBookable
    waitingListFrozenSince
    contactInfo
    serviceProviderId
    locationId
    permitNumber
    location {
      id
      addressLine1
      addressLine2
      postalCode
      locality
    }
    serviceProvider {
      id
      name
    }
    servicePricings {
      id
      hourRate
      validFrom
      validUntil
      serviceId
    }
    serviceMedia {
      id
      filename
      url
      mimeType
    }
    serviceVarieties {
      id
      name
      serviceVarietyOpenings(where: {type: {_eq: "School"}}) {
        id
        validFrom
        validUntil
        serviceVarietyId
        type
        isManuallySet
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
  }
}
    `;
export const HasCreatedWaitingListPropositionsDocument = gql`
    query HasCreatedWaitingListPropositions($id: String!) {
  service_aggregate(
    where: {id: {_eq: $id}, location_inquiries: {waitingListPropositions: {status: {_eq: "Created"}}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetFeaturesDocument = gql`
    query getFeatures($searchOn: String, $limit: Int, $offset: Int, $order_by: [feature_order_by!]) {
  feature(
    where: {_or: [{description: {_ilike: $searchOn}}, {persona_features: {persona: {name: {_ilike: $searchOn}}}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    id
    description
    isDefaultFeature
    persona_features {
      persona {
        name
        id
      }
    }
  }
  feature_aggregate(
    where: {_or: [{description: {_ilike: $searchOn}}, {persona_features: {persona: {name: {_ilike: $searchOn}}}}]}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetServiceFeaturesByServiceIdDocument = gql`
    query getServiceFeaturesByServiceId($serviceId: String!) {
  service_feature(where: {serviceId: {_eq: $serviceId}}) {
    id
    isMainFeature
    featureId
    feature {
      id
      description
      persona_features {
        feature {
          description
          id
        }
      }
    }
  }
}
    `;
export const GetServiceFeatureByIdDocument = gql`
    query getServiceFeatureById($serviceId: String!, $featureId: String!) {
  service_feature(
    where: {featureId: {_eq: $featureId}, serviceId: {_eq: $serviceId}}
  ) {
    id
    featureId
    serviceId
    isMainFeature
  }
}
    `;
export const GetServiceSettingsByServiceIdDocument = gql`
    query GetServiceSettingsByServiceId($serviceId: String!) {
  service_settings(where: {service: {id: {_eq: $serviceId}}}) {
    planningHorizonInMonths
    sendRemainOnWaitingListMailInInMinutes
    sendRemainOnWaitingListReminderMailInInMinutes
    inquiryExpiresInInMinutes
    useFlexkidsProducts
    showLinkToPartouOffers
    openingTimesNote
  }
}
    `;
export const GetServiceSettingsForServiceDetailPageByServiceIdDocument = gql`
    query getServiceSettingsForServiceDetailPageByServiceId($serviceId: String!) {
  service_settings(where: {service: {id: {_eq: $serviceId}}}) {
    useFlexkidsProducts
    openingTimesNote
  }
}
    `;
export const SetOpeningTimesNoteByServiceIdDocument = gql`
    mutation setOpeningTimesNoteByServiceId($serviceId: String!, $note: String!) {
  update_service_settings(
    where: {serviceId: {_eq: $serviceId}}
    _set: {openingTimesNote: $note}
  ) {
    returning {
      id
      serviceId
      openingTimesNote
    }
  }
}
    `;
export const SetPlanningHorizonByServiceIdDocument = gql`
    mutation setPlanningHorizonByServiceId($serviceId: String!, $planningHorizonInMonths: Int!) {
  update_service_settings(
    where: {serviceId: {_eq: $serviceId}}
    _set: {planningHorizonInMonths: $planningHorizonInMonths}
  ) {
    returning {
      id
      serviceId
      planningHorizonInMonths
    }
  }
}
    `;
export const SetUseFlexkidsProductsByServiceIdDocument = gql`
    mutation setUseFlexkidsProductsByServiceId($serviceId: String!, $useFlexkidsProducts: Boolean!) {
  update_service_settings(
    where: {serviceId: {_eq: $serviceId}}
    _set: {useFlexkidsProducts: $useFlexkidsProducts}
  ) {
    returning {
      id
      serviceId
      useFlexkidsProducts
    }
  }
}
    `;
export const GetDefaultSubscriptionsDocument = gql`
    query getDefaultSubscriptions {
  subscription {
    id
    name
    description
    features
    numberOfWeeks
    order
    reservationType
    surchargePercentageKdv
    surchargePercentageVso
    surchargePercentageNso
  }
}
    `;
export const GetSubscriptionsWithPricingsByServiceIdDocument = gql`
    query getSubscriptionsWithPricingsByServiceId($serviceId: String!) {
  subscription_service(where: {serviceId: {_eq: $serviceId}}) {
    id
    isAvailable
    isDisplayNote
    note
    subscriptionId
    subscription {
      id
      name
      description
      features
      numberOfWeeks
      order
      reservationType
      surchargePercentageKdv
      surchargePercentageVso
      surchargePercentageNso
    }
    service {
      servicePricings {
        id
        serviceId
        hourRate
        validFrom
        validUntil
      }
    }
  }
}
    `;
export const GetSignedDeleteUrlDocument = gql`
    query getSignedDeleteUrl($objectKey: String!, $objectKeyPrefix: String!) {
  getSignedDeleteUrl(ObjectKey: $objectKey, ObjectKeyPrefix: $objectKeyPrefix) {
    deleteUrl
  }
}
    `;
export const GetSignedUploadUrlDocument = gql`
    query getSignedUploadUrl($objectKey: String!, $objectKeyPrefix: String!) {
  getSignedUploadUrl(ObjectKey: $objectKey, ObjectKeyPrefix: $objectKeyPrefix) {
    uploadUrl
  }
}
    `;
export const CancelCreatedWaitingListPropositionDocument = gql`
    mutation CancelCreatedWaitingListProposition($waitingListPropositionId: String!, $causedByReason: String!) {
  cancelCreatedWaitingListProposition(
    waitingListPropositionId: $waitingListPropositionId
    causedByReason: $causedByReason
  ) {
    code
    message
  }
}
    `;
export const ProposeCreatedWaitingListPropositionDocument = gql`
    mutation ProposeCreatedWaitingListProposition($waitingListPropositionId: String!) {
  proposeCreatedWaitingListProposition(
    waitingListPropositionId: $waitingListPropositionId
  ) {
    code
    message
  }
}
    `;
export const RevokeProposedWaitingListPropositionDocument = gql`
    mutation RevokeProposedWaitingListProposition($waitingListPropositionId: String!, $causedByReason: String!) {
  revokeProposedWaitingListProposition(
    waitingListPropositionId: $waitingListPropositionId
    causedByReason: $causedByReason
  ) {
    code
    message
  }
}
    `;
export const GetLocationInquiryByIdDocument = gql`
    query getLocationInquiryById($id: String!) {
  location_inquiry_by_pk(id: $id) {
    id
    waitingListPlaces {
      dayOfWeek
      status
      serviceVariety {
        id
        name
      }
    }
    booking {
      placements {
        reservations(where: {status: {_neq: "Rejected"}}) {
          dayOfWeek
          status
          serviceVariety {
            name
          }
        }
      }
    }
    subscriptionService {
      subscription {
        name
      }
    }
    child {
      id
      number
    }
  }
}
    `;
export const GetWaitingListPlacesDocument = gql`
    query getWaitingListPlaces($serviceId: String, $selectedDay: String, $order_by: [location_inquiry_order_by!], $limit: Int, $offset: Int) {
  location_inquiry(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {serviceId: {_eq: $serviceId}, status: {_eq: "Active"}, waitingListPlaces: {dayOfWeek: {_eq: $selectedDay}, status: {_eq: "Waiting"}}, booking: {_or: [{bookingType: {_eq: "WaitingList"}, bookingStatus: {isEmailVerified: {_eq: true}}}, {bookingType: {_eq: "Mixed"}, bookingStatus: {isContractSigned: {_eq: true}}}]}}
  ) {
    id
    priority
    validFrom
    createdAt
    status
    waitingListPlaces {
      id
      dayOfWeek
      status
      serviceVariety {
        name
      }
    }
    child {
      firstName
      lastName
      dateOfBirth
    }
    subscriptionService {
      subscription {
        name
      }
    }
  }
  location_inquiry_aggregate(
    where: {serviceId: {_eq: $serviceId}, status: {_eq: "Active"}, waitingListPlaces: {dayOfWeek: {_eq: $selectedDay}, status: {_eq: "Waiting"}}, booking: {_or: [{bookingType: {_eq: "WaitingList"}, bookingStatus: {isEmailVerified: {_eq: true}}}, {bookingType: {_eq: "Mixed"}, bookingStatus: {isContractSigned: {_eq: true}}}]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export const GetWaitingListAnalysisResultsDocument = gql`
    query getWaitingListAnalysisResults($serviceId: String!, $fromDate: timestamptz, $untilDate: timestamptz, $didAllocateWaitingListSpots: Boolean) {
  waiting_list_analysis_result(
    where: {_and: [{serviceId: {_eq: $serviceId}}, {calculateDate: {_gte: $fromDate}}, {calculateDate: {_lte: $untilDate}}, {didAllocateWaitingListSpots: {_eq: $didAllocateWaitingListSpots}}]}
    order_by: {calculateDate: desc}
  ) {
    id
    serviceId
    calculateDate
    didAllocateWaitingListSpots
    waitingListAllocation
  }
}
    `;
export const GetWaitingListPropositionAnalysisResultsDocument = gql`
    query getWaitingListPropositionAnalysisResults($serviceId: String!, $fromDate: timestamptz, $untilDate: timestamptz, $didAllocateWaitingListSpots: Boolean) {
  waiting_list_proposition_analysis_result(
    where: {_and: [{serviceId: {_eq: $serviceId}}, {calculationDate: {_gte: $fromDate}}, {calculationDate: {_lte: $untilDate}}, {didAllocateWaitingListSpots: {_eq: $didAllocateWaitingListSpots}}]}
    order_by: {calculationDate: desc}
  ) {
    id
    serviceId
    calculationDate
    didAllocateWaitingListSpots
    data
  }
}
    `;
export const Operations = {
  Query: {
    GetNonManualServiceVarietyPricingByServiceVarietyId: 'GetNonManualServiceVarietyPricingByServiceVarietyId',
    getServicePricingByServiceId: 'getServicePricingByServiceId',
    getBookingsSinceLivegang: 'getBookingsSinceLivegang',
    getBookingById: 'getBookingById',
    getBookingChilds: 'getBookingChilds',
    fetchConfigurationByKey: 'fetchConfigurationByKey',
    getGroupPincodesOfGroup: 'getGroupPincodesOfGroup',
    getGroupPincodeById: 'getGroupPincodeById',
    GetOfferById: 'GetOfferById',
    fetchPostalcodeInfo: 'fetchPostalcodeInfo',
    getActivatedServiceProducts: 'getActivatedServiceProducts',
    getActiveBenefitCalculatorProducts: 'getActiveBenefitCalculatorProducts',
    getDeactivatedServiceProducts: 'getDeactivatedServiceProducts',
    getHasActivatedServiceProducts: 'getHasActivatedServiceProducts',
    getSingleServiceProduct: 'getSingleServiceProduct',
    getPropositionById: 'getPropositionById',
    getPropositionChilds: 'getPropositionChilds',
    GetAnalysisResultById: 'GetAnalysisResultById',
    getPropositions: 'getPropositions',
    getPropositionsAnalysis: 'getPropositionsAnalysis',
    getChildReservations: 'getChildReservations',
    getSchoolsByServiceId: 'getSchoolsByServiceId',
    SearchSchoolByLocalityAndName: 'SearchSchoolByLocalityAndName',
    getSchoolOfferCountBySchoolId: 'getSchoolOfferCountBySchoolId',
    canEnableWaitingList: 'canEnableWaitingList',
    ExportServiceSettings: 'ExportServiceSettings',
    getActiveSubscriptionsByServiceId: 'getActiveSubscriptionsByServiceId',
    GetControlVariablesByServiceId: 'GetControlVariablesByServiceId',
    getDashboardDataByServiceId: 'getDashboardDataByServiceId',
    getServices: 'getServices',
    GetServiceById: 'GetServiceById',
    HasCreatedWaitingListPropositions: 'HasCreatedWaitingListPropositions',
    getFeatures: 'getFeatures',
    getServiceFeaturesByServiceId: 'getServiceFeaturesByServiceId',
    getServiceFeatureById: 'getServiceFeatureById',
    GetServiceSettingsByServiceId: 'GetServiceSettingsByServiceId',
    getServiceSettingsForServiceDetailPageByServiceId: 'getServiceSettingsForServiceDetailPageByServiceId',
    getDefaultSubscriptions: 'getDefaultSubscriptions',
    getSubscriptionsWithPricingsByServiceId: 'getSubscriptionsWithPricingsByServiceId',
    getSignedDeleteUrl: 'getSignedDeleteUrl',
    getSignedUploadUrl: 'getSignedUploadUrl',
    getLocationInquiryById: 'getLocationInquiryById',
    getWaitingListPlaces: 'getWaitingListPlaces',
    getWaitingListAnalysisResults: 'getWaitingListAnalysisResults',
    getWaitingListPropositionAnalysisResults: 'getWaitingListPropositionAnalysisResults'
  },
  Mutation: {
    setFlexMarginByServiceId: 'setFlexMarginByServiceId',
    updateService: 'updateService',
    disableWaitingListAutomation: 'disableWaitingListAutomation',
    enableWaitingListAutomation: 'enableWaitingListAutomation',
    updateServiceIsPublished: 'updateServiceIsPublished',
    updateServiceToggleCustomSubscription: 'updateServiceToggleCustomSubscription',
    updateServiceValidateWaitingListPropositions: 'updateServiceValidateWaitingListPropositions',
    sendEmails: 'sendEmails',
    insertAndOverwriteGroupPinCode: 'insertAndOverwriteGroupPinCode',
    updateGroupPincode: 'updateGroupPincode',
    calculateCapacityForOffer: 'calculateCapacityForOffer',
    deleteServiceFeature: 'deleteServiceFeature',
    insertServiceFeature: 'insertServiceFeature',
    updateServiceFeature: 'updateServiceFeature',
    DeleteServiceMediaById: 'DeleteServiceMediaById',
    InsertServiceMedia: 'InsertServiceMedia',
    UpdateServiceMedia: 'UpdateServiceMedia',
    DeleteServicePricingById: 'DeleteServicePricingById',
    insertServicePricing: 'insertServicePricing',
    updateServicePricing: 'updateServicePricing',
    ActivateServiceProducts: 'ActivateServiceProducts',
    ActivateServiceProductIsUsedInChildBenefitCalculator: 'ActivateServiceProductIsUsedInChildBenefitCalculator',
    DeactivateServiceProductIsUsedInChildBenefitCalculator: 'DeactivateServiceProductIsUsedInChildBenefitCalculator',
    DeactivateServiceProduct: 'DeactivateServiceProduct',
    UpdateServiceProductShowTextualAddition: 'UpdateServiceProductShowTextualAddition',
    UpdateServiceProductTextualAddition: 'UpdateServiceProductTextualAddition',
    setShowLinkToPartouOffers: 'setShowLinkToPartouOffers',
    setWaitingListActualisationIntervals: 'setWaitingListActualisationIntervals',
    DeleteServiceVarietyOpeningById: 'DeleteServiceVarietyOpeningById',
    insertServiceVarietyOpening: 'insertServiceVarietyOpening',
    updateServiceVarietyOpening: 'updateServiceVarietyOpening',
    updateServiceVarietyOpeningDates: 'updateServiceVarietyOpeningDates',
    insertSubscriptionService: 'insertSubscriptionService',
    updateSubscriptionService: 'updateSubscriptionService',
    setPublicReadAccess: 'setPublicReadAccess',
    cancelWaitingList: 'cancelWaitingList',
    DeleteAnalysisResultById: 'DeleteAnalysisResultById',
    setOpeningTimesNoteByServiceId: 'setOpeningTimesNoteByServiceId',
    setPlanningHorizonByServiceId: 'setPlanningHorizonByServiceId',
    setUseFlexkidsProductsByServiceId: 'setUseFlexkidsProductsByServiceId',
    CancelCreatedWaitingListProposition: 'CancelCreatedWaitingListProposition',
    ProposeCreatedWaitingListProposition: 'ProposeCreatedWaitingListProposition',
    RevokeProposedWaitingListProposition: 'RevokeProposedWaitingListProposition'
  }
}