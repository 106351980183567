<template>
  <div class="d-flex flex-row mt-4 mb-4">
    <div class="mt-n13 mb-5 child-data">
      <div class="d-flex flex-column pb-2">
        <p class="data-labels">{{ $t('registrations.table.columns.childName') }}</p>
        <strong class="font-large">{{ childFirstName }}</strong>
      </div>
      <div class="d-flex flex-column">
        <p class="data-labels">{{ $t('registrations.table.columns.lastName') }}</p>
        <strong class="font-large">{{ childLastName }}</strong>
      </div>
      <div class="d-flex flex-column pb-2">
        <p class="data-labels">{{ $t('registrations.table.columns.childNumber') }}</p>
        <strong class="font-large">{{ childNumber || $t('registrations.table.childNumberUnavailable') }}</strong>
      </div>
    </div>
    <div class="date-of-birth-data"></div>
    <div class="valid-from-data"></div>
    <div class="valid-until-data"></div>
    <div class="location-data"></div>
    <div class="days-data">
      <template v-if="booking">
        <template v-if="booking.serviceKind == ServiceKind.SchoolCare">
          <div class="d-flex flex-row column-gap-4" >
            <DayTable class="mr-4" :serviceVarietyName="ServiceVarietyName.VSO" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces" />
            <DayTable :serviceVarietyName="ServiceVarietyName.NSO" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces" />
          </div>
        </template>
        <template v-else>
          <DayTable :serviceVarietyName="ServiceVarietyName.KDV" :reservations="reservations" :waitinglistPlaces="waitinglistPlaces" />
        </template>
    </template>
    </div>
    <div class="status-data d-flex flex-column justify-center">
      <div>
        <p class="data-labels mb-3">{{$t('registrations.table.status.bookingStatus')}}</p>
        <p class="font-large"> <strong>{{getBookingStatusTranslation(booking)}}</strong> </p>
      </div>
      <div>
        <p class="data-labels mb-3">{{$t('registrations.table.status.sync.syncStatus')}}</p>
        <p class="font-large"> <strong>{{getSyncStatusTranslation(booking)}}</strong> </p>
      </div>
    </div>
    <div class="expand-data" >
      <PartouFloatingCircleButton v-if="showAnalysisResultButton()" ref="analysis-button" class="analysis-button" iconName="$vuetify.icons.partouAnalysis" @click="onGoToAnalysisResultPageClick" />
    </div>
  </div>
</template>

<script lang="ts">
import { Booking, ServiceVarietyName, ServiceKind, PlacementStatus, WaitingListPlace, Reservation } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import DayTable from '@/components/DayTable/DayTable.vue'
import { ROUTES } from '@/router/routes'
import Permission from '@/models/enums/Permission'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'

@Component({
  components: { DaysCoverage, PartouStepper, DayTable, PartouFloatingCircleButton }
})
export default class RegistrationTableExpanded extends Vue {
  @Prop({ required: true })
  booking!: Booking

  @Prop({ required: true })
  waitinglistPlaces! : WaitingListPlace[]

  @Prop({ required: true })
  reservations! : Reservation[]

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind

  get childFirstName () {
    return this.booking.child?.firstName
  }

  get childLastName () {
    return this.booking.child?.lastName
  }

  get childNumber () {
    return this.booking.child?.number
  }

  getSyncStatusTranslation (booking: Booking) : string {
    const requiresWaitingListCallback = booking.locationInquiries.length > 0
    const receivedWaitingListCallback = booking.locationInquiries.every(x => x.isCallbackReceived)

    const activePlacements = booking.placements?.filter(x => x.status !== PlacementStatus.Cancelled)
    const requiresPlacementCallback = !!activePlacements && activePlacements.length > 0
    const receivedAllPlacementCallbacks = activePlacements.every(x => x.isCallbackReceived)

    const isBookingMissingCallback = (requiresWaitingListCallback && !receivedWaitingListCallback) ||
                                (requiresPlacementCallback && !receivedAllPlacementCallbacks)

    if (isBookingMissingCallback) {
      return this.$t('registrations.table.status.sync.notSynced').toString()
    }

    return this.$t('registrations.table.status.sync.synced').toString()
  }

  getBookingStatusTranslation (booking: Booking) : string {
    if (booking.bookingStatus?.isCancelled) {
      return this.$t('registrations.table.status.cancelled').toString()
    }

    if (booking.bookingStatus?.isCompleted) {
      return this.$t('registrations.table.status.completed').toString()
    }

    if (booking.bookingStatus?.isMandateSigned) {
      return this.$t('registrations.table.status.mandate').toString()
    }

    if (booking.bookingStatus?.isContractSigned) {
      return this.$t('registrations.table.status.contract').toString()
    }

    if (booking.bookingStatus?.isEmailVerified) {
      return this.$t('registrations.table.status.email').toString()
    }

    return this.$t('registrations.table.status.reserved').toString()
  }

  onGoToAnalysisResultPageClick () : void {
    if (this.booking.analysis_id == null) {
      throw new Error(`Booking with id ${this.booking.id} contains no analysis_id`)
    }

    const routeData = this.$router.resolve({ name: ROUTES.serviceAnalysisPreview, params: { analysisResultId: this.booking.analysis_id } })
    window.open(routeData.href, '_blank')
  }

  showAnalysisResultButton () : boolean {
    return this.booking.analysis_id != null && this.authService.hasPermission([Permission.GetAnalysis])
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.font-large {
  font-size: 16px;
}
.child-data {
  width: 14%;
  gap: 24px;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.data-labels {
    font-size: 13px;
    color: $partou-primary-black-fifty;
    margin-bottom: 0px;
  }
.date-of-birth-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 12%;
}
.valid-from-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 11.5%;
}

.valid-until-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 11.5%;
}
.days-data {
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 34.5%;
}
.status-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 11.50%;
  row-gap: 32px
}
.expand-data {
  width: 5%;
  align-self: flex-end;
}

.analysis-button {
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  button span svg {
    path {
      fill: $partou-primary-black-ninety !important;
      color: $partou-primary-black-ninety !important;
    }
  }
}
</style>
