<template>
  <div>
    <PartouDataTable
      :headers="headers"
      :items="tableItems"
      :items-per-page="itemsPerPage"
      :itemsPerPageOptions="itemsPerPageOptions"
      disable-search
    >
      <template v-slot:[`item.impact`]="{ item }">
        <span :class="item.value < item.impact ? 'impact-too-high' : '' ">
          {{item.impact}}
        </span>
      </template>

      <template v-slot:[`item.available`]="{ item }">
        <v-icon>{{ item.available ? "$vuetify.icons.partouAvailable" : "$vuetify.icons.partouNotAvailable" }}</v-icon>
      </template>
    </PartouDataTable>
  </div>
</template>

<script lang="ts">

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import { Proposition } from '@/models'
import _ from 'lodash'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable/PartouDataTable.vue'

export interface ILowestImpactPathTableRow {
  day?: string
  age?: number
  impact: number
  value: number
  group?: string
  available: boolean
}

@Component({
  components: { PartouCard, DateInputField, DaySelector, PartouDataTable }
})
export default class LowestImpactPathTable extends Vue {
  @Prop({ required: true })
  proposition!: Proposition

  @Prop({ required: true })
  serviceVarietyName!: string

  headers = [
    { text: 'analysis.table.day', value: 'day', sortable: false },
    { text: 'analysis.table.age', value: 'age', sortable: false },
    { text: 'analysis.table.value', value: 'value', sortable: false },
    { text: 'analysis.table.impact', value: 'impact', sortable: false },
    { text: 'analysis.table.group', value: 'group', sortable: false },
    { text: 'analysis.table.available', value: 'available', sortable: false }
  ]

  tableItems: ILowestImpactPathTableRow[] = []
  nrOfAvailableDays = 5
  itemsPerPage = 0

  get itemsPerPageOptions () : number[] {
    const options : number[] = []
    for (let i = 1; i <= 5; i++) { // 5 because we'll show a maximum of 5 days
      const newOption = this.itemsPerPage * i
      options.push(newOption)
      if (newOption >= this.tableItems.length) {
        break
      }
    }

    options.push(-1)
    return options
  }

  @Watch('proposition.analysis', { immediate: true })
  updateTableRows () : void {
    const sortedFilteredList = this.proposition.analysis?.filter(x => x.chosenForLowestImpactPath && x.serviceVarietyName === this.serviceVarietyName).sort((a, b) => a.age - b.age)
    const lowestImpactPath = _.groupBy(sortedFilteredList, x => x.dayOfWeek)
    const rows = [] as ILowestImpactPathTableRow[]

    for (const day in lowestImpactPath) {
      const values = lowestImpactPath[day]
      const totalValue = values.map(x => x.valueOfDay).reduce((a, b) => a + b, 0)
      const totalImpact = values.map(x => x.impactOfDay).reduce((a, b) => a + b, 0)
      const allAvailable = values.every(x => x.availableBasedOnCapacity)

      rows.push({
        day: this.$t(`days.${day.toLowerCase()}`).toString(),
        value: totalValue,
        impact: totalImpact,
        available: allAvailable
      })

      values.forEach(value => {
        rows.push({
          age: value.age,
          value: value.valueOfDay,
          impact: value.impactOfDay,
          group: value.groupName,
          available: value.availableBasedOnCapacity
        })
      })
    }

    this.tableItems = rows

    const nrOfAvailableDays = Object.keys(lowestImpactPath).length
    this.itemsPerPage = this.tableItems.length / nrOfAvailableDays
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

.impact-too-high {
  color:$partou-primary-salmon;
  font-weight: bold;
}
</style>
