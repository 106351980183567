import BaseModel from './_base/BaseModel'

import Proposition from './Proposition'
import Timespan from './Timespan'
import GroupPincode from './GroupPincode'
import PincodeItem from './PincodeItem'
import FlexMargin from './FlexMargin'
import WaitingListAnalysisResult from './WaitingListAnalysisResult'

export * from './generated/graphql' // eslint-disable-line
export * from './enums'

export { BaseModel, Proposition, Timespan, GroupPincode, FlexMargin, PincodeItem, WaitingListAnalysisResult } // eslint-disable-line
