import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IPropositionService } from './IPropositionService'
import { QueryRoot, Proposition, GetPropositionsAnalysisDocument, GetPropositionsAnalysisQueryVariables, GetAnalysisResultByIdDocument, DeleteAnalysisResultByIdMutation, DeleteAnalysisResultByIdDocument, GetAnalysisResultByIdQueryVariables, GetPropositionChildsDocument, GetPropositionChildsQuery, GetPropositionChildsQueryVariables, WaitingListProposition, GetPropositionByIdDocument, GetPropositionByIdQuery } from '@/models'
import Permission from '@/models/enums/Permission'
import WaitingListPropositionTableOptions from '@/pages/WaitingListPropositionPage/WaitingListPropositionTable/WaitingListPropositionTableOptions'
import _ from 'lodash'

@injectable()
export default class PropositionService implements IPropositionService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  public async getPropositionsAnalysisAsync (variables: GetPropositionsAnalysisQueryVariables): Promise<Proposition[]> {
    const propositionAnalysisResult = await this.apollo.query<QueryRoot>({
      query: GetPropositionsAnalysisDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })

    if (!propositionAnalysisResult?.data?.getPropositionsAnalysis) {
      return []
    }

    const id = propositionAnalysisResult.data.getPropositionsAnalysis.id

    const analysisResultResult = await this.getPropositionAnalysisResultAsync({ id })
    if(!analysisResultResult.length) { // eslint-disable-line
      return []
    }

    this.apollo.mutate<DeleteAnalysisResultByIdMutation>({
      mutation: DeleteAnalysisResultByIdDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })

    return analysisResultResult
  }

  public async getPropositionAnalysisResultAsync (variables : GetAnalysisResultByIdQueryVariables) : Promise<Proposition[]> {
    const analysisResultResult = await this.apollo.query<QueryRoot>({
      query: GetAnalysisResultByIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })

    if(!analysisResultResult?.data?.analysis_result_by_pk?.result) { // eslint-disable-line
      return []
    }

    return analysisResultResult.data.analysis_result_by_pk.result
  }

  async getWaitingListPropositionsPageAsync (options: WaitingListPropositionTableOptions): Promise<{ items: WaitingListProposition[]; totalCount: number }> {
    const variables : GetPropositionChildsQueryVariables = {
      locationSearchOn: options.filters.service ? `%${options.filters.service.name}%` : null,
      statusSearchOn: options.filters.propositionStatus ? `%${options.filters.propositionStatus}%` : null,
      searchOn: (options.filters.global && options.filters.global.length > 0) ? `%${options.filters.global}%` : null,
      limit: options.itemsPerPage,
      offset: options.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }

    const result = await this.apollo.query<GetPropositionChildsQuery>({ // eslint-disable-line @typescript-eslint/no-non-null-assertion
      query: GetPropositionChildsDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })

    const items: any[] = result?.data?.waiting_list_proposition // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result?.data?.waiting_list_proposition_aggregate?.aggregate?.count ?? 0 // eslint-disable-line

    return { items, totalCount }
  }

  async getOnePropositionAsync (id: string): Promise<Partial<WaitingListProposition>> {
    const variables = {
      propositionId: id
    }

    const result = await this.apollo.query<GetPropositionByIdQuery>({
      query: GetPropositionByIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })

    return result.data.waiting_list_proposition[0] as Partial<WaitingListProposition>
  }
}
