<template>
  <div class="subscription-activator-container">
    <div class="description-container">
      <div>
        <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.subscriptions.subscriptions')}}</h2>
        <p v-html="$t('services.controlVariables.subscriptions.subscriptionsDescription')" />
      </div>
    </div>
    <div class="controls-container" v-if="selectableSubscriptions">
      <template v-for="(selectableSubscription, index) in sortedSelectableSubscriptions">
        <div :key="selectableSubscription.subscription.id">
            <div class="d-flex flex-column fill-width">
              <h2>{{ selectableSubscription.subscription.name }}</h2>
              <div class="d-flex flex-row">
                <v-switch class="switch mt-0 mb-0" v-model="selectableSubscription.subscriptionService.isAvailable" @click="onSubscriptionChanged(index)" :disabled="subscriptionSwitchIsDisabled()"/>
                <div class="d-flex flex-row mt-1">
                  <p class='d-flex justify-center'>{{`${selectableSubscription.subscription.name} (${selectableSubscription.subscription.description})`}}</p>
                  <p><strong class="ml-1 d-flex justify-center">{{getPricing(selectableSubscription.subscription) | euro }}</strong> </p>
                  <p class="ml-1 d-flex justify-center">{{ getPricingValidUntil() }}</p>
                </div>
              </div>

              <div class="d-flex flex-row mt-n3 ml-8">
                <v-switch class="my-auto" v-model="selectableSubscription.subscriptionService.isDisplayNote" @click="onSubscriptionChanged(index)" :disabled="!editEnabled"/>
                <p class="my-auto pb-5">{{ $t('services.controlVariables.subscriptions.note')}}</p>
              </div>
              <v-textarea v-if="selectableSubscription.subscriptionService.isDisplayNote" v-model="selectableSubscription.subscriptionService.note" :disabled="!editEnabled"
                :placeholder="$t('services.controlVariables.subscriptions.notePlaceholder')" class="ml-16" outlined @change="onSubscriptionChanged(index)" />
            </div>
        </div>
      </template>
      <div class="d-flex flex-column fill-width">
        <h2>{{$t('services.controlVariables.subscriptions.customSubscriptionToggle')}}</h2>
        <div class="d-flex flex-row">
          <v-switch class="switch mt-0 mb-0" v-model="service.isCustomSubscriptionEnabled" :disabled="customSubscriptionSwitchIsDisabled()"/>
          <div class="d-flex flex-row mt-1">
            <p class='d-flex justify-center'>{{$t('services.controlVariables.subscriptions.customSubscriptionToggleDescription')}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import PartouNotify from '@/components/PartouComponents/PartouNotify.vue'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import PartouTextField from '@/components/PartouComponents/PartouTextField.vue'
import moment from 'moment'
import { isBetween } from '@/utils/dateUtils'
import { numberInputDirective } from '@/utils/directives'
import { SelectableSubscription } from './SelectableSubscription'
import { ServicePricingExtended } from '../ServicePricing/ServicePricingExtended'
import { Service, ServiceKind, Subscription } from '@/models'

@Component({
  directives: { numberInputDirective },
  components: { PartouSlider, PartouDialog, PartouNotify, PartouFloatingCircleButton, PartouTextField, DateInputField }
})
export default class SubscriptionActivator extends Vue {
  @Prop({ required: true })
  selectableSubscriptions! : SelectableSubscription[]

  @Prop({ required: true })
  servicePricings! : ServicePricingExtended[]

  @Prop({ required: true })
  service! : Service

  @Prop({ required: true })
  editEnabled! : boolean

  newPriceDate = moment().add(1, 'month').startOf('month').toDate()
  newPrice = 0
  isAddingPrice = false
  showEditPricingDialog = false
  showDeleteDialog = false
  isFormValid = false
  currentSelectedSubscription? : Partial<SelectableSubscription> = {}
  currentSelectedPricing? : ServicePricingExtended

  get sortedSelectableSubscriptions () : SelectableSubscription[] {
    return this.selectableSubscriptions.sort((a, b) => a.subscription.order - b.subscription.order)
  }

  @Watch('selectableSubscriptions')
  customSubscriptionSwitchIsDisabled () : boolean {
    return !this.editEnabled || !this.selectableSubscriptions.find(selectableSubscription => selectableSubscription.subscriptionService.isAvailable === true)
  }

  formatDate (date : Date) : string {
    return moment(date).format('DD-MM-YYYY')
  }

  @Watch('servicePricings')
  subscriptionSwitchIsDisabled () : boolean {
    return (!this.editEnabled || this.servicePricings.filter(x => !x.isDeleted).length === 0)
  }

  getPricingValidUntil () : string {
    const activeServicePricing = this.getActiveServicePricing(this.servicePricings)
    return activeServicePricing?.validUntil ? ` ${this.$t('services.controlVariables.subscriptions.until').toString()} ${moment(activeServicePricing.validUntil).format('DD-MM-YYYY')}` : ''
  }

  getPricing (subscription : Subscription) : number {
    const surchargePercentage = this.service.kind === ServiceKind.DayCare ? subscription.surchargePercentageKdv : subscription.surchargePercentageNso
    const activeServicePricing = this.getActiveServicePricing(this.servicePricings)
    return activeServicePricing?.hourRate * (surchargePercentage / 100)
  }

  getActiveServicePricing (servicePricings : ServicePricingExtended[]) : ServicePricingExtended | undefined {
    return servicePricings.filter(x => isBetween(moment().toDate(), x.validFrom, x.validUntil) && !x.isDeleted).sort((a, b) => { return (new Date(b.validFrom).getTime() - new Date(a.validFrom).getTime()) })[0]
  }

  @Emit('onSubscriptionChanged')
  onSubscriptionChanged (index : number): SelectableSubscription[] {
    this.selectableSubscriptions[index].isDirty = true
    return this.selectableSubscriptions
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  .black-ninety {
    color: $partou-primary-black-ninety !important;
  }

  .edit-icon {
    margin-top: 4px;
    width: 18px  !important;
    height: 18px  !important;
    path {
      fill: $partou-red !important;
    }
  }

  .edit-icon.v-icon--disabled{
    margin-top: 4px;
    width: 18px  !important;
    height: 18px  !important;
    path {
      fill: rgba(0, 0, 0, 0.38) !important;
    }
  }
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

 .subscription-activator-container {
    width: 100%;
    display: flex;

    .description-container {
      width: 50%;
      display: flex;

      div {
        padding-right: 10px

      }
    }

    .controls-container {
      width: 50%;
      display: flex;
      gap: 10px;
      justify-content: center;
      flex-direction: column;

      h2 {
        color: $partou-secondary-bordeaux !important;
      }
    }
 }

  .text {
    color: $partou-black;
    font-family: $body-font-family !important;
    font-size: 0.875rem !important;
    line-height: 17.57px;
    margin-bottom: 0px !important;
  }

  .price {
    font-size: 18px !important;
    font-family: $body-font-family !important;
    line-height: 17.57px;
    margin-bottom: 0px !important;
  }

  .add-button {
      width: 32px;
    height: 32px;
  }

  .date-input {
    width: 200px;
  }

  .price-input {
    max-width: 200px;
    width: 200px;
    padding-top: 0px !important;
  }
</style>
