import { DayOfWeek } from '@/models'
import Vue from 'vue'
import i18n from '@/plugins/i18n'

const shortDateFilter = function (dayOfWeek?: DayOfWeek): string {
  return i18n.t(`daysShort.${dayOfWeek?.toLowerCase()}`)?.toString()
}

Vue.filter('shortDate', shortDateFilter)
export default shortDateFilter
