var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"full-width"},[_c('v-data-table',{staticClass:"rounded-xl",attrs:{"headers":_vm.tableHeaders,"fixedHeader":_vm.fixedHeader,"items":_vm.items,"server-items-length":_vm.totalCount,"loading":_vm.isLoading,"options":_vm.options,"single-expand":true,"expanded":_vm.expandedEntities,"show-expand":_vm.showExpand,"must-sort":true,"loading-text":_vm.$t('table.loadingText'),"no-data-text":_vm.$t('table.noDataText'),"no-results-text":_vm.$t('table.noResultsText'),"footer-props":{
      showFirstLastPage: true,
      itemsPerPageText: _vm.$t('table.itemsPerPageText'),
      itemsPerPageAllText: _vm.$t('table.itemsPerPageAllText'),
      pageText: _vm.$t('table.pageText'),
      itemsPerPageOptions: _vm.options.itemsPerPageOptions
    },"item-class":_vm.itemClass},on:{"update:options":[function($event){_vm.options=$event},_vm.onOptionsChanged],"update:expanded":[function($event){_vm.expandedEntities=$event},_vm.onExpandedChanged]},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }