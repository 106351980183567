import { Emit, Prop, Vue } from 'vue-property-decorator'
import AbstractComponent from '../../utils/AbstractComponent'
import { DataTableHeader } from '@/components/PartouComponents/PartouDataTable'
import DataTableOptionsN from '@/components/PartouComponents/PartouDataTable/DataTableOptionsN'

@AbstractComponent
export default abstract class BaseEntityTable<TModel> extends Vue {
  abstract headers: DataTableHeader[];
  abstract items: Partial<TModel>[];
  abstract tableOptions : DataTableOptionsN

  @Prop({ required: true })
  isLoading!: boolean

  @Prop({ required: true })
  totalCount!: number

  @Emit('onTableOptionsChanged')
  async onTableOptionsChangedAsync (tableOptions : DataTableOptionsN): Promise<DataTableOptionsN> {
    this.tableOptions = tableOptions
    return tableOptions
  }
}
