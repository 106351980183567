import { DeleteServiceFeatureDocument, DeleteServiceFeatureMutation, DeleteServiceFeatureMutationVariables, Feature, GetFeaturesDocument, GetFeaturesQuery, GetFeaturesQueryVariables, GetServiceFeatureByIdDocument, GetServiceFeatureByIdQuery, GetServiceFeatureByIdQueryVariables, GetServiceFeaturesByServiceIdDocument, GetServiceFeaturesByServiceIdQuery, GetServiceFeaturesByServiceIdQueryVariables, InsertServiceFeatureDocument, InsertServiceFeatureMutation, InsertServiceFeatureMutationVariables, ServiceFeature, UpdateServiceFeatureDocument, UpdateServiceFeatureMutation, UpdateServiceFeatureMutationVariables } from '@/models'
import Permission from '@/models/enums/Permission'
import { ServiceFeatureTableOptions } from '@/pages/ServicesPage/ServiceFeature/ServiceFeatureTableOptions'
import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import _ from 'lodash'
import { IServiceFeatureService } from './IServiceFeatureService'

@injectable()
export default class ServiceFeatureService implements IServiceFeatureService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async deleteServiceFeatureAsync (variables: DeleteServiceFeatureMutationVariables): Promise<void> {
    await this.apollo.query<DeleteServiceFeatureMutation>({ query: DeleteServiceFeatureDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageFeature } } })
  }

  async getServiceFeatureByIdAsync (variables: GetServiceFeatureByIdQueryVariables): Promise<ServiceFeature> {
    const result = await this.apollo.query<GetServiceFeatureByIdQuery>({ query: GetServiceFeatureByIdDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageFeature } } })
    return result.data.service_feature[0] as ServiceFeature
  }

  async upsertServiceFeatureAsync (serviceId : string, feature: Feature, isMainFeature: boolean) : Promise<void> {
    if (await this.getServiceFeatureByIdAsync({ serviceId, featureId: feature.id })) {
      await this.updateServiceFeatureAsync({ serviceId, featureId: feature.id, isMainFeature })
    } else {
      await this.insertServiceFeatureAsync({ featureId: feature.id, serviceId, isMainFeature })
    }
  }

  async insertServiceFeatureAsync (variables: InsertServiceFeatureMutationVariables): Promise<void> {
    await this.apollo.query<InsertServiceFeatureMutation>({ query: InsertServiceFeatureDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageFeature } } })
  }

  async updateServiceFeatureAsync (variables: UpdateServiceFeatureMutationVariables): Promise<void> {
    await this.apollo.query<UpdateServiceFeatureMutation>({ query: UpdateServiceFeatureDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageFeature } } })
  }

  async getServiceFeaturesAsync (variables : GetServiceFeaturesByServiceIdQueryVariables): Promise<ServiceFeature[]> {
    const result = await this.apollo.query<GetServiceFeaturesByServiceIdQuery>({ query: GetServiceFeaturesByServiceIdDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.GetFeature } } })
    return result.data.service_feature as ServiceFeature[]
  }

  async getFeaturesAsync (options: ServiceFeatureTableOptions): Promise<{ items: Feature[], totalCount: number }> {
    const variables : GetFeaturesQueryVariables = {
      searchOn: options.filters?.search ?? '',
      limit: options.itemsPerPage,
      offset: options.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }
    if (variables.searchOn !== undefined) {
      variables.searchOn = `%${variables.searchOn}%`
    }

    const result = await this.apollo.query<GetFeaturesQuery>({ query: GetFeaturesDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.GetFeature } } })

    const items: any[] = result.data.feature as Feature[] // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result.data.feature_aggregate?.aggregate?.count || 0
    return { items, totalCount }
  }
}
