<template>
  <div>
    <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.calculateCapacityDescription')}}</h2>
    <div class="action-container">
      <div class="text-container">
        <p v-if="offer.capacityLastCalculatedDate" v-html="$t('services.controlVariables.capacityLastCalculatedDate', { date: formatDate(offer.capacityLastCalculatedDate)})"></p>
        <p v-else v-html="$t('services.controlVariables.capacityLastCalculatedDateNotSet')"></p>
      </div>
      <div class="button-container">
        <PartouButton ref="calculateCapacityButton" class="button card-action" :text="$t('services.controlVariables.triggerCalculateCapacity')" :disabled="capacityAlreadyBeingCalculated" @click="onCalculateCapacityClickedAsync" :loading="loading" />
        <div class="button-error-container">
          <span v-if="error" ref="calculateCapacityError" class="error-message">{{$t('services.controlVariables.triggerCalculateCapacityError')}}</span>
          <span v-else-if="capacityAlreadyBeingCalculated" ref="calculateCapacityAlreadyStarted" class="warning-message">{{$t('services.controlVariables.triggerCalculateCapacityAlreadyStarted')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import { Offer } from '@/models'
import { IOfferService } from '@/services/OfferService/IOfferService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import moment from 'moment'

@Component({
  components: { PartouButton }
})
export default class Actions extends Vue {
  offerService!: IOfferService

  @Prop({ required: true })
  offer! : Partial<Offer>

  loading = false
  error = false

  capacityAlreadyBeingCalculated = false

  @Watch('offer', { deep: true, immediate: true })
  onOfferChanged (newValue: Offer, oldValue: Offer) : void {
    if (!oldValue || oldValue.id !== newValue.id) {
      this.loading = false
      this.error = false
    }
  }

  beforeCreate () : void {
    this.offerService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
  }

  async mounted () : Promise<void> {
    const anHourAgo = moment().subtract(1, 'hours').toDate()
    const startTimeCapacityCalculation = moment(this.offer.startTimeCapacityCalculation).toDate()
    this.capacityAlreadyBeingCalculated = !!this.offer.startTimeCapacityCalculation && startTimeCapacityCalculation > anHourAgo
  }

  formatDate (date : Date) : string {
    return moment(date).format('DD-MM-YYYY HH:mm:ss')
  }

  @Emit('onCapacityCalculated')
  async onCalculateCapacityClickedAsync (): Promise<void> {
    this.error = false
    if (!this.offer.id) {
      return
    }

    this.loading = true
    await this.offerService.calculateCapacityForOfferAsync(this.offer.id)
      .then(capacityCalculated => {
        this.capacityAlreadyBeingCalculated = !capacityCalculated
      })
      .catch(() => {
        this.error = true
      })
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';
@import '@/styles/variables/variables.scss';

  .black-ninety {
    color: $partou-primary-black-ninety !important;
  }

  .action-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .text-container {
      flex: 1;

      p {
        margin: 0;
      }
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 240px;

      .button-error-container {
        display: flex;
        flex-direction: row-reverse;
        line-break: 16px;

        .error-message {
          max-width: 164px;
        }

        .warning-message {
          color: $partou-primary-black-ninety !important;
          caret-color: $error-font-color !important;
          font-family: $error-font-family !important;
          font-size: $error-font-size !important;
          font-weight: $error-font-weight !important;
        }
      }
    }
  }
</style>
