import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages, { defaultLanguage } from '@/locale'
import moment from 'moment'

moment.locale(defaultLanguage)
Vue.use(VueI18n)

export default new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages
})
