<template>
  <div>
    <template v-if="editEnabled">
      <v-select
        class="mb-0"
        :value="label"
        :items="selectablePincodes"
        item-text="value"
        @change="onPincodeChanged"
        hide-details
        dense
        return-object
      ></v-select>
    </template>
    <template v-else>
      {{ label }}
    </template>
  </div>
</template>

<script lang="ts">
import { GroupPincode, PartOfDay, ServiceKind, PincodeItem } from '@/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import { MAX_AGERANGE } from '@/models/enums/AgeCategory'

export type PincodeOption = { pincode: PincodeItem[], pm: number, value: string };

@Component({})
export default class PincodeSelect extends Vue {
  @Prop({ required: true, default: [] })
  value! : GroupPincode[]

  @Prop({ required: true, default: undefined })
  serviceKind! : ServiceKind

  @Prop({ required: true, default: false })
  editEnabled! : boolean

  @Prop({ required: true, default: '' })
  groupId! : string

  // eslint-disable-next-line @typescript-eslint/ban-types
  selectablePincodes : PincodeOption[] = []
  label : string | undefined = ''

  mounted () : void {
    this.selectablePincodes = []
    switch (this.serviceKind) {
    case ServiceKind.DayCare:
      this.selectablePincodes = this.pincodes.KDV
      break
    case ServiceKind.SchoolCare:
      this.selectablePincodes = this.pincodes.BSO
      break
    default: {
      this.selectablePincodes = [...this.pincodes.KDV, ...this.pincodes.BSO]
    }
    }
    this.renderLabel()
  }

  renderLabel (): void {
    this.label = ''
    if (this.value.length > 0) {
      const pincode = this.selectablePincodes.find((x) => _.isEqual(x.pincode, this.value[0].pincode))
      this.label = pincode?.value
    }
  }

  @Emit('input')
  onPincodeChanged (selectedPincode : PincodeOption) : GroupPincode[] {
    if (this.value.length > 0) {
      this.value.forEach((groupPincode) => {
        groupPincode.pincode = selectedPincode.pincode
        groupPincode.isDirty = true
      })
    } else {
      this.value.push({ partOfDay: PartOfDay.Morning, pincode: selectedPincode.pincode, groupId: this.groupId, isDirty: true } as GroupPincode)
      this.value.push({ partOfDay: PartOfDay.Afternoon, pincode: selectedPincode.pincode, groupId: this.groupId, isDirty: true } as GroupPincode)
    }
    this.renderLabel()
    return this.value
  }

  pincodes: { KDV: PincodeOption[], BSO: PincodeOption[] } = {
    KDV: [
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 0, value: '0:0:0:0 | gesloten' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 2 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 2 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 0, value: '2:2:0:0 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 4 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 4 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 2, value: '4:4:0:0 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 6 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 6 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '6:6:0:0 | 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 5 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 8 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '5:8:0:0 | 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 8 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 8 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 4, value: '8:8:0:0 | 4pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 1 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 3 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 1, value: '1:3:1:0 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 3 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 5 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 2, value: '3:5:1:0 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 4 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 9 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '4:9:1:0 | 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 5 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 7 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 0 }], pm: 3, value: '5:7:1:0 | 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 1 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 2 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 1 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 1 }], pm: 1, value: '1:2:1:1 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 2 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 3 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 3 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 2, value: '2:3:3:4 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 3 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 5 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 4 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 3, value: '3:5:4:4 | 3pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 4 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 4 }], pm: 1, value: '0:0:4:4 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 8 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 2, value: '0:0:8:8 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 8 }], pm: 1, value: '0:0:0:8 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 0, MaxAge: 12 }, Seats: 0 }, { AgeRange: { MinAge: 12, MaxAge: 24 }, Seats: 0 }, { AgeRange: { MinAge: 24, MaxAge: 36 }, Seats: 0 }, { AgeRange: { MinAge: 36, MaxAge: 48 }, Seats: 16 }], pm: 2, value: '0:0:0:16 | 2pm' }
    ],
    BSO: [
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 0, value: '0:0:0:0 | gesloten' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 10 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 1, value: '10:0 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 20 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 0 }], pm: 2, value: '20:0 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 9 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 2 }], pm: 1, value: '9:2  | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 18 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 4 }], pm: 2, value: '18:4 | 2pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 12 }], pm: 1, value: '0:12 | 1pm' },
      { pincode: [{ AgeRange: { MinAge: 48, MaxAge: 84 }, Seats: 0 }, { AgeRange: { MinAge: 84, MaxAge: MAX_AGERANGE }, Seats: 24 }], pm: 2, value: '0:24 | 2pm' }
    ]
  }
}
</script>
<style lang="scss" scoped>
.theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  content: none !important;
}
</style>
