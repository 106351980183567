<template>
  <div>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h1>{{ $t('waitingListPropositionAnalysis.children') }}</h1>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="header-content">
          <v-expansion-panel class="child-row" v-for="(child, index) in children" :key="`${index}-${child.id}`">
            <v-expansion-panel-header class="child-header" :hide-actions="child.dayCalculations.length === 0" :readonly="child.dayCalculations.length === 0">
              <div class="indicator" :class="child.dayCalculations.length > 0 ? 'possible' : 'not-possible'"></div>
              <div class="details">
                <v-row>
                  <v-col cols="2">
                    <h2>{{ $t('waitingListPropositionAnalysis.name') }}</h2>
                    <p>{{child.fullName}}</p>
                  </v-col>
                  <v-col cols="child.analysisResultId ? 1 : 2">
                    <h2>{{ $t('waitingListPropositionAnalysis.number') }}</h2>
                    <p>{{child.number}}</p>
                  </v-col>
                  <v-col cols="2">
                    <h2>{{ $t('waitingListPropositionAnalysis.priority') }}</h2>
                    <p>{{$t(`waitingListPropositions.table.priority.${child.priority.toLowerCase()}`)}}</p>
                  </v-col>
                  <v-col cols="2">
                    <h2>{{ $t('waitingListPropositionAnalysis.dateOfBirth') }}</h2>
                    <p>{{child.dateOfBirth | formatDate}}</p>
                  </v-col>
                  <v-col cols="2">
                    <h2>{{ $t('waitingListPropositionAnalysis.desiredStartDate') }}</h2>
                    <p>{{child.desiredStartDate | formatDate}}</p>
                  </v-col>
                  <v-col cols="2">
                    <h2>{{ $t('waitingListPropositionAnalysis.desiredDaysOfCare') }}</h2>
                    <div class="service-variety-row" v-for="serviceVarietyName in getServiceVarietiesOfDesiredDaysForChild(child)" :key="`${index}-${child.id}-${serviceVarietyName}-1`">
                      <span class="ma-0">{{serviceVarietyName}}: &zwnj;</span>
                      <DaysCoverage :daysOfWeek="getDaysOfWeekOfDesiredDaysForServiceVariety(child, serviceVarietyName)" :displayInline="true" />
                    </div>
                  </v-col>
                  <v-col cols="1" v-if="child.analysisResultId">
                    <PartouFloatingCircleButton v-if="child.analysisResultId" class="analysis-button" iconName="$vuetify.icons.partouAnalysis" @click="goToAvailabilityAnalysisPage(child.analysisResultId)" />
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="child-content" v-if="child.dayCalculations.length > 0">
              <div class="days">
                <v-row class="header-row">
                  <v-col cols="2">{{ $t('waitingListPropositionAnalysis.careType') }}</v-col>
                  <v-col cols="1">{{ $t('waitingListPropositionAnalysis.dayOfWeek') }}</v-col>
                  <v-col cols="2">{{ $t('waitingListPropositionAnalysis.hasPriority') }}</v-col>
                  <v-col cols="2">{{ $t('waitingListPropositionAnalysis.holePoints') }}</v-col>
                  <v-col cols="2">{{ $t('waitingListPropositionAnalysis.startsOn') }}</v-col>
                  <v-col cols="2">{{ $t('waitingListPropositionAnalysis.impact') }}</v-col>
                  <v-col cols="1">{{ $t('waitingListPropositionAnalysis.value') }}</v-col>
                </v-row>
                <div class="service-variety-row" v-for="serviceVarietyName in getServiceVarietiesOfCalculatedDaysForChild(child)" :key="`${index}-${child.id}-${serviceVarietyName}-2`">
                  <v-row class="data-row" v-for="day in getSortedChildDayCalculationForServiceVariety(child, serviceVarietyName)" :key="`${index}-${child.id}-${serviceVarietyName}-${day.dayOfWeek}`">
                    <v-col cols="2">{{day.serviceVarietyName}}</v-col>
                    <v-col cols="1">{{$t(`days.${day.dayOfWeek.toLocaleLowerCase()}`)}}</v-col>
                    <v-col cols="2">{{day.prioPoints > 0 ? 'Ja' : 'Nee'}}</v-col>
                    <v-col cols="2">{{day.holePoints}}</v-col>
                    <v-col cols="2">{{day.startsOn | formatDate}}</v-col>
                    <v-col cols="2">{{day.impact}}</v-col>
                    <v-col cols="1">{{day.value}}</v-col>
                  </v-row>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import { DayOfWeek, ServiceVarietyName } from '@/models'
import { Child, ChildDayCalculation } from '@/models/WaitingListPropositionAnalysisResult'
import { ROUTES } from '@/router/routes'
import { Vue, Component, Prop } from 'vue-property-decorator'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'

@Component({
  components: { DaysCoverage, PartouFloatingCircleButton }
})
export default class WaitingListPropositionAnalysisResultChildrenTable extends Vue {
  @Prop({ required: true })
  children!: Child[]

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  getServiceVarietiesOfDesiredDaysForChild (child: Child): ServiceVarietyName[] {
    const distinctList = child.daysOnWaitingListWithServiceVariety.filter((v, i, a) => a.findIndex(t => (t.serviceVarietyName === v.serviceVarietyName)) === i)
    return distinctList.map(x => x.serviceVarietyName)
  }

  getDaysOfWeekOfDesiredDaysForServiceVariety (child: Child, serviceVarietyName: ServiceVarietyName) : DayOfWeek[] {
    const daysForServiceVariety = child.daysOnWaitingListWithServiceVariety.filter(x => x.serviceVarietyName === serviceVarietyName)
    return daysForServiceVariety.map(x => x.dayOfWeek)
  }

  getServiceVarietiesOfCalculatedDaysForChild (child: Child): ServiceVarietyName[] {
    const distinctList = child.dayCalculations.filter((v, i, a) => a.findIndex(t => (t.serviceVarietyName === v.serviceVarietyName)) === i)
    return distinctList.map(x => x.serviceVarietyName)
  }

  getSortedChildDayCalculationForServiceVariety (child: Child, serviceVarietyName: ServiceVarietyName) : ChildDayCalculation[] {
    const daysForCareType = child.dayCalculations.filter(x => x.serviceVarietyName === serviceVarietyName)
    daysForCareType.sort((a, b) => this.orderedDays.indexOf(a.dayOfWeek) - this.orderedDays.indexOf(b.dayOfWeek))
    return daysForCareType
  }

  getDaysOfWeekOfCalculatedDaysForServiceVariety (child: Child, serviceVarietyName: ServiceVarietyName) : DayOfWeek[] {
    return this.getSortedChildDayCalculationForServiceVariety(child, serviceVarietyName).map(x => x.dayOfWeek)
  }

  goToAvailabilityAnalysisPage (analysisResultId: string): void {
    const routeData = this.$router.resolve({ name: ROUTES.serviceAnalysisPreview, params: { analysisResultId } })
    window.open(routeData.href, '_blank')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.header-content {
  padding-top: 16px;
  box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);
}

.child-row {
  .child-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 0 10px 0 0;

    .indicator {
      width: 10px;
      height: 64px;
      margin-right: 5px;

      &.possible {
        background-color: $partou-secondary-green;
      }

      &.not-possible {
        background-color: $partou-red;
      }
    }

    .col {
      padding-top: 0;
      padding-bottom: 0;
    }

    .details {
      display: flex;
      flex-direction: row;
      width: 100%;
      background-color: $partou-white;

      h2, p {
        background-color: $partou-white;
      }

      .expand-arrow {
        align-content: center;
        padding: 8px;

        .v-icon {
          cursor: pointer;
        }
      }

      .service-variety-row {
        margin-bottom: 10px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      .analysis-button {
        width: 35px;
        height: 35px;
        button span svg {
          path {
            fill: $partou-primary-black-ninety !important;
            color: $partou-primary-black-ninety !important;
          }
        }
      }
    }
  }

  .child-content {
    padding: 0 10px 10px 10px;
    box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);

    .days {
      padding-bottom: 10px;

      .header-row {
        font-weight: bold;
        height: 20px;
        font-size: 14px;

        .col {
          padding-left: 0;
        }
      }

      .service-variety-row {
        margin-bottom: 10px !important;

        &:last-child {
          margin-bottom: 0 !important;
        }

        .data-row {
          height: 20px;
          font-size: 14px;

          .col {
            padding-left: 0;
          }
        }
      }
    }
  }
}
</style>
