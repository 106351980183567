<template>
  <div v-if="locationInquiry.id">
    <div class="row">
      <div class="col-3">
        <div class="row">
          <div class="col-4"><strong>{{ $t('services.waitingList.table.childNumber') }}:</strong></div>
          <div class="col-8">{{ childnumber }}</div>
        </div>
        <div class="row">
          <div class="col-4"><strong>{{ $t('services.waitingList.table.subscriptionName') }}:</strong></div>
          <div class="col-8">{{ subscriptionName }}</div>
        </div>
      </div>
      <div class="col-9 coverage">
        <div v-for="serviceVarietyName in distinctServiceVarietyNames" :key="serviceVarietyName" class="service-variety">
          <div class="why-row">
            <div class="why-col why-header">{{serviceVarietyName}}:</div>
            <div class="why-col why-header centered" v-for="dayOfWeekString in dayOfWeekStrings" :key="dayOfWeekString">{{dayOfWeekString}}</div>
          </div>
          <div class="why-row">
            <div class="why-col">{{ $t('services.waitingList.table.reservation') }}</div>
            <div class="why-col even centered" v-for="dayOfWeek in workDays" :key="dayOfWeek">{{hasReservationForDayOfWeek(serviceVarietyName, dayOfWeek) ? 'v' : '-'}}</div>
          </div>
          <div class="why-row">
            <div class="why-col">{{ $t('services.waitingList.table.proposition') }}</div>
            <div class="why-col centered" v-for="dayOfWeek in workDays" :key="dayOfWeek">{{hasWaitingListPlaceForDayOfWeekWithStatus(serviceVarietyName, dayOfWeek, WaitingListPlaceStatus.Approached) ? 'v' : '-'}}</div>
          </div>
          <div class="why-row">
            <div class="why-col">{{ $t('services.waitingList.table.waitingListPlace') }}</div>
            <div class="why-col even centered" v-for="dayOfWeek in workDays" :key="dayOfWeek">{{hasWaitingListPlaceForDayOfWeekWithStatus(serviceVarietyName, dayOfWeek, WaitingListPlaceStatus.Waiting) ? 'v' : '-'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DayOfWeek, ReservationStatus, LocationInquiry } from '@/models'
import { Component, Prop, Vue } from 'vue-property-decorator'
import WaitingListPlaceStatus from '@/models/enums/WaitingListPlaceStatus'

@Component
export default class WaitingListTableExpanded extends Vue {
  @Prop({ required: true })
  locationInquiry!: Partial<LocationInquiry>

  @Prop({ required: true, default: null })
  status!: WaitingListPlaceStatus | null

  WaitingListPlaceStatus = WaitingListPlaceStatus

  get childnumber () : string {
    return this.locationInquiry.child?.number ?? '-'
  }

  get subscriptionName () : string {
    return this.locationInquiry.subscriptionService?.subscription.name ?? this.$t('services.waitingList.unknownSubscription').toString()
  }

  get distinctServiceVarietyNames (): string[] {
    const serviceVarietyNames = this.locationInquiry?.waitingListPlaces?.map(waitingListPlace => waitingListPlace.serviceVariety.name)
    return Array.from(new Set(serviceVarietyNames)).sort((a, b) => a.localeCompare(b))
  }

  get workDays (): DayOfWeek[] {
    return [
      DayOfWeek.Monday,
      DayOfWeek.Tuesday,
      DayOfWeek.Wednesday,
      DayOfWeek.Thursday,
      DayOfWeek.Friday
    ]
  }

  get dayOfWeekStrings (): string[] {
    return [
      this.$t('daysShort.monday').toString(),
      this.$t('daysShort.tuesday').toString(),
      this.$t('daysShort.wednesday').toString(),
      this.$t('daysShort.thursday').toString(),
      this.$t('daysShort.friday').toString()
    ]
  }

  hasReservationForDayOfWeek (serviceVarietyName: string, dayOfWeek: DayOfWeek) : boolean {
    return !!this.locationInquiry?.booking?.placements?.some(placement =>
      placement?.reservations.some(reservation =>
        reservation.serviceVariety.name.toUpperCase() === serviceVarietyName.toUpperCase() &&
        reservation.status !== ReservationStatus.Option &&
        reservation.dayOfWeek === dayOfWeek
      )
    )
  }

  hasWaitingListPlaceForDayOfWeekWithStatus (serviceVarietyName: string, dayOfWeek: DayOfWeek, waitingListPlaceStatus: WaitingListPlaceStatus) : boolean {
    return !!this.locationInquiry?.waitingListPlaces?.some(waitingListPlace =>
      waitingListPlace.serviceVariety.name.toUpperCase() === serviceVarietyName.toUpperCase() &&
      waitingListPlace.dayOfWeek === dayOfWeek &&
      waitingListPlace.status === waitingListPlaceStatus)
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.coverage {
  display: flex;

  .service-variety {
    &:first-child {
      margin-right: 30px;
    }
  }

  .why-row {
    display: flex;

    .why-col {
      width: 80px;
      height: 30px;
      line-height: 30px;

      &.even{
        background-color: $partou-primary-warm-grey-fourty;
      }

      &.why-header {
        font-weight: bold;
      }

      &.centered {
        width: 50px;
        text-align: center;
      }
    }
  }
}
</style>
