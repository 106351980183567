var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-btn',_vm._g(_vm._b({ref:"button",staticClass:"pr-5 pl-5 rounded-pill d-flex flex-row outline-on-focus",class:[_vm.className, {
      'button-size-large': _vm.size === 'large',
      'button-size-normal': _vm.size === 'normal',
      'button-size-small': _vm.size === 'small',
      'partou-primary-gradient': _vm.colour === 'partou-primary-gradient',
      'partou-secondary-bright-green': _vm.colour === 'partou-secondary-bright-green',
      'partou-black-ninety' : _vm.colour === 'partou-black-ninety',
      'partou-black-eighty' : _vm.colour === 'partou-black-eighty',
      'partou-secondary-bordeaux': _vm.colour == 'partou-secondary-bordeaux' || _vm.colour == 'partou-secondary-bordeaux-fourty',
      'partou-secondary-bordeaux-fourty': _vm.colour == 'partou-secondary-bordeaux-fourty',
      'partou-secondary-bordeaux-diop': _vm.colour == 'partou-secondary-bordeaux-diop',
      'partou-primary-salmon': _vm.colour == 'partou-primary-salmon',
      'width-100': _vm.fullWidth
    }],attrs:{"id":_vm.$attrs.id ? _vm.$attrs.id + '-btn' : undefined,"loading":_vm.loading,"disabled":_vm.disabled,"outlined":""},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('div',{staticClass:"v-progress-circular v-progress-circular--indeterminate",attrs:{"role":"progressbar","aria-valuemin":"0","aria-valuemax":"100"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$vuetify.icons.partouLoader")])],1)]},proxy:true}])},'v-btn',_vm.$attrs,false),_vm.$listeners),[_vm._t("append-item",function(){return [_c('div')]}),(_vm.iconPosition == 'left' && _vm.iconName)?_c('v-icon',{staticClass:"btn-icon left mr-1",class:_vm.iconSize},[_vm._v(_vm._s(_vm.iconName))]):_vm._e(),_c('span',{staticClass:"pt-2 pb-2",class:{ 'font-size-11px': _vm.fontSize === 11, 'font-size-14px': _vm.fontSize === 14, 'font-size-16px': _vm.fontSize === 16, 'font-size-24px': _vm.fontSize === 24 },domProps:{"innerHTML":_vm._s(_vm.text)}}),(_vm.iconPosition == 'right' && _vm.iconName)?_c('v-icon',{staticClass:"btn-icon right ml-1",class:_vm.iconSize},[_vm._v(_vm._s(_vm.iconName))]):_vm._e(),_vm._t("prepend-item",function(){return [_c('div')]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }