<template>
  <div class="col-12 pt-0">
    <div class="d-flex flex-row align-center justify-space-between">
      <template v-for="(step, index) in steps">
        <div class="d-flex flex-row align-center" :key="index + '-step'">
          <div class="step d-flex flex-column">
            <template v-if="index + 1 < currentStep">
              <div class="step-content">
                <div class="step-background-container pl-1 pr-1">
                  <v-icon large class="step-background-icon">$vuetify.icons.partouShieldWhite</v-icon>
                </div>
                <p class="d-flex justify-center mb-0 inner-step-text completed">
                  <v-icon>$vuetify.icons.partouCheck</v-icon>
                </p>
                <p class="d-flex justify-center mb-0 step-label completed">{{ $t(step.translationId) }}</p>
              </div>
            </template>
            <template v-else-if="index + 1 == currentStep">
              <div class="step-content">
                <div class="step-background-container pl-1 pr-1">
                  <v-icon large class="step-background-icon">$vuetify.icons.partouShieldGradient</v-icon>
                </div>
                <p class="d-flex justify-center mb-0 inner-step-text current">{{ index + 1 }}</p>
                <p class="d-flex justify-center mb-0 step-label"><strong>{{ $t(step.translationId) }}</strong></p>
              </div>
            </template>
            <template v-else>
              <div class="step-content">
                <div class="step-background-container pl-1 pr-1">
                  <v-icon large class="step-background-icon">$vuetify.icons.partouShieldWhite</v-icon>
                </div>
                <p class="d-flex justify-center mb-0 inner-step-text">{{ index + 1 }}</p>
                <p class="d-flex justify-center mb-0 step-label">{{ $t(step.translationId) }}</p>
              </div>
            </template>
          </div>
        </div>
        <div :key="`${index} + line`" v-if="index !== steps.length -1" class="line"/>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

export type PartouStepperStep = {
  translationId: string;
  backgroundIconString: string;
  stepLabel: string;
  isCompletedIconString: string;
};

@Component({
  components: {}
})
export default class PartouStepper extends Vue {
  @Prop({ required: true })
  steps!: PartouStepperStep[]

  @Prop({ required: true })
  currentStep!: number

  @Prop({ required: false, default: false })
  isVertical!: boolean

  @Prop({ required: false, default: true })
  showStepLabels!: boolean
}
</script>
<style lang="scss">
.stepper-component {
  width: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMiIgd2lkdGg9IjEiPjxsaW5lIHgxPSIwIiB5MT0iMSIgeDI9IjEiIHkyPSIxIiBzdHlsZT0ic3Ryb2tlOiNGMkU5RTI7c3Ryb2tlLXdpZHRoOjIiLz48L3N2Zz4K");
  background-repeat: repeat-x;
  background-position-y: 36px;
}
.step {
  .step-content {
    width: 80px;
    overflow: visible;
    .step-background-container {
      background-color: transparent !important;
      .step-background-icon {
        width: 72px !important;
        height: 72px !important;
      }
    }
  }

  .inner-step-text.current {
    margin-top: -44px;
    color: #ffffff !important;
  }
  .inner-step-text.completed {
    margin-top: -48px;
  }
  .inner-step-text {
    position: absolute;
    width: 80px;
    text-align: center;
    margin-top: -44px;
    z-index: 6;
  }

  .step-label.completed {
    margin-top: 14px;
  }
  .step-label {
    margin-top: 0px;
  }
}

.z0 {
  z-index: 0;
  left: 0;
}
.z1 {
  z-index: 1;
  left: 0;
}

.z2 {
  z-index: 2;
  left: 0;
}

.line {
  flex: 1 1;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMiIgd2lkdGg9IjEiPjxsaW5lIHgxPSIwIiB5MT0iMSIgeDI9IjEiIHkyPSIxIiBzdHlsZT0ic3Ryb2tlOiNGMkU5RTI7c3Ryb2tlLXdpZHRoOjIiLz48L3N2Zz4K");
  width:100%;
  height:86px;
  background-repeat: repeat-x;
  background-position-y: 36px;
}
</style>
