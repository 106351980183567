import { inject, injectable } from 'inversify'
import IPostalCodeService from './IPostalCodeService'
import ApolloClient from 'apollo-client'
import { FetchPostalcodeInfoDocument, FetchPostalcodeInfoOutput, FetchPostalcodeInfoQueryVariables, Maybe, QueryRoot } from '@/models'
import Permission from '@/models/enums/Permission'

@injectable()
export default class PostalCodeService implements IPostalCodeService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  isPostalCodeFormatValid (postalCode: string): boolean {
    const dutchValidationRegex = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i
    const regex = new RegExp(dutchValidationRegex)
    return regex.test(postalCode)
  }

  async getPostalCodeInfo (variables: FetchPostalcodeInfoQueryVariables): Promise<Maybe<FetchPostalcodeInfoOutput>> {
    const result = await this.apollo.query<QueryRoot>({
      query: FetchPostalcodeInfoDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })

    return result?.data?.fetchPostalcodeInfo
  }
}
