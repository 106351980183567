<template>
  <v-text-field
    v-on="$listeners" v-bind="$attrs"
    ref="text-field"
    :disabled="disabled"
    :label="label + (label && isRequired ? ' *': '')"
    :rules="[initialRule]"
    @blur="onBlur"
    @input="onChanged"
    :type="type"
    :error="!isComponentValid && internalErrorMessage !== ''"
    :error-messages="$t(internalErrorMessage)"
    :class="{ small: size === 'small', normal: size !== 'small', 'icon-left': iconName && iconPosition === 'left', 'icon-right': iconName && iconPosition === 'right', filled: filled && !disabled, suffix: suffix }"
    single-line>
      <template slot="prepend-inner" v-if="iconName && iconPosition === 'left'">
        <v-icon class='mr-2'>{{iconName}}</v-icon>
      </template>
      <template slot="append" v-if="iconName && iconPosition === 'right'">
        <v-icon class='mr-2'>{{iconName}}</v-icon>
      </template>
      <template slot="append" v-if="suffix">
        <p class="suffix">{{suffix}} </p>
      </template>
  </v-text-field>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

// function that's being called by vuetify rules
type ruleFunction = (arg0: string) => (Promise<boolean | string>) | boolean | string;

@Component({})
export default class PartouTextField extends Vue {
  @Prop({ required: false, default: '' })
  label!: string

  @Prop({ required: false, default: 'normal' })
  size!: 'small' | 'normal'

  @Prop({ required: false, default: 'none' })
  iconPosition!: 'none' | 'left' | 'right'

  @Prop({ required: false, default: '' })
  iconName!: string

  @Prop({ required: false, default: false })
  isRequired!: boolean

  @Prop({ required: false, default: false })
  disabled!: boolean

  @Prop({ required: false, default: () => [] })
  rules! : ruleFunction[]

  @Prop({ required: false, default: () => '' })
  errorMessage! : string

  @Prop({ required: false, default: false })
  validateOnBlur?: boolean

  @Prop({ required: false })
  suffix?: string

  @Prop({ required: false, default: 'text' })
  type?: string

  isComponentValid = false
  internalErrorMessage = this.errorMessage
  initial = true
  filled = false

  onBlur (value: string) : void {
    if (this.validateOnBlur) {
      this.isValidAsync(value)
    }
  }

  onChanged (value: string) : void {
    if (!this.validateOnBlur) {
      this.isValidAsync(value)
    }
  }

  initialRule (value: string) : boolean | string {
    // Only validate using the vuetify rules if first validation, and textfield has value
    // else only validate using the custom blur and input events
    if (this.initial && !!value) {
      this.isValidAsync(value)
    }

    const invalidInitialValue = this.initial && this.isRequired && !value
    this.initial = false

    if (invalidInitialValue) {
      return this.$t('form.isRequired').toString()
    }

    return true
  }

  async isValidAsync (value: string): Promise<boolean | string> {
    this.isComponentValid = false
    this.filled = !!value
    let errMsg = ''
    const ruleFunctions: ruleFunction[] = [...this.rules]

    if (this.isRequired) {
      ruleFunctions.unshift(this.checkRequiredRule)
    }

    for (const func of ruleFunctions) {
      const resp = await func(value)
      if (resp === false) {
        errMsg = 'Validation failed'
        break
      } else if (typeof resp === 'string') {
        errMsg = resp.toString()
        break
      }
    }

    this.internalErrorMessage = errMsg
    this.isComponentValid = !this.internalErrorMessage
    return this.isComponentValid
  }

  checkRequiredRule (value: string): boolean | string {
    const errorMsgIfNotValid = 'form.isRequired'
    return !!value || errorMsgIfNotValid
  }

  @Watch('errorMessage', { immediate: true })
  onErrorMessageChange () : void {
    this.internalErrorMessage = this.errorMessage
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/index.scss';
@import '@/styles/variables/variables.scss';

  .v-text-field::v-deep {
    opacity: 1;

    &.filled{
      input
      {
        color: $partou-primary-black !important;
      }
    }

    :not(.filled) {
      .v-label
      {
        color: $partou-primary-black-fourty !important;
      }
    }

    .v-input__slot {
      border-radius: 100px;
      background-color: $partou-primary-white;
      box-shadow: $partou-elevation-large-subtle;

      &:before {
        border: 0 !important;
      }
      &:after {
        border: 0 !important;
      }

      .v-input__prepend-inner {
        margin-left: 24px;
        margin-top: 12px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .v-input__append-inner {
        margin-right: 20px;
        margin-top: 12px;

        svg {
          width: 16px;
          height: 16px;
        }
      }

      .v-icon {
        path {
          fill: $partou-primary-salmon !important;
        }
      }

      .v-progress-linear.v-progress-linear--absolute{
        left: 24px !important;
        right: 24px !important;
        width: auto !important;
        bottom: 1px;
        .v-progress-linear__background {
          background-color: transparent !important;
        }
        .v-progress-linear__indeterminate {
          background-color: $partou-primary-salmon !important;
        }
      }
    }

    &.v-input--is-disabled {
      background-color: transparent;

      .v-label {
        color: $partou-primary-black-thirty !important;
        -webkit-text-fill-color: $partou-primary-black-thirty !important;
      }

      .v-input__slot {
        .v-icon {
          path {
            fill: $partou-primary-black-thirty !important;
          }
        }
      }
    }

    .v-label {
      padding-left: 24px;
      line-height: 18.4px !important;
      font-size: 16px !important;
      height: 56px;
      max-height: 56px;
      min-height: 56px;
      color: $partou-primary-black-eighty !important;

      &.error--text {
        color: $partou-primary-black-eighty !important;
      }

      &.v-label--active {
        left: 8px !important;
      }
    }

    &.error--text {
      .v-input__slot {
        border: 1px solid $partou-primary-salmon;
      }
    }

    .v-text-field__details {
      margin: auto auto;
      color: $partou-primary-salmon;
    }

    input {
      height: 56px;
      max-height: 56px;
      min-height: 56px;
      padding-left: 26px;
      padding-right: 26px;
      line-height: 18.4px;
      font-size: 16px;
      border-radius: 100px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    &.small {
      padding-top: 0;
      margin-top: 0;

      input {
        height: 40px;
        max-height: 40px;
        min-height: 40px;
      }

      .v-label {
        padding-top: 5px;

        &.v-label--active {
          top: -3px;
        }
      }

      .error--text {
        margin-bottom: 8px !important;
      }
    }

    &.normal {
      input {
        height: 56px;
        max-height: 56px;
        min-height: 56px;
      }

      .v-label {
        padding-top: 12px;

        &.v-label--active {
          top: -8px;
        }
      }

      .v-input__prepend-inner {
        margin-top: 21px;
      }

      .v-input__append-inner {
        margin-top: 21px;
      }

      .v-icon {
        margin-top: -2px;
      }
    }

    &.icon-left {
      input {
        padding-left: 8px;
      }

      .v-label {
        padding-left: 0;
      }
    }

    &.icon-right {
      input {
        padding-right: 10px;
      }
    }
  }
</style>
