<template>
  <div>
    <table class="col-12" v-if="dayData" summary="service-offer-pivotgrid">
      <tr>
        <th class="table-column-fixed-width-groups border-right border-bottom" scope="col">
          <span class="table-heading-text text-red">Groepen</span>
        </th>
        <th v-for="column in dayData.pivotGridColumnHeaderData" :key="column.ageCategory" class="border-right border-bottom" scope="col">
          <div>
            <span class="available-seats-text">{{ column.availableSeats }}</span>
            <span class="total-seats-text">/ {{ column.totalSeats }}</span>
          </div>
          <div>
            <span class="table-heading-text">{{ $t(`pages.ServiceOffer.ageCategory.${column.ageCategory}`) }} jaar</span>
            <span class="table-heading-text-right"><span :class="{ hidden: column.waitingList <= 0 }">{{ column.waitingList }}<v-icon>mdi-format-list-numbered</v-icon></span></span>
          </div>
        </th>
        <th class="table-column-fixed-width-pincodes border-bottom" scope="col">
          <span class="table-heading-text text-red">Optimale bezetting</span>
        </th>
      </tr>
      <tr  v-for="row in dayData.pivotGridRowData" :key="row.group.name" >
        <td class="table-column-fixed-width border-right border-bottom">
          <div @click="showChildrenDialog(row)" class="clickable d-flex justify-space-between">
            <span class="table-row-text ellipsis">{{ row.group.name }}</span>
            <span class="table-row-text-right ml-2">cap. {{ getPlanCapacity(row.group) }}</span>
          </div>
        </td>
        <td v-for="ageCategory in row.ageCategoryData" :key="ageCategory.ageCategory" class="border-right border-bottom pa-2">
          <div @click="showChildrenDialog(row)" class="clickable seats-container d-flex flex-column flex-wrap align-content-start">
            <template v-for="index in ageCategory.occupiedSeats">
              <div :key="'occupiedSeats'+index" class="seat seat-red" v-bind:class="{ 'seat-bordered': index <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.flexSeats" >
              <div :key="'flexSeat'+index" class="seat seat-blue" v-bind:class="{ 'seat-bordered': index + ageCategory.occupiedSeats <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.reservedSeats" >
              <div :key="'reservedSeats'+index" class="seat seat-yellow" v-bind:class="{ 'seat-bordered': index + ageCategory.flexSeats + ageCategory.occupiedSeats <= ageCategory.maxSeatsByPincode }" />
            </template>
            <template v-for="index in ageCategory.availableSeats" >
              <div :key="'availableSeats'+index" class="seat seat-green seat-bordered" />
            </template>
          </div>
        </td>
        <td class="table-column-fixed-width border-bottom">
          <PincodeSelect :key="pincodeSelectKey" v-model="row.groupPincodes" :editEnabled="editEnabled" :serviceKind="serviceKind" :groupId="row.group.id"></PincodeSelect>
        </td>
      </tr>
    </table>
    <ServiceOfferChildrenDialog v-if="childrenDialogData" :show="isChildrenDialogShown" @showChanged="onChildrenDiaglogShowChange" :value="childrenDialogData" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import DailyData from '../../models/DailyData.model'
import PincodeSelect from '@/pages/ServicesPage/ServiceOffer/ServiceOfferDashboard/PincodeSelect/PincodeSelect.vue'
import { Group, GroupCapacity, ServiceKind } from '@/models'
import { isBetween } from '@/utils/dateUtils'
import ServiceOfferChildrenDialog from '../ServiceOfferChildrenDialog/ServiceOfferChildrenDialog.vue'
import PivotGridRowData from '../../models/PivotGridRowData.model'
import DayOfWeek, { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'

@Component({
  components: { PartouCard, PincodeSelect, ServiceOfferChildrenDialog }
})
export default class ServiceOfferDashboardPivotGrid extends Vue {
  @Prop({ required: true })
  dayData!: DailyData

  @Prop({ required: true, default: false })
  editEnabled!: boolean

  @Prop({ required: true, default: undefined })
  serviceKind! : ServiceKind

  pincodeSelectKey = 0
  isChildrenDialogShown = false
  childrenDialogData: PivotGridRowData | null = null

  @Watch('dayData')
  onDayDataChanged () : void {
    this.pincodeSelectKey++
  }

  onChildrenDiaglogShowChange (value: boolean) : void {
    this.isChildrenDialogShown = value
  }

  getPlanCapacity (group : Group) : number {
    const groupCapacity = group.groupCapacities?.filter((x : GroupCapacity) => isBetween(this.dayData.date, x.validFrom, x.validUntil))[0]
    if (!groupCapacity) {
      return 0
    }

    const dayOfWeek = parseNumberToDayOfWeek(this.dayData.dayIndex)
    return this.getPlanCapacityForDayOfWeek(groupCapacity, dayOfWeek)
  }

  getPlanCapacityForDayOfWeek (groupCapacity : GroupCapacity, dayOfWeek : DayOfWeek) : number {
    switch (dayOfWeek) {
    case DayOfWeek.Monday: return groupCapacity.mondayPlanCapacity
    case DayOfWeek.Tuesday: return groupCapacity.tuesdayPlanCapacity
    case DayOfWeek.Wednesday: return groupCapacity.wednesdayPlanCapacity
    case DayOfWeek.Thursday: return groupCapacity.thursdayPlanCapacity
    case DayOfWeek.Friday: return groupCapacity.fridayPlanCapacity
    default: return 0
    }
  }

  showChildrenDialog (data: PivotGridRowData) : void {
    this.childrenDialogData = data
    this.isChildrenDialogShown = true
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

  .clickable {
    cursor: pointer;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  table > tr {
    > th {
      font-weight: $heading-font-weight;
    }

    > td, > th {
      padding: 16px;
      text-align: left;
      vertical-align: bottom;

      &:first-child {
        padding-left: 0px;
      }
    }
  }

  .table-column-fixed-width-groups {
    width: 260px;
  }

  .table-column-fixed-width-pincodes {
    width: 190px;
  }

  .table-row-text {
    font-size: $heading-font-size;
    max-width: 175px;
  }

  .table-row-text-right {
    font-size: $heading-font-size;
  }

  .table-heading-text {
    font-size: $heading-font-size;
    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
  }

  .table-heading-text-right {
    font-size: $body-font-size;
    float: right;

    font-family: $heading-font-family;
    font-weight: $heading-font-weight;
  }

  .hidden {
    visibility: hidden;
  }

  .available-seats-text {
    font-size: 32px;
  }

  .total-seats-text {
    font-size: 12px;
  }

  .text-red {
    color: $partou-red;
  }

  .border-right {
    border-right: 1px solid #C4C4C4;
  }

  .border-bottom {
    border-bottom: 1px solid #C4C4C4;
  }

  .seats-container {
    height: 40px;

    .seat {
      margin: 1px;
      width: 16px;
      height: 16px;
      border-radius: 2px;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);

      &.seat-green {
        background: $partou-green;
      }
      &.seat-blue {
        background: $partou-blue
      }
      &.seat-yellow {
        background: $partou-yellow;
      }
      &.seat-red {
        background: $partou-red;
      }
      &.seat-white {
        background: #FFFFFF;
      }
      &.seat-bordered {
        border: 2px solid #1D1D1B;
      }
    }
  }
</style>
