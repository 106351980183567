<template>
    <v-card class="entity-form-card rounded-xl pa-6">
      <v-card-text>
        <slot name="top" v-bind:formRef="$refs.form"></slot>
        <v-skeleton-loader :loading="!isLoaded" type="heading, list-item, heading, list-item, paragraph">
          <template #default>
            <slot name="header"></slot>
            <v-form class="mt-8" v-model="isFormValid" ref="form">
              <div>
                <div class="pl-2">
                  <slot name="form"></slot>
                </div>
              </div>
            </v-form>
          </template>
        </v-skeleton-loader>
      </v-card-text>
      <slot name="buttons" v-if="canManage">
        <v-btn v-if="!editEnabled" :disabled="isLoading" @click="onEditClicked()" id="edit-button" class="edit-button" ref="edit-button" fab dark large color="primary">
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
        <v-btn v-if="editEnabled" :disabled="!isFormValid || isLoading" @click="onSaveClicked()" id="save-button" class="save-button" ref="save-button" fab dark large color="primary">
          <v-icon dark> mdi-floppy </v-icon>
        </v-btn>
        <v-btn v-if="editEnabled" :disabled="isLoading" @click="onCancelClicked()" id="cancel-button" class="cancel-button" ref="cancel-button" fab dark large color="blue">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </slot>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="100"></v-progress-circular>
      </v-overlay>
    </v-card>
</template>

<script lang="ts">
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class EntityDetailContainer extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  isLoaded!: boolean

  @Prop({ required: true })
  editEnabled!: boolean

  @Prop({ required: true })
  isSaving!: boolean

  isFormValid = false

  get isLoading () : boolean {
    if (this.isSaving || !this.isLoaded) {
      return true
    } else { return false }
  }

  get canManage () : boolean {
    return this.authService.hasPermission([Permission.PublishService, Permission.ManageService, Permission.ManageServiceMedia])
  }

  onSaveClicked () : void {
    this.$emit('saveClicked')
  }

  onCancelClicked () : void {
    this.$emit('cancelClicked')
  }

  onEditClicked () : void {
    this.$emit('editClicked')
  }
}
</script>

<style lang="scss">
.entity-form-card {
  width:100%;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));

  .save-button, .edit-button {
    z-index: 6;
    position: absolute;
    right: -32px;
    top: 88px;
  }

  .cancel-button {
    z-index: 6;
    position: absolute;
    right: -32px;
    top: 162px;
  }

  #save-button:disabled, #edit-button:disabled {
    background-color: rgb(203, 90, 92, 0.8) !important;
  }

  #cancel-button:disabled {
    background-color: rgb(33, 150, 243, 0.8) !important;
  }
}
</style>
