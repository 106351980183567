import { createDecorator, VueDecorator } from 'vue-class-component'

export default function Delay (ms = 1000) : VueDecorator {
  return createDecorator((options : any, key) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const originalMethod = options.methods[key]
    options.methods[key] = async function wrapperMethod (...args : any[]) { // eslint-disable-line @typescript-eslint/no-explicit-any
      await new Promise(resolve => setTimeout(resolve, ms))
      originalMethod.apply(this, args)
    }
  })
}
