import Vue, { DirectiveOptions } from 'vue'

const numberInputDirective: DirectiveOptions = {
  bind: function (el: any) { // eslint-disable-line
    el.validateKeyDown = function (e: KeyboardEvent) {
      const reg = /^[0-9]|Enter|Delete|Backspace|ArrowRight|ArrowLeft|ArrowUp|ArrowDown|[.,]$/g
      if (!reg.test(e.key)) {
        e.preventDefault()
      }
    }
    el.addEventListener('keydown', el.validateKeyDown)
  },
  unbind: function (el: any) { // eslint-disable-line
    el.removeEventListener('keydown', el.validateKeyDown)
  }
}

Vue.directive('number-input', numberInputDirective)
export default numberInputDirective
