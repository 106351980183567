<template>
  <v-simple-table :key="openingTime.id" dense>
    <tr v-for="(day, index) in days" :key="index">
      <td class="pr-4">{{ getDayName(day) }}</td>
      <td>{{ getTimespanString(openingTime[day]) }}</td>
    </tr>
  </v-simple-table>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { ServiceVarietyOpening, Timespan } from '@/models'

@Component
export default class OpeningTimes extends Vue {
  @Prop({ required: true, default: '' })
  public openingTime!: ServiceVarietyOpening

  @Prop({ default: false })
  public showWeekend!: boolean

  @Prop({ default: false })
  public shortNames!: boolean

  get days () : string[] {
    const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
    if (this.showWeekend) {
      days.push('saturday', 'sunday')
    }

    return days
  }

  getTimespanString (timespans?: Timespan[]) : string {
    if (!timespans) {
      return this.$t('openingTimes.unknown').toString()
    } else if (timespans.length <= 0) {
      return this.$t('openingTimes.closed').toString()
    }

    // returns a string based on the earliest, till the latest openingHours
    const min = timespans.reduce((a, b) => a.From < b.From ? a : b).From
    const max = timespans.reduce((a, b) => a.Until > b.Until ? a : b).Until

    return !min || !max
      ? this.$t('openingTimes.unknown').toString()
      : `${min} - ${max}`
  }

  getDayName (day: string) : string {
    const fullDayName = this.$t(`days.${day}`).toString()
    if (!this.shortNames) {
      return fullDayName
    }

    return fullDayName.substring(0, 2).toLowerCase()
  }
}
</script>
