<template>
 <v-card :flat="true" class="rounded-xl card">
    <div>
      <slot name="card-header"></slot>
    </div>
    <div class="card-body pa-4">
      <slot name="card-body"></slot>
    </div>
    <v-card-actions v-if="!hideActions" class="pa-0">
      <div class="pa-4 d-flex justify-space-between card-footer rounded-b-xl">
        <slot name="card-footer"></slot>
      </div>
    </v-card-actions>
 </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'

@Component({
  components: { PartouButton }
})
export default class PartouCard extends Vue {
  @Prop({ required: false, default: false })
  hideActions?: boolean
}
</script>

<style lang="scss" scoped>
.card {
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));

  .card-body {
    border-radius: 24px;
    box-shadow: 0px 3px 16px -3px rgba(0, 0, 0, 0.15);
  }

  .card-footer {
    width:100%;
  }
}
</style>
