<template>
  <svg
    width="73"
    height="68"
    viewBox="0 0 73 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <g filter="url(#filter0_d)">
        <path
          d="M53.7148 24.8598C52.7874 19.3331 50.3826 17.7722 46.6693 16.9283C43.9429 16.3082 41.11 16 37.2229 16C30.8524 16 22.3727 18.447 20.3893 21.1475C18.406 23.848 17.7733 27.0995 18.069 32.4534C18.3647 37.8074 20.8069 45.4902 25.3189 49.1613C29.8309 52.8323 38.6082 52.4512 42.4473 51.4816C46.2863 50.512 50.1685 48.5699 51.982 43.2122C53.7954 37.8544 54.6431 30.3837 53.7148 24.8598Z"
          :fill="color"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="-12"
        y="-14"
        width="96.0946"
        height="96.1421"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="15" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <clipPath id="clip0">
        <rect width="73" height="68" fill="white" />
      </clipPath>
      <linearGradient
        id="paint0_linear"
        x1="1.26731e-07"
        y1="36.1421"
        x2="36.1421"
        y2="0.0475396"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A02846" />
        <stop offset="1" stop-color="#E8565D" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PartouShield extends Vue {
  @Prop({ required: true })
  color!: string
}
</script>
