import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { ISendEmailService } from './ISendEmailService'
import Permission from '@/models/enums/Permission'
import { SendEmailsDocument, SendEmailsMutation } from '@/models'

@injectable()
export default class SendEmailService implements ISendEmailService {
  private _apollo: ApolloClient<unknown>

  public constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this._apollo = apollo
  }

  public async triggerSendEmailsAsync (): Promise<void> {
    await this._apollo.mutate<SendEmailsMutation>({
      mutation: SendEmailsDocument,
      context: { headers: { 'X-Hasura-Role': Permission.CallActions } }
    })
  }
}
