import * as _ from 'lodash'

declare global {
  interface Array<T> {
    first(fn?: (x: T) => boolean): T | undefined
    last(fn?: (x: T) => boolean): T | undefined
    orderBy<TKey>(fn: (x: T) => TKey): Array<T>
    orderBy(fn: (x: T) => Date): Array<T>
    orderByDescending<TKey>(fn: (x: T) => TKey): Array<T>
    orderByDescending(fn: (x: T) => Date): Array<T>
    shuffle(): Array<T>
  }
}

// eslint-disable-next-line no-extend-native
Array.prototype.first = function <T> (predicate?: (x: T) => boolean): T | undefined {
  if (!predicate) {
    return this[0]
  }

  return this.find(predicate)
}

// eslint-disable-next-line no-extend-native
Array.prototype.last = function <T> (predicate?: (x: T) => boolean): T | undefined {
  const filtered = predicate
    ? this.filter(x => predicate(x))
    : this

  return filtered[filtered.length - 1]
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderBy = function <T, TKey> (fn: (x: T) => TKey): Array<T> {
  return _.orderBy(this, x => fn(x))
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderBy = function <T> (fn: (x: T) => Date): Array<T> {
  return this.sort((a, b) => new Date(fn(a)).getTime() > new Date(fn(b)).getTime() ? 1 : -1)
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderByDescending = function <T, TKey> (fn: (x: T) => TKey): Array<T> {
  return _.orderBy(this, x => fn(x), 'desc')
}

// eslint-disable-next-line no-extend-native
Array.prototype.orderByDescending = function <T> (fn: (x: T) => Date): Array<T> {
  return this.sort((a, b) => new Date(fn(a)).getTime() < new Date(fn(b)).getTime() ? 1 : -1)
}

// eslint-disable-next-line no-extend-native
Array.prototype.shuffle = function <T> (): Array<T> {
  const array = [] as Array<T>
  this.forEach(x => array.push(x))
  return _.shuffle(array)
}

export { }
