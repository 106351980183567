<template>
  <div ref="waiting-list-allocation-results" id="waiting-list-allocation-results">
    <div v-for="(waitingListAllocation, index) in waitingListAllocations"  :key="index">
       <WaitingListAllocationCard class="mb-6" :waitingListAllocation="waitingListAllocation"/>
    </div>
  </div>
</template>

<script lang="ts">
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { WaitingListAllocation } from '@/models/WaitingListAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListAllocationCard from './WaitingListAllocationCard.vue'

@Component({
  components: { PartouCard, WaitingListAllocationCard }
})
export default class waitingListAllocationResultsView extends Vue {
  @Prop({ required: true })
  waitingListAllocations!: WaitingListAllocation[]
}
</script>
