<template>
    <v-row>
      <v-col class="col-2 d-flex align-self-center flex-row">{{ $t(name) }}</v-col>
      <v-col class="col-8 d-flex align-self-center flex-row ">
        <v-text-field type="number" v-numberInputDirective v-on="$listeners" class="p0 m0 rounded" :disabled="disabled" :rules="rules" ref="text-field-input" :min="min" :solo="!disabled" :solo-inverted="disabled" dense rounded hide-details="auto" v-model="localValue"></v-text-field>
      </v-col>
    </v-row>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator'
import { numberInputDirective } from '@/utils/directives'

@Component({
  directives: { numberInputDirective }
})
export default class NumberFieldRow extends Vue {
  @Prop({ required: true })
  name!: string

  @Prop({ required: true })
  value?: number

  get localValue () : number | undefined {
    return this.value
  }

  set localValue (value: number | undefined) {
    this.$emit('input', value)
  }

  @Prop({ required: false, default: () => [] })
  rules?: ((value: number | string) => boolean | string)[]

  @Prop({ required: false, default: false })
  disabled?: boolean

  @Prop({ required: false, default: 0 })
  min?: number
}
</script>
