<template>
  <PartouCard :hide-actions="true" class="analysis-card mt-2 full-width">
    <template slot="card-header">
      <div class="rounded-t-xl card pt-4"
        :class="waitingListAllocation.didAllocateWaitingListSpots ? 'did-allocate-waiting-list-spots' : 'did-not-allocate-waiting-list-spots'">
      </div>
    </template>

    <template slot="card-body">
      <div class="waiting-list-allocation-card-header">
        <v-row>
          <v-col cols="5">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.calculationDate') }}</h2>
            <p>{{waitingListAllocation.calculationDate | dateTime}}</p>
          </v-col>
          <v-col cols="5">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.elapsed') }}</h2>
            <p>{{waitingListAllocation.elapsed | time}}</p>
          </v-col>
          <v-col cols="2">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.maxStartDate') }}</h2>
            <p>{{waitingListAllocation.maxStartDate | date}}</p>
          </v-col>
        </v-row>
        <v-row class="pb-0 pt-0"
          v-if="waitingListAllocation.didAllocateWaitingListSpots && bookedBookingWaitingListProposition.child">
          <v-col cols="5" class="pb-0 pt-0">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.bookedWaitingListProposition') }}</h2>
            <p> {{bookedBookingWaitingListProposition.child.firstName}}
              {{bookedBookingWaitingListProposition.child.lastName}}
              ({{bookedBookingWaitingListProposition.child.number}})</p>
          </v-col>
          <v-col cols="5" class="pb-0 pt-0">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.startDate') }}</h2>
            <p>{{bookedBookingWaitingListProposition.bestWaitingListAllocationProposition.startDate | date}}</p>
          </v-col>
          <v-col cols="2" class="pb-0 pt-0">
            <h2>{{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.bookedDays') }}</h2>
            <div
                v-for="(days, serviceVarietyName, svIndex) in bookedBookingWaitingListProposition.bestWaitingListAllocationProposition.availableDaysOfWeekPerServiceVariety"
                :key="svIndex">
                <v-row>
                  <p class="ma-0">{{serviceVarietyName}}: &zwnj;</p>
                  <DaysCoverage :daysOfWeek="days" />
                </v-row>
              </div>
          </v-col>
        </v-row>
        <v-card-actions  class="pb-0 pt-0">
          <v-btn text @click="showExtraInfo = !showExtraInfo" class="full-width">
            <v-spacer />
            <v-icon>{{ showExtraInfo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            <v-spacer />
          </v-btn>
        </v-card-actions>
      </div>
      <v-expand-transition>
        <div v-show="showExtraInfo">
          <div class="waiting-list-allocation-card-body">
            <v-expansion-panels accordion v-if="!!bookingWaitingListPropositions.length" class="full-width pl-6 pr-6">
              <BookingWaitingListPropositionPanel
                v-for="(bookingWaitingListProposition, index) in bookingWaitingListPropositions" :key="index"
                :bookingWaitingListProposition="bookingWaitingListProposition" />
            </v-expansion-panels>
            <h2 v-else style="text-align: center">
              {{ $t('waitingListAllocationAnalysis.waitingListAllocationCard.noWaitingListAllocations') }}
            </h2>
          </div>
        </div>
      </v-expand-transition>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { InquiryWaitingListAllocationProposition, WaitingListAllocation } from '@/models/WaitingListAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import BookingWaitingListPropositionPanel from './BookingWaitingListPropositionPanel.vue'
import WaitingListPropositionTable from './WaitingListPropositionTable.vue'

@Component({
  components: { PartouCard, BookingWaitingListPropositionPanel, WaitingListPropositionTable, DaysCoverage }
})
export default class WaitingListAllocationCard extends Vue {
  @Prop({ required: true })
  waitingListAllocation!: WaitingListAllocation

  showExtraInfo = false

  get bookingWaitingListPropositions (): InquiryWaitingListAllocationProposition[] {
    return this.waitingListAllocation.sortedBookingWaitingListAllocationPropositions
  }

  get bookedBookingWaitingListProposition (): InquiryWaitingListAllocationProposition | undefined {
    if (!this.waitingListAllocation.didAllocateWaitingListSpots) {
      return undefined
    }

    return this.bookingWaitingListPropositions.find(x => x.isBookedBookingWaitingListAllocationProposition) ?? undefined
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.did-allocate-waiting-list-spots {
  background-color: $partou-secondary-green;
}

.did-not-allocate-waiting-list-spots {
  background-color: $partou-red;
}

.waiting-list-allocation-card-header {
  padding-left: 92px;
  padding-right: 98px;
}
</style>
