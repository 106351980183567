import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'

const authGuard : NavigationGuard<Vue> = async (to: Route, from: Route, next: NavigationGuardNext<Vue>) : Promise<void> => {
  const authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  if (!authService.isAuthenticated) {
    await authService.loginAsync(false, window.location.origin + to.fullPath)
    return
  }

  next()
}

export default authGuard
