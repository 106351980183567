<template>
  <div class="settings-page-container mt-9">
    <div>
      <h1>{{ $t('pages.settings.globalActions') }}</h1>
      <div class="settings-action-container">
        <SettingCard
          :actionTitle="$t('pages.settings.actions.sendEmails.header')"
          :actionInformation="$t('pages.settings.actions.sendEmails.actionInformation')"
          :buttonText="$t('pages.settings.actions.sendEmails.buttonText')"
          :isLoading="isSendingEmails"
          :errorMessage="sendEmailsError ? $t('pages.settings.actions.sendEmails.error') : ''"
          @action="onSendEmailsClickedAsync"
        />
      </div>
    </div>
    <div>
      <h1>{{ $t('pages.settings.export') }}</h1>
      <div class="settings-action-container">
        <SettingCard
          ref="export-service-settings-card"
          v-if="userHasGetServicePermission"
          :actionTitle="$t('pages.settings.actions.exportServiceSettings.header')"
          :actionInformation="$t('pages.settings.actions.exportServiceSettings.actionInformation')"
          :buttonText="$t('pages.settings.actions.exportServiceSettings.buttonText')"
          :isLoading="isExportingServiceSettings"
          :errorMessage="exportServiceSettingsError ? $t('pages.settings.actions.exportServiceSettings.error') : ''"
          @action="onExportServiceSettingsClickedAsync"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { ISendEmailService } from '@/services/SendEmailService/ISendEmailService'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import Vue from 'vue'
import Component from 'vue-class-component'
import SettingCard from './SettingCard/SettingCard.vue'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { formatCurrentDateForCsvExportTitle } from '@/utils/dateUtils'

@Component({
  components: { PartouCard, PartouButton, SettingCard }
})
export default class SettingsPage extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  private _sendEmailService!: ISendEmailService
  private _serviceService!: IServiceService

  isSendingEmails = false
  isExportingServiceSettings = false
  sendEmailsError = false
  exportServiceSettingsError = false

  beforeCreate () : void {
    this._sendEmailService = container.get<ISendEmailService>(SERVICE_IDENTIFIERS.ISendEmailService)
    this._serviceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  }

  get userHasGetServicePermission ():boolean {
    return this.authService.hasPermission([Permission.GetService])
  }

  async onSendEmailsClickedAsync () : Promise<void> {
    this.sendEmailsError = false
    this.isSendingEmails = true
    await this._sendEmailService.triggerSendEmailsAsync()
      .catch(() => {
        this.sendEmailsError = true
      })
    this.isSendingEmails = false
  }

  async onExportServiceSettingsClickedAsync () : Promise<void> {
    this.exportServiceSettingsError = false
    this.isExportingServiceSettings = true
    const result = await this._serviceService.exportServiceSettingsAsync()
      .catch(() => {
        this.exportServiceSettingsError = true
      })
    if (result) {
      const formattedDate = formatCurrentDateForCsvExportTitle()
      const a = document.createElement('a')
      a.href = `data:text/pdf;base64,${result}`
      a.download = `locaties_instellingen_${formattedDate}.csv`
      a.click()
    }
    this.isExportingServiceSettings = false
  }
}
</script>

<style lang="scss">
.settings-action-container {
  .card {
    filter: none;
  }
}

.settings-action-container > div {
  border-radius: 24px !important;
}
</style>
<style lang="scss" scoped>
.settings-page-container {
  display: flex;
  flex-direction: column;
  gap: 76px;
  padding-left: 31px;

  h1 {
    line-height: 34.5px;
  }
}

.settings-action-container {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 365px);
  grid-row-gap: 50px;
  justify-content: space-between;
}
</style>
