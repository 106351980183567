import Vue from 'vue'
import Vuex, { StoreOptions, Store } from 'vuex'
import IState from '@/store/IState'
import { getModules } from '@/store'

Vue.use(Vuex)

export function createStore (): Store<IState> {
  const storeOptions: StoreOptions<IState> = {
    modules: getModules()
  }

  const store = new Store<IState>(storeOptions)

  return store
}
