enum WaitingListPropositionStatus {
    Created = 'Created',
    Proposed = 'Proposed',
    Declined = 'Declined',
    Expired = 'Expired',
    Definitive = 'Definitive',
    Cancelled = 'Cancelled',
    Reserved = 'Reserved',
    Revoked = 'Revoked'
}

export default WaitingListPropositionStatus
