<template>
  <v-app id="app">
    <v-main id="main-content">
      <AppMenu/>

      <v-container>
        <router-view/>

        <div id="app-version">Versie: {{VUE_APP_VERSION}}</div>
      </v-container>

    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import 'document-register-element/build/document-register-element'
import AppMenu from './AppMenu.vue'

@Component({
  components: { AppMenu }
})
export default class App extends Vue {
  public VUE_APP_VERSION = process.env.VUE_APP_VERSION
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
@import '@/styles/variables/variables.scss';

#app-version {
  margin-top: 13px;
  text-align: center;
  font-size: 12px;
  color: $partou-primary-black-ninety;
  z-index: 5000;
}

.home + #app-version {
  color: $partou-primary-white;
  position: absolute;
  bottom: 40px;
  left: 50%;
}
</style>
