import { FetchConfigurationByKeyDocument, FetchConfigurationByKeyQuery, FetchConfigurationByKeyQueryVariables } from '@/models'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import Permission from '@/models/enums/Permission'

@injectable()
export default class ConfigurationService implements IConfigurationService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getConfigurationByKeyAsync<T> (variables: FetchConfigurationByKeyQueryVariables): Promise<T> {
    const result = await this.apollo.query<FetchConfigurationByKeyQuery>({
      query: FetchConfigurationByKeyDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })
    return result.data.configuration[0]?.value as T
  }
}
