<template>
  <div class="col-12">
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="100"></v-progress-circular>
    </v-overlay>
    <v-card :flat="true" class="rounded-xl card pa-8">
      <v-card-text>
        <h1> {{$t('services.controlVariables.settings')}} </h1>
        <h2>{{$t('services.controlVariables.planSettings')}}</h2>
        <PlanningHorizonSettings class="mb-12" v-if="canGetSettings" :planningHorizons="planningHorizonOptions" :activePlanningHorizon="activePlanningHorizon" :editEnabled="editEnabled" @onPlanningHorizonChanged="onPlanningHorizonChanged"/>
        <FlexMarginSettings v-if="canGetSettings" v-model="flexMargin" :editEnabled="editEnabled"/>

        <h2>{{$t('services.controlVariables.waitinglistManager')}}</h2>
        <WaitingListActualisationIntervalsComponent class="mb-12" v-if="canGetSettings" :waitingListActualisationIntervalsOptions="waitingListActualisationIntervalsOptions" :activeWaitingListActualisationIntervals="activeWaitingListActualisationIntervals" :editEnabled="editEnabled" @onWaitingListActualisationIntervalsChanged="onWaitingListActualisationIntervalsChanged"/>

        <h2 v-if="canGetPricing || canGetSettings"> {{$t('services.controlVariables.offer')}}</h2>
        <ServicePricingSettings v-if="canGetPricing" v-model="servicePricings" :editEnabled="editEnabled"/>
        <SubscriptionActivator v-if="canGetPricing" class="mt-10" :selectableSubscriptions="selectableSubscriptions" :servicePricings="servicePricings" :editEnabled="editEnabled" @onSubscriptionChanged="onSubscriptionChanged" :service="service"/>
        <OrderProductSettings v-if="canGetSettings" v-model="showLinkToPartouOffers" :editEnabled="editEnabled"/>
        <template v-if="canManageProducts">
          <h2>{{$t('services.controlVariables.temporarySettings')}}</h2>
          <TemporarySettings v-model="isUsingFlexkidsProducts" :selectableSubscriptions="selectableSubscriptions" :servicePricings="servicePricings" :service="service" :editEnabled="editEnabled"/>
        </template>
        <template v-if="offer && canCallActions">
          <h2>{{$t('services.controlVariables.actions')}}</h2>
          <Actions :offer="offer" @onCapacityCalculated="onCapacityCalculated"/>
        </template>
        <div name="buttons" v-if="canManageSettings || canManagePricing">
          <v-btn v-if="!editEnabled" :disabled="isLoading" @click="onEditClicked()" id="edit-button" class="edit-button" ref="edit-button" fab dark large color="primary">
            <v-icon dark> mdi-pencil </v-icon>
          </v-btn>
          <v-btn v-if="editEnabled" :disabled="isLoading" @click="onSaveClickedAsync()" id="save-button" class="save-button" ref="save-button" fab dark large color="primary">
            <v-icon dark> mdi-floppy </v-icon>
          </v-btn>
          <v-btn v-if="editEnabled" :disabled="isLoading" @click="onCancelClickedAsync()" id="cancel-button" class="cancel-button" ref="cancel-button" fab dark large color="blue">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouSlider from '@/components/PartouComponents/PartouSlider.vue'
import { FlexMargin, Service, Subscription, SubscriptionService, Offer } from '@/models'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { parseStringToDayOfWeek } from '@/models/enums/DayOfWeek'
import Delay from '@/utils/decorators/delayDecorator'
import FlexMarginSettings from '@/pages/ServicesPage/ControlVariables/FlexMargin/FlexmarginSettings.vue'
import SubscriptionActivator from './Subscriptions/SubscriptionActivator.vue'
import { SelectableSubscription } from './Subscriptions/SelectableSubscription'
import { ISubscriptionService } from '@/services/SubscriptionService/ISubscriptionService'
import ServicePricingSettings from './ServicePricing/ServicePricingSettings.vue'
import { ServicePricingExtended } from './ServicePricing/ServicePricingExtended'
import PlanningHorizonSettings from './PlanningHorizon/PlanningHorizonSettings.vue'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import Actions from '@/pages/ServicesPage/ControlVariables/Actions/Actions.vue'
import TemporarySettings from '@/pages/ServicesPage/ControlVariables/TemporarySettings/TemporarySettings.vue'
import { IOfferService } from '@/services/OfferService/IOfferService'
import { IServiceSettingsService } from '@/services/ServiceSettingsService/IServiceSettingsService'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import PlanSettings from '@/models/configuration/PlanSettings'
import WaitingListActualisationIntervals from '@/models/configuration/WaitingListActualisationIntervals'
import WaitingListActualisationIntervalsComponent from './WaitingListActualisationIntervals/WaitingListActualisationIntervals.vue'
import OrderProductSettings from '@/pages/ServicesPage/ControlVariables/OrderProductSettings/OrderProductSettings.vue'

@Component({
  components: { PartouSlider, FlexMarginSettings, SubscriptionActivator, ServicePricingSettings, PlanningHorizonSettings, Actions, TemporarySettings, WaitingListActualisationIntervalsComponent, OrderProductSettings }
})
export default class ControlVariables extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  serviceId!: string

  subscriptionService! : ISubscriptionService
  serviceService!: IServiceService
  offerService!: IOfferService
  serviceSettingsService!: IServiceSettingsService

  service : Partial<Service> = {}
  selectableSubscriptions : SelectableSubscription[] = []
  servicePricings : ServicePricingExtended[] = []
  activePlanningHorizon : number | null = null
  planningHorizonOptions : number[] = []
  offer? : Partial<Offer> | null = null
  flexMargin : FlexMargin = { Monday: 0, Tuesday: 0, Wednesday: 0, Thursday: 0, Friday: 0, Saturday: 0, Sunday: 0 }
  waitingListActualisationIntervalsOptions : WaitingListActualisationIntervals[] = []
  activeWaitingListActualisationIntervals : WaitingListActualisationIntervals | null = null
  showLinkToPartouOffers = false

  isLoading = false
  editEnabled = false
  slidersKey = 0
  isUsingFlexkidsProducts = false

  beforeCreate () : void {
    this.subscriptionService = container.get<ISubscriptionService>(SERVICE_IDENTIFIERS.ISubscriptionService)
    this.serviceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
    this.offerService = container.get<IOfferService>(SERVICE_IDENTIFIERS.IOfferService)
    this.serviceSettingsService = container.get<IServiceSettingsService>(SERVICE_IDENTIFIERS.IServiceSettingsService)
  }

  @Watch('servicePricings', { deep: true, immediate: true })
  onServicePricingsChanged () : void {
    if (this.servicePricings.length > 0 && this.servicePricings.filter(x => !x.isDeleted).length === 0) {
      for (const selectableSubscription of this.selectableSubscriptions) {
        selectableSubscription.subscriptionService.isAvailable = false
        selectableSubscription.isDirty = true
      }
    }
  }

  get canGetSettings ():boolean {
    return this.authService.hasPermission([Permission.GetSetting])
  }

  get canGetPricing ():boolean {
    return this.authService.hasPermission([Permission.GetPricing])
  }

  get canManageSettings ():boolean {
    return this.authService.hasPermission([Permission.ManageSetting])
  }

  get canManagePricing ():boolean {
    return this.authService.hasPermission([Permission.ManagePricing])
  }

  get canCallActions ():boolean {
    return this.authService.hasPermission([Permission.CallActions])
  }

  get canManageProducts ():boolean {
    return this.authService.hasPermission([Permission.ManageProduct])
  }

  onFlexMarginChanged () : void {
    this.slidersKey++
  }

  @Watch('serviceId', { immediate: true })
  @Delay(1000)
  async fetchPageDataAsync () : Promise<void> {
    this.editEnabled = false
    this.isLoading = true
    this.getConfigurationTableDataAsync()
    await this.getServiceByIdAsync(this.serviceId)
    await this.getOfferAsync(this.serviceId)
    if (this.service.id) {
      if (this.canGetSettings) {
        this.flexMargin = this.service.flexMargin
        Object.keys(this.flexMargin).forEach((key) => {
          this.flexMargin[parseStringToDayOfWeek(key)] *= 100
        })

        await this.getActiveServiceSettings()
      }
      if (this.canGetPricing) {
        const defaultSubscriptions = await this.getDefaultSubscriptions()
        this.selectableSubscriptions = this.createSelectableSubscriptions(defaultSubscriptions, await this.getSelectedSubscriptions(this.service.id))
      }
    }
    this.isLoading = false
  }

  async getOfferAsync (id: string): Promise<void> {
    this.offer = await this.offerService.getOfferByIdAsync(id)
  }

  onCapacityCalculated () : void {
    if (this.offer?.id) {
      this.getOfferAsync(this.offer.id)
    }
  }

  createSelectableSubscriptions (defaultSubscriptions : Subscription[], selectedSubscriptions? : SubscriptionService[]) : SelectableSubscription[] {
    const selectableSubscriptions : SelectableSubscription[] = []

    defaultSubscriptions.forEach(defaultSubscription => {
      const subscription = defaultSubscription
      const subscriptionService = selectedSubscriptions?.find(x => x.subscriptionId === subscription.id) ?? { serviceId: this.serviceId, subscriptionId: subscription.id, isAvailable: false }
      selectableSubscriptions.push({ subscription, subscriptionService, isDirty: false })
    })
    return selectableSubscriptions
  }

  onSubscriptionChanged (subscriptions : SelectableSubscription[]) : void {
    this.selectableSubscriptions = subscriptions
  }

  onPlanningHorizonChanged (planningHorizon : number) : void {
    this.activePlanningHorizon = planningHorizon
  }

  onWaitingListActualisationIntervalsChanged (waitingListActualisationIntervals : WaitingListActualisationIntervals) {
    this.activeWaitingListActualisationIntervals = waitingListActualisationIntervals
  }

  async getDefaultSubscriptions () : Promise<Subscription[]> {
    return await this.subscriptionService.getDefaultSubscriptionsAsync()
  }

  async getSelectedSubscriptions (serviceId : string) : Promise<SubscriptionService[]> {
    return await this.subscriptionService.getSubscriptionWithPricingsByServiceIdAsync({ serviceId })
  }

  async getActiveServiceSettings () : Promise<void> {
    const serviceSettings = await this.serviceSettingsService.getServiceSettingsByServiceIdAsync(this.serviceId)

    this.activeWaitingListActualisationIntervals = {
      inquiryExpiresInInMinutes: serviceSettings.inquiryExpiresInInMinutes,
      sendRemainOnWaitingListMailInInMinutes: serviceSettings.sendRemainOnWaitingListMailInInMinutes,
      sendRemainOnWaitingListReminderMailInInMinutes: serviceSettings.sendRemainOnWaitingListReminderMailInInMinutes
    }
    this.activePlanningHorizon = serviceSettings?.planningHorizonInMonths
    this.isUsingFlexkidsProducts = serviceSettings?.useFlexkidsProducts
    this.showLinkToPartouOffers = serviceSettings?.showLinkToPartouOffers
  }

  async getServiceByIdAsync (id: string) : Promise<void> {
    if (this.canGetSettings) {
      this.service = await this.serviceService.getControlVariablesByServiceIdAsync(id)
    } else {
      this.service = await this.serviceService.getOneAsync(id)
    }

    if (this.canGetPricing && this.service) {
      this.servicePricings = this.service.servicePricings?.map(x => { return { ...x, isDirty: false, isDeleted: false, isAdded: false } }) ?? []
    }
  }

  get days () : string[] {
    return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const resultPlanHorizonConfiguration: PlanSettings = await configurationService.getConfigurationByKeyAsync({ key: 'plan_settings', date: new Date() })
    this.planningHorizonOptions = resultPlanHorizonConfiguration.planningHorizonOptions

    const resultWaitingListActualisationIntervalsConfiguration: {waitingListActualisationIntervals: WaitingListActualisationIntervals[]} = await configurationService.getConfigurationByKeyAsync({ key: 'waiting_list_actualisation_settings', date: new Date() })
    this.waitingListActualisationIntervalsOptions = resultWaitingListActualisationIntervalsConfiguration.waitingListActualisationIntervals
  }

  async onCancelClickedAsync () : Promise<void> {
    this.editEnabled = false
    await this.fetchPageDataAsync()
  }

  onEditClicked () : void {
    this.editEnabled = true
  }

  @Delay(500)
  async onSaveClickedAsync () : Promise<void> {
    this.isLoading = true

    if (this.canManageSettings) {
      await this.updateSettingsAsync()
    }

    if (this.canManagePricing) {
      await this.updateSubscriptionServicesAsync()
      await this.updateServicePricingAsync()
    }

    this.isLoading = false
    this.editEnabled = false
    await this.fetchPageDataAsync()
  }

  async updateSettingsAsync () : Promise<void> {
    const newFlexMargin = { ...this.flexMargin }
    Object.keys(newFlexMargin).forEach((key) => {
      newFlexMargin[parseStringToDayOfWeek(key)] /= 100
    })
    await this.serviceService.setFlexMarginByServiceIdAsync({ id: this.serviceId, flexMargin: newFlexMargin })
    await this.serviceSettingsService.setPlanningHorizonByServiceIdAsync(this.serviceId, this.activePlanningHorizon ?? 0)
    await this.serviceSettingsService.setWaitingListActualisationIntervalsAsync(this.serviceId, this.activeWaitingListActualisationIntervals?.inquiryExpiresInInMinutes ?? 0, this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListMailInInMinutes ?? 0, this.activeWaitingListActualisationIntervals?.sendRemainOnWaitingListReminderMailInInMinutes ?? 0)
    await this.serviceSettingsService.setUseFlexkidsProductsAsync(this.serviceId, this.isUsingFlexkidsProducts)
    await this.serviceSettingsService.setShowLinkToPartouOffersAsync(this.serviceId, this.showLinkToPartouOffers)
  }

  async updateSubscriptionServicesAsync () : Promise<void> {
    const dirtySubscriptions = this.selectableSubscriptions.filter(selectableSubscription => selectableSubscription.isDirty)
    for (const dirtySubscription of dirtySubscriptions) {
      if (dirtySubscription.subscriptionService && this.subscriptionService) {
        await this.subscriptionService.upsertSubscriptionServiceAsync(dirtySubscription.subscriptionService)
      }
    }

    if (this.service?.id && this.service?.isCustomSubscriptionEnabled !== undefined) {
      await this.serviceService.toggleCustomSubscriptionAsync(this.service.id, this.service.isCustomSubscriptionEnabled)
    }
  }

  async updateServicePricingAsync () : Promise<void> {
    const deletedPricings = this.servicePricings.filter(pricing => pricing.isDeleted)
    const addedPricings = this.servicePricings.filter(pricing => pricing.isAdded && !pricing.isDeleted)
    const dirtyPricings = this.servicePricings.filter(pricing => pricing.isDirty && pricing.id && !pricing.isDeleted)

    for (const deletedPricing of deletedPricings) {
      if (this.subscriptionService && deletedPricing.id) {
        await this.subscriptionService.deletedServicePricingAsync({ id: deletedPricing.id })
      }
    }

    for (const addedPricing of addedPricings) {
      if (this.subscriptionService && this.serviceId) {
        await this.subscriptionService.insertSubscriptionPricingAsync({ hourRate: addedPricing.hourRate, serviceId: this.serviceId, validFrom: addedPricing.validFrom, validUntil: addedPricing.validUntil })
      }
    }

    for (const dirtyPricing of dirtyPricings) {
      if (this.subscriptionService && dirtyPricing.id) {
        await this.subscriptionService.updateServicePricingByIdAsync({ id: dirtyPricing.id, validFrom: dirtyPricing.validFrom, validUntil: dirtyPricing.validUntil })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

  h2 {
    color: $partou-secondary-bordeaux !important;
    margin: 48px 0;
  }

 .card {
    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));
  }

  .save-button, .edit-button {
    position: absolute;
    right: -32px;
    top: 88px;
  }

  .cancel-button {
    z-index: 6;
    position: absolute;
    right: -32px;
    top: 162px;
  }
</style>
