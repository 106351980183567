<template>
    <div v-if="canGetService" class="service-picker">
      <span>{{$t('servicePicker.locationSelection')}}</span>
      <PartouAutocomplete
        class="rounded-xl picker-auto-complete"
        :items="sortedServices"
        :value="value"
        @input="onServiceChanged"
        item-text="name"
        item-value="id"
        label="name"
        :appendIconString="'$vuetify.icons.partouChevronDown'"
        :clearable="isClearable"
        :placeholder="$t('servicePicker.searchPlaceholder')"
        :noDataText="$t('servicePicker.noData')" />
    </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { Service } from '@/models'
import Permission from '@/models/enums/Permission'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'

@Component({
  components: { PartouAutocomplete }
})
export default class ServicePicker extends Vue {
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true, default: [] })
  services!: Service[]

  @Prop({ required: false, default: null })
  value!: Service | null

  @Prop({ required: false, default: false })
  isClearable!: boolean

  get canGetService ():boolean {
    return this.authService.hasPermission([Permission.GetService])
  }

  get sortedServices () : Array<Service> {
    return this.services.sort((a, b) => a.name && b.name && a.name > b.name ? 1 : -1)
  }

  @Emit('input')
  onServiceChanged (service:Service): Service {
    return service
  }
}
</script>
<style lang="scss">
@import "@/styles/variables/variables.scss";

.service-picker {
  min-width: 300px;
}

.service-picker.background-grey {
  .vs__selected-options {
  background: $partou-background !important;
  }
  .vs__dropdown-menu {
    background: $partou-background !important;
  }
}

.service-picker .vs__selected-options {
  height: 40px;

  .vs__search {
    font-weight: bold;
  }
  .vs__selected {
    display:flex;
    font-weight: bold;
    align-items: center;
    color: $partou-primary-black-ninety  !important;
  }
  .vs__search::placeholder {
    color: $partou-primary-black-fourty !important;
    font-weight: normal;
  }
}

.picker-auto-complete {
  margin-top: 6px;
}
</style>
