<template>
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.09348 11.646C6.6163 11.3679 6.00406 11.5293 5.72599 12.0065C5.44793 12.4837 5.60934 13.0959 6.08652 13.374L7.09348 11.646ZM12.9165 17.354C13.3937 17.6321 14.0059 17.4707 14.284 16.9935C14.5621 16.5163 14.4007 15.9041 13.9235 15.626L12.9165 17.354ZM13.914 6.37369C14.391 6.09532 14.5521 5.48297 14.2737 5.00597C13.9953 4.52897 13.383 4.36795 12.906 4.64631L13.914 6.37369ZM6.08597 8.62631C5.60897 8.90468 5.44795 9.51703 5.72631 9.99403C6.00468 10.471 6.61703 10.6321 7.09403 10.3537L6.08597 8.62631ZM18 4C18 5.10457 17.1046 6 16 6V8C18.2091 8 20 6.20914 20 4H18ZM16 6C14.8954 6 14 5.10457 14 4H12C12 6.20914 13.7909 8 16 8V6ZM14 4C14 2.89543 14.8954 2 16 2V0C13.7909 0 12 1.79086 12 4H14ZM16 2C17.1046 2 18 2.89543 18 4H20C20 1.79086 18.2091 0 16 0V2ZM6 11C6 12.1046 5.10457 13 4 13V15C6.20914 15 8 13.2091 8 11H6ZM4 13C2.89543 13 2 12.1046 2 11H0C0 13.2091 1.79086 15 4 15V13ZM2 11C2 9.89543 2.89543 9 4 9V7C1.79086 7 0 8.79086 0 11H2ZM4 9C5.10457 9 6 9.89543 6 11H8C8 8.79086 6.20914 7 4 7V9ZM18 18C18 19.1046 17.1046 20 16 20V22C18.2091 22 20 20.2091 20 18H18ZM16 20C14.8954 20 14 19.1046 14 18H12C12 20.2091 13.7909 22 16 22V20ZM14 18C14 16.8954 14.8954 16 16 16V14C13.7909 14 12 15.7909 12 18H14ZM16 16C17.1046 16 18 16.8954 18 18H20C20 15.7909 18.2091 14 16 14V16ZM6.08652 13.374L12.9165 17.354L13.9235 15.626L7.09348 11.646L6.08652 13.374ZM12.906 4.64631L6.08597 8.62631L7.09403 10.3537L13.914 6.37369L12.906 4.64631Z" :fill="color"/>
  </svg>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator'

@Component
export default class PartouShare extends Vue {
  @Prop({ required: true })
  color!: string
}
</script>
