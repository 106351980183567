<template>
  <div>
    <v-btn ref="button" :id="$attrs.id ? $attrs.id + '-btn' : undefined" class="pr-5 pl-5 rounded-pill d-flex flex-row outline-on-focus"
      :class="[className, {
        'button-size-large': size === 'large',
        'button-size-normal': size === 'normal',
        'button-size-small': size === 'small',
        'partou-primary-gradient': colour === 'partou-primary-gradient',
        'partou-secondary-bright-green': colour === 'partou-secondary-bright-green',
        'partou-black-ninety' : colour === 'partou-black-ninety',
        'partou-black-eighty' : colour === 'partou-black-eighty',
        'partou-secondary-bordeaux': colour == 'partou-secondary-bordeaux' || colour == 'partou-secondary-bordeaux-fourty',
        'partou-secondary-bordeaux-fourty': colour == 'partou-secondary-bordeaux-fourty',
        'partou-secondary-bordeaux-diop': colour == 'partou-secondary-bordeaux-diop',
        'partou-primary-salmon': colour == 'partou-primary-salmon',
        'width-100': fullWidth
      }]"
      v-on="$listeners"
      v-bind="$attrs"
      :loading="loading"
      :disabled="disabled"
      outlined>
        <slot name="append-item">
          <div/>
        </slot>
        <v-icon v-if="iconPosition == 'left' && iconName" :class="iconSize" class="btn-icon left mr-1" >{{iconName}}</v-icon>
          <span class="pt-2 pb-2" :class="{ 'font-size-11px': fontSize === 11, 'font-size-14px': fontSize === 14, 'font-size-16px': fontSize === 16, 'font-size-24px': fontSize === 24 }" v-html="text"/>
        <v-icon v-if="iconPosition == 'right' && iconName" :class="iconSize" class="btn-icon right ml-1">{{iconName}}</v-icon>
        <template v-slot:loader>
          <div role="progressbar" aria-valuemin="0" aria-valuemax="100" class="v-progress-circular v-progress-circular--indeterminate">
            <v-icon class='mr-2'>$vuetify.icons.partouLoader</v-icon>
          </div>
        </template>
        <slot name="prepend-item">
          <div/>
        </slot>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import { Component as VueComponent } from 'vue'

@Component
export default class PartouButton extends Vue {
  @Ref('button')
  button!: VueComponent & { $el: HTMLButtonElement }

  @Prop({ required: false, default: '' })
  text? : string

  @Prop({ required: false, default: 'normal' })
  iconSize? : 'normal' | 'large' | 'extra-large'

  @Prop({ required: false, default: 16 })
  fontSize? : 11 | 14 | 16 | 24

  @Prop({ required: false, default: '' })
  iconName? : string

  @Prop({ required: false, default: 'right' })
  iconPosition? : 'left' | 'right'

  @Prop({ required: false, default: 'normal' })
  size? : 'normal' | 'small' | 'large'

  @Prop({ required: false, default: 'partou-primary-gradient' })
  colour! : 'partou-primary-gradient' | 'partou-secondary-bright-green' | 'partou-black-ninety' | 'partou-secondary-bordeaux' | 'partou-secondary-bordeaux-fourty' | 'partou-black-eighty' | 'partou-primary-salmon'

  @Prop({ required: false, default: false })
  disabled?: boolean

  @Prop({ required: false, default: false })
  loading?: boolean

  @Prop({ required: false, default: '' })
  className! : string

  @Prop({ required: false, default: false })
  fullWidth?: boolean

  focus () : void {
    this.$nextTick(() => this.button.$el.focus())
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/typography.scss';

  .width-100 {
    width: 100%;
  }
  .centered {
    margin: auto auto;
  }
  .v-btn {
    &.button-size-large {
      @extend .button-size-large-typography;
      &.partou-black-ninety {
        @extend .button-size-large-typography-medium;
        &.v-btn--disabled {
          @extend .button-size-large-typography;
        }
      }

      min-height: 56px !important;
      height: fit-content !important;

      .v-btn__content {
        span {
          &.font-size-11px {
            font-size: 11px !important;
          }
          &.font-size-14px {
            font-size: 14px !important;
          }
          &.font-size-16px {
            font-size: 16px !important;
          }
          &.font-size-24px {
            font-size: 24px !important;
          }
        }
        svg {
          &.normal {
            width: 16px !important;
            height: 16px !important;
          }
          &.large {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      .v-progress-circular {
        width: 40px !important;
        height: 40px !important;
        padding-left: 8px !important;
      }

      svg {
        color: $partou-primary-white !important;
      }
    }

    &.button-size-normal {
      @extend .button-size-normal-typography;
      &.partou-black-ninety {
        @extend .button-size-normal-typography-medium;
        &.v-btn--disabled {
          @extend .button-size-normal-typography;
        }
      }

      min-height: 40px !important;
      height: fit-content !important;
      .v-btn__content {
        span {
          &.font-size-11px {
            font-size: 11px !important;
          }
          &.font-size-14px {
            font-size: 14px !important;
          }
          &.font-size-16px {
            font-size: 16px !important;
          }
          &.font-size-24px {
            font-size: 24px !important;
          }
        }
        svg {
          &.normal {
            width: 16px !important;
            height: 16px !important;
          }
          &.large {
            width: 24px !important;
            height: 24px !important;
          }
        }
      }

      .v-progress-circular {
        width: 40px !important;
        height: 40px !important;
        padding-left: 8px !important;
      }

      svg {
        color: $partou-primary-white !important;
      }
    }

    &.button-size-small {
      @extend .button-size-small-typography;
      &.partou-black-ninety {
        @extend .button-size-small-typography-medium;
        &.v-btn--disabled {
          @extend .button-size-small-typography;
        }
      }

      height: 24px !important;
      .v-btn__content {
        span {
          &.font-size-11px {
            font-size: 11px !important;
          }
          &.font-size-14px {
            font-size: 14px !important;
          }
          &.font-size-16px {
            font-size: 16px !important;
          }
          &.font-size-24px {
            font-size: 24px !important;
          }
        }
        font-size: 11px !important;
        svg {
          width: 8px !important;
          height: 8px !important;
        }
      }

      .v-progress-circular {
        width: 32px !important;
        height: 32px !important;
        padding-left: 8px !important;
      }
    }

    .btn-icon {
      &.large {
        .v-btn__content {
          font-size: 24px !important;
        }
        width: 24px !important;
        height: 24px !important;
      }

      &.left {
        margin-right: 8px !important;
      }

      &.right {
        margin-left: 8px !important;
      }
    }
  }

  .partou-primary-gradient {
    text-transform: unset !important;
    background: $partou-primary-gradient;
    color: $partou-primary-white !important;
    span, i, .v-icon {
      color: $partou-primary-white !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-white !important;

        path {
          fill: $partou-primary-white !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }

  .partou-primary-gradient:hover {
    text-transform: unset !important;
    background: $partou-primary-salmon !important;
    color: $partou-primary-white !important;
    span, i, .v-icon {
      color: $partou-primary-white !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-white !important;

        path {
          fill: $partou-primary-white !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }

  .partou-black-ninety {
    text-transform: unset !important;
    background: transparent;
    border: 1px solid $partou-primary-black-ninety;
    color: $partou-primary-black-ninety !important;
    span, i, .v-icon {
      color: $partou-primary-black-ninety !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-black-ninety !important;
      }
    }

    .v-progress-circular svg {
      color: $partou-primary-black-ninety !important;
    }

    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }

      span, i, .v-icon {
        .btn-icon {
          fill: $partou-primary-white !important;
        }
      }
    }
  }

  .partou-black-ninety:hover {
    text-transform: unset !important;
    background: transparent;
    border: 1px solid $partou-secondary-bordeaux;
    color: $partou-secondary-bordeaux !important;
    span, i, .v-icon {
      color: $partou-secondary-bordeaux !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-secondary-bordeaux !important;

        path {
          fill: $partou-secondary-bordeaux !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }

  .partou-black-eighty {
    text-transform: unset !important;
    background: transparent;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: $partou-primary-black-eighty !important;
    span, i, .v-icon {
      color: $partou-primary-black-eighty !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-black-eighty !important;
      }
    }

    .v-progress-circular svg {
      color: $partou-primary-black-eighty !important;
    }

    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }

      span, i, .v-icon {
        .btn-icon {
          fill: $partou-primary-white !important;
        }
      }
    }
  }

  .partou-black-eighty:hover {
    text-transform: unset !important;
    background: transparent;
    border: 1px solid $partou-secondary-bordeaux;
    color: $partou-secondary-bordeaux !important;
    span, i, .v-icon {
      color: $partou-secondary-bordeaux !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-secondary-bordeaux !important;

        path {
          fill: $partou-secondary-bordeaux !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }
  .partou-primary-salmon {
background-color: $partou-primary-salmon;

  }
  .partou-secondary-bordeaux-diop {
    text-transform: unset !important;
    background: transparent;
    border: 1px solid $partou-secondary-bordeaux;
    color: $partou-secondary-bordeaux !important;
    span, i, .v-icon {
      font-weight: bold;
      color: $partou-secondary-bordeaux !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-secondary-bordeaux !important;
      }
    }

    .v-progress-circular svg {
      color: $partou-primary-black-ninety !important;
    }

    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }

      span, i, .v-icon {
        .btn-icon {
          fill: $partou-primary-white !important;
        }
      }
    }
  }

  .partou-secondary-bordeaux-diop:hover {
    text-transform: unset !important;
    background: $partou-primary-white;
    border: 1px solid $partou-primary-salmon;
    color: $partou-primary-salmon !important;
    span, i, .v-icon {
      color: $partou-primary-salmon !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-salmon !important;

        path {
          fill: $partou-secondary-bordeaux !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }

  .partou-secondary-bright-green {
    text-transform: unset !important;
    background: $partou-secondary-bright-green !important;
    color: $partou-primary-white !important;
    span, i, .v-icon {
      color: $partou-primary-white !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-white !important;

        path {
          fill: $partou-primary-white !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-primary-black-thirty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
  }

  .partou-secondary-bordeaux {
    text-transform: none !important;
    background: $partou-secondary-bordeaux !important;
    color: $partou-primary-white !important;
    span, i, .v-icon {
      color: $partou-primary-white !important;
      .btn-icon {
        margin-left: 4px;
        fill: $partou-primary-white !important;

        path {
          fill: $partou-primary-white !important;
        }
      }
    }
    &.v-btn--disabled {
      color: $partou-primary-white !important;
      border: none;
      background: $partou-secondary-bordeaux-fourty !important;

      .v-progress-circular svg {
        color: $partou-primary-white !important;
      }
    }
    &-fourty {
      background: $partou-secondary-bordeaux-fourty !important;
    }
  }
</style>
