import { RouteConfig } from 'vue-router'

import HomePage from '@/pages/HomePage'
import ServicesPage from '@/pages/ServicesPage/ServicesPage.vue'
import ServiceDetail from '@/pages/ServicesPage/ServiceDetail'
import ControlVariables from '@/pages/ServicesPage/ControlVariables/ControlVariables.vue'
import ServiceOffer from '@/pages/ServicesPage/ServiceOffer/ServiceOffer.vue'

import NotFoundPage from '@/pages/statusPages/404NotFound.vue'
import ForbiddenPage from '@/pages/statusPages/403Forbidden.vue'
import AuthGuard from './guards/AuthGuard'
import RegistrationPage from '@/pages/RegistrationPage/RegistrationPage.vue'
import WaitingListPropositionPage from '@/pages/WaitingListPropositionPage/WaitingListPropositionPage.vue'
import LivegangPage from '@/pages/LivegangPage/LivegangPage.vue'
import WaitingList from '@/pages/ServicesPage/WaitingList/WaitingList.vue'
import ServiceFeatures from '@/pages/ServicesPage/ServiceFeature/ServiceFeatures.vue'
import ServiceAnalysis from '@/pages/Analysis/ServiceAnalysis/ServiceAnalysis.vue'
import Permission from '@/models/enums/Permission'
import WaitingListAnalysisPage from '@/pages/Analysis/WaitingListAnalysis/WaitingListAnalysisPage.vue'
import WaitingListAnalysisPageNew from '@/pages/Analysis/WaitingListAnalysisNew/WaitingListPropositionAnalysisPage.vue'
import AnalysisPage from '@/pages/Analysis/AnalysisPage.vue'
import SettingsPage from '@/pages/SettingsPage/SettingsPage.vue'
import ServiceProducts from '@/pages/ServicesPage/ServiceProduct/ServiceProducts.vue'

export const ROUTES = {
  home: 'home',
  services: 'services',
  serviceDetail: 'serviceDetail',
  serviceOffer: 'serviceOffer',
  serviceWaitingList: 'serviceWaitingList',
  serviceFeatures: 'serviceFeatures',
  serviceProducts: 'serviceProducts',
  serviceControlVariables: 'serviceControlVariables',
  bookingsSinceLivegang: 'bookingsSinceLivegang',
  analysis: 'analysis',
  serviceAnalysis: 'serviceAnalysis',
  serviceAnalysisPreview: 'serviceAnalysisPreview',
  waitingListAnalysis: 'waitingListAnalysis',
  waitingListPropositionAnalysis: 'waitingListPropositionAnalysis',
  serviceWaitingListAnalysis: 'serviceWaitingListAnalysis',
  serviceWaitingListPropositionAnalysisNew: 'serviceWaitingListPropositionAnalysisNew',
  reservations: 'reservations',
  waitingListPropositions: 'waitingListPropositions',
  settings: 'settings',
  forbidden: 'forbidden',
  notFound: 'notFound'
}

const routes:Array<RouteConfig> = [
  {
    path: '/',
    name: ROUTES.home,
    component: HomePage,
    beforeEnter: AuthGuard,
    meta: { allowAllUsers: true }
  },
  {
    name: ROUTES.bookingsSinceLivegang,
    path: '/bookings/since-livegang',
    component: LivegangPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.GetBooking] }
  },
  {
    path: '/services',
    name: ROUTES.services,
    component: ServicesPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.GetService] },
    children: [
      {
        name: ROUTES.serviceDetail,
        path: '/services/:entityId/info',
        component: ServiceDetail,
        meta: { allowedPermissions: [Permission.GetService] },
        props: true
      },
      {
        name: ROUTES.serviceOffer,
        path: '/services/:serviceId/aanbod',
        component: ServiceOffer,
        meta: { allowedPermissions: [Permission.GetOffer] },
        props: true
      },
      {
        name: ROUTES.serviceWaitingList,
        path: '/services/:serviceId/wachtlijst',
        component: WaitingList,
        meta: { allowedPermissions: [Permission.GetWaitingList] },
        props: true
      },
      {
        name: ROUTES.serviceFeatures,
        path: '/services/:serviceId/kenmerken',
        component: ServiceFeatures,
        meta: { allowedPermissions: [Permission.GetFeature] },
        props: true
      },
      {
        name: ROUTES.serviceProducts,
        path: '/services/:serviceId/producten',
        component: ServiceProducts,
        meta: { allowedPermissions: [Permission.GetProduct] },
        props: true
      },
      {
        name: ROUTES.serviceControlVariables,
        path: '/services/:serviceId/instellingen',
        component: ControlVariables,
        meta: { allowedPermissions: [Permission.GetSetting, Permission.GetPricing] },
        props: true
      }
    ]
  },
  {
    path: '/inschrijvingen',
    name: ROUTES.reservations,
    component: RegistrationPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.GetBooking] }
  },
  {
    path: '/wachtlijst-aanbiedingen',
    name: ROUTES.waitingListPropositions,
    component: WaitingListPropositionPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.GetWaitingList] }
  },
  {
    name: ROUTES.analysis,
    path: '/analyse',
    component: AnalysisPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.GetAnalysis] },
    children: [
      {
        name: ROUTES.serviceAnalysis,
        path: '/analyse/service',
        component: ServiceAnalysis,
        meta: { allowedPermissions: [Permission.GetAnalysis] },
        props: false
      },
      {
        name: ROUTES.serviceAnalysisPreview,
        path: '/analyse/service/:analysisResultId',
        component: ServiceAnalysis,
        meta: { allowedPermissions: [Permission.GetAnalysis] },
        props: true
      },
      {
        name: ROUTES.waitingListAnalysis,
        path: '/analyse/wachtlijst',
        component: WaitingListAnalysisPage,
        meta: { allowedPermissions: [Permission.GetService] },
        props: false
      },
      {
        name: ROUTES.waitingListPropositionAnalysis,
        path: '/analyse/wachtlijst_new',
        component: WaitingListAnalysisPageNew,
        meta: { allowedPermissions: [Permission.GetService] },
        props: false
      },
      {
        name: ROUTES.serviceWaitingListAnalysis,
        path: '/analyse/wachtlijst/:serviceId',
        component: WaitingListAnalysisPage,
        meta: { allowedPermissions: [Permission.GetService] },
        props: true
      },
      {
        name: ROUTES.serviceWaitingListPropositionAnalysisNew,
        path: '/analyse/wachtlijst_new/:serviceId',
        component: WaitingListAnalysisPageNew,
        meta: { allowedPermissions: [Permission.GetService] },
        props: true
      }
    ]
  },
  {
    name: ROUTES.settings,
    path: '/instellingen',
    component: SettingsPage,
    beforeEnter: AuthGuard,
    meta: { allowedPermissions: [Permission.CallActions] }
  },
  {
    path: '/403',
    name: ROUTES.forbidden,
    component: ForbiddenPage,
    meta: { allowAllUsers: true }
  },
  {
    path: '/404',
    name: ROUTES.notFound,
    component: NotFoundPage,
    meta: { allowAllUsers: true }
  },
  {
    path: '*',
    redirect: { name: ROUTES.notFound }
  }
]

export default routes
