<template>
  <div class="planning-horizon-container">
    <div class="description-container">
      <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.planHorizon.title')}}</h2>
      <p>{{$t('services.controlVariables.planHorizon.description')}}</p>
    </div>
    <div class="controls-container">
      <template v-for="planningHorizon in planningHorizons">
        <PartouButton
          class="horizon-option-button"
          :colour="getButtonColour(planningHorizon)"
          :key="planningHorizon"
          :text="$tc('services.controlVariables.planHorizon.monthPlural', planningHorizon)"
          :disabled="!editEnabled"
          @click="onPlanningHorizonChanged(planningHorizon)"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import '@/utils/listUtils'

@Component({
  components: { PartouButton }
})
export default class PlanningHorizonSettings extends Vue {
  @Prop({ required: true })
  planningHorizons! : number[]

  @Prop({ required: true })
  activePlanningHorizon! : number | null

  @Prop({ required: true })
  editEnabled! : boolean

  getButtonColour (planningHorizon: number) : string {
    return planningHorizon === this.activePlanningHorizon ? 'partou-secondary-bright-green' : 'partou-black-ninety'
  }

  @Emit('onPlanningHorizonChanged')
  onPlanningHorizonChanged (planningHorizon: number) : number {
    return planningHorizon
  }
}
</script>

<style lang="scss">
@import '@/styles/variables/variables.scss';

  .horizon-option-button {
    width: 112px;

    button {
      width: 100%;
      border: none !important;
      box-shadow: $partou-elevation-large-subtle !important;

      .v-btn__content {
        font-weight: 700;
      }
    }
  }
</style>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
  .black-ninety {
    color: $partou-primary-black-ninety !important;
  }

  .planning-horizon-container {
    width: 100%;
    display: flex;

    .description-container {
      width: 50%;
    }

    .controls-container {
      width: 50%;
      display: flex;
      gap: 16px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
</style>
