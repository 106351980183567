<template>
 <div>
    <h1 class="pl-8 pt-4">{{ $t('registrations.table.heading') }}</h1>
    <RegistrationTable
      :items="items"
      :expandedItem="expandedItem"
      :totalCount="totalCount"
      :services="services"
      :isLoading="isLoading"
      @onTableOptionsChanged="onTableOptionsChangedAsync"
      @onExpandedChanged="onExpandedChangedAsync">
    </RegistrationTable>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { Booking, Service } from '@/models'
import ServicePicker from '@/components/ServicePicker'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import IBookingService from '@/services/BookingService/IBookingService'
import Debounce from '@/utils/decorators/debounceDecorator'
import RegistrationTable from './RegistrationTable'
import { namespace } from 'vuex-class'
import { ACTIONS, NAMESPACES, STATE } from '@/store'
import RegistrationTableOptions from './RegistrationTable/RegistrationTableOptions'

const serviceModule = namespace(NAMESPACES.service)

@Component({
  components: { ServicePicker, PartouCard, RegistrationTable }
})
export default class RegistrationPage extends Vue {
  bookingService: IBookingService = container.get<IBookingService>(SERVICE_IDENTIFIERS.IBookingService)

  @serviceModule.State(STATE.service.services) services!: Service[]
  @serviceModule.Action(ACTIONS.service.getServices) getServices!: () => void

  created (): void {
    this.getServices()
  }

  items: Partial<Booking>[] = []
  expandedItem? : Partial<Booking> = {}
  totalCount = 0
  isLoading = false

  @Debounce(400)
  async onTableOptionsChangedAsync (tableOptions : RegistrationTableOptions) : Promise<void> {
    this.isLoading = true
    const result = await this.bookingService.getRegistrationPageAsync(tableOptions)
    this.items = result.items
    this.totalCount = result.totalCount

    this.isLoading = false
  }

  async onExpandedChangedAsync (items : Partial<Booking>[]) : Promise<void> {
    this.expandedItem = {}
    if (items[0] && items[0].id) {
      this.isLoading = true
      this.expandedItem = await this.bookingService.getOneAsync(items[0].id)
      this.isLoading = false
    }
  }
}
</script>
