import * as serviceModule from '@/store/serviceModule'
import { Module } from 'vuex'
import IState from '@/store/IState'
import IServiceState from '@/store/serviceModule/IServiceState'

type modules = {
  service: Module<IServiceState, IState>
}

export function getModules () : modules {
  return {
    service: serviceModule.module()
  }
}

export const NAMESPACES = {
  service: serviceModule.NAMESPACE
}

export const ACTIONS = {
  service: serviceModule.ACTIONS
}

export const MUTATIONS = {
  service: serviceModule.MUTATIONS
}

export const STATE = {
  service: serviceModule.STATE
}
