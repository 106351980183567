import Vue from 'vue'
import moment from 'moment'

const timeFilter = function (time: Date): string {
  const timestamp = moment(time, 'HH:mm:ss')
  return timestamp.format('HH:mm:ss')
}

Vue.filter('time', timeFilter)
export default timeFilter
