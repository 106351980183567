<template>
  <v-expansion-panel ref="BookingWaitingListPropositionPanel" id="BookingWaitingListPropositionPanel"
    class="full-width booking-waitinglist-proposition-expension-panel" :class="{
      'best-proposal': bookingWaitingListProposition.isBookedBookingWaitingListAllocationProposition,
      'not-used-for-calculation': !bookingWaitingListProposition.usedForCalculation
    }">
    <div style="width:1%"
      :class="bookingWaitingListProposition.foundBookableWaitingListAllocationProposition ? 'found-bookable-waiting-list-proposition' : 'no-bookable-waiting-list-proposition'">
    </div>
    <div class="full-width">
      <v-expansion-panel-header class="pl-6 pr-6"
        :hide-actions="!bookingWaitingListProposition.usedForCalculation">
        <div class="waiting-list-allocation-card-header full-width pl-6"
          :class=" bookingWaitingListProposition.usedForCalculation ? 'pr-6': 'pr-12'">
          <v-row>
            <v-col cols="2.5">
              <h2>{{ $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.child') }}</h2>
              <p> {{bookingWaitingListProposition.child.firstName}}
                {{bookingWaitingListProposition.child.lastName}}
                ({{bookingWaitingListProposition.child.number}})</p>
            </v-col>
            <v-col cols="2.5">
              <h2>{{ $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.registrationDate') }}</h2>
              <p>{{bookingWaitingListProposition.registrationDate | date}}</p>
            </v-col>
            <v-col cols="2.5">
              <h2>{{ $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.requestDate') }}</h2>
              <p>{{bookingWaitingListProposition.requestedStartDate | date}}</p>
            </v-col>
            <v-col cols="2.5">
              <h2>{{ $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.priority') }}</h2>
              <p>{{bookingWaitingListProposition.waitingListPriority}}</p>
            </v-col>
            <v-col cols="2">
              <h2>{{ $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.waitingListDays') }}</h2>
              <div
                v-for="(days, serviceVarietyName, svIndex) in bookingWaitingListProposition.waitingListDaysPerServiceVariety"
                :key="svIndex">
                <v-row>
                  <p class="ma-0">{{serviceVarietyName}}: &zwnj;</p>
                  <DaysCoverage :daysOfWeek="days"></DaysCoverage>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <div v-if="bookingWaitingListProposition.foundBookableWaitingListAllocationProposition">
            <WaitingListPropositionTable
              :waitingListPropositions="[bookingWaitingListProposition.bestWaitingListAllocationProposition]"
              :showHeader="bookingWaitingListProposition.foundBookableWaitingListAllocationProposition" />
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="bookingWaitingListProposition.usedForCalculation">
        <div class="waiting-list-allocation-card-body full-width pl-12 pr-16">
          <h2 v-if="bookingWaitingListProposition.foundBookableWaitingListAllocationProposition">{{
          $t('waitingListAllocationAnalysis.bookingWaitingListPropositionPanel.allPossibleStartDates') }}</h2>
          <WaitingListPropositionTable
            :waitingListPropositions="getSortedWaitingListPropositions(bookingWaitingListProposition)"
            :showHeader="!bookingWaitingListProposition.foundBookableWaitingListAllocationProposition"
            :bestWaitingListPropositions="bookingWaitingListProposition.bestWaitingListAllocationProposition" />
        </div>
      </v-expansion-panel-content>
    </div>
  </v-expansion-panel>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { InquiryWaitingListAllocationProposition, WaitingListProposition } from '@/models/WaitingListAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListPropositionTable from './WaitingListPropositionTable.vue'

@Component({
  components: { PartouCard, DaysCoverage, WaitingListPropositionTable }
})
export default class BookingWaitingListPropositionPanel extends Vue {
  @Prop({ required: true })
  bookingWaitingListProposition!: InquiryWaitingListAllocationProposition

  getSortedWaitingListPropositions (bookingWaitingListProposition: InquiryWaitingListAllocationProposition): WaitingListProposition[] {
    return bookingWaitingListProposition.sortedWaitingListAllocationPropositions.orderByDescending(x => x.startDate)
  }
}
</script>

<style lang="scss" scoped>
$disabledColor: rgba(0, 0, 0, 0.322);
$green: #91C37D;
$red: #cb5a5c;

.v-expansion-panel-header {
  padding-bottom: 0px;
  padding-top: 0px;
}

.v-expansion-panel-content {
  font-size: 0.9375rem;
  line-height: 1;
}

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.not-used-for-calculation,
.not-used-for-calculation p,
.not-used-for-calculation h1,
.not-used-for-calculation h2,
.not-used-for-calculation span {
  color: $disabledColor !important;
}

.best-proposal {
  border-style: solid;
}

.no-bookable-waiting-list-proposition {
  background-color: $red;
}

.found-bookable-waiting-list-proposition {
  background-color: $green;
}

.not-used-for-calculation .found-bookable-waiting-list-proposition,
.not-used-for-calculation .no-bookable-waiting-list-proposition {
  background-color: $disabledColor;
}

.waiting-list-allocation-card-body {
  padding-top: 15px;
  box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);
}

.booking-waitinglist-proposition-expension-panel {
  display: flex;
}
</style>
