<template>
  <PartouCard :hide-actions="true" class="mt-2 full-width">
    <template v-slot:card-body>
      <ServiceFeatureTable
        :items="items"
        :editEnabled="true"
        :totalCount="totalCount"
        :isLoading="isloading"
        @onTableOptionsChanged="onTableOptionsChanged"
        @onFeatureChanged="onFeatureChanged"
      />
    </template>
  </PartouCard>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { IServiceFeatureService } from '@/services/ServiceFeatureService/IServiceFeatureService'
import { ServiceFeatureTableItem } from './ServiceFeatureTableItem'
import ServiceFeatureTable from './ServiceFeatureTable.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import Debounce from '@/utils/decorators/debounceDecorator'
import { ServiceFeatureTableOptions } from './ServiceFeatureTableOptions'
import { Feature } from '@/models'

@Component({
  components: { PartouCard, ServiceFeatureTable }
})
export default class ServiceFeatures extends Vue {
  @Prop({ required: true })
  public serviceId!: string

  items : ServiceFeatureTableItem[] = []
  totalCount = 0
  options? : ServiceFeatureTableOptions = { sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10, itemsPerPageOptions: [5, 10, 25, 50, 100], filters: { search: '' } }
  serviceService: IServiceFeatureService = container.get<IServiceFeatureService>(SERVICE_IDENTIFIERS.IServiceFeatureService)
  isloading = false

  mounted () : void {
    this.getTableData()
  }

  @Watch('serviceId')
  async getTableData () : Promise<void> {
    this.items = await this.getServiceFeatureTableDataAsync(this.options as ServiceFeatureTableOptions)
  }

  async getServiceFeatureTableDataAsync (options: ServiceFeatureTableOptions): Promise<ServiceFeatureTableItem[]> {
    Vue.set(this, 'isloading', true)
    const tableItems : ServiceFeatureTableItem[] = []
    const { items, totalCount } = await this.serviceService.getFeaturesAsync(options)
    const serviceFeatures = await this.serviceService.getServiceFeaturesAsync({ serviceId: this.serviceId })

    items.forEach(feature => {
      const serviceFeature = serviceFeatures.find(sf => sf.featureId === feature.id)
      tableItems.push({ id: feature.id, description: feature.description, personas: feature.persona_features.map(x => x.persona.name).join(', '), isMainFeature: serviceFeature?.isMainFeature ?? false, isEnabled: !!serviceFeature })
    })

    this.totalCount = totalCount
    Vue.set(this, 'isloading', false)
    return tableItems
  }

  @Debounce(400)
  async onTableOptionsChanged (tableOptions : ServiceFeatureTableOptions) : Promise<void> {
    this.items = await this.getServiceFeatureTableDataAsync(tableOptions)
  }

  onFeatureChanged (item : ServiceFeatureTableItem) : void {
    if (item.isEnabled) {
      this.serviceService.upsertServiceFeatureAsync(this.serviceId, item as Feature, item.isMainFeature)
    } else {
      item.isMainFeature = false
      this.serviceService.deleteServiceFeatureAsync({ serviceId: this.serviceId, featureId: item.id! }) // eslint-disable-line
    }
  }
}

</script>
