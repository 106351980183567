<template>
  <div class="d-flex flex-row mt-4 mb-6">
    <div class="mt-n13 mb-5 child-data">
      <div class="d-flex flex-column pb-6">
        <p class="mb-0">{{ $t('waitingListPropositions.table.expanded.childName') }}</p>
        <strong class="child-information">{{ childFirstName }}</strong>
      </div>
      <div class="d-flex flex-column pb-6">
        <p class="mb-0">{{ $t('waitingListPropositions.table.columns.lastName') }}</p>
        <strong class="child-information">{{ childLastName }}</strong>
      </div>
      <div class="d-flex flex-column">
        <p class="mb-0">{{ $t('waitingListPropositions.table.columns.childNumber') }}</p>
        <strong class="child-information">{{ childNumber || $t('waitingListPropositions.table.childNumberUnavailable') }}</strong>
      </div>
    </div>

    <div class="date-data"></div>

    <div class="start-data d-flex flex-column gap-32px">
      <div v-if="getReservedOrOptionReservationsForPlacementByServiceVariety(currentProposition.placement, ServiceVarietyName.NSO).length > 0">
        <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.startNSO') }}</p>
        <div class="d-flex justify-space-between"
         v-for="reservation in getSortedReservationsForCareType(currentProposition.placement, ServiceVarietyName.NSO)"
         :key="reservation.id">
          <p class="start-data-day">{{ $t(`days.${reservation.dayOfWeek.toLowerCase()}`) }}</p>
          <p class="start-data-date">{{ getFormattedDate(reservation.validFrom) }}</p>
        </div>
      </div>

      <div v-if="getReservedOrOptionReservationsForPlacementByServiceVariety(currentProposition.placement, ServiceVarietyName.VSO).length > 0">
        <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.startVSO') }}</p>
        <div class="d-flex justify-space-between"
            v-for="reservation in getSortedReservationsForCareType(currentProposition.placement, ServiceVarietyName.VSO)"
            :key="reservation.id">
          <p class="start-data-day">{{ $t(`days.${reservation.dayOfWeek.toLowerCase()}`) }}</p>
          <p class="start-data-date">{{ getFormattedDate(reservation.validFrom) }}</p>
        </div>
      </div>

      <div v-if="getReservedOrOptionReservationsForPlacementByServiceVariety(currentProposition.placement, ServiceVarietyName.KDV).length > 0">
        <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.startKDV') }}</p>
        <div class="d-flex justify-space-between"
            v-for="reservation in getSortedReservationsForCareType(currentProposition.placement, ServiceVarietyName.KDV)"
            :key="reservation.id">
          <p class="start-data-day">{{ $t(`days.${reservation.dayOfWeek.toLowerCase()}`) }}</p>
          <p class="start-data-date">{{ getFormattedDate(reservation.validFrom) }}</p>
        </div>
      </div>
    </div>

    <div class="date-data"></div>

    <div class="location-data">
      <template v-if="currentProposition">
        <template v-if="currentProposition.locationInquiry">
          <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.priority')}}</p>
          <p class="mb-8"><b>{{ getPriorityText(proposition) }}</b></p>
          <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.expiresOn')}}</p>
          <p class="mb-8"><b>{{ getFormattedDate(currentProposition.expiresOn) }}</b></p>
          <p class="data-labels">{{ $t('waitingListPropositions.table.expanded.proposedOn')}}</p>
          <p><b>{{ getFormattedDate(currentProposition.proposedOn) }}</b></p>
        </template>
      </template>
    </div>
    <div class="days-data">
      <template v-if="currentProposition">
        <template v-if="currentProposition.locationInquiry.booking.serviceKind == ServiceKind.SchoolCare">
          <div class="d-flex flex-column column-gap-4">
            <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.VSO" :proposition="currentProposition"/>
            <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.NSO" :proposition="currentProposition"/>
          </div>
        </template>
        <template v-else>
          <DayTableWaitingListProposition :serviceVarietyName="ServiceVarietyName.KDV" :proposition="currentProposition"/>
        </template>
      </template>
    </div>
    <div class="status-data d-flex flex-column justify-center">
      <div>
        <p class="data-labels">{{$t('waitingListPropositions.table.status.bookingStatus')}}</p>
        <p class="font-large"> <strong>{{getBookingStatusTranslation(currentProposition.locationInquiry.booking)}}</strong> </p>
      </div>
      <div>
        <p class="data-labels">{{$t('waitingListPropositions.table.status.sync.syncStatus')}}</p>
        <p class="font-large"> <strong>{{getSyncStatusTranslation(currentProposition)}}</strong> </p>
      </div>
      <div ref="cancel-reason-container" class="cancel-reason-container" v-if="currentProposition.status !== 'Revoked' && currentProposition.placement.status === 'Cancelled'">
        <p class="data-labels" v-html="$t('waitingListPropositions.table.expanded.reasonForCancel', { causedBy: getCausedBy(currentProposition.causedBy)})" />
        <p><b>{{ getCausedByReason(currentProposition.causedByReason) }}</b></p>
      </div>
      <div ref="revoke-reason-container" class="revoke-reason-container" v-if="currentProposition.status === 'Revoked'">
        <p class="data-labels" v-html="$t('waitingListPropositions.table.expanded.reasonForRevoke', { causedBy: getCausedBy(currentProposition.causedBy)})" />
        <p><b>{{ getCausedByReason(currentProposition.causedByReason) }}</b></p>
      </div>
      <div v-if="canManageWaitingList && currentProposition.status === 'Created'" class="d-flex flex-row flex-wrap column-gap-4">
        <PartouButton class="mb-2" @click="onCancelClicked" :text="$t('waitingListPropositions.table.expanded.cancel')" :size="'normal'" colour="partou-black-ninety"></PartouButton>
        <v-btn :ripple="false" rounded class="propose-button" @click="onProposeClicked">{{ $t('waitingListPropositions.table.expanded.propose') }}</v-btn>
      </div>
      <PartouButton
        v-if="canManageWaitingList && currentProposition.status === 'Proposed'"
        class="revoke-proposition-button w-full mb-2"
        @click="onRevokeClicked"
        :text="$t('waitingListPropositions.table.expanded.revoke')"
        :size="'normal'"
        fullWidth
        colour="partou-black-ninety"
      ></PartouButton>
    </div>
    <div class="expand-data">
      <PartouFloatingCircleButton v-if="showAnalysisResultButton()" ref="analysis-button" class="analysis-button" iconName="$vuetify.icons.partouAnalysis" @click="onGoToAnalysisResultPageClick" />
    </div>
    <PartouDialog
      ref="propose-proposition-dialog"
      :width="600"
      v-if="showProposeDialog"
      :showDialog="showProposeDialog"
      :showLoaderOnConfirm="true"
      :onNotifyAccepted="OnWaitinglistPropositionProposedAsync"
      @canceled="onPropositionActionCancelled"
      :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
      :confirmTranslationString="$t('waitingListPropositions.table.expanded.propose')">
        <p class="mb-6" v-html="$t('waitingListPropositions.table.expanded.proposeCheck', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}`})" />
        <div class="button-error-container">
          <span v-if="plannerActionError" class="error-message">{{$t('services.controlVariables.triggerCalculateCapacityError')}}</span>
        </div>
    </PartouDialog>
    <PartouDialog
      ref="cancel-proposition-dialog"
      :width="600"
      v-if="showCancelDialog"
      :isConfirmButtonDisabled="invalidCausedByReason()"
      :showDialog="showCancelDialog"
      :showLoaderOnConfirm="true"
      :onNotifyAccepted="OnWaitinglistPropositionCancelledAsync"
      @canceled="onPropositionActionCancelled"
      :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
      :confirmTranslationString="$t('waitingListPropositions.table.expanded.cancel')">
        <p v-html="$t('waitingListPropositions.table.expanded.cancelCheck', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}`})" />
        <p class="mb-6">{{ $t('waitingListPropositions.table.expanded.cancelReason') }}</p>
        <PartouAutocomplete
          class="cancel-reason-options rounded-xl mb-6"
          :items="cancelReasons"
          :value="causedByReason"
          @input="onCausedByReasonChanged"
          item-text="name"
          item-value="id"
          label="name"
          :appendIconString="'$vuetify.icons.partouChevronDown'"
          :clearable="false"
          :placeholder="$t('causedByReasonPicker.placeholder')"
          :noDataText="$t('servicePicker.noData')"
        />
        <v-textarea
          flat
          solo
          v-if="showCustomCausedByReason"
          v-model="customCausedByReason"
          class="cancel-reason-field mb-6"
          :rules="[v => validateCustomCausedByReason(v)]"
          :placeholder="$t('causedByReasonPicker.customReasonPlaceholder')"
        />
        <div class="button-error-container">
          <span v-if="plannerActionError" class="error-message">{{$t('services.controlVariables.triggerCalculateCapacityError')}}</span>
        </div>
    </PartouDialog>
    <PartouDialog
      ref="revoke-proposition-dialog"
      :width="600"
      v-if="showRevokeDialog"
      :isConfirmButtonDisabled="invalidCausedByReason()"
      :showDialog="showRevokeDialog"
      :showLoaderOnConfirm="true"
      :onNotifyAccepted="OnWaitinglistPropositionRevokedAsync"
      @canceled="onPropositionActionCancelled"
      :cancelTranslationString="$t('waitingListPropositions.table.expanded.cancelHandling')"
      :confirmTranslationString="$t('waitingListPropositions.table.expanded.revoke')">
        <p v-html="$t('waitingListPropositions.table.expanded.revokeCheck', { name: `${currentProposition.locationInquiry.child.firstName} ${currentProposition.locationInquiry.child.lastName}`})" />
        <p class="mb-6">{{ $t('waitingListPropositions.table.expanded.revokeReason') }}</p>
        <PartouAutocomplete
          class="revoke-reason-options rounded-xl mb-6"
          :items="revokeReasons"
          :value="causedByReason"
          @input="onCausedByReasonChanged"
          item-text="name"
          item-value="id"
          label="name"
          :appendIconString="'$vuetify.icons.partouChevronDown'"
          :clearable="false"
          :placeholder="$t('causedByReasonPicker.placeholder')"
          :noDataText="$t('servicePicker.noData')"
        />
        <v-textarea
          flat
          solo
          v-if="showCustomCausedByReason"
          v-model="customCausedByReason"
          class="revoke-reason-field mb-6"
          :rules="[v => validateCustomCausedByReason(v)]"
          :placeholder="$t('causedByReasonPicker.customReasonPlaceholder')"
        />
        <div class="button-error-container">
          <span v-if="plannerActionError" class="error-message">{{$t('services.controlVariables.triggerCalculateCapacityError')}}</span>
        </div>
    </PartouDialog>
  </div>
</template>

<script lang="ts">
import { Booking, ServiceVarietyName, ServiceKind, PlacementStatus, WaitingListProposition, Maybe, Placement, Reservation, ReservationStatus } from '@/models'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import PartouStepper from '@/components/PartouComponents/PartouStepper.vue'
import { ROUTES } from '@/router/routes'
import Permission from '@/models/enums/Permission'
import { PlannerCausedByReason } from '@/models/enums/PlannerCausedBy'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IAuthService } from '@/services/AuthService/IAuthService'
import { IWaitingListPropositionService } from '@/services/WaitingListPropositionService/IWaitingListPropositionService'
import { IConfigurationService } from '@/services/ConfigurationService/IConfigurationService'
import PartouFloatingCircleButton from '@/components/PartouComponents/PartouFloatingCircleButton.vue'
import PartouAutocomplete from '@/components/PartouComponents/Input/PartouAutoComplete/PartouAutoComplete.vue'
import TextAreaFieldRow from '@/components/TextAreaFieldRow'
import PartouButton from '@/components/PartouComponents/PartouButton.vue'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import moment from 'moment'
import WaitingListPlacePriority from '@/models/enums/WaitingListPlacePriority'
import { PriorityOption } from '@/pages/ServicesPage/WaitingList/PriorityOption'
import DayTableWaitingListProposition from './DayTableWaitingListProposition.vue'
import WaitingListPropositionStatus from '@/models/enums/WaitingListPropositionStatus'
import Actor from '@/models/enums/Actor'
import { WaitingListPropositionResponse } from '@/services/WaitingListPropositionService/WaitingListPropositionResponse'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'
import { TranslateResult } from 'vue-i18n'

@Component({
  components: { DaysCoverage, PartouStepper, DayTableWaitingListProposition, PartouFloatingCircleButton, PartouAutocomplete, PartouButton, PartouDialog, TextAreaFieldRow }
})
export default class WaitingListPropositionTableExpanded extends Vue {
  @Prop({ required: true })
  proposition!: WaitingListProposition

  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)
  waitingListPropositionService: IWaitingListPropositionService = container.get<IWaitingListPropositionService>(SERVICE_IDENTIFIERS.IWaitingListPropositionService)
  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)

  ServiceVarietyName = ServiceVarietyName
  ServiceKind = ServiceKind

  showProposeDialog = false
  showCancelDialog = false
  showRevokeDialog = false
  showCustomCausedByReason = false

  customCausedByReason = ''
  causedByReason = ''
  cancelReasons: PlannerCausedByReason[] = []
  revokeReasons: PlannerCausedByReason[] = []
  possiblePriorities: PriorityOption[] = []

  currentProposition!: WaitingListProposition
  plannerActionError = false

  orderedDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

  get childFirstName () {
    return this.currentProposition.locationInquiry.child?.firstName
  }

  get childLastName () {
    return this.currentProposition.locationInquiry.child?.lastName
  }

  get childNumber () {
    return this.currentProposition.locationInquiry.child?.number
  }

  created () {
    this.currentProposition = this.proposition
    for (const waitingListPlacePriority in WaitingListPlacePriority) {
      this.possiblePriorities.push({ value: waitingListPlacePriority, text: this.$t(`waitingListPropositions.table.priority.${waitingListPlacePriority.toLowerCase()}`).toString() })
    }
  }

  mounted () {
    this.getConfigurationTableDataAsync()
  }

  get canManageWaitingList () : boolean {
    return this.authService.hasPermission([Permission.ManageWaitingList])
  }

  async getConfigurationTableDataAsync (): Promise<void> {
    const configurationService = container.get<IConfigurationService>(SERVICE_IDENTIFIERS.IConfigurationService)
    const cancelResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_cancel_reasons', date: new Date() })
    this.cancelReasons = cancelResult

    const revokeResult: PlannerCausedByReason[] = await configurationService.getConfigurationByKeyAsync({ key: 'planner_proposition_revoke_reasons', date: new Date() })
    this.revokeReasons = revokeResult
  }

  getSyncStatusTranslation (selectedProposition: WaitingListProposition) : string {
    const activePlacement = selectedProposition.placement.status !== PlacementStatus.Cancelled
    const requiresPlacementCallback = !!activePlacement
    const receivedPlacementCallback = selectedProposition.placement.isCallbackReceived

    const isBookingMissingCallback = requiresPlacementCallback && !receivedPlacementCallback

    if (isBookingMissingCallback) {
      return this.$t('registrations.table.status.sync.notSynced').toString()
    }

    return this.$t('registrations.table.status.sync.synced').toString()
  }

  getBookingStatusTranslation (booking: Booking) : string {
    if (booking.bookingStatus?.isCancelled) {
      return this.$t('registrations.table.status.cancelled').toString()
    }

    if (booking.bookingStatus?.isCompleted) {
      return this.$t('registrations.table.status.completed').toString()
    }

    if (booking.bookingStatus?.isMandateSigned) {
      return this.$t('registrations.table.status.mandate').toString()
    }

    if (booking.bookingStatus?.isContractSigned) {
      return this.$t('registrations.table.status.contract').toString()
    }

    if (booking.bookingStatus?.isEmailVerified) {
      return this.$t('registrations.table.status.email').toString()
    }

    return this.$t('registrations.table.status.reserved').toString()
  }

  getFormattedDate (date: Date, withTime = false): string {
    const formattedDate = !withTime ? moment(date).format('DD-MM-YYYY') : moment(date).format('DD-MM-YYYY HH:mm:ss')
    return formattedDate === 'Invalid date' ? '-' : formattedDate
  }

  getPriorityText (selectedProposition: WaitingListProposition) : string {
    const priorityValue = selectedProposition.locationInquiry.priority
    return this.possiblePriorities.find(priorityOption => priorityOption.value === priorityValue)?.text ?? this.$t('services.waitingList.unknown').toString()
  }

  getCausedByReason (reason: Maybe<string>) : string {
    if (reason === null || reason === '' || reason === undefined) {
      return '-'
    }
    return reason
  }

  getCausedBy (causedBy: Maybe<string>) : TranslateResult {
    return causedBy === Actor.Parent ? this.$t('waitingListPropositions.table.parent') : this.$t('waitingListPropositions.table.planner')
  }

  getSortedReservationsForCareType (placement: Placement, serviceVarietyName: ServiceVarietyName) : Reservation[] {
    const reservationsForCareType = this.getReservedOrOptionReservationsForPlacementByServiceVariety(placement, serviceVarietyName)
    reservationsForCareType.sort((a, b) => this.orderedDays.indexOf(a.dayOfWeek) - this.orderedDays.indexOf(b.dayOfWeek))
    return reservationsForCareType
  }

  getReservedOrOptionReservationsForPlacementByServiceVariety (placement: Placement, serviceVarietyName: ServiceVarietyName) : Reservation[] {
    return placement.reservations.filter(reservation =>
      reservation.serviceVariety.name === serviceVarietyName &&
      (reservation.status === ReservationStatus.Reserved || reservation.status === ReservationStatus.Option))
  }

  onGoToAnalysisResultPageClick () : void {
    if (this.currentProposition.locationInquiry.booking.serviceId == null) {
      throw new Error(`Booking with id ${this.currentProposition.locationInquiry.booking.id} contains no service_id`)
    }

    const routeData = this.$router.resolve({ name: ROUTES.serviceWaitingListAnalysis, params: { serviceId: this.currentProposition.locationInquiry.booking.serviceId } })
    window.open(routeData.href, '_blank')
  }

  onProposeClicked () : void {
    this.causedByReason = ''
    this.plannerActionError = false
    this.showProposeDialog = true
  }

  onCancelClicked () : void {
    this.causedByReason = ''
    this.plannerActionError = false
    this.showCancelDialog = true
  }

  onRevokeClicked () : void {
    this.causedByReason = ''
    this.plannerActionError = false
    this.showRevokeDialog = true
  }

  async OnWaitinglistPropositionProposedAsync () : Promise<void> {
    const updatedPropositionResult = await this.waitingListPropositionService.proposeCreatedWaitingListProposition({ waitingListPropositionId: this.currentProposition.id })
    const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
    this.showProposeDialog = !handleResult
  }

  async OnWaitinglistPropositionCancelledAsync () : Promise<void> {
    const chosenCausedByReason = this.causedByReason === 'Anders' ? this.customCausedByReason : this.causedByReason
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.cancelCreatedWaitingListProposition({ waitingListPropositionId: this.currentProposition.id, causedByReason: chosenCausedByReason })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.causedByReason = chosenCausedByReason
      this.showCancelDialog = !handleResult
    }
  }

  async OnWaitinglistPropositionRevokedAsync () : Promise<void> {
    const chosenCauseByReason = this.causedByReason === 'Anders' ? this.customCausedByReason : this.causedByReason
    if (!this.invalidCausedByReason()) {
      const updatedPropositionResult = await this.waitingListPropositionService.revokeProposedWaitingListProposition({ waitingListPropositionId: this.currentProposition.id, causedByReason: chosenCauseByReason })
      const handleResult = await this.handleUpdatedProposition(updatedPropositionResult)
      this.causedByReason = chosenCauseByReason
      this.showRevokeDialog = !handleResult
    }
  }

  onPropositionActionCancelled () : void {
    this.showProposeDialog = false
    this.showCancelDialog = false
    this.showRevokeDialog = false
  }

  onCausedByReasonChanged (reason: string) : void {
    this.showCustomCausedByReason = reason === 'Anders'
    this.causedByReason = reason
  }

  async handleUpdatedProposition (updatedPropositionResult: WaitingListPropositionResponse) : Promise<boolean> {
    if (!updatedPropositionResult || !updatedPropositionResult.isSuccess) {
      this.plannerActionError = true
      return false
    }

    if (this.currentProposition) {
      this.plannerActionError = false
      const updatedProposition = await this.propositionService.getOnePropositionAsync(this.currentProposition.id)
      // Overwrite currentProposition values with new values to update the expanded proposition item
      this.currentProposition.status = updatedProposition.status ?? ''
      this.currentProposition.causedByReason = updatedProposition.causedByReason
      this.currentProposition.expiresOn = updatedProposition.expiresOn
      this.currentProposition.proposedOn = updatedProposition.proposedOn
      this.currentProposition.placement.reservations = updatedProposition.placement?.reservations ?? []
      this.currentProposition.placement.status = updatedProposition.placement?.status ?? ''
      this.onExpandedStatusChanged(updatedProposition.status as WaitingListPropositionStatus)
    }
    return true
  }

  showAnalysisResultButton () : boolean {
    return this.currentProposition.locationInquiry.booking.analysis_id != null && this.authService.hasPermission([Permission.GetAnalysis])
  }

  invalidCausedByReason () : boolean {
    if (this.causedByReason !== '') {
      if ((this.causedByReason === 'Anders' && this.customCausedByReason.trim() === '') || (this.causedByReason === 'Anders' && this.customCausedByReason.length > 125)) {
        return true
      }
      return false
    }
    return true
  }

  validateCustomCausedByReason (value: string) : string | boolean {
    if (value.length > 125) {
      return 'Maximaal 125 tekens'
    } else if (value.trim() === '') {
      return 'Verplicht veld'
    }
    return true
  }

  @Emit('onExpandedStatusChanged')
  onExpandedStatusChanged (status: WaitingListPropositionStatus) : WaitingListPropositionStatus {
    return status
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';
@import '@/styles/helpers.scss';

.font-large {
  font-size: 16px;
  margin-bottom: 32px;
}
.child-information {
  margin-top: 4px;
  font-size: 16px;
}

.propose-button {
  color: $partou-primary-white;
  background-color: $partou-primary-salmon !important;
  text-transform: none !important;
}

.child-data {
  width: 16%;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}
.data-labels {
  font-size: 13px;
  color: $partou-primary-black-fifty;
  margin-bottom: 13px;
}
.date-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 12%;
}
.start-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 12%;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }
}
.location-data {
  padding-left: 16px;
  padding-right: 16px;
  width:12%;
}
.days-data {
  overflow: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 17%;
}
.status-data {
  padding-left: 16px;
  padding-right: 16px;
  width: 17%;
}
.expand-data {
  width: 2%;
  align-self: center;
}
.cancel-reason-field,
.revoke-reason-field {
  padding: 3px;
  border-radius: 24px;
  box-shadow: 0px 0px 4px 0px #00000040 inset;
}

.cancel-reason-field,
.revoke-reason-field,
.cancel-reason-options,
.revoke-reason-options {
  font-size: 16px !important;
}

.cancel-reason-container,
.revoke-reason-container {
  max-width: 260px;
}

.analysis-button {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 24px;
  button span svg {
    path {
      fill: $partou-primary-black-ninety !important;
      color: $partou-primary-black-ninety !important;
    }
  }
}

.button-error-container {
  display: flex;
  line-break: 16px;

  .error-message {
    padding-bottom: 5px;
  }

  .warning-message {
    color: $partou-primary-black-ninety !important;
    caret-color: $error-font-color !important;
    font-family: $error-font-family !important;
    font-size: $error-font-size !important;
    font-weight: $error-font-weight !important;
  }
}

.start-data-date,
.start-data-day {
  margin-bottom: 4px;
}

.start-data-day {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.start-data-date {
  font-weight: 700;
  text-align: right;
  min-width: 80px;
}
</style>
<style lang="scss">
.revoke-reason-options {
  .vs--searchable {
    .vs__dropdown-menu {
      .vs__dropdown-option {
        white-space: initial !important;
        overflow: unset !important;
        text-overflow: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
