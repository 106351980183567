import { InMemoryCache } from 'apollo-cache-inmemory'
import ApolloClient, { DefaultOptions } from 'apollo-client'
import { ApolloLink, concat } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import VueApollo, { ApolloProvider } from 'vue-apollo'
import { IAuthService } from '../AuthService/IAuthService'
import { setContext } from 'apollo-link-context'

export interface HasuraOptions {
  url: string;
  headers?: any; /*eslint-disable-line*/// because of any type: headers should be any, see HttpLink.Options
  middleware?: ApolloLink[];
}

export class HasuraService {
  apollo!: ApolloClient<any> /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible

  init (hasuraOptions: HasuraOptions, apolloOptions?: DefaultOptions, override = false): void {
    if (this.apollo && !override) {
      console.warn('An Apollo instance has already been set')
      return
    }

    this.apollo = this.createApolloClient(hasuraOptions, apolloOptions)
  }

  createHttpLink (options: HasuraOptions): ApolloLink {
    const httpOptions: HttpLink.Options = {
      uri: options.url,
      fetch,
      headers: options.headers ?? {}
    }

    return new HttpLink(httpOptions)
  }

  createAuthLink (authService: IAuthService): ApolloLink {
    const authLink = setContext(async (_, { headers }) => {
      const token = await authService.getTokenAsync()
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : null
        }
      }
    })

    return authLink
  }

  addMiddleware (options: HasuraOptions, link: ApolloLink): ApolloLink {
    // Add middleware
    if (options.middleware) {
      // Reverse list to make middleware more user friendly: first one in list is first one called
      options.middleware.reverse()
      options.middleware.forEach(m => {
        link = concat(m, link)
      })
    }

    return link
  }

  createApolloClient(options: HasuraOptions, apolloOptions?: DefaultOptions): ApolloClient<any> {  /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible
    let httpLink = this.createHttpLink(options)
    httpLink = this.addMiddleware(options, httpLink)

    return new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
      defaultOptions: apolloOptions
    })
  }

  getProvider(client?: ApolloClient<any>): ApolloProvider {  /*eslint-disable-line*/// because of any type: type doesn't really matter yet, and this keeps it flexible
    client = client ?? this.apollo
    return new VueApollo({
      defaultClient: client
    })
  }
}

const hasuraService = new HasuraService()
export default hasuraService
