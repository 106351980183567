import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { IWaitingListService } from './IWaitingListService'
import { GetWaitingListPlacesQuery, GetWaitingListPlacesDocument, GetWaitingListAnalysisResultsDocument, GetWaitingListPlacesQueryVariables, CancelWaitingListMutation, CancelWaitingListDocument, CancelWaitingListMutationVariables, GetWaitingListAnalysisResultsQueryVariables, GetWaitingListAnalysisResultsQuery, LocationInquiry, GetLocationInquiryByIdQuery, GetLocationInquiryByIdDocument, GetWaitingListPropositionAnalysisResultsQueryVariables, GetWaitingListPropositionAnalysisResultsDocument, GetWaitingListPropositionAnalysisResultsQuery } from '@/models'
import { WaitingListTableOptions } from '@/pages/ServicesPage/WaitingList/WaitingListTableOptions'
import _ from 'lodash'
import Permission from '@/models/enums/Permission'
import { WaitingListAllocation } from '@/models/WaitingListAnalysisResult'
import WaitingListPropositionAnalysisResult from '@/models/WaitingListPropositionAnalysisResult'

@injectable()
export default class WaitingListService implements IWaitingListService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getWaitingListTableItems (options: WaitingListTableOptions): Promise<{ items: LocationInquiry[]; totalCount: number }> {
    const variables : GetWaitingListPlacesQueryVariables = {
      serviceId: options.filters.serviceId,
      selectedDay: options.filters.selectedDay,
      limit: options.itemsPerPage,
      offset: options.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }

    const result = await this.apollo.query<GetWaitingListPlacesQuery>({
      query: GetWaitingListPlacesDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetWaitingList } }
    })

    const items: LocationInquiry[] = result.data.location_inquiry as LocationInquiry[]
    const totalCount = result.data.location_inquiry_aggregate?.aggregate?.count ?? 0

    return { items, totalCount }
  }

  async getOneAsync (id: string): Promise<LocationInquiry> {
    const result = await this.apollo.query<GetLocationInquiryByIdQuery>({ query: GetLocationInquiryByIdDocument, variables: { id }, context: { headers: { 'X-Hasura-Role': Permission.GetWaitingList } } })
    return result.data.location_inquiry_by_pk as LocationInquiry
  }

  async cancelWaitingListPlace (variables: CancelWaitingListMutationVariables): Promise<void> {
    await this.apollo.mutate<CancelWaitingListMutation>({ mutation: CancelWaitingListDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.ManageWaitingList } } })
  }

  async getWaitingListAllocationAnalysisResults (variables: GetWaitingListAnalysisResultsQueryVariables): Promise<WaitingListAllocation[]> {
    const result = await this.apollo.query<GetWaitingListAnalysisResultsQuery>({ query: GetWaitingListAnalysisResultsDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } } })
    return result.data.waiting_list_analysis_result.map(x => x.waitingListAllocation) as WaitingListAllocation[]
  }

  async getWaitingListPropositionAnalysisResults (variables: GetWaitingListPropositionAnalysisResultsQueryVariables): Promise<WaitingListPropositionAnalysisResult[]> {
    const result = await this.apollo.query<GetWaitingListPropositionAnalysisResultsQuery>({ query: GetWaitingListPropositionAnalysisResultsDocument, variables, context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } } })
    return result.data.waiting_list_proposition_analysis_result as WaitingListPropositionAnalysisResult[]
  }
}
