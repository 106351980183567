import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { Booking, GetBookingByIdDocument, GetBookingByIdQuery, GetBookingChildsDocument, GetBookingChildsQuery, GetBookingChildsQueryVariables, GetBookingsSinceLivegangDocument, GetBookingsSinceLivegangQuery } from '@/models'
import IBookingService from './IBookingService'
import Permission from '@/models/enums/Permission'
import RegistrationTableOptions from '@/pages/RegistrationPage/RegistrationTable/RegistrationTableOptions'
import _ from 'lodash'

@injectable()
export default class BookingService implements IBookingService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getRegistrationPageAsync (options: RegistrationTableOptions): Promise<{ items: Booking[]; totalCount: number }> {
    const variables : GetBookingChildsQueryVariables = {
      locationSearchOn: options.filters.service ? `%${options.filters.service.name}%` : null,
      searchOn: (options.filters.global && options.filters.global.length > 0) ? `%${options.filters.global}%` : null,
      limit: options.itemsPerPage,
      offset: options.itemsPerPage ? options.itemsPerPage * (options.page - 1) : undefined,
      order_by: options.sortBy?.map((sortBy, index) => _.set({}, sortBy, options.sortDesc[index] ? 'desc' : 'asc')) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    }

    const result = await this.apollo.query<GetBookingChildsQuery>({ // eslint-disable-line @typescript-eslint/no-non-null-assertion
      query: GetBookingChildsDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })

    const items: any[] = result?.data?.booking // eslint-disable-line @typescript-eslint/no-explicit-any
    const totalCount = result?.data?.booking_aggregate?.aggregate?.count ?? 0 // eslint-disable-line

    return { items, totalCount }
  }

  async getOneAsync (id: string): Promise<Partial<Booking>> {
    const variables = {
      bookingId: id
    }

    const result = await this.apollo.query<GetBookingByIdQuery>({
      query: GetBookingByIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })

    return result.data.booking[0] as Partial<Booking>
  }

  async getBookingsSinceLivegangAsync (): Promise<Array<Booking>> {
    const result = await this.apollo.query<GetBookingsSinceLivegangQuery>({
      query: GetBookingsSinceLivegangDocument,
      context: { headers: { 'X-Hasura-Role': Permission.GetBooking } }
    })

    return result.data.booking as Array<Booking>
  }
}
