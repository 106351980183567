enum WaitingListPlacePriority {
    OwnStaff = 'OwnStaff',
    Smi = 'Smi',
    SecondChild = 'SecondChild',
    DayCareToSchoolCare = 'DayCareToSchoolCare',
    Expansion = 'Expansion',
    WaitingListFamily = 'WaitingListFamily',
    MoveHouse = 'MoveHouse',
    New = 'New',
    ChangeDays = 'ChangeDays',
    ChangeGroup = 'ChangeGroup',
    ChangeLocation = 'ChangeLocation',
    Extension = 'Extension',
    SleepingWaitingList = 'SleepingWaitingList',
    VveIndication = 'VveIndication',
    PovWithoutKot = 'PovWithoutKot'
}

export default WaitingListPlacePriority

export function parseNumberToWaitingListPlacePriority (value : number) : WaitingListPlacePriority {
  switch (value) {
  case 1: return WaitingListPlacePriority.OwnStaff
  case 2: return WaitingListPlacePriority.Smi
  case 3: return WaitingListPlacePriority.SecondChild
  case 4: return WaitingListPlacePriority.DayCareToSchoolCare
  case 5: return WaitingListPlacePriority.Expansion
  case 6: return WaitingListPlacePriority.WaitingListFamily
  case 7: return WaitingListPlacePriority.MoveHouse
  case 8: return WaitingListPlacePriority.New
  case 9: return WaitingListPlacePriority.ChangeDays
  case 10: return WaitingListPlacePriority.ChangeGroup
  case 11: return WaitingListPlacePriority.ChangeLocation
  case 12: return WaitingListPlacePriority.Extension
  case 13: return WaitingListPlacePriority.SleepingWaitingList
  case 14: return WaitingListPlacePriority.VveIndication
  case 15: return WaitingListPlacePriority.PovWithoutKot
  }
  throw new Error(`given number: ${value} cannot be parsed to waiting list place priority`)
}
