import IServiceState, { STATE } from '@/store/serviceModule/IServiceState'
import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Service } from '@/models'

export const MUTATIONS = {
  setServices: 'setServices'
}

export const serviceMutations: MutationTree<IServiceState> = {
  setServices (state: IServiceState, services: Array<Service>) {
    Vue.set(state, STATE.services, services)
  }
}
