<template>
  <div>
    <div class="hide-menu"></div>
    <div class="content">
        <div class="total-bookings">
          <span class="number">{{ nrBookings }}</span>
          <span class="detail">Inschrijvingen</span>
        </div>
        <div class="stats">
          <div class="stat">
            <span class="number">{{ nrRegularBookings }}</span>
            <span class="detail">met alleen plaatsingen</span>
          </div>

          <div class="stat">
            <span class="number">{{ nrWaitingListBookings }}</span>
            <span class="detail">met alleen wachtlijstplekken</span>
          </div>

          <div class="stat">
            <span class="number">{{ nrMixedBookings }}</span>
            <span class="detail">met plaatsingen en wachtlijstplekken</span>
          </div>

          <div class="stat">
            <span class="number">{{ nrContractSignedBookings }}</span>
            <span class="detail">met getekend contract</span>
          </div>

          <div class="stat">
            <span class="number">{{ nrCancelledBookings }}</span>
            <span class="detail">geannuleerd</span>
          </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator'
import { Booking } from '@/models'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import IBookingService from '@/services/BookingService/IBookingService'
import confetti from 'canvas-confetti'
import BookingType from '@/models/enums/BookingType'

  @Component({
    components: { PartouCard }
  })
export default class LivegangPage extends Vue {
    bookingService: IBookingService = container.get<IBookingService>(SERVICE_IDENTIFIERS.IBookingService)

    bookingsSinceLivegang: Array<Booking> = []
    nrBookings = 0
    nrRegularBookings = 0
    nrWaitingListBookings = 0
    nrMixedBookings = 0
    nrContractSignedBookings = 0
    nrCancelledBookings = 0

    hasStartedConfetti = false

    created () : void {
      this.startConfetti()
      this.startPollingNewBookings()
    }

    startPollingNewBookings () : void {
      const interval = 1000 * 60 * 10 // Fetch new bookings every 10 minutes
      this.getBookingsSinceLivegang()

      setInterval(() : any => { // eslint-disable-line @typescript-eslint/no-explicit-any
        this.getBookingsSinceLivegang()
      }, interval)
    }

    async getBookingsSinceLivegang () : Promise<void> {
      this.bookingsSinceLivegang = await this.bookingService.getBookingsSinceLivegangAsync()
      const bookingsWithPlacements = this.bookingsSinceLivegang.filter(x => x.bookingType === BookingType.Mixed || x.bookingType === BookingType.Regular)

      this.nrBookings = this.bookingsSinceLivegang.length
      this.nrRegularBookings = this.bookingsSinceLivegang.filter(x => x.bookingType === BookingType.Regular).length
      this.nrWaitingListBookings = this.bookingsSinceLivegang.filter(x => x.bookingType === BookingType.WaitingList).length
      this.nrMixedBookings = this.bookingsSinceLivegang.filter(x => x.bookingType === BookingType.Mixed).length
      this.nrContractSignedBookings = bookingsWithPlacements.filter(x => x.bookingStatus?.isContractSigned).length
      this.nrCancelledBookings = bookingsWithPlacements.filter(x => x.bookingStatus?.isCancelled).length
    }

    startConfetti () {
      if (this.hasStartedConfetti) {
        return
      }

      const defaults = { startVelocity: 30, spread: 360, ticks: 120, zIndex: 0 }
      setInterval(() : any => { // eslint-disable-line @typescript-eslint/no-explicit-any
        const particleCount = 50
        // since particles fall down, start a bit higher than random
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 } }))
        confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.9), y: Math.random() - 0.2 } }))
      }, 250)

      this.hasStartedConfetti = true
    }
}

function randomInRange (min: number, max: number) : number {
  return Math.random() * (max - min) + min
}
</script>

<style lang="scss" scoped>
.hide-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 93px;
  background-color: #FFF;
  z-index: 999;
}

.content {
  width:100vw;
  height: 100vh;
  background-color: #FFF;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 93px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.total-bookings {
  margin-top: -15vh;
  margin-bottom: -17vh;

  .number {
    font-size: 28vw;
  }

  .detail {
    font-size: 4vw;
  }
}

.stats {
  width: auto;
  text-align: left;

  .stat {
    margin-bottom: -2vh;

    .number {
      font-size: 2vw;
      width: 4vw;
      display: inline-block;
      text-align: center;
    }

    .detail {
      font-size: 1vw;
      display: inline-block;
    }
  }
}
</style>
