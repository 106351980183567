import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import { DeleteServiceMediaByIdDocument, DeleteServiceMediaByIdMutation, InsertServiceMediaDocument, InsertServiceMediaMutation, ServiceMedia, UpdateServiceMediaDocument, UpdateServiceMediaMutation } from '@/models'
import { IServiceMediaService } from './IServiceMediaService'
import Permission from '@/models/enums/Permission'

@injectable()
export default class ServiceMediaService implements IServiceMediaService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async upsertOneAsync (entity: ServiceMedia): Promise<ServiceMedia> {
    return entity.id
      ? this.updateOneAsync(entity)
      : this.insertOneAsync(entity)
  }

  async updateOneAsync (entity: ServiceMedia): Promise<ServiceMedia> {
    const { id, filename, url, mimeType, serviceId } = entity
    const result = await this.apollo.mutate<UpdateServiceMediaMutation>({
      mutation: UpdateServiceMediaDocument,
      variables: { id, filename, url, mimeType, serviceId },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
    })
    return result.data?.update_service_media_by_pk as ServiceMedia // eslint-disable-line
  }

  async insertOneAsync (entity: ServiceMedia): Promise<ServiceMedia> {
    const { filename, url, mimeType, serviceId } = entity
    const result = await this.apollo.mutate<InsertServiceMediaMutation>({
      mutation: InsertServiceMediaDocument,
      variables: { filename, url, mimeType, serviceId },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
    })
    return result.data?.insert_service_media_one as ServiceMedia // eslint-disable-line
  }

  async deleteOneAsync (id: string): Promise<void> {
    await this.apollo.mutate<DeleteServiceMediaByIdMutation>({
      mutation: DeleteServiceMediaByIdDocument,
      variables: { id },
      context: { headers: { 'X-Hasura-Role': Permission.ManageServiceMedia } }
    })
  }
}
