<template>
  <PartouCard :hide-actions="true" class="mt-2 full-width">
    <template slot="card-body">
      <v-form class="analysis-form" v-if="!isResultPreview">
        <v-text-field v-model="formData.postalCode" :label="$t('analysis.postalCode')" class="mb-5" :disabled="isLoading" :error="postalCodeErrorTranslationString !== ''" :error-messages="$t(postalCodeErrorTranslationString)"></v-text-field>
        <div class="row">
          <DateInputField class="date" :date="dateOfBirth" :minDate="dateOfBirthMinDate" :label="$t('analysis.dateOfBirth')" :disabled="isLoading" :allowWeekends="true" :use-single-line="false" v-model="formData.dateOfBirth" :ignoreInitDateChange="true" :errorMessageTranslationString="dateOfBirthErrorTranslationString"></DateInputField>
          <DateInputField class="date" :date="startDateOfCare" :minDate="startDateOfCareMinDate" :maxDate="startDateOfCareMaxDate" :label="$t('analysis.startDateOfCare')" :disabled="!formData.dateOfBirth || isLoading" :startAtMinDate="true"  :allowWeekends="true" :use-single-line="false" v-model="formData.startDateOfCare" :ignoreInitDateChange="true" :errorMessageTranslationString="startDateErrorTranslationString"></DateInputField>
        </div>

        <v-switch class="ma-0 pa-0" v-model="withPriority" :label="$t('analysis.dayPriorityToggle')" @change="onPriorityToggleChanged"/>
        <DaySelector :disabled="isLoading" class="mt-2 mb-5" v-model="formData.days" priorityToggle :withPriority="withPriority"/>
        <span  v-if="daysErrorTranslationString != ''" class="error-message">{{$t(daysErrorTranslationString)}}</span>
        <v-autocomplete v-model="formData.selectedServiceVarieties" class="rounded-xl autocomplete" :hide-details="true" :items="serviceVarieties" item-text="name" item-value="id" :label="$t('analysis.serviceVarieties')" solo :disabled="isLoading"/>
        <v-autocomplete v-model="selectedLocation" class="rounded-xl autocomplete" :hide-details="true" :items="localityAutoCompleteItems.flatMap((school) => school.locality)" item-text="name" item-value="id" :label="$t('analysis.locality')" @change="onLocationNameChangedAsync" solo :disabled="isLoading" />
        <v-autocomplete v-model="selectedSchool" class="rounded-xl autocomplete" :hide-details="true" :items="schoolAutoCompleteItems.flatMap((school) => school.name)" item-text="name" item-value="id" :label="$t('analysis.school')" @change="onSchoolNameChangedAsync" :disabled="isLoading || schoolInputDisabled" solo />
        <div v-if="schoolErrorTranslationString != ''" class="error-message">{{$t(schoolErrorTranslationString)}}</div>
        <v-btn @click="onAnalyseButtonClickedAsync" :loading="isLoading" >{{$t('analysis.analyseButton')}}</v-btn>
        <template slot="prepend-inner">
          <span class="ml-2" ></span>
        </template>
        <div class="error-message general">{{$t(generalErrorTranslationString)}}</div>
      </v-form>

      <div v-if="fetchedResult && !isLoading && propositions.length === 0">{{$t('analysis.noResult')}}</div>
      <div v-else-if="fetchedResult && !isLoading && propositions.length > 0">
        <template v-for="(proposition, index) in propositions">
          <PartouCard :key="proposition.name + index" :hide-actions="true" class="mt-2 full-width">
            <template slot="card-header">
              <h2 class="card-header-title">{{proposition.name}}</h2>
            </template>

            <template slot="card-body">
              <div class="key-value">
                <div class="key">{{$t('analysis.availability')}}</div>
                <div :class="{ good: proposition.availabilityCoverage === 1, bad: proposition.availabilityCoverage === 0, mediocre: proposition.availabilityCoverage > 0 && proposition.availabilityCoverage < 1, questionable: proposition.availabilityCoverage === -1}">{{$t(getAvailabilityCoverageTranslationString(proposition.availabilityCoverage))}}</div>
              </div>

              <div class="key-value">
                <div class="key">{{$t('analysis.distance')}}</div>
                <div>{{proposition.distance.toFixed(2)}}{{$t('analysis.kilometers')}}</div>
              </div>

              <div class="key-value">
                <div class="key">{{$t('analysis.totalImpact')}}</div>
                <div v-for="serviceVarietyProposition in proposition.serviceVarietyPropositions" :key="`${serviceVarietyProposition.serviceVarietyName}_${proposition.id}_1`">
                  <span class="value-padding">
                    {{serviceVarietyProposition.serviceVarietyName}}: {{serviceVarietyProposition.totalImpact}}
                  </span>
                </div>
              </div>

              <div class="key-value">
                <div class="key">{{$t('analysis.totalValue')}}</div>
                <div v-for="serviceVarietyProposition in proposition.serviceVarietyPropositions" :key="`${serviceVarietyProposition.serviceVarietyName}_${proposition.id}_2`">
                  <span class="value-padding">
                    {{serviceVarietyProposition.serviceVarietyName}}: {{serviceVarietyProposition.totalValue}}
                  </span>
                </div>
              </div>

              <div class="service-varieties" v-for="serviceVarietyProposition in proposition.serviceVarietyPropositions" :key="`${serviceVarietyProposition.serviceVarietyName}_${proposition.id}_3`">
                <div class="service-variety-name">
                  {{serviceVarietyProposition.serviceVarietyName}}:
                  <div class="days">
                    <div v-for="dayOfWeek in daysInWeek" :key="`${dayOfWeek}_${proposition.id}`" class="day">
                      <div :style="isSelectedDay(dayOfWeek) ? 'font-weight:bold;' : ''">{{ $t(`days.${dayOfWeek.toLowerCase()}`) }}</div>
                      <v-icon>{{ serviceVarietyProposition.availabilityPerDay[dayOfWeek] ? "$vuetify.icons.partouAvailable" : "$vuetify.icons.partouNotAvailable" }}</v-icon>
                      <p v-if="withPriority" class="ma-0 mt-1">{{ getPriorityForDayOfWeek(dayOfWeek) }}</p>
                    </div>
                  </div>

                  <v-expansion-panels accordion class="mt-4" v-if="lowestImpactPathFound(proposition, serviceVarietyProposition.serviceVarietyName)">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="alignt-center">{{$t("analysis.chosenPath")}}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <LowestImpactPathTable :proposition="proposition" :serviceVarietyName="serviceVarietyProposition.serviceVarietyName"/>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>

              <div class="show-more" @click="toggleProposition(proposition.offerId)">{{$t('analysis.show')}} {{getShowProposition(proposition.offerId) ? $t('analysis.less') :$t('analysis.more')}}</div>
              <template v-if="getShowProposition(proposition.offerId)">
                <PartouCard  v-for="(analysisItem, index2) in proposition.analysis" :key="index.toString() + index2.toString()" :hide-actions="true" class="mt-2 full-width">
                  <template slot="card-header">
                    <h2 class="sub-card-header-title">{{$t(getDayOfWeekTranslationString(analysisItem.dayOfWeek))}} - {{$t('analysis.age')}}: {{analysisItem.age}} - {{$t('analysis.group')}}: {{analysisItem.groupName}} ({{analysisItem.serviceVarietyName}})</h2>
                  </template>

                  <template slot="card-body">
                    <table v-if="analysisItem.capacities.length > 0" class="capacity-table" aria-describedby="capacity-table">
                      <tr>
                        <th id="th-available-from">{{$t('analysis.availableFrom')}}</th>
                        <th id="th-available-until">{{$t('analysis.availableUntil')}}</th>
                        <th id="th-base-capacity">{{$t('analysis.baseCapacity')}}</th>
                        <th id="th-current-capacity">{{$t('analysis.currentCapacity')}}</th>
                        <th id="th-extra-capacity">{{$t('analysis.extraCapacity')}}</th>
                        <th id="th-total-capacity">{{$t('analysis.totalCapacity')}}</th>
                        <th id="th-occupied-capacity">{{$t('analysis.occupiedCapacity')}}</th>
                        <th id="th-amount-of-day-of-week-in-period">{{$t('analysis.amountOfDayOfWeekInPeriod')}}</th>
                        <th id="th-impact-per-day">{{$t('analysis.impactPerDay')}}</th>
                        <th id="th-total-impact-per-day">{{$t('analysis.totalImpactPerDay')}}</th>
                        <th id="th-extra-capacity-type-label">{{$t('analysis.extraCapacityTypeLabel')}}</th>
                      </tr>
                      <tr v-for="(capacity, index3) in analysisItem.capacities" :key="index.toString() + index2.toString() + index3.toString()" :class="{ good: capacity.currentCapacity + capacity.extraCapacity > 0, bad: capacity.currentCapacity + capacity.extraCapacity <= 0 }">
                        <td>{{moment(capacity.availableFrom).format('DD-MM-YYYY')}}</td>
                        <td>{{capacity.availableUntil ? moment(capacity.availableUntil).format('DD-MM-YYYY') : $t('analysis.noEndDate')}}</td>
                        <td>{{capacity.baseCapacity}}</td>
                        <td>{{capacity.currentCapacity}}</td>
                        <td>{{capacity.extraCapacity}}</td>
                        <td>{{capacity.totalCapacity}}</td>
                        <td>{{capacity.occupiedCapacity}}</td>
                        <td>{{capacity.amountOfDayOfWeekInPeriod}}</td>
                        <td>{{capacity.lowestImpact}}</td>
                        <td>{{capacity.currentCapacity > 0 ? '0' : capacity.lowestImpact * capacity.amountOfDayOfWeekInPeriod}}</td>
                        <td>{{capacity.lowestExtraCapacity ? $t(getExtraCapacityTranslationString(capacity.lowestExtraCapacity.type)) + ' (' + capacity.lowestExtraCapacity.amount + ')' : ''}}</td>
                      </tr>
                      <tr class="total">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{{analysisItem.valueOfDay}}</td>
                        <td></td>
                        <td :class="{ good: analysisItem.impactOfDay <= analysisItem.valueOfDay, bad: analysisItem.impactOfDay > analysisItem.valueOfDay }">{{analysisItem.impactOfDay}}</td>
                        <td></td>
                      </tr>
                    </table>
                    <div v-else>{{$t('analysis.noCapacities')}}</div>
                  </template>
                </PartouCard>
              </template>

              <div class="show-more" v-if="getShowProposition(proposition.offerId)" @click="toggleProposition(proposition.offerId)">{{$t('analysis.show')}} {{$t('analysis.less')}}</div>
            </template>
          </PartouCard>
        </template>
      </div>
    </template>
  </PartouCard>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import { IPropositionService } from '@/services/PropositionService/IPropositionService'
import { ISchoolService } from '@/services/SchoolService/ISchoolService'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { DayOfWeek, FetchPostalcodeInfoOutput, GetPropositionsAnalysisQueryVariables, Proposition, School, SchoolInput, ServiceKind, ServiceVarietyDaySelection, ServiceVarietyName } from '@/models'
import DayCheckboxType from '@/components/InputFields/DaySelector/DayCheckboxType'
import DateInputField from '@/components/InputFields/DateInputField/DateInputField.vue'
import DaySelector from '@/components/InputFields/DaySelector/DaySelector.vue'
import { timeBetween, unitOfTime, getDateOfBirthMinDate, getStartDateOfCareMinDate, getStartDateOfCareMaxDate } from '@/utils/dateUtils'
import moment from 'moment'
import IPostalCodeService from '@/services/PostalCodeService/IPostalCodeService'
import DayCheckboxState from '@/components/InputFields/DaySelector/DayCheckboxState'
import LowestImpactPathTable from '@/pages/Analysis/ServiceAnalysis/components/LowestImpactPathTable.vue'
import Day, { fromDayOfWeek } from '@/models/enums/Day'
import { parseStringToServiceVarietyName } from '@/models/enums/ServiceVarietyName'

interface IFormData {
  postalCode: string,
  dateOfBirth?: Date,
  startDateOfCare?: Date,
  days: DayCheckboxState[],
  selectedServiceVarieties: string[]
  selectedSchool?: School,
}

@Component({
  components: { PartouCard, DateInputField, DaySelector, LowestImpactPathTable }
})
export default class ServiceAnalysis extends Vue {
  @Prop({ required: false })
  analysisResultId?: string

  propositionService: IPropositionService = container.get<IPropositionService>(SERVICE_IDENTIFIERS.IPropositionService)
  schoolService: ISchoolService = container.get<ISchoolService>(SERVICE_IDENTIFIERS.ISchoolService)
  postalCodeService: IPostalCodeService = container.get<IPostalCodeService>(SERVICE_IDENTIFIERS.IPostalCodeService)
  moment = moment
  daysInWeek = [DayOfWeek.Monday, DayOfWeek.Tuesday, DayOfWeek.Wednesday, DayOfWeek.Thursday, DayOfWeek.Friday]

  formData: IFormData = {
    postalCode: '',
    dateOfBirth: undefined,
    startDateOfCare: undefined,
    days: [
      { day: DayOfWeek.Monday, type: DayCheckboxType.Check, isChecked: false },
      { day: DayOfWeek.Tuesday, type: DayCheckboxType.Check, isChecked: false },
      { day: DayOfWeek.Wednesday, type: DayCheckboxType.Check, isChecked: false },
      { day: DayOfWeek.Thursday, type: DayCheckboxType.Check, isChecked: false },
      { day: DayOfWeek.Friday, type: DayCheckboxType.Check, isChecked: false }
    ],
    selectedServiceVarieties: [],
    selectedSchool: undefined
  }

  propositions?: Proposition[] = []
  serviceVarieties = [
    [ServiceVarietyName.KDV],
    [ServiceVarietyName.VSO],
    [ServiceVarietyName.NSO],
    [ServiceVarietyName.VSO, ServiceVarietyName.NSO]
  ]

  dateOfBirth: Date = new Date()
  startDateOfCare: Date = new Date()
  localityAutoCompleteItems : School[] = []
  schoolAutoCompleteItems : School[] = []
  schoolInput = ''
  locationInput = ''
  schoolInputDisabled = true
  selectedLocation = ''
  selectedSchool = ''
  schoolErrorTranslationString = ''
  isValidSchool = false
  isLoading = false
  fetchedResult = false
  isPostalCodeLoading = false
  postalCodeErrorTranslationString = ''
  daysErrorTranslationString = ''
  dateOfBirthErrorTranslationString = ''
  startDateErrorTranslationString = ''
  toggledPropositions: string[] = []
  generalErrorTranslationString = ''
  withPriority = false

  @Watch('formData', { deep: true })
  onFormDataChanged (newValue: IFormData) : void {
    const selectedDays : DayOfWeek[] = newValue.days.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.day)
    if (selectedDays.length === 0) {
      this.daysErrorTranslationString = 'analysis.noDaysSelected'
    } else {
      this.daysErrorTranslationString = ''
    }
  }

  get isResultPreview () : boolean {
    return !!this.analysisResultId
  }

  get dateOfBirthMinDate () : Date | undefined {
    return getDateOfBirthMinDate(moment().toDate())
  }

  get startDateOfCareMinDate () : Date | undefined {
    return getStartDateOfCareMinDate(this.formData.dateOfBirth)
  }

  get startDateOfCareMaxDate (): Date | undefined {
    return getStartDateOfCareMaxDate(this.formData.dateOfBirth)
  }

  dateOfBirthChanged (newValue: Date) : void {
    this.formData.dateOfBirth = newValue
  }

  startDateOfCareChanged (newValue: Date) : void {
    this.formData.startDateOfCare = newValue
  }

  async onLocationNameChangedAsync (value : string) : Promise<void> {
    this.schoolErrorTranslationString = ''
    this.locationInput = value
    this.selectedLocation = value
    this.schoolInput = ''
    this.schoolInputDisabled = this.locationInput.length <= 0
    this.schoolAutoCompleteItems = await this.schoolService.getSchoolByLocalityAndName({ locationInput: this.locationInput, nameInput: this.schoolInput }) ?? []
  }

  async onSchoolNameChangedAsync (value : string) : Promise<void> {
    this.schoolErrorTranslationString = ''
    this.schoolInput = value
    const selectedSchools = await this.schoolService.getSchoolByLocalityAndName({ locationInput: this.locationInput, nameInput: this.schoolInput }) ?? []

    if (selectedSchools.length > 0) {
      const selectedSchoolofferCount = await this.schoolService.getSchoolServiceCountBySchoolId({ schoolId: selectedSchools[0].id }) ?? 0
      if (selectedSchoolofferCount > 0) {
        this.formData.selectedSchool = selectedSchools[0]
        this.isValidSchool = true
      } else {
        this.schoolErrorTranslationString = 'analysis.invalidSchool'
        this.isValidSchool = false
      }
    }
  }

  async created () : Promise<void> {
    if (this.isResultPreview && this.analysisResultId) {
      this.loadAnalysisResultFromId(this.analysisResultId)
    }
  }

  async mounted () : Promise<void> {
    this.localityAutoCompleteItems = await this.schoolService.getSchoolByLocalityAndName({ locationInput: '', nameInput: '' })
  }

  async onAnalyseButtonClickedAsync () : Promise<void> {
    this.propositions = []
    this.generalErrorTranslationString = ''
    const selectedDays : DayOfWeek[] = this.formData.days.filter((checkbox) => checkbox.isChecked).map((checkbox) => checkbox.day)

    if (!this.validateForm(selectedDays)) {
      return
    }

    this.isLoading = true
    const postalCode = await this.getPostalCodeCoordinatesAsync(this.formData.postalCode)
    if (!postalCode) {
      this.isLoading = false
      return
    }

    this.fetchedResult = true
    this.toggledPropositions = []
    const serviceKind = this.determineTypeOfCare(this.formData.dateOfBirth!, this.formData.startDateOfCare!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    const daysOfWeek = selectedDays.map((day) => fromDayOfWeek(day))
    if (serviceKind === ServiceKind.SchoolCare) {
      await this.getBSOPropositionsAsync(daysOfWeek, postalCode)
    } else {
      await this.getKDVPropositionsAsync(daysOfWeek, postalCode)
    }

    this.isLoading = false
  }

  validateForm (selectedDays: DayOfWeek[]) : boolean {
    let isValid = true
    this.postalCodeErrorTranslationString = ''
    this.daysErrorTranslationString = ''
    this.dateOfBirthErrorTranslationString = ''
    this.startDateErrorTranslationString = ''
    this.generalErrorTranslationString = ''

    if (!this.formData.postalCode) {
      this.postalCodeErrorTranslationString = 'analysis.invalidPostalCode'
      isValid = false
    }
    if (selectedDays.length === 0) {
      this.daysErrorTranslationString = 'analysis.noDaysSelected'
      isValid = false
    }
    if (!this.formData.dateOfBirth) {
      this.dateOfBirthErrorTranslationString = 'form.isRequired'
      isValid = false
    }
    if (!this.formData.startDateOfCare) {
      this.startDateErrorTranslationString = 'form.isRequired'
      isValid = false
    }
    if (this.formData.selectedServiceVarieties.length === 0) {
      this.generalErrorTranslationString = 'analysis.noServiceVarietySelected'
      isValid = false
    }

    return isValid
  }

  async getBSOPropositionsAsync (selectedDays: Day[], postalCode: FetchPostalcodeInfoOutput): Promise<void> {
    if (this.formData.selectedServiceVarieties.some((selectedServiceVariety: string) => selectedServiceVariety === 'KDV')) {
      this.generalErrorTranslationString = 'analysis.bsoWrongServiceKindSelected'
    } else if (!this.formData.selectedSchool) {
      this.generalErrorTranslationString = 'analysis.noSchoolSelected'
    } else {
      const selectedSchool = this.formData.selectedSchool
      const selectedSchoolInput : SchoolInput = { id: selectedSchool.id, addressLine1: selectedSchool.addressLine1, addressLine2: selectedSchool.addressLine2, locality: selectedSchool.locality, name: selectedSchool.name, postalCode: selectedSchool.postalCode }
      const analysisResult = await this.propositionService.getPropositionsAnalysisAsync(this.getPropositionRequest(selectedDays, postalCode, selectedSchoolInput))
      this.propositions = analysisResult
    }
  }

  async getKDVPropositionsAsync (selectedDays: Day[], postalCode: FetchPostalcodeInfoOutput): Promise<void> {
    if (this.formData.selectedServiceVarieties.some((selectedServiceVariety: string) => selectedServiceVariety !== 'KDV')) {
      this.generalErrorTranslationString = 'analysis.kdvWrongServiceKindSelected'
    } else {
      const analysisResult = await this.propositionService.getPropositionsAnalysisAsync(this.getPropositionRequest(selectedDays, postalCode))
      this.propositions = analysisResult
    }
  }

  async loadAnalysisResultFromId (analysisResultId: string) : Promise<void> {
    this.isLoading = true
    this.fetchedResult = false

    const analysisResult = await this.propositionService.getPropositionAnalysisResultAsync({ id: analysisResultId })
    this.propositions = analysisResult

    this.isLoading = false
    this.fetchedResult = true
  }

  getPropositionRequest (selectedDays: Day[], postalCode: FetchPostalcodeInfoOutput, selectedSchoolInput?: SchoolInput) : GetPropositionsAnalysisQueryVariables {
    const serviceVarietyDaySelection: ServiceVarietyDaySelection = {}
    this.formData.selectedServiceVarieties.map(x => parseStringToServiceVarietyName(x)).forEach(x => {
      const checkedDaysForServiceVariety = this.formData.days?.filter(y => y.isChecked)
      serviceVarietyDaySelection[x] = checkedDaysForServiceVariety?.map(y => { return { DayOfWeek: y.day, Priority: y.priority } })
    })

    return {
      latitude: postalCode.postalCodeInfo?.latitude,
      longitude: postalCode.postalCodeInfo?.longitude,
      birthDate: moment(this.formData.dateOfBirth).format('YYYY-MM-DD'),
      startDateOfCare: moment(this.formData.startDateOfCare).format('YYYY-MM-DD'),
      daysOfWeek: selectedDays,
      selectedSchool: selectedSchoolInput,
      selectedServiceVarietyNames: this.formData.selectedServiceVarieties,
      serviceVarietyDaySelection
    } as GetPropositionsAnalysisQueryVariables
  }

  async getPostalCodeCoordinatesAsync (postalCode: string) : Promise<FetchPostalcodeInfoOutput | undefined> {
    this.isPostalCodeLoading = true
    this.postalCodeErrorTranslationString = ''
    const response = await this.postalCodeService.getPostalCodeInfo({ postalCode }) as FetchPostalcodeInfoOutput | undefined
    this.isPostalCodeLoading = false
    if (!response) {
      this.postalCodeErrorTranslationString = 'analysis.invalidPostalCode'
    }
    return response
  }

  getPriorityForDayOfWeek (dayOfWeek: DayOfWeek) : number | undefined {
    return this.formData.days.find(x => x.day === dayOfWeek)?.priority
  }

  toggleProposition (offerId: string) : void {
    const index = this.toggledPropositions.indexOf(offerId)
    if (index === -1) {
      this.toggledPropositions.push(offerId)
    } else {
      this.toggledPropositions.splice(index, 1)
    }
  }

  getShowProposition (offerId: string) : boolean {
    return this.toggledPropositions.indexOf(offerId) !== -1
  }

  getAvailabilityCoverageTranslationString (coverage: number): string {
    if (coverage === 1) {
      return 'analysis.available'
    } else if (coverage === 0) {
      return 'analysis.waitingList'
    } else if (coverage === -1) {
      return 'analysis.notBookable'
    }
    return 'analysis.partlyAvailable'
  }

  getAgeRange (lifeYear: number): string {
    switch (lifeYear) {
    case 0:
      return '0-1'
    case 1:
      return '1-2'
    case 2:
      return '2-3'
    case 3:
      return '3-4'
    case 4:
    case 5:
    case 6:
    case 7:
      return '4-7'
    default:
      return '7+'
    }
  }

  getExtraCapacityTranslationString (extraCapacityTypeString: string): string {
    return `analysis.extraCapacityType.${extraCapacityTypeString}`
  }

  getDayOfWeekTranslationString (dayOfWeek: string): string {
    return `days.${dayOfWeek.toLowerCase()}`
  }

  determineTypeOfCare (dateOfBirth: Date, startDateOfCare: Date) : ServiceKind {
    const age = Math.floor(timeBetween(dateOfBirth, startDateOfCare, unitOfTime.year))
    if (age >= 4) {
      return ServiceKind.SchoolCare
    } else { return ServiceKind.DayCare }
  }

  isSelectedDay (day: DayOfWeek) : boolean {
    return this.formData.days.filter(x => x.day === day).every(x => x.isChecked)
  }

  lowestImpactPathFound (proposition: Proposition, serviceVarietyName: string) : boolean {
    return proposition.analysis.some(x => x.serviceVarietyName === serviceVarietyName && x.chosenForLowestImpactPath)
  }

  onPriorityToggleChanged () : void {
    this.formData.days.forEach(day => {
      day.priority = day.isChecked ? 0 : undefined
    })
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

$good: #91C37D;
$mediocre: #F6A500;
$bad: #cb5a5c;

.v-card {
  margin-top: 30px !important;
}

.card-header-title {
  padding-left: 20px;
  padding-top: 2px;
  height: 40px;
}

.analysis-form {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .error-message {
    color: $bad;
    font-size: 12px;
    margin: -10px 0 10px 10px;

    &.general{
      margin: 0;
      margin-top:10px;
    }
  }

  .autocomplete {
    margin-bottom: 12px;
  }

  .row {
    .date {
      padding-left: 12px;
    }
  }
}

.sub-card-header-title {
  padding-left: 20px;
  padding-top: 2px;
  height: 40px;
}

.key-value {
  display:flex;
  flex-direction: row;

  .key {
    width: 200px;
  }

  .value-padding{
    padding-right: 24px;
  }
}

.service-variety-name {
  margin-top: 20px;
}

.days {
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.show-more {
  text-align: center;
    margin-top: 20px;

  &:hover {
    cursor: pointer;
    color: #AAA;
  }
}

.sub-card {
  margin-top: 20px;
}

.capacity-table {
  width: 100%;
  padding: 0;

  th {
    text-align: left;
  }

  tr {
    &:nth-child(even) {
      background-color: #f2f2f2;
    }

    &:hover {
      background-color: #eee;
    }

    &.total {
      background-color: #ddd;
    }
  }
}

.good {
  color: $good;
}

.mediocre {
  color: $mediocre;
}

.bad {
  color: $bad;
}

.questionable {
  color: $partou-primary-purple;
}
</style>
