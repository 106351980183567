<template>
    <div>
      <h2 class="mb-1 black-ninety">{{$t('services.controlVariables.useFlexkidsProducts.heading')}}</h2>
      <div class="action-container">
        <div class="text-container">
          <p>{{$t('services.controlVariables.useFlexkidsProducts.flexkidsProductsEnabled')}}</p>
          <p>{{$t('services.controlVariables.useFlexkidsProducts.flexkidsProductsDisabled')}}</p>
        </div>
        <div class="controls-container d-flex flex-row">
          <v-switch ref="flexkids-products-switch" class="switch mt-0 mb-0 pt-0"  v-model="isUsingFlexkidsProducts" :disabled="!editEnabled" @click="onUseFlexkidsProductsClicked()" />
          <p>{{$t('services.controlVariables.useFlexkidsProducts.label')}}</p>
        </div>
      </div>
      <PartouDialog
      :width="600"
      v-if="showProductErorDialog"
      :showDialog="showProductErorDialog"
      @accepted="onNoActiveProductsDialogConfirmed"
      @canceled="onNoActiveProductsDialogCancelled"
      :confirmTranslationString="$t('services.serviceDetail.dialog.errorProductSettingsButton')">
        <h3>{{ $t('services.controlVariables.temporySettingsProductDialog.title') }} </h3>
        <p class="mb-6 mt-6"> {{ $t('services.controlVariables.temporySettingsProductDialog.description', {serviceVariety: serviceVarietyTranslationForPopup}) }} </p>
    </PartouDialog>
    <PartouDialog
      :width="600"
      v-if="showSubscriptionErrorDialog"
      :showDialog="showSubscriptionErrorDialog"
      @canceled="onShowSubscriptionErrorDialogCancelled">
        <h3>{{ $t('services.controlVariables.temporySettingsSubscriptionDialog.title') }} </h3>
        <p class="mb-6 mt-6"> {{ $t('services.controlVariables.temporySettingsSubscriptionDialog.description') }} </p>
        <ul class="mb-6 mt-6" v-if="dialogErrors.size">
           <li v-for="error in dialogErrors" :key="error">
              {{error}}
            </li>
        </ul>
    </PartouDialog>
    </div>
  </template>

<script lang="ts">
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { School, SchoolGroup, SchoolGroupOpening, Service, ServiceKind, ServiceVarietyName } from '@/models'
import { IProductService } from '@/services/ProductService/IProductService'
import { ISchoolService } from '@/services/SchoolService/ISchoolService'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import { isBetween } from '@/utils/dateUtils'
import { Component, Prop, VModel, Vue } from 'vue-property-decorator'
import PartouDialog from '@/components/PartouComponents/PartouDialog.vue'
import { ROUTES } from '@/router/routes'
import _ from 'lodash'
import { SelectableSubscription } from '../Subscriptions/SelectableSubscription'
import { ServicePricingExtended } from '../ServicePricing/ServicePricingExtended'

@Component({
  components: { PartouDialog }
})
export default class TemporarySettings extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  schoolService: ISchoolService = container.get<ISchoolService>(SERVICE_IDENTIFIERS.ISchoolService)

  @Prop({ required: true })
  service!: Service

  @VModel({ required: true })
  isUsingFlexkidsProducts! : boolean

  @Prop({ required: true, default: [] })
  selectableSubscriptions!: SelectableSubscription[]

  @Prop({ required: true })
  servicePricings!: ServicePricingExtended[]

  @Prop({ required: true, default: [] })
  editEnabled! : boolean

  loading = false
  error = false
  hasProductValidationError = false
  hasSubscriptionValidationError = false
  showProductErorDialog = false
  showSubscriptionErrorDialog = false
  dialogErrors: Set<string> = new Set()

  hasValidationErrors () : boolean {
    if (this.isUsingFlexkidsProducts) {
      return this.hasProductValidationError
    }
    return this.hasSubscriptionValidationError
  }

  get serviceVarietyTranslationForPopup () : string {
    return this.service.serviceVarieties[0].name === ServiceVarietyName.KDV.toString() ? '' : this.$t('services.controlVariables.temporySettingsProductDialog.NSO').toString()
  }

  async onUseFlexkidsProductsClicked () : Promise<void> {
    this.hasSubscriptionValidationError = false
    this.hasProductValidationError = false
    this.dialogErrors.clear()
    this.hasProductValidationError = !await this.productService.getHasActivatedServiceProductsAsync(this.service.id)
    if (this.service.isBookable) await this.checkIsBookableAsync()
    if (this.service.isPublishedForChildBenefitCalculator) await this.checkCanEnableChildBenefitCalculatorAsync()
    if (!this.service.waitingListFrozenSince) await this.checkCanEnableWaitingListAsync()
    if (this.dialogErrors.size > 0) {
      this.hasSubscriptionValidationError = true
    }
    if (this.hasValidationErrors() && this.editEnabled) {
      if (this.isUsingFlexkidsProducts) {
        this.showProductErorDialog = true
        this.isUsingFlexkidsProducts = false
      } else {
        this.showSubscriptionErrorDialog = true
        this.isUsingFlexkidsProducts = true
      }
    }
  }

  onNoActiveProductsDialogConfirmed (): void {
    this.showProductErorDialog = false
    this.$router.push({ name: ROUTES.serviceProducts, params: { serviceId: this.service.id } })
  }

  onNoActiveProductsDialogCancelled (): void {
    this.showProductErorDialog = false
  }

  onShowSubscriptionErrorDialogCancelled (): void {
    this.showSubscriptionErrorDialog = false
  }

  async checkIsBookableAsync () : Promise<void> {
    if (!this.getHasActivePrice()) {
      this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noActivePricing').toString())
    }
    if (this.selectableSubscriptions.filter(x => x.subscriptionService.isAvailable).length < 1) {
      this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noActiveSubscription').toString())
    }
  }

  async checkCanEnableChildBenefitCalculatorAsync () : Promise<void> {
    if (!this.getHasActivePrice()) {
      this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noActivePricing').toString())
    }
    await this.checkIfChildBenefitCalculatorWillRunIntoErrorsAsync()
  }

  async checkCanEnableWaitingListAsync () : Promise<void> {
    const canEnableWaitingList = await this.serviceService.canEnableWaitingList(this.service)
    if (!canEnableWaitingList?.result) {
      const arrayErrors = canEnableWaitingList?.errors.map(x => x ? this.$t(`services.serviceDetail.dialog.${_.camelCase(x)}`).toString() : '') ?? []
      arrayErrors.forEach(item => this.dialogErrors.add(item))
    }
  }

  getHasActivePrice (): boolean {
    const now = new Date()
    return this.servicePricings.some((x) => { return isBetween(now, x.validFrom, x.validUntil) && !x.isDeleted }) // eslint-disable-line @typescript-eslint/no-non-null-assertion
  }

  async checkIfChildBenefitCalculatorWillRunIntoErrorsAsync () : Promise<void> {
    this.checkActiveOpeningTimesErrors()
    if (this.service!.kind === ServiceKind.SchoolCare) { // eslint-disable-line @typescript-eslint/no-non-null-assertion
      await this.checkSchoolErrorsAsync()
    }
  }

  checkActiveOpeningTimesErrors (): void {
    const hasActiveOpeningTime = this.service!.serviceVarieties.some((x) => { return x.serviceVarietyOpenings.some((y) => { return isBetween(new Date(), y.validFrom, y.validUntil) }) }) // eslint-disable-line @typescript-eslint/no-non-null-assertion
    if (!hasActiveOpeningTime) {
      this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noActiveOpeningTimes').toString())
    }
  }

  async checkSchoolErrorsAsync (): Promise<void> {
    const now = new Date()
    const schools = await this.schoolService.getSchoolsByServiceIdAsync(this.service.id)
    if (!schools.length) {
      this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noSchool').toString())
    } else {
      const anySchoolHasActiveSchoolGroupOpeningTimes = schools.some((school: School) => {
        if (!school.schoolGroups?.length) {
          return false
        }

        const anySchoolGroupCurrentlyHasOpeningTimes = school.schoolGroups.some((schoolGroup: SchoolGroup) => {
          if (!schoolGroup.schoolGroupOpenings?.length) {
            return false
          }

          const anySchoolGroupOpeningIsCurrentlyOpen = schoolGroup.schoolGroupOpenings.some((schoolGroupOpening: SchoolGroupOpening) => {
            return isBetween(now, schoolGroupOpening.validFrom, schoolGroupOpening.validUntil)
          })
          return anySchoolGroupOpeningIsCurrentlyOpen
        })
        return anySchoolGroupCurrentlyHasOpeningTimes
      })

      if (!anySchoolHasActiveSchoolGroupOpeningTimes) {
        this.dialogErrors.add(this.$t('services.serviceDetail.dialog.noActiveSchoolGroupOpeningTimes').toString())
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';
@import '@/styles/variables/variables.scss';

.action-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .text-container {
    flex: 1;
  }

  .controls-container {
    width: 50%;
    display: flex;
    gap: 10px;

    h2 {
      color: $partou-secondary-bordeaux !important;
    }
  }
}
</style>
