<template>
  <div class="d-flex flex-column mb-2" :class="{ 'inline': displayInline }">
    <span v-if="!!heading" class="mr-2">{{ heading }}:&nbsp;</span>
    <div class="flex-column" :class="{ 'inline': displayInline }" >
      <span class="mr-2 fixed-width flex-row" :class="{ 'inline': displayInline }" v-for="(coverageDay, index) in coverageDays" :key="index">
        <template v-if="coverageDay.isCovered">
          <strong>{{ coverageDay.text }}</strong>
        </template>
        <template v-else>
          -
        </template>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { DayOfWeek } from '@/models'
import { DayCoverage } from './DayCoverage'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DaysCoverage extends Vue {
  @Prop({ required: false })
  heading?: string

  @Prop({ required: true })
  daysOfWeek!: DayOfWeek[]

  @Prop({ required: false, default: false })
  displayInline?: boolean

  coverageDays?: DayCoverage[] = []

  @Watch('daysOfWeek', { immediate: true })
  onDaysOfWeekChanged (): void {
    this.coverageDays = [
      { dayOfWeek: DayOfWeek.Monday, text: this.$t('daysShort.monday').toString(), isCovered: false },
      { dayOfWeek: DayOfWeek.Tuesday, text: this.$t('daysShort.tuesday').toString(), isCovered: false },
      { dayOfWeek: DayOfWeek.Wednesday, text: this.$t('daysShort.wednesday').toString(), isCovered: false },
      { dayOfWeek: DayOfWeek.Thursday, text: this.$t('daysShort.thursday').toString(), isCovered: false },
      { dayOfWeek: DayOfWeek.Friday, text: this.$t('daysShort.friday').toString(), isCovered: false }
    ]

    this.coverageDays.forEach(day => {
      day.isCovered = this.daysOfWeek?.some(dayOfWeek => dayOfWeek === day.dayOfWeek)
    })
  }
}
</script>

<style lang="scss" scoped>
  .fixed-width {
    width: 25px;
    display: inline-block;
    text-align: center;
  }

  .inline {
    display: inline !important;
  }
</style>
