<template>
  <v-container>
    <v-row>
      <v-card class="rounded-xl card pa-8 full-width" :flat="true">
        <v-card-text>
          <div class="products-card-header">
            <h1>{{$t('services.products.pageTitle')}}</h1>
            <v-btn v-if="canManageProducts" class="hidden-sm-and-down add-bookable-products" :ripple="false" rounded @click="onAddBookableProductsClicked()">{{$t('services.products.addBookableProducts')}}</v-btn>
          </div>
          <ServiceProductTable
            v-model="tableOptions"
            :serviceId="serviceId"
            :serviceName="serviceName"
            :serviceIsPublished="serviceIsPublished"
            :serviceIsBookable="serviceIsBookable"
            :serviceIsPublishedForChildBenefitCalculator="serviceIsPublishedForChildBenefitCalculator"
            :items="items"
            :expandedItem="expandedItem"
            :totalCount="totalCount"
            :isLoading="isLoading"
            :categoryOptions="categoryOptions"
            :serviceVarietyOptions="serviceVarietyOptions"
            :displayServiceVarietyFilter="displayServiceVarietyFilter"
            :activatedProducts="activatedProducts"
            @onTableOptionsChanged="onTableOptionsChangedAsync"
            @onExpandedChanged="onExpandedChangedAsync"
            @onProductDeactivated="getActivatedProducts">
          </ServiceProductTable>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row v-if="canManageProducts && serviceKind=='DayCare' && items.length" class="mt-8">
      <BenefitCalculatorProductSelectionDayCare
        :serviceId="serviceId"
        :availableServiceProducts="activatedProducts"
        :isChildBenefitCalculatorActive="serviceIsPublishedForChildBenefitCalculator"
        @onSelectedCalculatorProductChanged="onSelectedCalculatorProductChanged"
      />
    </v-row>
    <v-row v-if="canManageProducts && serviceKind=='SchoolCare' && items.length" class="mt-8">
      <BenefitCalculatorProductSelectionSchoolCare
        :serviceId="serviceId"
        :availableServiceProducts="activatedProducts"
        :isChildBenefitCalculatorActive="serviceIsPublishedForChildBenefitCalculator"
        @onSelectedCalculatorProductChanged="onSelectedCalculatorProductChanged"
      />
    </v-row>
    <AddProductsToServiceDialog
      v-if="canManageProducts"
      v-model="showAddProductDialog"
      :serviceId="serviceId"
      :serviceName="serviceName"
      :displayServiceVarietyFilter="displayServiceVarietyFilter"
      :categoryOptions="categoryOptions"
      :serviceVarietyOptions="serviceVarietyOptions"
      :activatedProducts="activatedProducts"
      @addBookableProductsConfirmedAsync="addBookableProductsConfirmedAsync"
      />
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import ServiceProductTable from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTable.vue'
import Debounce from '@/utils/decorators/debounceDecorator'
import ServiceProductTableOptions from '@/pages/ServicesPage/ServiceProduct/ServiceProductTable/ServiceProductTableOptions'
import container, { SERVICE_IDENTIFIERS } from '@/container'
import { IProductService } from '@/services/ProductService/IProductService'
import { ServiceKind, ServiceProduct, ServiceVarietyName } from '@/models'
import AddProductsToServiceTable from '@/pages/ServicesPage/ServiceProduct/AddProductsToServiceTable/AddProductsToServiceTable.vue'
import { IServiceService } from '@/services/ServiceService/IServiceService'
import AddProductsToServiceDialog from '@/pages/ServicesPage/ServiceProduct/AddProductsToServiceTable/AddProductsToServiceDialog.vue'
import PartouDataTable from '@/components/PartouComponents/PartouDataTable'
import { CategoryOption } from '@/pages/ServicesPage/ServiceProduct/CategoryOptions'
import Permission from '@/models/enums/Permission'
import { IAuthService } from '@/services/AuthService/IAuthService'
import BenefitCalculatorProductSelectionDayCare from '@/pages/ServicesPage/ServiceProduct/BenefitCalculatorProductSelection/BenefitCalculatorProductSelectionDayCare.vue'
import BenefitCalculatorProductSelectionSchoolCare from '@/pages/ServicesPage/ServiceProduct/BenefitCalculatorProductSelection/BenefitCalculatorProductSelectionSchoolCare.vue'

@Component({
  components: { PartouCard, AddProductsToServiceDialog, ServiceProductTable, AddProductsToServiceTable, BenefitCalculatorProductSelectionDayCare, BenefitCalculatorProductSelectionSchoolCare }
})
export default class ServiceProducts extends Vue {
  productService: IProductService = container.get<IProductService>(SERVICE_IDENTIFIERS.IProductService)
  serviceService: IServiceService = container.get<IServiceService>(SERVICE_IDENTIFIERS.IServiceService)
  authService: IAuthService = container.get<IAuthService>(SERVICE_IDENTIFIERS.IAuthService)

  @Prop({ required: true })
  public serviceId!: string

  @Ref('partou-data-table')
  partouDataTable!: PartouDataTable<ServiceProduct>

  items: Partial<ServiceProduct>[] = []
  activatedProducts: Partial<ServiceProduct>[] = []

  expandedItem: Partial<ServiceProduct> = {}
  isLoading = false
  showAddProductDialog = false
  totalCount = 0
  serviceName = ''
  serviceKind = ''
  serviceIsPublished = false
  serviceIsBookable = false
  serviceIsPublishedForChildBenefitCalculator = false
  displayServiceVarietyFilter = false
  categoryOptions: CategoryOption[] = [{ name: this.$t('services.products.table.category.AllWeeks').toString(), value: 'AllWeeks' }, { name: this.$t('services.products.table.category.SchoolWeeks').toString(), value: 'SchoolWeeks' }]
  serviceVarietyOptions: string[] = [ServiceVarietyName.NSO, ServiceVarietyName.VSO]

  tableOptions = this.getDefaultTableOptions()

  mounted () : void {
    this.getTableDataAsync()
  }

  getDefaultTableOptions () : ServiceProductTableOptions {
    return {
      sortBy: ['product.name', 'product.category', 'product.serviceVariety'],
      sortDesc: [false, false, false],
      page: 1,
      itemsPerPage: 50,
      itemsPerPageOptions: [5, 10, 25, 50, 100],
      filters: {}
    }
  }

  @Watch('serviceId')
  async getTableDataAsync () : Promise<void> {
    await this.getServiceByIdAsync(this.serviceId)
    this.tableOptions = this.getDefaultTableOptions()
    this.onTableOptionsChangedAsync(this.tableOptions)
    await this.getActivatedProducts()
  }

  async addBookableProductsConfirmedAsync () : Promise<void> {
    await this.onTableOptionsChangedAsync(this.tableOptions)
    await this.getActivatedProducts()
  }

  onAddBookableProductsClicked () : void {
    this.showAddProductDialog = true
  }

  async getActivatedProducts () : Promise<void> {
    const activatedResult = await this.productService.getActivatedServiceProductsAsync(this.serviceId)
    this.activatedProducts = activatedResult.items
  }

  get canManageProducts () : boolean {
    return this.authService.hasPermission([Permission.ManageProduct])
  }

  async getServiceByIdAsync (id: string) : Promise<void> {
    const service = await this.serviceService.getOneAsync(id)
    this.displayServiceVarietyFilter = service.kind !== ServiceKind.DayCare
    this.serviceName = service.name
    this.serviceKind = service.kind
    this.serviceIsPublished = service.isPublished
    this.serviceIsBookable = service.isBookable
    this.serviceIsPublishedForChildBenefitCalculator = service.isPublishedForChildBenefitCalculator
  }

  @Debounce(400)
  async onTableOptionsChangedAsync (tableOptions : ServiceProductTableOptions) : Promise<void> {
    this.isLoading = true
    const result = await this.productService.getActivatedServiceProductsAsync(this.serviceId, tableOptions)
    this.items = result.items
    this.totalCount = result.totalCount
    this.isLoading = false
  }

  async onExpandedChangedAsync (items : Partial<ServiceProduct>[]) : Promise<void> {
    if (items?.[0]?.productId) {
      this.isLoading = true
      this.expandedItem = await this.productService.getSingleServiceProductAsync(this.serviceId, items[0].productId)
      this.isLoading = false
    }
  }

  async onSelectedCalculatorProductChanged (disableCalculationTool: boolean) {
    if (disableCalculationTool) {
      await this.serviceService.updateServiceIsPublishedAsync(this.serviceId, this.serviceIsPublished, false, this.serviceIsBookable)
    }

    await this.onTableOptionsChangedAsync(this.tableOptions)
  }
}

</script>

<style lang="scss" scoped>
@import '@/styles/variables/colours.scss';

.card {
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.25));

  .v-card__text {
    padding: 0;
  }
}

.products-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .v-btn {
    text-transform: none !important;
    box-shadow: none;
  }

  .add-bookable-products {
    color: $partou-primary-white;
    background-color: $partou-primary-salmon !important;
  }
}
</style>
