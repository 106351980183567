// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMiIgd2lkdGg9IjEiPjxwYXRoIHN0eWxlPSJzdHJva2U6I2YyZTllMjtzdHJva2Utd2lkdGg6MiIgZD0iTTAgMWgxIi8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stepper-component{width:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-x;background-position-y:36px}.step .step-content{width:80px;overflow:visible}.step .step-content .step-background-container{background-color:transparent!important}.step .step-content .step-background-container .step-background-icon{width:72px!important;height:72px!important}.step .inner-step-text.current{margin-top:-44px;color:#fff!important}.step .inner-step-text.completed{margin-top:-48px}.step .inner-step-text{position:absolute;width:80px;text-align:center;margin-top:-44px;z-index:6}.step .step-label.completed{margin-top:14px}.step .step-label{margin-top:0}.z0{z-index:0;left:0}.z1{z-index:1;left:0}.z2{z-index:2;left:0}.line{flex:1 1;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:100%;height:86px;background-repeat:repeat-x;background-position-y:36px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
