import { GetSchoolOfferCountBySchoolIdDocument, GetSchoolOfferCountBySchoolIdQuery, GetSchoolOfferCountBySchoolIdQueryVariables, GetSchoolsByServiceIdDocument, GetSchoolsByServiceIdQuery, GetSchoolsByServiceIdQueryVariables, School, SearchSchoolByLocalityAndNameDocument, SearchSchoolByLocalityAndNameQuery, SearchSchoolByLocalityAndNameQueryVariables } from '@/models'
import Permission from '@/models/enums/Permission'
import { ApolloClient } from 'apollo-client'
import { inject, injectable } from 'inversify'
import { getSchoolByLocalityAndNamePayload, getSchoolOfferCountBySchoolIdPayload, ISchoolService } from './ISchoolService'

@injectable()
export default class SchoolService implements ISchoolService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  async getSchoolByLocalityAndName (input: getSchoolByLocalityAndNamePayload): Promise<School[]> {
    const locationInput = `%${input.locationInput}%`
    const nameInput = `%${input.nameInput}%`

    const variables: SearchSchoolByLocalityAndNameQueryVariables = { locationInput, nameInput }
    const result = await this.apollo.query<SearchSchoolByLocalityAndNameQuery>({
      query: SearchSchoolByLocalityAndNameDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })
    return result.data.school as School[]
  }

  async getSchoolServiceCountBySchoolId (input: getSchoolOfferCountBySchoolIdPayload): Promise<number> {
    const variables: GetSchoolOfferCountBySchoolIdQueryVariables = { schoolId: input.schoolId }
    const result = await this.apollo.query<GetSchoolOfferCountBySchoolIdQuery>({
      query: GetSchoolOfferCountBySchoolIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.GetAnalysis } }
    })

    return result.data.school_service_aggregate.aggregate?.count as number
  }

  async getSchoolsByServiceIdAsync (serviceId: string): Promise<Array<School>> {
    const variables: GetSchoolsByServiceIdQueryVariables = { serviceId }
    const result = await this.apollo.query<GetSchoolsByServiceIdQuery>({
      query: GetSchoolsByServiceIdDocument,
      variables,
      context: { headers: { 'X-Hasura-Role': Permission.PublishService } }
    })

    const schools = [] as School[]
    result.data?.school_service.forEach((schoolService: any) => { // eslint-disable-line
      schools.push(schoolService.school)
    })
    return schools
  }
}
