<template>
  <div ref="waiting-list-proposition-results" id="waiting-list-proposition-results">
    <div v-for="(waitingListPropositionAnalysisResult, index) in waitingListPropositionAnalysisResults" :key="index">
       <WaitingListPropositionAnalysisResultCard class="mb-6" :waitingListPropositionAnalysisResult="waitingListPropositionAnalysisResult"/>
    </div>
  </div>
</template>

<script lang="ts">
import PartouCard from '@/components/PartouComponents/PartouCard.vue'
import WaitingListPropositionAnalysisResult from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'
import WaitingListPropositionAnalysisResultCard from './WaitingListPropositionAnalysisResultCard.vue'

@Component({
  components: { PartouCard, WaitingListPropositionAnalysisResultCard }
})
export default class WaitingListPropositionAnalysisResultsView extends Vue {
  @Prop({ required: true })
  waitingListPropositionAnalysisResults!: WaitingListPropositionAnalysisResult[]
}
</script>
