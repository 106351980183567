import { GroupPincode, InsertAndOverwriteGroupPinCodeMutation, InsertAndOverwriteGroupPinCodeDocument } from '@/models'
import { parseNumberToDayOfWeek } from '@/models/enums/DayOfWeek'
import { startOfUtcDay } from '@/utils/dateUtils'
import ApolloClient from 'apollo-client'
import { inject, injectable } from 'inversify'
import moment from 'moment'

import { IGroupPincodeService } from './IGroupPincodeService'
import Permission from '@/models/enums/Permission'

@injectable()
export default class GroupPincodeService implements IGroupPincodeService {
  private apollo: ApolloClient<unknown>

  constructor (@inject(ApolloClient) apollo: ApolloClient<unknown>) {
    this.apollo = apollo
  }

  // Pincodes can either be totally new (no id) or a changed pincode from the past (existing id)
  // For example: current pincode is valid from 01-01-2020 and on 08-01-2020 this pincode is overwritten to another value
  // The current pincode will have to be updated to be valid until 08-01-2020 and a new pincode is added with valid from 08-01-2020
  // Future pincodes will be deleted in the insert-query
  async persistGroupPincodesAsync (date: Date, toReplace: GroupPincode[]) : Promise<boolean> {
    // Set time to beginning of utc day to have correct date in db
    const utcDate = startOfUtcDay(date)

    const validPinCodes = toReplace.filter(pinCode => pinCode.pincode)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any[] = validPinCodes.map(pinCode => ({
      groupId: pinCode.groupId,
      pinCode: JSON.stringify(pinCode.pincode), // Ensure pinCode is serialized as JSON
      partOfDay: pinCode.partOfDay,
      dayOfWeek: parseNumberToDayOfWeek(moment(date).isoWeekday()), // Ensure dayOfWeek is correctly parsed
      validFrom: utcDate.toISOString().split('T')[0], // Format date as YYYY-MM-DD
      validUntil: null
    }))

    try {
      const result = await this.apollo.mutate<InsertAndOverwriteGroupPinCodeMutation>({
        mutation: InsertAndOverwriteGroupPinCodeDocument,
        variables: { pinCodesToInsertAndOverwrite: data },
        context: { headers: { 'X-Hasura-Role': Permission.ManageOffer } }
      })
      if (!result || result.errors || result.data?.insertAndOverwriteGroupPinCode?.message) {
        return false
      }

      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
}
