<template>
  <v-expansion-panels multiple>
    <v-expansion-panel class="day-combination-row" v-for="(dayCombination, index) in dayCombinations" :key="`${index}-${dayCombination}`">
      <v-expansion-panel-header class="day-combination-header">
        <div class="days">
          <span v-for="(serviceVarietyName, index) in getServiceVarietiesForDayCombination(dayCombination)" :key="`${index}-${dayCombination}-${serviceVarietyName}`">
            {{getDaysForServiceVarietyOfCombination(serviceVarietyName, dayCombination)}}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="day-combination-content">
        <v-row class="header-row">
          <v-col>{{ $t('waitingListPropositionAnalysis.childName') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.prioPoints') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.holePoints') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.impact') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.value') }}</v-col>
        </v-row>
        <v-row class="data-row" v-for="(childDetail, index) in dayCombination.childDetails" :key="`${index}-${childDetail}`">
          <v-col>{{childDetail.childFullName}}</v-col>
          <v-col>{{childDetail.prioPoints}}</v-col>
          <v-col>{{childDetail.holePoints}}</v-col>
          <v-col>{{childDetail.impact}}</v-col>
          <v-col>{{childDetail.value}}</v-col>
        </v-row>
      </v-expansion-panel-content >
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import { ServiceVarietyName } from '@/models'
import { DayCombination } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class WaitingListPropositionAnalysisResultDayCombinationsTable extends Vue {
  @Prop({ required: true })
  dayCombinations!: DayCombination[]

  getServiceVarietiesForDayCombination (dayCombination: DayCombination) : ServiceVarietyName[] {
    const serviceVarieties = dayCombination.dayOfWeekWithServiceVarieties
      .filter((v, i, a) => a.findIndex(t => (t.serviceVarietyName === v.serviceVarietyName)) === i)
      .map(x => x.serviceVarietyName)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    return serviceVarieties
  }

  getDaysForServiceVarietyOfCombination (serviceVarietyName: ServiceVarietyName, dayCombination: DayCombination) : string {
    const daysForServiceVariety = dayCombination.dayOfWeekWithServiceVarieties.filter(y => y.serviceVarietyName === serviceVarietyName)
    return `${serviceVarietyName}: ` + daysForServiceVariety.map(x => this.$t(`days.${x.dayOfWeek.toLocaleLowerCase()}`)).join(', ')
  }
}
</script>

<style lang="scss" scoped>

.day-combination-row {
  .day-combination-header {
    .days {
      display:flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .day-combination-content {
    box-shadow: inset 1px 0px 15px 1px rgba(0, 0, 0, 0.20);

    .header-row {
      font-weight: bold;
      height: 20px;
      font-size: 14px;
    }

    .data-row {
      height: 20px;
      font-size: 14px;
    }
  }
}
</style>
