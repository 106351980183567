<template>
  <v-expansion-panel class="possible-proposition-row">
    <v-expansion-panel-header :hide-actions="true" :readonly="true" class="possible-proposition-header" :class="{ 'chosen-proposition': chosenProposition }">
      <div class="details">
        <v-row class="header-row">
          <v-col>{{ $t('waitingListPropositionAnalysis.childName') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.daysOfWeek') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.prioPoints') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.holePoints') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.impact') }}</v-col>
          <v-col>{{ $t('waitingListPropositionAnalysis.value') }}</v-col>
        </v-row>
        <v-row class="data-row" v-for="(item, index) in possibleProposition.items" :key="`${index}-${item}`">
          <v-col>{{item.childFullName}}</v-col>
          <v-col>{{getDaysForPossiblePropositionItem(item)}}</v-col>
          <v-col>{{item.prioPoints}}</v-col>
          <v-col>{{item.holePoints}}</v-col>
          <v-col>{{item.impact}}</v-col>
          <v-col>{{item.value}}</v-col>
        </v-row>
        <v-row class="data-row">
          <v-col>{{ $t('waitingListPropositionAnalysis.total') }}</v-col>
          <v-col></v-col>
          <v-col>{{possibleProposition.totalPrioPoints}}</v-col>
          <v-col>{{possibleProposition.totalHolePoints}}</v-col>
          <v-col>{{possibleProposition.totalImpact}}</v-col>
          <v-col>{{possibleProposition.totalValue}}</v-col>
        </v-row>
      </div>
    </v-expansion-panel-header>
  </v-expansion-panel>
</template>

<script lang="ts">
import DaysCoverage from '@/components/DaysCoverage/DaysCoverage.vue'
import { PossibleProposition, PossiblePropositionItem } from '@/models/WaitingListPropositionAnalysisResult'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: { DaysCoverage }
})
export default class WaitingListPropositionAnalysisResultPossiblePropositionTable extends Vue {
  @Prop({ required: true })
  possibleProposition!: PossibleProposition

  @Prop({ required: true })
  chosenProposition!: boolean

  getDaysForPossiblePropositionItem (possiblePropositionItem: PossiblePropositionItem) : string {
    return possiblePropositionItem.dayOfWeekWithServiceVarieties
      .map(x => `${this.$t(`days.${x.dayOfWeek.toLocaleLowerCase()}`)} (${x.serviceVarietyName})`)
      .join(', ')
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables/variables.scss';

.possible-proposition-row {
  width: 100%;

  .possible-proposition-header {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 4px 10px 4px 10px;

    &.chosen-proposition {
      border: 2px solid $partou-green;
    }

    .details {
      .col {
        padding: 0 10px;
      }

      .header-row {
        font-weight: bold;
        height: 20px;
        font-size: 14px;
      }

      .data-row {
        font-size: 14px;
      }
    }
  }
}
</style>
