import Vue from 'vue'
import i18n from '@/plugins/i18n'
import { parseNumberToWaitingListPlacePriority } from '@/models/enums/WaitingListPlacePriority'

const waitingListPlacePriorityFilter = function (priority: number): string {
  const enumValue = parseNumberToWaitingListPlacePriority(priority)
  const lowerCased = enumValue.toString().toLowerCase()
  return i18n.t(`services.waitingList.waitingListPlacePriority.${lowerCased}`)?.toString()
}

Vue.filter('waitingListPlacePriority', waitingListPlacePriorityFilter)
export default waitingListPlacePriorityFilter
