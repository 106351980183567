import { injectable } from 'inversify'

@injectable()
export default class RetryUtils {
  static async callAsyncWithRetry (callback : () => Promise<void>, retries = 3, timeout = 1000) : Promise<void> {
    try {
      await callback()
    } catch (error) {
      retries--

      if (retries <= 0) {
        throw error
      }

      await new Promise(resolve => setTimeout(resolve, timeout))
      await this.callAsyncWithRetry(callback, retries)
    }
  }
}
